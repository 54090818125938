import React from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { store } from './index';
import 'styles/theme.scss';
import LayoutComponent from 'components/Layout';
import { loadUserFromStorage, refreshAuthToken } from 'services/userService';
import { getConfigFromApi } from 'services/apiService';
import Loader from './components/Loader/Loader';
import { getCurrentUser, loginSuccessfull } from 'actions/auth';
import holdsActions from 'actions/LegalHold/LegalHoldListActions';
import 'styles/themeStyles.scss';
import closeIcon from './images/icons/close.svg';
import { withRouter } from 'utils/withRouter';

const CloseButton = ({ closeToast }) => (
  <img src={closeIcon} onClick={closeToast} alt={'close'} width={'15px'} height={'15px'} />
);

class App extends React.Component {
  constructor() {
    super();
    this.refreshToken();
  }

  refreshToken = async () => {
    try {
      const newToken = refreshAuthToken();
      this.refreshTokenTimeout = setTimeout(this.refreshToken, 5 * 60 * 1000);
    } catch (error) {
      console.error('Error refreshing token:', error);
    }
  };

  componentDidMount() {
    async function getUser() {
      await loadUserFromStorage(store);
      let configData = await getConfigFromApi();
      let currentUser = configData.currentUser;
      let permissions = configData.auth.grantedPolicies;
      store.dispatch(getCurrentUser(currentUser, permissions));
    }
    getUser();
  }

  componentDidUpdate(prevProps) {
    const currentUser = this.props?.currentUser;
    if (prevProps.currentUser !== currentUser && currentUser?.userName) {
      this.props.dispatch(holdsActions.doGetRefreshInterval(currentUser?.userName));
    }
    if (currentUser?.isAuthenticated) {
      loginSuccessfull();
    }
  }

  render() {
    return (
      <>
        <ToastContainer autoClose={5000} hideProgressBar closeButton={<CloseButton />} />
        {this.props.currentUser ? <LayoutComponent navigate={this.props.navigate} /> : <Loader />}
      </>
    );
  }
}

function mapStateToProps(store) {
  return {
    currentUser: store.auth.currentUser,
    permissions: store.auth.permissions
  };
}

export default withRouter(connect(mapStateToProps)(App));
