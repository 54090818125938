import React, { useEffect } from 'react';
import { signoutRedirectCallback } from '../../../services/userService';
import { useNavigate } from 'react-router-dom';
import Loader from 'components/Loader/Loader';

function SignoutOidc() {
  const navigate = useNavigate();
  useEffect(() => {
    async function signoutAsync() {
      await signoutRedirectCallback();
      navigate('/login');
    }
    signoutAsync();
  }, [navigate]);

  return <Loader />;
}

export default SignoutOidc;
