import React, { useState, useRef, useCallback } from 'react';
import Cron from 'react-js-cron';
import 'react-js-cron/dist/styles.css';
import './styles.css';

const CronGenerator = ({ onValueChange, disabled, defaultValue }) => {
  const inputRef = useRef(null);
  const [value, setValue] = useState(defaultValue);
  const customSetValue = useCallback(
    (newValue) => {
      setValue(newValue);
      inputRef.current && inputRef.current.setValue(newValue);
      onValueChange && onValueChange(newValue);
    },
    [inputRef, onValueChange]
  );
  const allowedPeriods = ['year', 'month', 'week', 'day', 'hour'];
  const [error, setError] = useState();

  return (
    <div>
      <Cron
        className='my-project-cron'
        value={value}
        disabled={disabled}
        setValue={customSetValue}
        allowedPeriods={allowedPeriods}
        setError={setError}
        leadingZero='always'
      />

      {error && <p style={{ marginTop: 20 }}>Error: {error ? error.description : 'undefined'}</p>}
    </div>
  );
};

export default CronGenerator;
