import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import s from 'components/Tooltip/Tooltip.module.scss';
import 'components/Tooltip/Tooltip.css';
import Loader from 'components/Loader/Loader';

export default function UserIdentitiesTooltip({ identitiesList, position, identityType, count }) {
  const tooltipStyle = {
    position: 'absolute',
    zIndex: 2000,
    padding: 16,
    top: position.top - 100,
    left: position.left + 20
  };
  return ReactDOM.createPortal(
    <div className={s.tooltip} style={tooltipStyle}>
      {identitiesList ? (
        <>
          <p className='fw-bold d-flex justify-content-left mb-2 fs-6'>
            {`${identityType} addresses`}
          </p>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '300px',
              maxHeight: '200px',
              overflowY: 'scroll',
              wordBreak: 'break-word'
            }}
          >
            {count > 0 ? (
              <>
                {identitiesList?.map((item) => {
                  return <p style={{ fontSize: '12.25px' }}>{item}</p>;
                })}
              </>
            ) : (
              <p>No data available!</p>
            )}
          </div>
        </>
      ) : (
        <Loader />
      )}
    </div>,
    document.querySelector('#modal-root')
  );
}
