import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import { signoutRedirect } from '../services/userService';
import { getTheme } from 'services/themeService';
import { store } from 'index';
import Loader from 'components/Loader/Loader';
import 'components/Layout/layoutStyles.css';

function PermissionDenied() {
  const currentUser = store.getState().auth.currentUser;
  const permissions = store.getState().auth.permissions;
  const [theme, setTheme] = useState('#333964');

  const handleGetTheme = async () => {
    if (currentUser) {
      const selectedTheme = await getTheme(currentUser?.userName);
      setTheme(selectedTheme?.settingValue);
    }
  };

  useEffect(() => {
    if (!permissions['LegalUI.Credential.KeyManagement']) {
      handleGetTheme();
    }
  }, [currentUser]);

  const bodyStyle = {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  };
  return (
    <>
      {currentUser ? (
        <Modal size='lg' isOpen={true} className={`themeStyle${theme?.replace('#', '')}`}>
          <ModalBody style={{ height: '400px' }}>
            <div style={bodyStyle}>
              <h1>User does not have access!</h1>
              <div style={{ marginTop: '20px' }}>
                <Button
                  color='danger'
                  size='md'
                  onClick={() => {
                    signoutRedirect();
                  }}
                >
                  Go Back
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default PermissionDenied;
