import React from "react";
import ReactDOM from "react-dom";
import s from "components/Tooltip/Tooltip.module.scss";
import "components/Tooltip/Tooltip.css";
import styles from "./Wizard.module.scss";

export default function TemporaryCustodiansTooltip({
  selectedCustodians,
  position,
}) {
  const tooltipStyle = {
    position: "absolute",
    zIndex: 2000,
    padding: 16,
    minWidth: "300px",
    maxHeight: "300px",
    top: position.top - 100,
    left: position.left + 20,
  };

  return ReactDOM.createPortal(
    <div className={s.tooltip} style={tooltipStyle}>
      {selectedCustodians?.length > 0 ? (
        <div className="w-100">
          <p className="fw-semi-bold">Current Selection:</p>
          <div className={styles.temporarySelectedContainer}>
            {selectedCustodians?.map((item) => (
              <div className={styles.temporarySelected}>
                <p className="mb-0 w-50 text-left" key={item?.custodianId}>
                  {item.displayName}
                </p>
                <p className="mb-0 w-50 text-left">{item.department}</p>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <p className="fw-semi-bold">There are no selected custodians!</p>
      )}
    </div>,
    document.querySelector("#modal-root")
  );
}
