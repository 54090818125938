import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import 'styles/theme.scss';
import ErrorPage from 'pages/error';
import DocumentationLayoutComponent from 'documentation/DocumentationLayout';
import Login from 'pages/auth/login';
import SigninOidc from 'pages/auth/login/SigninOidc';
import SignoutOidc from 'pages/auth/login/SignoutOidc';
import PermissionDenied from 'routes/PermissionDenied';
import NoAccessPage from 'routes/NoAccessPage';
import 'styles/themeStyles.scss';
import 'styles/themeStyles.scss';
import LegalCaseFormPage from 'components/CRUD/LegalCase/form/LegalCaseFormPage';
import LegalCaseListPage from 'components/CRUD/LegalCase/list/LegalCaseListPage';
import LegalCaseViewPage from 'components/CRUD/LegalCase/view/LegalCaseViewPage';
import LegalHoldFormPage from 'components/CRUD/LegalHold/form/LegalHoldFormPage';
import LegalHoldListPage from 'components/CRUD/LegalHold/list/LegalHoldListPage';
import LegalHoldViewPage from 'components/CRUD/LegalHold/view/LegalHoldViewPage';
import SearchViewPage from 'components/CRUD/Search/view/SearchViewPage';
import SearchDetailsPage from 'components/CRUD/Search/details/SearchDetailsPage';
import Dashboard from 'pages/dashboard/Dashboard';
import SettingsPage from 'pages/settings/SettingsPage';
import CredentialListPage from 'pages/credentials/CredentialListPage';
import ExportPage from 'components/CRUD/Export/list/ExportPage';
import ExportDetailsPage from 'components/CRUD/Export/details/ExportDetailsPage';
import ActivityPage from 'components/CRUD/ActivityPage/ActivityPage';
import AuditPage from 'components/CRUD/AuditLogs/AuditPage';
import ReportsPage from 'components/CRUD/Reports/ReportsPage';
import ClosedLegalCaseViewPage from 'components/CRUD/LegalCase/closedLegalCaseView/ClosedLegalCaseViewPage';
import RsmfViewerPage from 'components/CRUD/RsmfViewer/RsmfViewerPage';
import ClosedPage from 'components/CRUD/LegalCase/closedLegalCasesList/ClosedPage';
import s from 'components/Layout/Layout.module.scss';
import AppLayout from './AppLayout';

const routesConfig = (props) => (
  <div className={s.container}>
    <Routes>
      <Route path='/documentation' element={<DocumentationLayoutComponent />} />
      <Route path='/' element={<Navigate to='/app/Dashboard' replace />} />
      <Route path='/login' exact element={<Login />} />
      <Route path='/signin-oidc' element={<SigninOidc />} />
      <Route path='/signout-oidc' element={<SignoutOidc />} />
      <Route path='/denied' exact element={<PermissionDenied />} />
      <Route path='/no-access' exact element={<NoAccessPage />} />
      <Route path='/error' exact element={<ErrorPage />} />
      <Route path='*' element={<Navigate to='/error' replace />} />
      <Route path='/admin' element={<AppLayout />}>
        <Route index element={<Navigate to='/admin/credentials' replace />} />
        <Route
          path='/admin/credentials'
          element={
            props?.permissions['LegalUI.Credential'] ? <CredentialListPage /> : <NoAccessPage />
          }
        />
      </Route>

      {props.permissions['LegalUI.Credential'] &&
      props.permissions['LegalUI.Credential.KeyManagement'] &&
      Object.keys(props.permissions).length === 2 ? (
        <Navigate to='/no-access' replace />
      ) : (
        <Route path='/app' element={<AppLayout />}>
          <Route index element={<Navigate to='/error' replace />} />
          <Route path='/app/Dashboard' element={<Dashboard />} />
          <Route path='/app/settings' element={<SettingsPage />} />
          <Route path='/app/Export' element={<ExportPage />} />
          <Route path='/app/Export/:id' element={<ExportDetailsPage />} />
          <Route path='/app/CaseManager' element={<ClosedPage />} />
          <Route path='/app/Activity' element={<ActivityPage />} />
          <Route path='/app/Audit' element={<AuditPage />} />
          <Route path='/app/Reports' element={<ReportsPage />} />
          <Route path='/app/Dashboard' element={<Dashboard />} />
          <Route path='/app/LegalCase' element={<LegalCaseListPage />} />
          <Route path='/app/LegalCase/new' element={<LegalCaseFormPage />} />
          <Route path='/app/LegalCase/:id/edit' element={<LegalCaseFormPage />} />
          <Route path='/app/LegalCase/:id' element={<LegalCaseViewPage />} />
          <Route path='/app/ClosedLegalCase/:id' element={<ClosedLegalCaseViewPage />} />
          <Route path='/app/LegalHold' element={<LegalHoldListPage />} />
          <Route path='/app/LegalHold/:id/edit' element={<LegalHoldFormPage />} />
          <Route path='/app/LegalHold/:id' element={<LegalHoldViewPage />} />
          <Route path='/app/search/:id/:caseId' element={<SearchViewPage />} />
          <Route path='/app/search/:id' element={<SearchDetailsPage />} />
          <Route path='/app/rsmfViewer' element={<RsmfViewerPage />} />
        </Route>
      )}
    </Routes>
  </div>
);
export default routesConfig;
