import { ErrorMessage, Formik } from 'formik';
import React, { Component } from 'react';
import Loader from 'components/Loader';
import InputFormItem from 'components/FormItems/items/InputFormItem';
import SelectFormItem from 'components/FormItems/items/SelectFormItem';
import TextAreaFormItem from 'components/FormItems/items/TextAreaFormItem';
import searchFields from 'components/CRUD/Search/searchFields';
import IniValues from 'components/FormItems/iniValues';
import PreparedValues from 'components/FormItems/preparedValues';
import 'react-awesome-query-builder/lib/css/styles.css';
import 'react-awesome-query-builder/lib/css/compact_styles.css';
import LegalHoldAutocompleteFormItem from '../../LegalHold/autocomplete/LegalHoldAutocompleteFormItem';
import { ButtonGroup, Button, Row, Col, Nav, NavItem, NavLink, Progress } from 'reactstrap';
import s from 'components/CRUD/LegalHold/form/Wizard.module.scss';
import styles from 'components/CRUD/Search/form/SearchForm.module.scss';
import * as Yup from 'yup';
import classnames from 'classnames';
import plusIcon from 'images/icons/plus.svg';
import formActions from 'actions/search/searchFormActions';
import trashIcon from 'images/icons/trash.svg';
import caretDown from 'images/icons/caret-down.svg';
import caretUp from 'images/icons/caret-up.svg';
import SearchFormTooltip from './SearchFormTooltip';
import { ImInfo } from 'react-icons/im';
const count = 3;
class SearchForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      builderVisible: false,
      queryStr: '',
      searchInBody: true,
      searchInAttachment: true,
      onlyMailsWithAttachments: false,
      searchInHeader: true,
      searchInSubject: true,
      currentStep: 1,
      progress: 33,
      selectedHolds: [],
      queryBuilder: '',
      statements: [
        {
          enableNotOperator: '',
          groupStatement: 'AND',
          collapsed: false,
          rules: [
            {
              inputString: '',
              inputOperator: 'AND'
            }
          ]
        }
      ],
      notOperator: '',
      statementOperators: [],
      allRules: [],
      viaBuilder: false,
      viaText: true,
      enableFrom: false,
      enableToRecipient: false,
      enableCcRecipients: false,
      enableBccRecipients: false,
      enableMessageId: false,
      enableRecipients: false,
      isSplitField: false,
      position: {
        top: 0,
        left: 0
      },
      message:
        'Searching through header will search by all properties of header including subject.',
      showTooltip: false
    };
    this.nextStep = this.nextStep.bind(this);
    this.previousStep = this.previousStep.bind(this);
  }

  nextStep = () => {
    let currentStep = this.state.currentStep;
    if (currentStep >= count) {
      currentStep = count;
    } else {
      currentStep += 1;
    }
    this.setState({
      currentStep,
      progress: (100 / count) * currentStep
    });
  };

  previousStep = () => {
    let currentStep = this.state.currentStep;
    if (currentStep <= 1) {
      currentStep = 1;
    } else {
      currentStep -= 1;
    }

    this.setState({
      currentStep,
      progress: (100 / count) * currentStep
    });
  };

  handleQueryString = () => {
    const { statements } = this.state;
    let array = [];
    let operators = [];
    let groupStatement = '';
    statements.map((statement, index) => {
      let rule = [];
      statement?.rules.map((item, i) => {
        if (item.inputString !== '') {
          rule.push(item.inputString);
          rule.push(item.inputOperator);
        }
      });
      groupStatement = statement?.groupStatement;
      rule.pop();
      array.push(rule);
      if (index > 0 && array.join('')?.length > 0) {
        operators.push(groupStatement);
      }
    });
    let parsedArray = [];
    array.map((item) => {
      let parsedRule = this.parse(item);
      parsedArray.push(parsedRule);
    });
    this.setState({
      allRules: parsedArray,
      statementOperators: operators
    });
  };

  handleAddNewRule = (index) => {
    const { statements } = this.state;
    const newRule = {
      inputString: '',
      inputOperator: 'AND'
    };
    statements[index].rules.push(newRule);
    this.setState({ statements });
  };

  handleAddNewStatement = (index) => {
    this.setState({
      statements: [
        ...this.state.statements,
        {
          enableNotOperator: '',
          groupStatement: 'AND',
          collapsed: false,
          rules: [
            {
              inputString: '',
              inputOperator: 'AND'
            }
          ]
        }
      ]
    });
  };

  handleCollapseGroup = (index) => {
    const { statements } = this.state;
    statements[index].collapsed = !statements[index].collapsed;
    this.setState({
      statements
    });
  };

  handleRemoveRule = (index, i, form) => {
    if (i === 0 && this.state.statements[index].rules?.length === 1) {
      this.setState((prevState) => {
        const updatedStatements = [...prevState.statements];
        let ruleToUpdate = updatedStatements[index].rules[i];
        ruleToUpdate.inputString = '';
        return { statements: updatedStatements };
      });
      if (index === 0 && i === 0) {
        form.setFieldValue('queryBuilder', '');
        form.setFieldTouched('queryBuilder', true);
      }
    } else {
      this.setState((prevState) => {
        const updatedStatements = [...prevState.statements];
        const updatedRules = [...prevState.statements[index].rules];
        updatedRules.splice(i, 1);
        updatedStatements[index].rules = updatedRules;
        return { statements: updatedStatements };
      });
    }
  };

  handleRemoveStatement = (index, form) => {
    this.setState((prevState) => {
      const updatedStatements = [...prevState.statements];
      updatedStatements.splice(index, 1);
      return {
        statements: updatedStatements
      };
    });
    form.setFieldValue('queryBuilder', this.state.queryBuilder);
  };

  toggleEnableNotOperator = (index, event) => {
    const { statements } = this.state;
    const value = event.target.value;
    statements[index].enableNotOperator = value;
    this.setState({
      statements
    });
    if (statements[0].enableNotOperator === 'NOT') {
      this.setState({
        notOperator: 'NOT'
      });
    } else {
      this.setState({
        notOperator: ''
      });
    }
    this.handleQueryString();
  };

  toggleBuilder = (form) => {
    this.setState({
      queryBuilder: '',
      statements: [
        {
          enableNotOperator: '',
          groupStatement: 'AND',
          collapsed: false,
          rules: [
            {
              inputString: '',
              inputOperator: 'AND'
            }
          ]
        }
      ],
      viaBuilder: !this.state.builderVisible,
      viaText: this.state.builderVisible
    });
    form.setFieldValue('queryBuilder', '');
    form.setFieldValue('queryString', '');
    form.setFieldValue('viaBuilder', !this.state.builderVisible);
    form.setFieldValue('viaText', this.state.builderVisible);
  };

  iniValues = () => {
    const record = {
      ...this.props.record,
      searchInBody: this.state.searchInBody,
      searchInAttachment: this.state.searchInAttachment,
      onlyMailsWithAttachments: this.state.onlyMailsWithAttachments,
      searchInHeader: this.state.searchInHeader,
      searchInSubject: this.state.searchInHeader
        ? this.state.searchInHeader
        : this.state.searchInSubject,
      viaBuilder: this.state.viaBuilder,
      viaText: this.state.viaText,
      queryString: this.props.record?.usedBuilder
        ? undefined
        : this.props.record?.queryString?.query,
      subject: this.props.record?.queryString?.subject,
      to: this.props.record?.queryString?.to,
      cc: this.props.record?.queryString?.cc,
      bcc: this.props.record?.queryString?.bcc,
      messageId: this.props.record?.queryString?.messageId,
      from: this.props.record?.queryString?.from,
      recipients: this.props.record?.queryString?.recipients,
      isSplitField: this.props.record?.queryString?.isSplitField,
      legalHoldIds: undefined
    };
    return IniValues(searchFields, Object.assign({}, record));
  };

  parse = (input) => {
    const words = input;
    const ouptut = [];
    let swaps = 0;
    for (let i = 0; i < words.length; i) {
      const lhs = words[i + 0];
      const operator = words[i + 1];
      const rhs = words[i + 2];
      const nextOperator = words[i + 3];
      ouptut.push(lhs);
      ouptut.push(operator);
      if (operator != nextOperator && nextOperator != undefined) {
        if (swaps % 2 == 0) {
          ouptut.push('(');
        } else {
          ouptut.push(rhs);
          ouptut.push(')');
          ouptut.push(nextOperator);
          i += 2;
        }
        swaps++;
      }
      i += 2;
    }
    if (swaps % 2 == 1) {
      ouptut.push(')');
    }
    return ouptut.join(' ');
  };

  hasMatchingBrackets(input) {
    const stack = [];
    const openingBrackets = ['('];
    const closingBrackets = [')'];

    for (let i = 0; i < input.length; i++) {
      const bracket = input[i];
      if (openingBrackets.includes(bracket)) {
        stack.push(bracket);
      } else if (closingBrackets.includes(bracket)) {
        const openingBracket = openingBrackets[closingBrackets.indexOf(bracket)];
        if (stack.length === 0 || stack.pop() !== openingBracket) {
          return false;
        }
      }
    }
    return stack.length === 0;
  }

  containsNonWhitespace(string) {
    return /\S/.test(string);
  }

  checkStringValidity(str) {
    const stack = [];
    const openingBrackets = ['('];
    const closingBrackets = [')'];
    const openingQuotes = ['"', "'", '`'];
    const closingQuotes = ['"', "'", '`'];

    for (let i = 0; i < str.length; i++) {
      const char = str[i];

      if (openingBrackets.includes(char)) {
        stack.push(char);
      } else if (closingBrackets.includes(char)) {
        const openingBracket = stack.pop();
        if (
          !openingBracket ||
          openingBrackets.indexOf(openingBracket) !== closingBrackets.indexOf(char)
        ) {
          return false;
        }
      } else if (openingQuotes.includes(char)) {
        if (stack[stack.length - 1] === char) {
          stack.pop();
        } else {
          stack.push(char);
        }
      } else if (closingQuotes.includes(char)) {
        if (stack[stack.length - 1] === char) {
          stack.pop();
        } else {
          stack.push(char);
        }
      }
    }

    return stack.length === 0;
  }

  hasQuotesAndBrackets = (str) => {
    let hasQuotes = false;
    let hasBrackets = false;
    for (let i = 0; i < str.length; i++) {
      const char = str[i];
      if (char === '"' || char === "'") {
        hasQuotes = true;
      }
      if (char === '(' || char === ')') {
        hasBrackets = true;
      }
    }
    return hasQuotes && hasBrackets;
  };

  componentDidMount() {
    if (this.props.isDiscarded === true) {
      let query = localStorage.getItem(
        `${this.props.contentSearch?.name}-${this.props.legalCaseId}`
      );
      query = JSON.parse(query);
      this.setState({
        searchInBody: this.props?.record?.searchInBody,
        searchInAttachment: this.props?.record?.searchInAttachment,
        onlyMailsWithAttachments: this.props?.record?.onlyMailsWithAttachments,
        searchInHeader: this.props?.record?.searchInHeader,
        searchInSubject: this.props?.record?.searchInHeader
          ? true
          : this.props?.record?.searchInSubject,
        viaBuilder: this.props?.record?.usedBuilder,
        viaText: !this.props?.record?.usedBuilder,
        queryBuilder: this.props.record?.usedBuilder ? this.props.record?.queryString?.query : '',
        builderVisible: this.props.record?.usedBuilder ? true : false,
        statements: this.props?.record?.usedBuilder ? query : this.state.statements,
        enableToRecipient:
          this.props.record?.queryString?.to && this.props.record?.queryString?.to !== ''
            ? true
            : false,
        enableFrom:
          this.props.record?.queryString?.from && this.props.record?.queryString?.from !== ''
            ? true
            : false,
        enableCcRecipients:
          this.props.record?.queryString?.cc && this.props.record?.queryString?.cc !== ''
            ? true
            : false,
        enableBccRecipients:
          this.props.record?.queryString?.bcc && this.props.record?.queryString?.bcc !== ''
            ? true
            : false,
        enableMessageId:
          this.props.record?.queryString?.messageId &&
          this.props.record?.queryString?.messageId !== ''
            ? true
            : false,
        enableRecipients:
          this.props.record?.queryString?.recipients &&
          this.props.record?.queryString?.recipients !== ''
            ? true
            : false,
        isSplitField: this.props.record?.queryString?.isSplitField ? true : false
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.statements !== this.state.statements) {
      this.handleQueryString();
    }
    if (
      prevState.allRules !== this.state.allRules ||
      prevState.notOperator !== this.state.notOperator
    ) {
      const mergedArray = [];
      for (let i = 0; i < this.state.allRules?.length; i++) {
        if (i < this.state.allRules.length) {
          if (i === 0) {
            mergedArray.push(this.state.allRules[i]);
          } else if (this.state.allRules[i] !== '') {
            let formattedValue = '(' + this.state.allRules[i] + ')';
            formattedValue = `${formattedValue}`;
            mergedArray.push(formattedValue);
          }
        }
        if (i < this.state.statementOperators.length && this.state.allRules[i + 1] !== '') {
          mergedArray.push(this.state.statementOperators[i]);
        }
      }
      let finalBuilder = mergedArray.join(' ');
      let hasCharacters = this.containsNonWhitespace(finalBuilder);
      this.setState({
        queryBuilder: !hasCharacters ? '' : `${this.state.notOperator}(${finalBuilder})`
      });
    }
  }

  FormValidationSchema = [
    Yup.object().shape({
      name: Yup.string().trim().nullable(true).required('Search Name* is a required field'),
      legalHoldIds: Yup.array()
        .required('Legal Holds* is a required field')
        .test('empty-array', 'Legal Holds* is a required field', (legalHoldIds) => {
          if (legalHoldIds?.length !== 0) return true;
        })
    }),
    Yup.object().shape({
      viaText: Yup.boolean(),
      viaBuilder: Yup.boolean(),
      queryBuilder: Yup.string().when('viaBuilder', {
        is: true,
        then: () =>
          Yup.string()
            .trim()
            .nullable(true)
            .required('Query Builder* is a required field')
            .test(
              'valid-query',
              '*Every group should have at least one keyword',
              (queryBuilder) => {
                let hasEmptyGroup = this.handleEmptyGroupCheck(this.state.allRules);
                return !hasEmptyGroup;
              }
            )
      }),
      queryString: Yup.string().when('viaText', {
        is: true,
        then: () =>
          Yup.string()
            .trim()
            .nullable(true)
            .required('Query* is a required field')
            .test('valid-words', 'Query has mismatched quotes', (queryString) => {
              if (!queryString) return true;
              let countSingle = 0;
              let countDouble = 0;
              for (let i = 0; i < queryString.length; i++) {
                if (queryString[i] === '"') {
                  countDouble++;
                } else if (queryString[i] === "'") {
                  countSingle++;
                }
              }
              return countSingle % 2 === 0 && countDouble % 2 === 0;
            })
            .test('valid-words', 'Query has mismatched brackets', (queryString) => {
              if (!queryString) return true;
              let hasMatchingBrackets = this.hasMatchingBrackets(queryString);
              return hasMatchingBrackets;
            })
      })
    })
  ];

  fuzzynessOptions = (data) => {
    const arr = [];
    if (typeof data === 'string') {
      return data;
    }
    if (Array.isArray(data)) {
      for (let key in data) {
        const obj = {};
        obj.value = JSON.parse(data[key]);
        obj.label = data[key];
        arr.push(obj);
      }
    } else {
      for (let key in data) {
        const obj = {};
        obj.value = JSON.parse(key);
        obj.label = data[key];
        arr.push(obj);
      }
    }
    return arr;
  };

  validateSearchName = (value) => {
    if (!value) {
      return undefined;
    }
    let error;
    this.props?.searchNames?.forEach((item) => {
      let createdSearchName = item?.toLowerCase().trim();
      let newSearchName = value?.toLowerCase().trim();
      if (createdSearchName === newSearchName) error = 'This content search name already exists';
    });
    return error;
  };

  handleSubmit = (values, actions) => {
    if (this.state.builderVisible && this.state.queryBuilder !== '') {
      values.queryString = this.state.queryBuilder;
    }
    const formValues = {
      ...values,
      searchInBody: this.state.searchInBody,
      searchInAttachment: this.state.searchInAttachment,
      onlyMailsWithAttachments: this.state.onlyMailsWithAttachments,
      searchInHeader: this.state.searchInHeader,
      searchInSubject: this.state.searchInHeader ? false : this.state.searchInSubject,
      queryString: {
        query: values?.queryString?.trim(),
        subject: values?.subject,
        to: values?.to,
        cc: values?.cc,
        bcc: values?.bcc,
        from: values?.from,
        messageId: values?.messageId,
        recipients: values?.recipients,
        isSplitField: values?.isSplitField
      }
    };
    let { id, ...data } = PreparedValues(searchFields, formValues || {});
    if (this.state.currentStep === count) {
      if (this.state.builderVisible) {
        localStorage.setItem(
          `${data.name}-${this.props.legalCaseId}`,
          JSON.stringify(this.state.statements)
        );
      }
      data.viaBuilder = undefined;
      data.viaText = undefined;
      data.queryBuilder = undefined;
      data.recipients = undefined;
      data.to = undefined;
      data.cc = undefined;
      data.bcc = undefined;
      data.from = undefined;
      data.query = undefined;
      data.subject = undefined;
      data.messageId = undefined;
      data.isSplitField = undefined;
      this.props.onSubmit(id, { ...data });
    } else {
      if (this.state.currentStep === 1) {
        this.setState({
          selectedHolds: values.legalHoldIds
        });
      }
      this.nextStep();
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  handleInputChange = (index, i, event, form) => {
    const value = event.target.value;
    this.setState((prevState) => {
      const updatedStatements = [...prevState.statements];
      let ruleToUpdate = updatedStatements[index].rules[i];
      ruleToUpdate.inputString = value;
      return { statements: updatedStatements };
    });
    form.setFieldValue('queryBuilder', this.state.queryBuilder);
  };

  handleEmptyGroupCheck = (array) => {
    let hasEmptyGroup = false;
    array.map((item) => {
      if (item === '') {
        hasEmptyGroup = true;
      }
    });
    return hasEmptyGroup;
  };

  handleOperatorChange = (index, i, event) => {
    const value = event.target.value;
    this.setState((prevState) => {
      const updatedStatements = [...prevState.statements];
      let ruleToUpdate = updatedStatements[index].rules[i];
      ruleToUpdate.inputOperator = value;
      return { statements: updatedStatements };
    });
  };

  handleStatementOperatorChange = (index, event) => {
    const value = event.target.value;
    this.setState((prevState) => {
      const updatedStatements = [...prevState.statements];
      let statementToUpdate = updatedStatements[index];
      statementToUpdate.groupStatement = value;
      return { statements: updatedStatements };
    });
  };

  validateQueryString = (value) => {
    if (!value) {
      return undefined;
    }
    let error;
    let hasQuotesAndBrackets = this.hasQuotesAndBrackets(value);
    let validString = this.checkStringValidity(value);
    if (hasQuotesAndBrackets && !validString) {
      error = 'Query has mismatched brackets and quotes';
    }
    return error;
  };

  firstStep(form) {
    return (
      <div>
        <div>
          <InputFormItem
            name={'name'}
            schema={searchFields}
            autoFocus
            validate={this.validateSearchName}
          />
        </div>
        <div>
          <LegalHoldAutocompleteFormItem
            name={'legalHoldIds'}
            schema={searchFields}
            showCreate={!this.props.modal}
            mode='multiple'
            legalCaseId={this.props.legalCaseId}
            indexed
            isDiscarded={this.props.isDiscarded}
            legalHoldIds={this.props?.record?.legalHoldIds}
            selectedHolds={this.state.selectedHolds}
          />
          <ErrorMessage name='legalHoldIds'>
            {(msg) => <div className={styles.errorMessage}>{msg}</div>}
          </ErrorMessage>
        </div>
      </div>
    );
  }

  secondStep = (form) => (
    <div>
      {this.state.showTooltip && (
        <SearchFormTooltip message={this.state.message} position={this.state.position} />
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <a
          className={styles.builderSwitch}
          onClick={() => {
            this.setState({
              builderVisible: !this.state.builderVisible
            });
            this.props.dispatch(formActions.doToggleBuilder(!this.state.builderVisible));
            this.toggleBuilder(form);
          }}
        >
          Builder
        </a>
      </div>
      {!this.state.builderVisible && (
        <>
          <div className='d-flex w-100 mb-0'>
            <div className={styles.queryMainContainer}>
              <TextAreaFormItem
                name={'queryString'}
                schema={searchFields}
                validate={this.validateQueryString}
                textAreaStyle={{
                  height: '110px',
                  overflowY: 'scroll',
                  wordBreak: 'break-word'
                }}
              />
            </div>

            <div style={{ width: '20%', marginTop: '15px' }}>
              <p className='mb-2'>Query Scope</p>
              <div>
                <p className='mb-1 d-flex'>
                  <label htmlFor='searchInHeader' className={styles.container}>
                    Header
                    <input
                      name='searchInHeader'
                      checked={this.state.searchInHeader}
                      type='checkbox'
                    />
                    <span
                      className={styles.checkmark}
                      onClick={() => {
                        this.setState(
                          {
                            searchInHeader: !this.state.searchInHeader
                          },
                          () => {
                            if (this.state.searchInHeader) {
                              this.setState({
                                searchInSubject: true
                              });
                            }
                          }
                        );
                        form.values.searchInHeader = this.state.searchInHeader;
                        form.values.searchInSubject = this.state.searchInSubject;
                      }}
                    ></span>
                  </label>
                  <span>
                    <ImInfo
                      onMouseOver={(e) => {
                        this.setState({
                          showTooltip: true,
                          position: {
                            top: e.pageY,
                            left: e.pageX
                          }
                        });
                      }}
                      onMouseLeave={() => {
                        this.setState({
                          showTooltip: false,
                          position: {
                            top: 0,
                            left: 0
                          }
                        });
                      }}
                      className='ms-2 mb-1'
                    />
                  </span>
                </p>
                <p className={styles.subjectFilter}>
                  <label htmlFor='searchInSubject' className={styles.container}>
                    Subject
                    <input
                      disabled={this.state.searchInHeader}
                      name='searchInSubject'
                      checked={this.state.searchInSubject}
                      type='checkbox'
                    />
                    <span
                      className={styles.checkmark}
                      onClick={() => {
                        if (this.state.searchInHeader) {
                          this.setState({
                            searchInSubject: true
                          });
                          form.values.searchInSubject = this.state.searchInSubject;
                        } else {
                          this.setState({
                            searchInSubject: !this.state.searchInSubject
                          });
                          form.values.searchInSubject = this.state.searchInSubject;
                        }
                      }}
                    ></span>
                  </label>
                </p>
                <p className='mb-1'>
                  <label htmlFor='searchInBody' className={styles.container}>
                    Body
                    <input name='searchInBody' checked={this.state.searchInBody} type='checkbox' />
                    <span
                      className={styles.checkmark}
                      onClick={() => {
                        this.setState({
                          searchInBody: !this.state.searchInBody
                        });
                        form.values.searchInBody = this.state.searchInBody;
                      }}
                    ></span>
                  </label>
                </p>
                <p className='mb-0'>
                  <label htmlFor='searchInAttachment' className={styles.container}>
                    Attachments
                    <input
                      name='searchInAttachment'
                      checked={this.state.searchInAttachment}
                      type='checkbox'
                    />
                    <span
                      className={styles.checkmark}
                      onClick={() => {
                        this.setState({
                          searchInAttachment: !this.state.searchInAttachment
                        });
                        form.values.searchInAttachment = this.state.searchInAttachment;
                      }}
                    ></span>
                  </label>
                </p>
              </div>
            </div>
          </div>

          <div className={styles.splitFields}>
            <label htmlFor='splitFields' className={styles.container}>
              Split Fields
              <input name='splitFields' type='checkbox' checked={this.state.isSplitField} />
              <span
                className={styles.checkmark}
                onClick={(e) => {
                  this.setState(
                    {
                      isSplitField: !this.state.isSplitField
                    },
                    () => {
                      if (this.state.isSplitField) {
                        form.setFieldValue('isSplitField', this.state.isSplitField);
                        form.setFieldValue('recipients', undefined);
                      }
                      if (!this.state.isSplitField) {
                        this.setState({
                          enableCcRecipients: false,
                          enableBccRecipients: false,
                          enableToRecipient: false,
                          enableRecipients: false
                        });
                        form.setFieldValue('isSplitField', false);
                        form.setFieldValue('to', undefined);
                        form.setFieldValue('cc', undefined);
                        form.setFieldValue('bcc', undefined);
                        form.setFieldValue('recipients', undefined);
                      }
                    }
                  );
                }}
              ></span>
            </label>
          </div>
          <div>
            <div className='w-100 d-flex justify-content-between mt-4'>
              <div style={{ width: '45%' }}>
                {this.state.isSplitField && (
                  <>
                    <div className='position-relative'>
                      <label htmlFor='enableToRecipient' className={styles.container}>
                        <input
                          name='enableToRecipient'
                          type='checkbox'
                          checked={this.state.enableToRecipient}
                        />
                        <span
                          className={styles.checkboxInput}
                          onClick={(e) => {
                            this.setState(
                              {
                                enableToRecipient: !this.state.enableToRecipient
                              },
                              () => {
                                if (!this.state.enableToRecipient) {
                                  form.setFieldValue('to', undefined);
                                }
                              }
                            );
                          }}
                        ></span>
                      </label>
                      <div className={styles.inputFilter}>
                        <InputFormItem
                          name={'to'}
                          schema={searchFields}
                          showCheckbox={true}
                          isDisabled={!this.state.enableToRecipient}
                          key={this.state.enableToRecipient}
                          autoFocus
                        />
                      </div>
                    </div>
                  </>
                )}
                {!this.state.isSplitField && (
                  <>
                    <div className='position-relative'>
                      <label htmlFor='enableRecipients' className={styles.container}>
                        <input
                          type='checkbox'
                          name='enableRecipients'
                          checked={this.state.enableRecipients}
                        />
                        <span
                          className={styles.checkboxInput}
                          onClick={(e) => {
                            this.setState(
                              {
                                enableRecipients: !this.state.enableRecipients
                              },
                              () => {
                                if (!this.state.recipients) {
                                  form.setFieldValue('to', undefined);
                                }
                              }
                            );
                          }}
                        ></span>
                      </label>
                      <div className={styles.inputFilter}>
                        <InputFormItem
                          name={'recipients'}
                          schema={searchFields}
                          showCheckbox={true}
                          isDisabled={!this.state.enableRecipients}
                          key={this.state.enableRecipients}
                          autoFocus
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className='position-relative'>
                  <label htmlFor='enableCcRecipients' className={styles.container}>
                    <input
                      name='enableCcRecipients'
                      disabled={!this.state.isSplitField}
                      type='checkbox'
                      checked={this.state.enableCcRecipients}
                    />
                    <span
                      className={styles.checkboxInput}
                      onClick={(e) => {
                        if (this.state.isSplitField) {
                          this.setState(
                            {
                              enableCcRecipients: !this.state.enableCcRecipients
                            },
                            () => {
                              if (!this.state.enableCcRecipients) {
                                form.setFieldValue('cc', undefined);
                              }
                            }
                          );
                        }
                      }}
                    ></span>
                  </label>
                  <div className={styles.inputFilter}>
                    <InputFormItem
                      name={'cc'}
                      schema={searchFields}
                      showCheckbox={true}
                      isDisabled={!this.state.enableCcRecipients || !this.state.isSplitField}
                      key={this.state.enableCcRecipients}
                      autoFocus
                    />
                  </div>
                </div>
                <div className='position-relative'>
                  <label htmlFor='enableBccRecipients' className={styles.container}>
                    <input
                      name='enableBccRecipients'
                      type='checkbox'
                      checked={this.state.enableBccRecipients}
                      disabled={!this.state.isSplitField}
                    />
                    <span
                      className={styles.checkboxInput}
                      onClick={(e) => {
                        if (this.state.isSplitField) {
                          this.setState(
                            {
                              enableBccRecipients: !this.state.enableBccRecipients
                            },
                            () => {
                              if (!this.state.enableBccRecipients) {
                                form.setFieldValue('bcc', undefined);
                              }
                            }
                          );
                        }
                      }}
                    ></span>
                  </label>
                  <div className={styles.inputFilter}>
                    <InputFormItem
                      name={'bcc'}
                      schema={searchFields}
                      showCheckbox={true}
                      isDisabled={!this.state.enableBccRecipients || !this.state.isSplitField}
                      key={this.state.enableBccRecipients}
                      autoFocus
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: '45%'
                }}
              >
                <div className='position-relative'>
                  <label htmlFor='enableFrom' className={styles.container}>
                    <input name='enableFrom' type='checkbox' checked={this.state.enableFrom} />
                    <span
                      className={styles.checkboxInput}
                      onClick={(e) => {
                        this.setState(
                          {
                            enableFrom: !this.state.enableFrom
                          },
                          () => {
                            if (!this.state.enableFrom) {
                              form.setFieldValue('from', undefined);
                            }
                          }
                        );
                      }}
                    ></span>
                  </label>
                  <div className={styles.inputFilter}>
                    <InputFormItem
                      name={'from'}
                      schema={searchFields}
                      showCheckbox={true}
                      isDisabled={!this.state.enableFrom}
                      key={this.state.enableFrom}
                      autoFocus
                    />
                  </div>
                </div>
                <div className='position-relative'>
                  <label htmlFor='enableMessageId' className={styles.container}>
                    <input
                      name='enableMessageId'
                      type='checkbox'
                      checked={this.state.enableMessageId}
                    />
                    <span
                      className={styles.checkboxInput}
                      onClick={(e) => {
                        this.setState(
                          {
                            enableMessageId: !this.state.enableMessageId
                          },
                          () => {
                            if (!this.state.enableMessageId) {
                              form.setFieldValue('messageId', undefined);
                            }
                          }
                        );
                      }}
                    ></span>
                  </label>
                  <div className={styles.inputFilter}>
                    <InputFormItem
                      name={'messageId'}
                      schema={searchFields}
                      showCheckbox={true}
                      isDisabled={!this.state.enableMessageId}
                      key={this.state.enableMessageId}
                      autoFocus
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {this.state.builderVisible && (
        <div>
          <div className={styles.queryContainer}>
            <p>Query*</p>
            <p className={styles.query}>{this.state.queryBuilder}</p>
          </div>

          {form?.errors?.queryBuilder && form?.touched?.queryBuilder ? (
            <div className={styles.errorMessage}>{form.errors?.queryBuilder}</div>
          ) : null}
          <section>
            <div className={styles.mainContainer}>
              {this.state.statements.map((item, index) => {
                return (
                  <div key={index}>
                    <div className={styles.card}>
                      <div>
                        <div className={styles.addGroup}>
                          <div className={styles.statementContainer}>
                            {index > 0 ? (
                              <div
                                style={{
                                  display: 'flex',
                                  width: '100%'
                                }}
                              >
                                {item?.collapsed && (
                                  <img
                                    src={trashIcon}
                                    alt={'trash'}
                                    className={'me-5 ms-2 mt-2 fw-b'}
                                    width={'20px'}
                                    height={'20px'}
                                    onClick={() => this.handleRemoveStatement(index, form)}
                                  />
                                )}
                                <select
                                  className={styles.statementDropdown}
                                  name='inputOperator'
                                  id='inputOperator'
                                  onChange={(event) => {
                                    this.toggleEnableNotOperator(index, event);
                                    this.handleStatementOperatorChange(index, event);
                                  }}
                                  value={this.state.statements[index]?.groupStatement}
                                >
                                  <option value='AND'>AND CONTAINS</option>
                                  <option value='OR'>OR CONTAINS</option>
                                  <option value='AND NOT'>AND DOES NOT CONTAIN</option>
                                  <option value='OR NOT'>OR DOES NOT CONTAIN</option>
                                </select>
                                {item?.collapsed && (
                                  <div className={styles.collapsedQuery}>
                                    {this.state.allRules[index]}
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: 'flex',
                                  width: '100%'
                                }}
                              >
                                <select
                                  className={`${styles.statementDropdown}`}
                                  style={{
                                    marginLeft: item?.collapsed ? '70px' : ''
                                  }}
                                  name='inputOperator'
                                  id='inputOperator'
                                  onChange={(event) => {
                                    this.toggleEnableNotOperator(index, event);
                                  }}
                                  value={this.state.statements[index]?.enableNotOperator}
                                >
                                  <option value=''>CONTAINS</option>
                                  <option value='NOT'>DOES NOT CONTAIN</option>
                                </select>
                                {item?.collapsed && (
                                  <div className={styles.collapsedQuery}>
                                    {this.state.allRules[index]}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                          <div className={styles.addGroupContainer}>
                            <button
                              hidden={`${this.state.statements.length > index + 1 ? 'hidden' : ''}`}
                              className='btn me-2 first-body-text'
                              type='button'
                              onClick={() => this.handleAddNewStatement(index)}
                            >
                              Add Group
                            </button>
                            <button
                              className='btn first-body-text'
                              type='button'
                              onClick={() => this.handleCollapseGroup(index)}
                            >
                              {this.state.statements[index].collapsed ? (
                                <img
                                  src={caretDown}
                                  alt={'uncollapsed'}
                                  width='16px'
                                  height='16px'
                                />
                              ) : (
                                <img src={caretUp} alt={'collapsed'} width='16px' height='16px' />
                              )}
                            </button>
                            {index > 0 && !this.state.statements[index].collapsed ? (
                              <img
                                src={trashIcon}
                                alt={'trash'}
                                className={'ms-2 mt-2 fw-b'}
                                width={'20px'}
                                height={'20px'}
                                onClick={() => this.handleRemoveStatement(index, form)}
                              />
                            ) : null}
                          </div>
                        </div>
                        {!this.state.statements[index]?.collapsed ? (
                          <div className={styles.ruleContainer}>
                            {this.state.statements[index].rules?.map((item, i) => {
                              return (
                                <span className={styles.oneRule} key={i}>
                                  <span
                                    style={{
                                      width: '150px',
                                      marginRight: '5px'
                                    }}
                                  >
                                    <input
                                      type='text'
                                      placeholder={'Enter Keyword'}
                                      className={'form-control search-input'}
                                      onChange={(event) => {
                                        this.handleInputChange(index, i, event, form);
                                      }}
                                      value={this.state.statements[index].rules[i].inputString}
                                    />
                                  </span>
                                  <span className={styles.removeRule}>
                                    <img
                                      src={trashIcon}
                                      alt={'trash'}
                                      className={'me-2 fw-b'}
                                      width={'20px'}
                                      height={'20px'}
                                      onClick={() => this.handleRemoveRule(index, i, form)}
                                    />
                                  </span>
                                  <span>
                                    <button
                                      disabled={
                                        this.state.statements[index].rules[i].inputString === ''
                                      }
                                      hidden={`${
                                        this.state.statements[index].rules.length > i + 1
                                          ? 'hidden'
                                          : ''
                                      }`}
                                      className='btn first-body-text'
                                      type='button'
                                      style={{
                                        height: '35px',
                                        width: '35px',
                                        marginRight: '10px'
                                      }}
                                      onClick={() => {
                                        this.handleAddNewRule(index);
                                      }}
                                    >
                                      <img
                                        src={plusIcon}
                                        alt={'plus'}
                                        width='16px'
                                        height='16px'
                                        className='me-2'
                                      />
                                    </button>
                                  </span>
                                  <span>
                                    {this.state.statements[index].rules.length > 1 &&
                                      i < this.state.statements[index].rules.length - 1 && (
                                        <span
                                          style={{
                                            height: '100%',
                                            display: 'flex',
                                            alignItems: 'center'
                                          }}
                                        >
                                          <select
                                            className={styles.ruleOperator}
                                            name='inputOperator'
                                            id='inputOperator'
                                            onChange={(event) =>
                                              this.handleOperatorChange(index, i, event)
                                            }
                                            value={
                                              this.state.statements[index].rules[i].inputOperator
                                            }
                                          >
                                            <option value='AND'>AND</option>
                                            <option value='OR'>OR</option>
                                          </select>
                                        </span>
                                      )}
                                  </span>
                                </span>
                              );
                            })}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </section>
        </div>
      )}
    </div>
  );

  thirdStep = (form) => (
    <div>
      <div>
        <span>
          <label className={'col-form-label me-4 mb-4'}>Only Mails With Attachments</label>
          <ButtonGroup>
            <button
              type='button'
              className={
                this.state.onlyMailsWithAttachments === true
                  ? `${styles.activeButton} first-body-text`
                  : `${styles.inactiveButton} first-body-text`
              }
              onClick={() => {
                this.setState({
                  onlyMailsWithAttachments: true
                });
                form.values.onlyMailsWithAttachments = true;
              }}
            >
              Yes
            </button>
            <button
              type='button'
              className={
                this.state.onlyMailsWithAttachments === false
                  ? `${styles.activeButton} first-body-text`
                  : `${styles.inactiveButton} first-body-text`
              }
              onClick={() => {
                this.setState({
                  onlyMailsWithAttachments: false
                });
                form.values.onlyMailsWithAttachments = false;
              }}
            >
              No
            </button>
          </ButtonGroup>
        </span>
      </div>

      <div>
        <SelectFormItem
          name={'fuzzyness'}
          schema={searchFields}
          mode={'fetch'}
          data={this.fuzzynessOptions(this.props?.fuzzynessTypes)}
        />
      </div>
    </div>
  );

  renderStepContent = (currentStep, form) => {
    switch (currentStep) {
      case 1:
        return this.firstStep(form);

      case 2:
        return this.secondStep(form);
      case 3:
        return this.thirdStep(form);
      default:
        return this.firstStep(form);
    }
  };

  renderForm() {
    const { saveLoading } = this.props;
    const currentStep = this.state.currentStep;
    const validateStep = this.state.currentStep - 1;
    return (
      <div className={s.root}>
        <Row>
          <Col sm={12}>
            <Nav pills justified className={s.wizardNavigation}>
              <NavItem>
                <NavLink
                  className={classnames({
                    wizardActiveItem: currentStep === 1
                  })}
                >
                  <small>1.</small>
                  &nbsp; General
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    wizardActiveItem: currentStep === 2
                  })}
                >
                  <small>2.</small>
                  &nbsp; Query
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    wizardActiveItem: currentStep === 3
                  })}
                >
                  <small>3.</small>
                  &nbsp; Optional Filters
                </NavLink>
              </NavItem>
            </Nav>
            <Progress value={this.state.progress} className='progress-xs-wizard' />
            <div>
              <div className={s.stepBody}>
                <Formik
                  onSubmit={this.handleSubmit}
                  initialValues={this.iniValues()}
                  validationSchema={this.FormValidationSchema[validateStep]}
                >
                  {(form) => {
                    return (
                      <form onSubmit={form.handleSubmit} className={styles.formBody}>
                        <div>{this.renderStepContent(this.state.currentStep, form)}</div>
                        <div>
                          <ul className={styles.wizardList}>
                            <li className='previous'>
                              <Button
                                hidden={currentStep === 1}
                                color='primary'
                                onClick={this.previousStep}
                                className='first-body-text'
                              >
                                <i className='fa fa-caret-left' />
                                &nbsp;Previous
                              </Button>
                            </li>

                            <li className='next'>
                              <Button
                                color='primary'
                                onClick={() => {
                                  if (
                                    currentStep === 2 &&
                                    this.state.queryBuilder === '' &&
                                    this.state.builderVisible
                                  ) {
                                    form.setFieldValue('queryBuilder', '');
                                    form.setFieldTouched('queryBuilder', true);
                                    form.handleSubmit();
                                  } else {
                                    form.handleSubmit();
                                  }
                                }}
                                disabled={saveLoading}
                                className='first-body-text'
                              >
                                {currentStep === count ? (
                                  <span>
                                    Save <i className='fa fa-check' />
                                  </span>
                                ) : (
                                  <span>
                                    Next <i className='fa fa-caret-right' />
                                  </span>
                                )}
                              </Button>
                            </li>
                          </ul>
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    const { findLoading, record } = this.props;

    if (findLoading) {
      return <Loader />;
    }

    if (!record) {
      return <Loader />;
    }

    return this.renderForm();
  }
}

export default SearchForm;
