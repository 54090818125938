import React from 'react';
import { connect } from 'react-redux';
import Widget from '../../components/Widget';
import styles from './Dashboard.module.scss';
import AuditLogsTable from 'components/CRUD/AuditLogs/AuditLogsTable';
import DashboardStats from './DashboardStats';
import ExportStatistics from 'components/CRUD/Export/exportStatistics/ExportStatistics';
import { Navigate } from 'react-router-dom';

class Dashboard extends React.Component {
  render() {
    return (
      <>
        {this.props?.currentUser?.isAuthenticated ? (
          <div className={styles.dashboardContainer}>
            {this.props?.permissions['LegalUI.Dashboard'] ? (
              <Widget className={styles.statsWidget}>
                <h3 className={'first-headline-text me-4 mb-10'}>Dashboard</h3>
                <DashboardStats />
              </Widget>
            ) : null}
            {this.props?.permissions['LegalUI.Audit'] ? <AuditLogsTable /> : null}
            <div>{this.props?.permissions['LegalUI.Export'] ? <ExportStatistics /> : null}</div>
          </div>
        ) : (
          <Navigate to='/login' replace />
        )}
      </>
    );
  }
}

function mapStateToProps(store) {
  return {
    currentUser: store.auth.currentUser,
    permissions: store.auth.permissions
  };
}

export default connect(mapStateToProps)(Dashboard);
