import React from 'react';
import ReactDOM from 'react-dom';
import s from 'components/Tooltip/Tooltip.module.scss';
import 'components/Tooltip/Tooltip.css';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import styles from 'components/CRUD/Credentials/list/CredentialList.module.scss';
import { emptyDataMessage } from 'actions/common';
import * as dataFormat from 'components/CRUD/LegalHold/list/LegalHoldDataFormatters';

export default function CredentialListTooltip({ credentialContent, position }) {
  const tooltipStyle = {
    position: 'absolute',
    zIndex: 2000,
    padding: '10px',
    maxWidth: '350px',
    top: position.top - 50,
    left: position.left + 20
  };
  return ReactDOM.createPortal(
    <div className={s.tooltip} style={tooltipStyle}>
      <p className={styles.tooltipHeader}>Encryption Key Versions</p>
      <div className={styles.popupTableContainer}>
        <ToolkitProvider
          bootstrap4
          columns={[
            {
              dataField: 'version',
              text: 'Version'
            },
            {
              dataField: 'timeStamp',
              text: 'TimeStamp',
              formatter: dataFormat.dateTimeFormatter
            }
          ]}
          data={credentialContent}
          keyField='timeStamp'
        >
          {(props) => (
            <>
              <BootstrapTable
                bordered={true}
                classes={`table-striped table-hover mt-4 fs-sm ${styles.popupTable} `}
                noDataIndication={emptyDataMessage}
                {...props.baseProps}
              />
            </>
          )}
        </ToolkitProvider>
      </div>
    </div>,
    document.querySelector('#modal-root')
  );
}
