const initialData = {
  rows: [],
  loading: false,
  count: 0,
  showTableView: false,
  isDiscarded: false,
  nodesList: [],
  detectedLanguage: '',
  translatedEmail: '',
  analyzedSentimentText: {},
  keyPhrases: [],
  summarizedEmail: '',
  actionItemsList: [],
  emailTopics: []
};

export default (state = initialData, { type, payload }) => {
  if (type === 'SEARCH_LIST_FETCH_STARTED') {
    return {
      ...state,
      loading: true
    };
  }

  if (type === 'SEARCH_LIST_FETCH_SUCCESS') {
    return {
      ...state,
      loading: false,
      rows: payload.rows,
      count: payload.count
    };
  }

  if (type === 'SEARCH_LIST_FETCH_ERROR') {
    return {
      ...state,
      loading: false,
      rows: []
    };
  }

  if (type === 'SEARCH_LIST_OPEN_CONFIRM') {
    return {
      ...state,
      loading: false,
      modalOpen: true
    };
  }

  if (type === 'SEARCH_LIST_CLOSE_CONFIRM') {
    return {
      ...state,
      loading: false,
      modalOpen: false,
      isDiscarded: false
    };
  }
  if (type === 'SEARCH_LIST_SHOW_TABLE_CONFIRM') {
    return {
      ...state,
      showTableView: true
    };
  }
  if (type === 'SEARCH_LIST_HIDE_TABLE_CONFIRM') {
    return {
      ...state,
      showTableView: false
    };
  }
  if (type === 'SEARCH_DISCARD_CONFIRM') {
    return {
      ...state,
      isDiscarded: true
    };
  }
  if (type === 'SEARCH_DISCARD_TOGGLE') {
    return {
      ...state,
      isDiscarded: false
    };
  }
  if (type === 'TREE_NODES_FETCH_STARTED') {
    return {
      ...state,
      loading: true
    };
  }

  if (type === 'TREE_NODES_FETCH_SUCCESS') {
    return {
      ...state,
      loading: false,
      nodesList: payload
    };
  }

  if (type === 'TREE_NODES_FETCH_ERROR') {
    return {
      ...state,
      loading: false,
      nodesList: []
    };
  }

  if (type === 'DETECT_LANGUAGE_SUCCESS') {
    return {
      ...state,
      detectedLanguage: payload
    };
  }

  if (type === 'DETECT_LANGUAGE_ERROR') {
    return {
      ...state,
      detectedLanguage: ''
    };
  }
  if (type === 'EMAIL_TRANSLATION_SUCCESS') {
    return {
      ...state,
      translatedEmail: payload
    };
  }

  if (type === 'EMAIL_TRANSLATION_ERROR') {
    return {
      ...state,
      translatedEmail: ''
    };
  }
  if (type === 'ANALYZE_SENTIMENT_SUCCESS') {
    return {
      ...state,
      analyzedSentimentText: payload
    };
  }

  if (type === 'ANALYZE_SENTIMENT_ERROR') {
    return {
      ...state,
      analyzedSentimentText: {}
    };
  }
  if (type === 'KEY_PHRASES_EXTRACTION_SUCCESS') {
    return {
      ...state,
      keyPhrases: payload
    };
  }

  if (type === 'KEY_PHRASES_EXTRACTION_ERROR') {
    return {
      ...state,
      keyPhrases: []
    };
  }
  if (type === 'SUMMARIZE_EMAIL_SUCCESS') {
    return {
      ...state,
      summarizedEmail: payload
    };
  }

  if (type === 'SUMMARIZE_EMAIL_ERROR') {
    return {
      ...state,
      summarizedEmail: ''
    };
  }
  if (type === 'ACTION_ITEMS_FETCH_SUCCESS') {
    return {
      ...state,
      actionItemsList: payload
    };
  }

  if (type === 'ACTION_ITEMS_FETCH_ERROR') {
    return {
      ...state,
      actionItemsList: []
    };
  }
  if (type === 'TOPICS_FETCH_SUCCESS') {
    return {
      ...state,
      emailTopics: payload
    };
  }

  if (type === 'TOPICS_FETCH_ERROR') {
    return {
      ...state,
      emailTopics: []
    };
  }
  return state;
};
