import React, { Component } from 'react';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Widget from 'components/Widget/Widget';
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Col,
  Row,
  Progress
} from 'reactstrap';
import actions from 'actions/Export/ExportListActions';
import { connect } from 'react-redux';
import Pagination from 'react-js-pagination';
import styles from 'components/CRUD/LegalHold/list/LegalHoldList.module.scss';
import exportStyles from 'components/CRUD/Export/Export.module.scss';
import * as dataFormat from 'components/CRUD/LegalHold/list/LegalHoldDataFormatters';
import { emptyDataMessage } from 'actions/common';
import searchIcon from 'images/icons/search.svg';
import caretDown from 'images/icons/caret-down.svg';

class ExportStatistics extends Component {
  state = {
    totalCount: 0,
    pageNumber: 1,
    pageSize: 5,
    paginationModalOpen: false,
    newRows: [],
    sortOrder: 'name_asc',
    searchString: ''
  };

  diskSpaceFormatter = (item) => {
    if (!item) {
      return null;
    }
    const totalSpace = item?.freeSpaceinBytes + item?.usedSpaceinBytes;
    let value = Math.floor((item?.usedSpaceinBytes / totalSpace) * 100);
    if (!item?.usedSpaceinBytes) {
      return null;
    }
    return (
      <Progress animated={value < 100} value={value} style={{ position: 'relative' }}>
        <span
          style={{
            left: 'calc(100% / 2 - 10px)',
            position: 'absolute'
          }}
        >
          {value} %
        </span>
      </Progress>
    );
  };

  refreshTable = () => {
    const { dispatch } = this.props;
    const interval = this.props?.refreshInterval.concat('000');
    const refreshInterval = JSON.parse(interval);
    this.intervalID = setInterval(() => {
      dispatch(
        actions.doGetExportPolicyStats({
          pageNumber: this.state.pageNumber,
          pageSize: this.state.pageSize,
          sortOrder: this.state.sortOrder,
          searchString: this.state.searchString
        })
      ).then(() => {
        this.setState({
          totalCount: this.props.exportStatsCount,
          newRows: this.props.exportStatsList
        });
      });
    }, refreshInterval);
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(
      actions.doGetExportPolicyStats({
        sortOrder: this.state.sortOrder,
        pageNumber: this.state.pageNumber,
        pageSize: this.state.pageSize,
        searchString: this.state.searchString
      })
    ).then(() => {
      this.setState({
        totalCount: this.props.exportStatsCount,
        newRows: this.props.exportStatsList
      });
    });
    if (this.props?.refreshInterval && this.props?.refreshInterval !== null) {
      this.refreshTable();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { dispatch } = this.props;
    if (
      prevState.pageSize !== this.state.pageSize ||
      prevState.pageNumber !== this.state.pageNumber ||
      prevState.sortOrder !== this.state.sortOrder
    ) {
      dispatch(
        actions.doGetExportPolicyStats({
          sortOrder: this.state.sortOrder,
          pageNumber: this.state.pageNumber,
          pageSize: this.state.pageSize,
          searchString: this.state.searchString
        })
      ).then(() => {
        this.setState({
          newRows: this.props.exportStatsList,
          totalCount: this.props.exportStatsCount
        });
      });
    }
    if (prevState.searchString !== this.state.searchString && this.state.searchString === '') {
      dispatch(
        actions.doGetExportPolicyStats({
          sortOrder: this.state.sortOrder,
          pageNumber: this.state.pageNumber,
          pageSize: this.state.pageSize,
          searchString: this.state.searchString
        })
      ).then(() => {
        this.setState({
          newRows: this.props.exportStatsList,
          totalCount: this.props.exportStatsCount
        });
      });
    }
    if (prevProps.exportStatsList !== this.props.exportStatsList) {
      this.setState({
        newRows: this.props.exportStatsList,
        totalCount: this.props.exportStatsCount
      });
    }
    if (prevProps.refreshInterval !== this.props.refreshInterval) {
      this.refreshTable();
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  handlePageChange = (pageNumber) => {
    this.setState({ pageNumber: pageNumber });
  };

  sortFormatter(sortField) {
    const SortEnum = {
      name: 'name',
      moduleName: 'modulename',
      totalSpaceinBytes: 'totalspaceinbytes',
      outputPath: 'outputpath',
      freeSpaceinBytes: 'freespaceinbytes',
      isValidLocation: 'invalidlocation'
    };
    return SortEnum[sortField];
  }

  handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === 'sort') {
      this.setState({
        pageNumber: 1,
        sortOrder: this.sortFormatter(sortField).concat('_').concat(sortOrder)
      });
    }
  };

  handleSearch = () => {
    const { dispatch } = this.props;
    this.setState({ pageNumber: 1 });
    dispatch(
      actions.doGetExportPolicyStats({
        sortOrder: this.state.sortOrder,
        pageNumber: this.state.pageNumber,
        pageSize: this.state.pageSize,
        searchString: this.state.searchString
      })
    ).then(() => {
      this.setState({
        newRows: this.props.exportStatsList,
        totalCount: this.props.exportStatsCount
      });
    });
  };

  handleSpinners = (cell, row) => {
    const totalSpace = row?.totalSpaceinBytes;
    const freeSpace = row?.freeSpaceinBytes;
    const isValid = row?.isValidLocation;
    if (isValid && totalSpace === 0 && freeSpace === 0) {
      return <div className='spinner-border text-light' role='status' />;
    } else {
      return dataFormat.sizeFormatter(cell);
    }
  };

  render() {
    const columns = [
      {
        dataField: 'name',
        text: 'Name',
        sort: true,
        formatter: (cell, row) => <div className={exportStyles.exportName}>{cell}</div>
      },
      {
        dataField: 'moduleName',
        text: 'Module Name',
        formatter: (cell, row) => <div className={exportStyles.exportName}>{cell}</div>,
        sort: true
      },
      {
        dataField: 'outputPath',
        text: 'Location',
        formatter: (cell, row) => <div className={exportStyles.exportName}>{cell}</div>,
        sort: true
      },
      {
        dataField: 'isValidLocation',
        text: 'Valid Location',
        formatter: (cell, row) => (
          <div className={exportStyles.exportName}>{cell ? <span>Yes</span> : <span>No</span>}</div>
        ),
        sort: true
      },
      {
        dataField: 'totalSpaceinBytes',
        text: 'Total Space',

        formatter: (cell, row) => this.handleSpinners(cell, row),
        sort: true
      },
      {
        dataField: 'freeSpaceinBytes',
        text: 'Free Space',
        formatter: (cell, row) => this.handleSpinners(cell, row),
        sort: true
      },
      {
        dataField: 'id',
        text: 'Used Space',
        formatter: (cell, row) => {
          const totalSpace = row?.totalSpaceinBytes;
          const freeSpace = row?.freeSpaceinBytes;
          const isValid = row?.isValidLocation;
          if (isValid && totalSpace === 0 && freeSpace === 0) {
            return <div className='spinner-border text-light' role='status' />;
          } else {
            return this.diskSpaceFormatter.bind(this)(row);
          }
        }
      }
    ];
    return (
      <>
        <Widget style={{ marginTop: '20px', width: '100%' }}>
          <ToolkitProvider bootstrap4 columns={columns} data={this.state.newRows} keyField='id'>
            {(props) => (
              <>
                <Row className='mb-4'>
                  <Col style={{ marginTop: '0px', marginBottom: '10px' }}>
                    <div className='d-flex align-items-center justify-content-between'>
                      <h3 className={'first-headline-text me-4 mb-0'}>Export Policy Statistics</h3>
                      <div className='d-flex'>
                        <span>
                          <input
                            type='search'
                            placeholder={'Search'}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                this.handleSearch();
                              }
                            }}
                            style={{
                              border: '0.5px solid #0046b1',
                              borderRadius: '3px'
                            }}
                            className={'form-control search-input me-5 w-200'}
                            value={this.state.searchString}
                            onChange={(e) => {
                              this.setState({
                                searchString: e.target.value
                              });
                            }}
                          />{' '}
                        </span>
                        <span>
                          <button
                            className='btn search-button-with-icon me-2 ms-10'
                            type='button'
                            onClick={() => this.handleSearch()}
                            style={{ height: '37px', width: '37px' }}
                          >
                            <img
                              title={'search'}
                              alt={'search'}
                              width={14}
                              height={14}
                              src={searchIcon}
                            />
                          </button>
                        </span>
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className='table-container'>
                  <BootstrapTable
                    bordered={false}
                    classes={`table-striped table-hover fs-sm custom-table`}
                    remote={{
                      filter: false,
                      pagination: false,
                      sort: true,
                      cellEdit: false
                    }}
                    onTableChange={this.handleTableChange}
                    noDataIndication={emptyDataMessage}
                    {...props.baseProps}
                  />
                </div>
                <Row key={'pagination'} className='mt-3'>
                  <Col>
                    <p className={styles.totalCount}>Total: {this.state.totalCount}</p>
                  </Col>
                  <Col className='d-flex justify-content-end'>
                    {this.state.totalCount ? (
                      <Pagination
                        totalItemsCount={this.state.totalCount}
                        onChange={this.handlePageChange}
                        activePage={this.state.pageNumber}
                        itemsCountPerPage={this.state.pageSize}
                        pageRangeDisplayed={5}
                        prevPageText={'<'}
                        nextPageText={'>'}
                        firstPageText={'<<'}
                        lastPageText={'>>'}
                        linkClassFirst={styles.paginationNext}
                        linkClassPrev={styles.paginationNext}
                        linkClassNext={styles.paginationNext}
                        linkClassLast={styles.paginationNext}
                        linkClass={styles.pageLink}
                        activeLinkClass={styles.activeLinkClass}
                      />
                    ) : null}
                    <Dropdown
                      isOpen={this.state.paginationModalOpen}
                      toggle={() =>
                        this.setState({
                          paginationModalOpen: !this.state.paginationModalOpen
                        })
                      }
                      className={styles.pageSizeDropdown}
                    >
                      <DropdownToggle className='bg-transparent text-white d-flex'>
                        <p
                          style={{ marginBottom: '3px', marginRight: '2px' }}
                          className='first-body-text'
                        >
                          {this.state.pageSize}
                        </p>
                        <img
                          src={caretDown}
                          alt={'uncollapsed'}
                          width='20px'
                          height='20px'
                          className='ms-2'
                        />
                      </DropdownToggle>
                      <DropdownMenu className='dropdown-position-fixed'>
                        {[5, 10, 25].map((item) => (
                          <DropdownItem
                            className={styles.dropdownItem}
                            key={item}
                            onClick={() => {
                              this.setState({
                                pageSize: item,
                                paginationModalOpen: false,
                                pageNumber: 1
                              });
                            }}
                          >
                            {item}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                </Row>
              </>
            )}
          </ToolkitProvider>
        </Widget>
      </>
    );
  }
}
function mapStateToProps(store) {
  return {
    exportStatsList: store.Export.list.exportStatsList,
    exportStatsCount: store.Export.list.exportStatsCount,
    refreshInterval: store.LegalHold.list.refreshInterval
  };
}
export default connect(mapStateToProps)(ExportStatistics);
