const initialData = {
  rows: [],
  closedLegalCases: [],
  closedLegalCasesCount: null,
  count: null,
  loading: false,
  caseUsers: [],
  caseUsersCount: null,
  legalHoldsList: []
};

export default (state = initialData, { type, payload }) => {
  if (type === 'LEGALCASE_LIST_FETCH_STARTED') {
    return {
      ...state,
      loading: true
    };
  }

  if (type === 'LEGALCASE_LIST_FETCH_SUCCESS') {
    return {
      ...state,
      loading: false,
      rows: payload.rows,
      count: payload.count
    };
  }

  if (type === 'LEGALCASE_CLOSED_LIST_FETCH_SUCCESS') {
    return {
      ...state,
      loading: false,
      closedLegalCases: payload.closedLegalCases,
      closedLegalCasesCount: payload.closedLegalCasesCount
    };
  }

  if (type === 'LEGALCASE_LIST_FETCH_ERROR') {
    return {
      ...state,
      loading: false,
      rows: []
    };
  }

  if (type === 'SIMPLIFIED_CASES_LIST_FETCH_STARTED') {
    return {
      ...state,
      loading: true
    };
  }

  if (type === 'SIMPLIFIED_CASES_LIST_FETCH_SUCCESS') {
    return {
      ...state,
      loading: false,
      simplifiedCases: payload.simplifiedCases
    };
  }

  if (type === 'SIMPLIFIED_CASES_LIST_FETCH_ERROR') {
    return {
      ...state,
      loading: false,
      simplifiedCases: []
    };
  }

  if (type === 'LEGALCASE_LIST_OPEN_CONFIRM') {
    return {
      ...state,
      loading: false,
      modalOpen: true,
      idToDelete: payload.id
    };
  }

  if (type === 'LEGALCASE_LIST_CLOSE_CONFIRM') {
    return {
      ...state,
      loading: false,
      modalOpen: false
    };
  }
  if (type === 'LEGALCASE_USERS_LIST_FETCH_STARTED') {
    return {
      ...state,
      loading: true
    };
  }

  if (type === 'LEGALCASE_USERS_LIST_FETCH_SUCCESS') {
    return {
      ...state,
      loading: false,
      caseUsers: payload.caseUsers,
      caseUsersCount: payload.caseUsersCount
    };
  }
  if (type === 'LEGALCASE_USERS_LIST_FETCH_ERROR') {
    return {
      ...state,
      loading: false,
      caseUsers: []
    };
  }
  if (type === 'LEGALCASE_CLOSE_CASE_FAILED') {
    return {
      ...state,
      legalHoldsList: payload.legalHoldsList
    };
  }
  return state;
};
