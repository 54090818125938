import React, { Component } from 'react';
import Loader from 'components/Loader';
import { Button, Modal, ModalBody, ModalHeader, Col, Row } from 'reactstrap';
import Widget from 'components/Widget';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import personIcon from '../../../../images/icons/person.svg';
import styles from './styles.module.scss';
import cx from 'classnames';
import actions from '../../../../actions/search/searchFormActions';
import { Client } from '../../../../legal-api';
import config from '../../../../config';
import { customCloseBtn } from 'actions/common';

class BulkSelectionForm extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    widget: [],
    contentSearchIdArr: [],
    searchString: '',
    status: null,
    modalOpen: false
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { dispatch, navigate } = this.props;
    if (prevState.searchString !== this.state.searchString && this.state.searchString.length) {
      const formattedValues = {
        legalCaseId: this.props.legalCaseId,
        contentSearchId: this.props.contentSearchId,
        searchString: this.state.searchString,
        pageSize: 10000,
        attachmentTypes: ['All'],
        isExcluded: null,
        hasAttachments: null
      };
      new Client(config.externalApi).searchItemsPOST(formattedValues).then((res) => {
        this.props.dispatch({
          type: 'SEARCH_FORM_ITEMS_BULK_FIND_SUCCESS',
          payload: {
            rows: res,
            count: res.length
          }
        });
      });
      // dispatch(actions.doFindItemsBulk(this.props.legalCaseId, this.props.contentSearchId, this.state.searchString, 9999,navigate))
    }
    if (prevState.searchString.length && !this.state.searchString) {
      dispatch(
        actions.doFindItemsMountBulk(
          this.props.legalCaseId,
          this.props.contentSearchId,
          '',
          9999,
          navigate
        )
      ).then(() => {
        this.onSearchSelect(true);
      });
    }
    if (prevProps.items !== this.props.items && this.state.searchString.length) {
      this.onSearchSelect().then((data) => {
        this.setState({
          widget: data.indexes,
          contentSearchIdArr: data.items
        });
      });
    }
  }

  contentSearchItemHandle(widgetNum, contentSearchId, item) {
    const aWidget = this.state.widget,
      contentSearchIdArr = this.state.contentSearchIdArr;
    if (this.state.widget.includes(widgetNum)) {
      const item = aWidget.findIndex((item) => item == widgetNum);
      const contentItem = contentSearchIdArr.findIndex((item) => item.id == contentSearchId);
      aWidget.splice(item, 1);
      contentSearchIdArr.splice(contentItem, 1);
    } else {
      aWidget.push(widgetNum);
      contentSearchIdArr.push({
        id: contentSearchId,
        isExcluded: item.isExcluded
      });
    }
    this.setState((prevState) => ({
      ...prevState,
      contentSearchIdArr: contentSearchIdArr,
      widget: aWidget
    }));
  }

  onCancel = () => {
    this.setState({
      ...this.state,
      widget: []
    });
  };

  onSearchSelect = (abort) => {
    if (abort) {
      this.setState({
        widget: [],
        contentSearchIdArr: []
      });
    }
    let aIndex = [],
      bIndex = [];
    this.props.items.map((item, index) => {
      aIndex.push(index);
      bIndex.push({
        ...item,
        contentSearchId: item.id
      });
    });

    return Promise.resolve({
      indexes: aIndex,
      items: bIndex
    });
  };

  onInclude = () => {
    this.setState(
      {
        ...this.state,
        status: true
      },
      async () => {
        const newArr = this.state.contentSearchIdArr.map((item) => item.id);
        this.props.dispatch(
          actions.doAddIconToItem(this.state.contentSearchIdArr, this.state.status)
        );
        await this.props.dispatch(actions.doIncludeItems(this.props.contentSearchId, newArr));
        setTimeout(
          () =>
            this.props.dispatch(
              actions.doGetExcludedStats(
                this.props.legalCaseId,
                this.props.contentSearchId,
                this.props.navigate
              )
            ),
          2000
        );
      }
    );
  };

  onExclude = () => {
    this.setState(
      {
        ...this.state,
        modalOpen: false,
        status: false
      },
      async () => {
        const newArr = this.state.contentSearchIdArr.map((item) => item.id);
        this.props.dispatch(
          actions.doAddIconToItem(this.state.contentSearchIdArr, this.state.status)
        );
        await this.props.dispatch(actions.doExcludeItems(this.props.contentSearchId, newArr));
        setTimeout(
          () =>
            this.props.dispatch(
              actions.doGetExcludedStats(
                this.props.legalCaseId,
                this.props.contentSearchId,
                this.props.navigate
              )
            ),
          2000
        );
      }
    );
  };

  openModal = () => {
    this.setState({
      modalOpen: true
    });
  };

  onSearch = (value) => {
    this.setState({
      ...this.state,
      searchString: value
    });
  };

  modalClose = () => {
    this.setState({
      modalOpen: false
    });
  };

  renderForm() {
    const { saveLoading } = this.props;

    return (
      <div>
        <Row>
          <Col sm={12} className={styles.list}>
            <input
              type='search'
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  this.contentSearchItemHandle();
                }
              }}
              placeholder={'Search'}
              className={'form-control mb-3'}
              value={this.state.searchString}
              onChange={(e) => {
                this.onSearch(e.target.value);
              }}
            />
            <AutoSizer>
              {({ width, height }) => (
                <List
                  height={height}
                  itemCount={this.props?.items?.length}
                  itemSize={100}
                  width={width}
                >
                  {({ index, style }) => {
                    const item = this.props?.items[index];

                    return (
                      <Widget
                        style={{
                          ...style,
                          height: 'auto',
                          minHeight: 83
                        }}
                        onClick={() => this.contentSearchItemHandle(index, item.id, item)}
                        className={cx({
                          [styles.activeWidget]: this.state.widget.includes(index)
                        })}
                        key={index}
                      >
                        <div className={'d-flex justify-content-between'}>
                          <div className={'d-flex flex-column'}>
                            <small className={'pe-3'}>
                              <img src={personIcon} className={'me-2'} alt={'person'} />
                              {item.sender ? item.sender.smtpAddress : 'Unknown'}
                            </small>
                            <h6 className={'fw-semi-bold mb-0'} style={{ marginTop: 12 }}>
                              {item.subject?.length > 40
                                ? item.subject?.slice(0, 30) + '...'
                                : item.subject}
                            </h6>
                          </div>
                          <div
                            className={`d-flex flex-column align-items-end ${
                              !item.isExcluded &&
                              !this.props.itemIcon.includes(item.id) &&
                              'justify-content-center'
                            }`}
                          >
                            {this.props.itemIcon.includes(item.id) && (
                              <span
                                className={'close'}
                                aria-hidden='true'
                                style={{
                                  color: '#FF2D2E',
                                  textShadow: 'none'
                                }}
                              >
                                ×
                              </span>
                            )}
                            <small
                              className={'text-muted'}
                              style={{
                                marginTop: `${
                                  item.isExcluded ||
                                  (this.props.itemIcon.includes(item.id) && 'auto')
                                }`
                              }}
                            >
                              {item.itemDate}
                            </small>
                          </div>
                        </div>
                      </Widget>
                    );
                  }}
                </List>
              )}
            </AutoSizer>
          </Col>
          <Col sm={12} className={'mt-4'}>
            <div className={'d-flex justify-content-end'}>
              <Button color={'default'} style={{ marginRight: 'auto' }} onClick={this.onCancel}>
                Clear
              </Button>
              <Button
                color={'default'}
                className={'me-3'}
                onClick={this.openModal}
                disabled={!this.state.contentSearchIdArr.length}
              >
                Exclude
              </Button>
              <Button
                color={'default'}
                onClick={this.onInclude}
                disabled={!this.state.contentSearchIdArr.length}
              >
                Include
              </Button>
            </div>
          </Col>
        </Row>
        <Modal
          size='md'
          isOpen={this.state.modalOpen}
          toggle={() => this.modalClose()}
          className={`themeStyle${this.props.currentTheme?.replace('#', '')}`}
        >
          <ModalHeader close={customCloseBtn(() => this.modalClose())}>
            <p className='second-headline-text'>Confirm</p>
          </ModalHeader>
          <ModalBody>
            <p className='text-center'>
              Are you sure you want to exclude {this.state.contentSearchIdArr.length} items?
            </p>
            <div className={'d-flex justify-content-between mt-4'}>
              <button className='btn secondary-btn first-body-text' onClick={this.modalClose}>
                Cancel
              </button>
              <button className='btn first-body-text' onClick={this.onExclude}>
                Yes
              </button>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }

  render() {
    return this.renderForm();
  }
}

export default BulkSelectionForm;
