const AuditFields = {
  users: {
    type: "string",
    label: "Users",
  },
  legalCaseId: {
    type: "string",
    label: "Cases*",
    required: true,
  },
  auditTypes: {
    type: "auditTypes",
    label: "Audit Record",
  },
  dateFrom: {
    type: "datetime",
    label: "Start*",
    required: true,
  },
  dateTo: {
    type: "datetime",
    label: "End*",
    required: true,
  },
  dateRange: {
    type: "string",
    label: "Default Date Ranges",
    required: false,
  },
};

export default AuditFields;
