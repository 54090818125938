const initialData = {
  saveLoading: false,
  legalHolds: [],
  contentSearches: [],
  exportPolicies: [],
  modalOpen: false,
  moduleIds: [],
  exportNamesList: [],
};

export default (state = initialData, { type, payload }) => {
  if (type === "EXPORT_LEGAL_CASE_FORM_CREATE_STARTED") {
    return {
      ...state,
      saveLoading: true,
    };
  }

  if (type === "EXPORT_LEGAL_CASE_FORM_CREATE_SUCCESS") {
    return {
      ...state,
      saveLoading: false,
    };
  }

  if (type === "EXPORT_LEGAL_CASE_FORM_CREATE_ERROR") {
    return {
      ...state,
      saveLoading: false,
    };
  }

  if (type === "EXPORT_FORM_FIND_LEGALHOLDS_SUCCESS") {
    return {
      ...state,
      legalHolds: payload,
    };
  }

  if (type === "EXPORT_FORM_FIND_CONTENT_SEARCHES_SUCCESS") {
    return {
      ...state,
      contentSearches: payload,
    };
  }

  if (type === "EXPORT_FORM_FIND_EXPORT_POLICIES_SUCCESS") {
    return {
      ...state,
      exportPolicies: payload,
    };
  }
  if (type === "EXPORT_FORM_FIND_EXPORT_MODULES_SUCCESS") {
    return {
      ...state,
      moduleIds: payload,
    };
  }
  if (type === "EXPORT_FORM_FIND_EXPORT_NAMES_SUCCESS") {
    return {
      ...state,
      exportNamesList: payload,
    };
  }
  if (type === "EXPORT_FORM_FIND_ERROR") {
    return {
      ...state,
      legalHolds: [],
      contentSearches: [],
      exportPolicies: [],
      exportNamesList: [],
    };
  }
  if (type === "EXPORT_FORM_OPEN_CONFIRM") {
    return {
      ...state,
      modalOpen: true,
    };
  }

  if (type === "EXPORT_FORM_CLOSE_CONFIRM") {
    return {
      ...state,
      modalOpen: false,
    };
  }

  return state;
};
