import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import s from 'components/Tooltip/Tooltip.module.scss';
export default class LegalCaseTooltip extends Component {
  customStyle = {
    position: 'absolute',
    zIndex: 1000,
    padding: 16,
    width: '400px',
    fontSize: '12.25px',
    top: this.props.position.top + 10,
    left: this.props.position.left + 20,
    display: this.props.position.top ? 'block' : 'none'
  };

  render() {
    return ReactDOM.createPortal(
      <div className={s.tooltip} style={this.customStyle}>
        {this.props.message}
      </div>,
      document.querySelector('#modal-root')
    );
  }
}
