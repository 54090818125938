const initialData = {
  findLoading: false,
  saveLoading: false,
  record: null,
  modalOpen: false,
  findStatsLoading: false,
  stats: null
};

export default (state = initialData, { type, payload, ...rest }) => {
  if (type === 'LEGALCASE_FORM_RESET') {
    return {
      ...initialData,
    };
  }

  if (type === 'LEGALCASE_FORM_FIND_STARTED') {
    return {
      ...state,
      record: null,
      findLoading: true,
    };
  }

  if (type === 'LEGALCASE_FORM_FIND_SUCCESS') {
    return {
      ...state,
      record: payload,
      findLoading: false,
    };
  }

  if (type === 'LEGALCASE_FORM_STATS_FIND_STARTED') {
    return {
      ...state,
      stats: null,
      findStatsLoading: true,
    };
  }

  if (type === 'LEGALCASE_FORM_STATS_FIND_SUCCESS') {
    return {
      ...state,
      stats: payload,
      findStatsLoading: false,
    };
  }

  if (type === 'LEGALCASE_FORM_FIND_ERROR') {
    return {
      ...state,
      record: null,
      findLoading: false,
    };
  }

  if (type === 'LEGAL_CASE_USERS_FETCH_STARTED') {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === 'LEGAL_CASE_USERS_FETCH_SUCCESS') {
    return {
      ...state,
      loading: false,
      caseUsers: payload.caseUsers,
    };
  }

  if (type === 'LEGAL_CASE_USERS_LIST_FETCH_ERROR') {
    return {
      ...state,
      loading: false,
      caseUsers: [],
    };
  }

  if (type === 'LEGALCASE_FORM_CREATE_STARTED') {
    return {
      ...state,
      saveLoading: true,
    };
  }

  if (type === 'LEGALCASE_FORM_CREATE_SUCCESS') {
    return {
      ...state,
      saveLoading: false,
      modalOpen: false
    };
  }

  if (type === 'LEGALCASE_FORM_CREATE_ERROR') {
    return {
      ...state,
      saveLoading: false,
      modalOpen: false
    };
  }

  if (type === 'LEGALCASE_FORM_UPDATE_STARTED') {
    return {
      ...state,
      saveLoading: true,
    };
  }

  if (type === 'LEGALCASE_FORM_UPDATE_SUCCESS') {
    return {
      ...state,
      saveLoading: false,
    };
  }

  if (type === 'LEGALCASE_FORM_UPDATE_ERROR') {
    return {
      ...state,
      saveLoading: false,
    };
  }

  return state;
};
