import React, { Component } from 'react';
import { Formik } from 'formik';
import InputFormItem from 'components/FormItems/items/InputFormItem';
import LegalHoldFields from '../LegalHoldFields.js';
import formActions from 'actions/LegalHold/LegalHoldFormActions.js';
import { connect } from 'react-redux';
import * as Yup from 'yup';

class ExternalCustodiansForm extends Component {
  iniValues() {
    const record = {
      emailAddress: '',
      displayName: '',
      companyName: '',
      userId: ''
    };
    return record;
  }

  FormValidationSchema = [
    Yup.object().shape({
      emailAddress: Yup.string().trim().required('Email Address* is a required field')
    }),
    Yup.object().shape({
      emailAddress: Yup.string()
        .trim()
        .test(
          'email-address',
          'At least one of email address or user ID is required',
          function (value) {
            if (!value && !this.parent.userId) return false;
            if (value?.length === 0 && this.parent.userId?.length === 0) {
              return false;
            } else {
              return true;
            }
          }
        ),
      userId: Yup.string()
        .trim()
        .test('user-id', 'At least one of email address or user ID is required', function (value) {
          if (!value && !this.parent.emailAddress) return false;
          if (value?.length === 0 && this.parent.emailAddress?.length === 0) {
            return false;
          } else {
            return true;
          }
        })
    })
  ];

  handleSubmit = (values, actions) => {
    const { dispatch } = this.props;
    const currentValue = values;
    const found = this.props?.selectedExternalCustodians?.find(
      (item) => item.emailAddress === currentValue.emailAddress
    );
    if (!found) {
      let externalArray = [...this.props.selectedExternalCustodians, currentValue];
      dispatch(formActions.doSetSelectedExternalCustodians(externalArray)).then(() => {
        const externalIndex = this.props?.selectedExternalCustodians?.length - 1;
        let formattedCustodian = {
          custodianId: externalIndex,
          displayName: currentValue.displayName,
          companyName: currentValue.companyName,
          smtpAddress: currentValue.emailAddress,
          userId: currentValue.userId,
          isExternal: true
        };
        let currentList = [...this.props.selectedCustodiansList, formattedCustodian];
        dispatch(formActions.doSetSelectedCustodians(currentList));
      });
    }
    actions.resetForm();
  };

  validateEmailAddress = (value) => {
    if (!value) {
      return undefined;
    }
    let error;
    this.props?.selectedExternalCustodians?.forEach((item) => {
      const selectedAddress = item?.emailAddress?.toLowerCase().trim();
      const newAddress = value.toLowerCase().trim();
      if (selectedAddress === newAddress) error = 'This email address already exists';
    });
    return error;
  };

  render() {
    return (
      <div>
        <Formik
          onSubmit={this.handleSubmit}
          initialValues={this.iniValues()}
          validationSchema={
            !this.props.isChat ? this.FormValidationSchema[0] : this.FormValidationSchema[1]
          }
        >
          {(form) => {
            return (
              <form onSubmit={form.handleSubmit}>
                <div>
                  <InputFormItem
                    name={'emailAddress'}
                    schema={LegalHoldFields}
                    autoFocus
                    validate={this.validateEmailAddress}
                  />
                </div>
                {this.props.isChat && (
                  <div>
                    <InputFormItem name={'userId'} schema={LegalHoldFields} autoFocus />
                  </div>
                )}
                <div>
                  <InputFormItem name={'displayName'} schema={LegalHoldFields} autoFocus />
                </div>
                <div>
                  <InputFormItem name={'companyName'} schema={LegalHoldFields} autoFocus />
                </div>
                <div className='d-flex justify-content-center align-items-center mt-4'>
                  <button
                    className='btn me-3 first-body-text w-200'
                    type='button'
                    onClick={form.handleSubmit}
                    style={{ height: '37px' }}
                  >
                    Add
                  </button>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    selectedCustodiansList: store.LegalHold.form.selectedCustodiansList,
    selectedExternalCustodians: store.LegalHold.form.selectedExternalCustodians
  };
}
export default connect(mapStateToProps)(ExternalCustodiansForm);
