import React, { useState, useEffect } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import 'rc-slider/assets/index.css';
import config from 'config';
import { store } from '../../index';
import holdListActions from 'actions/LegalHold/LegalHoldListActions';
import { defaultOptions } from '../../services/userService';
import { toast } from 'react-toastify';

export default function RefreshInterval() {
  const [value, setValue] = useState(15);
  const currentUser = store.getState().auth.currentUser;
  const onSliderChange = (value) => {
    setValue(value);
  };
  const saveRefreshInterval = () => {
    const { name: firstName, surName: lastName, email, userName } = currentUser || {};
    const expireonUser = {
      firstName,
      lastName,
      email,
      userName
    };
    const data = {
      settingName: 'RefreshInterval',
      settingValue: JSON.stringify(value),
      expireonUser
    };

    fetch(`${config.externalApi}/api/Widget/AddInterval`, {
      method: 'POST',
      ...defaultOptions,
      body: JSON.stringify(data)
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Request failed with status ${response.status}`);
        }
        toast.success('Refresh interval updated successfully', { icon: false });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getRefreshInterval = () => {
    const { dispatch } = store;
    dispatch(holdListActions.doGetRefreshInterval(currentUser.userName)).then(() => {
      const interval = store.getState().LegalHold.list.refreshInterval;
      setValue(JSON.parse(interval));
    });
  };
  useEffect(() => {
    getRefreshInterval();
  }, []);
  return (
    <>
      <div>
        <p style={{ fontWeight: 'bold' }}>{value} s</p>
        <div style={{ display: 'flex', padding: '20px' }}>
          <span style={{ marginRight: '20px', marginTop: '-4px' }}>15</span>
          <Slider
            min={15}
            defaultValue={15}
            max={300}
            value={value}
            onChange={onSliderChange}
            railStyle={{
              height: 2
            }}
            handleStyle={{
              height: 18,
              width: 18,
              marginLeft: -8,
              marginTop: -8,
              backgroundColor: 'blue',
              border: 0
            }}
            trackStyle={{
              background: 'white'
            }}
          />
          <span style={{ marginLeft: '20px', marginTop: '-4px' }}>300</span>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginRight: '30px'
        }}
      >
        <button
          className='btn first-body-text d-flex align-items-center'
          onClick={saveRefreshInterval}
        >
          Save
        </button>
      </div>
    </>
  );
}
