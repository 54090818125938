const initialData = {
  rows: [],
  loading: false,
  count: 0,
  exportStatsList: [],
  exportStatsCount: 0,
  exportJob: {},
  showTableView: true
};

export default (state = initialData, { type, payload }) => {
  if (type === 'EXPORT_LIST_FETCH_STARTED') {
    return {
      ...state,
      loading: true
    };
  }

  if (type === 'EXPORT_LIST_FETCH_SUCCESS') {
    return {
      ...state,
      loading: false,
      rows: payload.rows,
      count: payload.count
    };
  }

  if (type === 'EXPORT_LIST_FETCH_ERROR') {
    return {
      ...state,
      loading: false,
      rows: []
    };
  }

  if (type === 'EXPORT_POLICY_STATS_FETCH_SUCCESS') {
    return {
      ...state,
      exportStatsList: payload.exportStatsList,
      exportStatsCount: payload.count
    };
  }

  if (type === 'EXPORT_POLICY_STATS_FETCH_ERROR') {
    return {
      ...state,
      exportStatsList: []
    };
  }

  if (type === 'EXPORT_JOB_FETCH_SUCCESS') {
    return {
      ...state,
      exportJob: payload.exportJob
    };
  }

  if (type === 'EXPORT_JOB_FETCH_ERROR') {
    return {
      ...state,
      exportJob: {}
    };
  }
  if (type === 'EXPORT_LIST_SHOW_TABLE_CONFIRM') {
    return {
      ...state,
      showTableView: true
    };
  }
  if (type === 'EXPORT_LIST_HIDE_TABLE_CONFIRM') {
    return {
      ...state,
      showTableView: false
    };
  }

  return state;
};
