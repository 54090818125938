import Errors from 'components/FormItems/error/errors';
import config from '../../config';
import { Client } from '../../legal-api';
import { defaultOptions } from '../../services/userService';
import { store } from 'index';
import { isLegalAdmin } from 'utils/isLegalAdmin';
export function getPageRangeDisplayed(currentPage) {
  if (currentPage > 999) {
    return 2;
  }
  if (currentPage > 99) {
    return 3;
  }
  return 5;
}

async function list(legalCaseId, searchString, pageNumber, pageSize, sortOrder) {
  const currentUser = store.getState().auth?.currentUser;
  const legalAdmin = isLegalAdmin(currentUser);
  const loggedUser = currentUser?.userName;
  const response = await new Client(config.externalApi).contentSearchAll(
      legalCaseId,
      sortOrder,
      undefined,
      searchString,
      pageNumber,
      pageSize,
      loggedUser,
      legalAdmin
  );
  return response;
}

async function getTreeNodes(legalCaseId, loggedUser) {
  let baseUrl = `${config.externalApi}/legal/api/ContentSearch/GetMockupNodes?`;

  if (legalCaseId !== undefined && legalCaseId !== null && legalCaseId !== '') {
    baseUrl += 'legalCaseId=' + encodeURIComponent('' + legalCaseId) + '&';
  }
  if (loggedUser !== undefined && loggedUser !== '') {
    baseUrl += 'loggedUser=' + encodeURIComponent('' + loggedUser) + '&';
  }
  baseUrl = baseUrl.replace(/[?&]$/, '');

  const response = await fetch(baseUrl, {
    ...defaultOptions,
    method: 'GET'
  });

  const data = await response.json();
  return data;
}

async function detectLanguage(text) {
  const url = `${config.aiExternalApi}/api/Translation/detect-language`;
  const data = { text };
  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: defaultOptions?.headers?.Authorization,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  };
  let response = await fetch(url, requestOptions);
  return response.text();
}

async function translateEmail(text, sourceLanguage, targetLanguage) {
  const url = `${config.aiExternalApi}/api/Translation/translate`;
  const data = { text, sourceLanguage, targetLanguage };
  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: defaultOptions?.headers?.Authorization,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  };
  const response = await fetch(url, requestOptions);
  return await response.text();
}

async function analyzeSentiment(text) {
  const url = `${config.aiExternalApi}/api/SentimentAnalysis/analyze-sentiment`;
  const data = { input: text };
  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: defaultOptions?.headers?.Authorization,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  };
  const response = await fetch(url, requestOptions);
  return await response.json();
}

async function extractKeyPhrases(text) {
  const url = `${config.aiExternalApi}/api/SentimentAnalysis/extract-key-phrases`;
  const data = { input: text };
  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: defaultOptions?.headers?.Authorization,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  };
  const response = await fetch(url, requestOptions);
  return await response.json();
}

async function summarizeEmail(text) {
  const url = `${config.aiExternalApi}/api/Summarize/summarize`;
  const data = { input: text };
  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: defaultOptions?.headers?.Authorization,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  };
  const response = await fetch(url, requestOptions);
  return await response.text();
}

async function getActionItems(text) {
  const url = `${config.aiExternalApi}/api/Summarize/action-items`;
  const data = { input: text };
  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: defaultOptions?.headers?.Authorization,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  };
  const response = await fetch(url, requestOptions);
  return await response.json();
}

async function getTopics(text) {
  const url = `${config.aiExternalApi}/api/Summarize/topics`;
  const data = { input: text };
  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: defaultOptions?.headers?.Authorization,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  };
  const response = await fetch(url, requestOptions);
  return await response.json();
}

const actions = {
  doFetch:
      (filter, keepPagination = false) =>
          async (dispatch, getState) => {
            try {
              dispatch({
                type: 'SEARCH_LIST_FETCH_STARTED',
                payload: { filter, keepPagination }
              });

              const response = await list(
                  filter.legalCaseId,
                  filter.searchString,
                  filter.pageNumber,
                  filter.pageSize,
                  filter.sortOrder
              );
              dispatch({
                type: 'SEARCH_LIST_FETCH_SUCCESS',
                payload: {
                  rows: response.contentSearches,
                  count: response.totalCount
                }
              });
            } catch (error) {
              Errors.handle(error);
              dispatch({
                type: 'SEARCH_LIST_FETCH_ERROR'
              });
            }
          },
  doOpenConfirm: () => async (dispatch) => {
    dispatch({
      type: 'SEARCH_LIST_OPEN_CONFIRM'
    });
  },
  doCloseConfirm: () => async (dispatch) => {
    dispatch({
      type: 'SEARCH_LIST_CLOSE_CONFIRM'
    });
  },
  doShowTable: () => async (dispatch) => {
    dispatch({
      type: 'SEARCH_LIST_SHOW_TABLE_CONFIRM'
    });
  },
  doHideTable: () => async (dispatch) => {
    dispatch({
      type: 'SEARCH_LIST_HIDE_TABLE_CONFIRM'
    });
  },
  doDiscardSearch: (id) => async (dispatch) => {
    dispatch({
      type: 'SEARCH_DISCARD_CONFIRM'
    });
  },
  doToggleDiscardSearch: () => async (dispatch) => {
    dispatch({
      type: 'SEARCH_DISCARD_TOGGLE'
    });
  },
  doFetchTreeNodes: (legalCaseId, loggedUser) => async (dispatch) => {
    try {
      dispatch({
        type: 'TREE_NODES_FETCH_STARTED'
      });

      const response = await getTreeNodes(legalCaseId, loggedUser);
      dispatch({
        type: 'TREE_NODES_FETCH_SUCCESS',
        payload: response
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'TREE_NODES_FETCH_ERROR'
      });
    }
  },
  doDetectLanguage: (text) => async (dispatch) => {
    try {
      dispatch({
        type: 'DETECT_LANGUAGE_STARTED'
      });
      const response = await detectLanguage(text);

      dispatch({
        type: 'DETECT_LANGUAGE_SUCCESS',
        payload: response
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'DETECT_LANGUAGE_ERROR'
      });
    }
  },
  doTranslateEmail: (text, sourceLanguage, targetLanguage) => async (dispatch) => {
    try {
      dispatch({
        type: 'EMAIL_TRANSLATION_STARTED'
      });
      const response = await translateEmail(text, sourceLanguage, targetLanguage);

      dispatch({
        type: 'EMAIL_TRANSLATION_SUCCESS',
        payload: response
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'EMAIL_TRANSLATION_ERROR'
      });
    }
  },
  doAnalyzeSentiment: (text) => async (dispatch) => {
    try {
      dispatch({
        type: 'ANALYZE_SENTIMENT_STARTED'
      });
      const response = await analyzeSentiment(text);

      dispatch({
        type: 'ANALYZE_SENTIMENT_SUCCESS',
        payload: response
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'ANALYZE_SENTIMENT_ERROR'
      });
    }
  },
  doExtractKeyPhrases: (text) => async (dispatch) => {
    try {
      dispatch({
        type: 'KEY_PHRASES_EXTRACTION_STARTED'
      });
      const response = await extractKeyPhrases(text);
      dispatch({
        type: 'KEY_PHRASES_EXTRACTION_SUCCESS',
        payload: response
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'KEY_PHRASES_EXTRACTION_ERROR'
      });
    }
  },
  doSummarizeEmail: (text) => async (dispatch) => {
    try {
      dispatch({
        type: 'SUMMARIZE_EMAIL_STARTED'
      });
      const response = await summarizeEmail(text);

      dispatch({
        type: 'SUMMARIZE_EMAIL_SUCCESS',
        payload: response
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'SUMMARIZE_EMAIL_ERROR'
      });
    }
  },
  doGetActionItems: (text) => async (dispatch) => {
    try {
      dispatch({
        type: 'ACTION_ITEMS_FETCH_STARTED'
      });
      const response = await getActionItems(text);

      dispatch({
        type: 'ACTION_ITEMS_FETCH_SUCCESS',
        payload: response?.actionItems
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'ACTION_ITEMS_FETCH_ERROR'
      });
    }
  },
  doGetTopics: (text) => async (dispatch) => {
    try {
      dispatch({
        type: 'TOPICS_FETCH_STARTED'
      });
      const response = await getTopics(text);

      dispatch({
        type: 'TOPICS_FETCH_SUCCESS',
        payload: response?.topics
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'TOPICS_FETCH_ERROR'
      });
    }
  }
};

export default actions;
