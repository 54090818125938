import React from "react";
import ReportsTable from "./ReportsTable";
import EmailPoliciesTable from "./EmailPoliciesTable";
import LocationPoliciesTable from "./LocationPoliciesTable";

class ReportsPage extends React.Component {
  render() {
    return (
      <>
        <div>
          <ReportsTable />
        </div>
        <div style={{ marginTop: "50px" }}>
          <EmailPoliciesTable />
        </div>
        <div style={{ marginTop: "50px" }}>
          <LocationPoliciesTable />
        </div>
      </>
    );
  }
}

export default ReportsPage;
