import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'utils/withRouter';
import s from './Sidebar.module.scss';
import LinksGroup from './LinksGroup/LinksGroup';
import { changeActiveSidebarItem } from '../../actions/navigation';
import logo from '../../images/expireon-logo.png';
import homeIcon from 'images/home.svg';
import gavelIcon from 'images/gavel-solid.svg';
import lockIcon from 'images/lock-500.svg';
import { signoutRedirect } from '../../services/userService';
import exportIcon from 'images/box-arrow-right.svg';
import activityIcon from 'images/activity-white.svg';
import reportIcon from 'images/icons/report.svg';

class Sidebar extends React.Component {
  static propTypes = {
    sidebarStatic: PropTypes.bool,
    sidebarOpened: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    activeItem: PropTypes.string,
    location: PropTypes.shape({
      pathname: PropTypes.string
    }).isRequired
  };

  static defaultProps = {
    sidebarStatic: false,
    activeItem: ''
  };

  constructor(props) {
    super(props);

    this.doLogout = this.doLogout.bind(this);
  }

  doLogout() {
    signoutRedirect();
  }

  render() {
    const roleName = this.props?.currentUser?.roles[0];
    return (
      <>
        {this.props?.currentUser?.isAuthenticated ? (
          <div
            className={`${
              !this.props.sidebarOpened && !this.props.sidebarStatic ? s.sidebarClose : ''
            } ${s.sidebarWrapper}`}
          >
            <nav
              onMouseEnter={this.onMouseEnter}
              onMouseLeave={this.onMouseLeave}
              className={s.root}
            >
              <header className={s.logo}>
                <img
                  src={logo}
                  alt={'expireon'}
                  className={'img-fluid'}
                  width={'100px'}
                  height={'100px'}
                  onClick={()=> this.props.navigate('/app/Dashboard')}
                />
              </header>
              {!(
                this.props.permissions['LegalUI.Credential'] &&
                this.props.permissions['LegalUI.Credential.KeyManagement'] &&
                Object.keys(this.props.permissions).length === 2
              ) && (
                <ul className={s.nav}>
                  <LinksGroup
                    onActiveSidebarItemChange={(activeItem) =>
                      this.props.dispatch(changeActiveSidebarItem(activeItem))
                    }
                    activeItem={this.props.activeItem}
                    header='Dashboard'
                    link='/app/Dashboard'
                    isHeader
                    iconImage={homeIcon}
                  />
                  {this.props.permissions['LegalUI.Cases'] ? (
                    <LinksGroup
                      onActiveSidebarItemChange={(activeItem) =>
                        this.props.dispatch(changeActiveSidebarItem(activeItem))
                      }
                      activeItem={this.props.activeItem}
                      header='Active Cases'
                      link='/app/LegalCase'
                      isHeader
                      iconImage={gavelIcon}
                    />
                  ) : null}
                  {this.props.permissions['LegalUI.CaseManager'] ? (
                    <LinksGroup
                      onActiveSidebarItemChange={(activeItem) =>
                        this.props.dispatch(changeActiveSidebarItem(activeItem))
                      }
                      activeItem={this.props.activeItem}
                      header='Case Manager'
                      link='/app/CaseManager'
                      isHeader
                      iconImage={lockIcon}
                    />
                  ) : null}
                  {this.props.permissions['LegalUI.Export'] ? (
                    <LinksGroup
                      onActiveSidebarItemChange={(activeItem) =>
                        this.props.dispatch(changeActiveSidebarItem(activeItem))
                      }
                      activeItem={this.props.activeItem}
                      header='Export'
                      link='/app/Export'
                      isHeader
                      iconImage={exportIcon}
                    />
                  ) : null}
                  {this.props.permissions['LegalUI.Audit'] ? (
                    <LinksGroup
                      onActiveSidebarItemChange={(activeItem) =>
                        this.props.dispatch(changeActiveSidebarItem(activeItem))
                      }
                      activeItem={this.props.activeItem}
                      header='Audit'
                      link='/app/Audit'
                      isHeader
                      iconImage={activityIcon}
                    />
                  ) : null}
                  {this.props.permissions['LegalUI.Report'] ? (
                    <LinksGroup
                      onActiveSidebarItemChange={(activeItem) =>
                        this.props.dispatch(changeActiveSidebarItem(activeItem))
                      }
                      activeItem={this.props.activeItem}
                      header='Reports'
                      link='/app/Reports'
                      isHeader
                      iconImage={reportIcon}
                    />
                  ) : null}
                </ul>
              )}
            </nav>
          </div>
        ) : null}
      </>
    );
  }
}

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
    sidebarStatic: store.navigation.sidebarStatic,
    activeItem: store.navigation.activeItem,
    currentUser: store.auth.currentUser,
    permissions: store.auth.permissions
  };
}

export default withRouter(connect(mapStateToProps)(Sidebar));
