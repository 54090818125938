import React, { Component } from 'react';
import Loader from 'components/Loader';
import Widget from 'components/Widget';
import { Col, Row } from 'reactstrap';
import backIcon from '../../../../images/icons/arrow-left.svg';
import editIcon from '../../../../images/icons/edit.svg';
import userIcon from '../../../../images/icons/person.svg';
import deleteIcon from '../../../../images/icons/close.svg';
import LegalHoldListPage from '../../LegalHold/list/LegalHoldListPage';
import SearchListPage from '../../Search/list/SearchListPage';
import { FormattingService } from '../../../../utils/sizeFormatting';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import viewerActions from 'actions/viewers/LegalCaseViewerFormActions';
import LegalCaseViewerFormPage from '../form/LegalCaseViewerFormPage';
import LegalCaseFormPage from '../form/LegalCaseFormPage';
import caseActions from 'actions/LegalCase/LegalCaseListActions';
import ExportListPage from 'components/CRUD/Export/list/ExportListPage';
import { store } from '../../../../index';
import lockIcon from 'images/icons/lockIcon.svg';
import { Formik } from 'formik';
import TextAreaFormItem from 'components/FormItems/items/TextAreaFormItem';
import * as Yup from 'yup';
import colorFormatter from '../../../../core/colorFormatter';
import restoreIcon from 'images/icons/window-restore.svg';
import LegalCaseTooltip from './LegalCaseTooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { emptyDataMessage, customCloseBtn } from 'actions/common';
import styles from 'components/CRUD/LegalCase/list/styles.module.scss';
import * as dataFormat from 'components/CRUD/LegalHold/list/LegalHoldDataFormatters';
import { isLegalAdmin } from 'utils/isLegalAdmin';
class LegalCaseView extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    isEditing: false,
    currentViewer: {},
    openDeleteModal: false,
    openCloseCaseModal: false,
    infoMessage:
      'Restoring of Index is in progress. Please wait until it is finished to index any Legal Hold or preview any Content Search. Creation of Legal holds or Export jobs can still be performed',
    openInfoTooltip: false,
    infoPosition: {
      top: 0,
      left: 0
    },
    openInfoModal: false
  };

  componentDidUpdate(prevProps) {
    if (prevProps.legalHoldsList !== this.props?.legalHoldsList) {
      this.setState({
        openInfoModal: true
      });
    }
  }

  openViewModal() {
    const { dispatch } = this.props;
    dispatch(viewerActions.doOpenConfirm());
  }

  closeViewModal() {
    const { dispatch } = this.props;
    dispatch(viewerActions.doCloseConfirm());
  }

  openEditModal() {
    const { dispatch } = this.props;
    this.setState({
      isEditing: true
    });
    dispatch(caseActions.doOpenConfirm());
  }

  closeEditModal() {
    const { dispatch } = this.props;
    this.setState({
      isEditing: false
    });
    dispatch(caseActions.doCloseConfirm());
  }

  openDeleteModal(currentViewer) {
    this.setState({
      openDeleteModal: true,
      currentViewer: currentViewer
    });
  }

  closeDeleteModal() {
    this.setState({
      openDeleteModal: false
    });
  }

  deleteExpireonViewer(currentViewer) {
    const { dispatch, navigate } = this.props;
    const legalCaseId = this.props.record?.legalCaseId;
    const email = currentViewer?.email;
    dispatch(viewerActions.doDeleteExpireonViewer(legalCaseId, email, navigate)).then(() => {
      this.closeDeleteModal();
    });
  }

  openCloseCaseModal() {
    this.setState({
      openCloseCaseModal: true
    });
  }

  toggleCloseCaseModal() {
    this.setState({
      openCloseCaseModal: false
    });
  }

  toggleInfoModal() {
    this.setState({
      openInfoModal: !this.state.openInfoModal
    });
  }

  legalHoldsToData(array) {
    let formattedArray = [];
    array.forEach((element) => {
      formattedArray.push({ legalHoldName: element });
    });
    return formattedArray;
  }

  handleSubmit = (values) => {
    const { dispatch, navigate } = this.props;
    const legalCaseId = this.props?.record?.legalCaseId;
    const expireonUser = this.props?.record?.expireonUser;
    const closureReason = values.closureReason;
    dispatch(caseActions.doCloseCase(legalCaseId, expireonUser, closureReason, navigate));
    this.toggleCloseCaseModal();
  };

  caseStatusFormatter = (caseStatus) => {
    return (
      <>
        <div
          style={{
            width: 8,
            height: 8,
            backgroundColor: colorFormatter(caseStatus),
            borderRadius: '50%'
          }}
          className={'me-2 d-inline-block'}
        />
        {caseStatus}
      </>
    );
  };

  formValidations = () => {
    return Yup.object().shape({
      closureReason: Yup.string().trim().required('Case Closure Reason* is a required field')
    });
  };

  renderView() {
    const toReadableSizeFormat = new FormattingService();

    const attachmentsCount =
      this.props?.stats &&
      toReadableSizeFormat.getBytesReadable(
        this.props?.stats.summaryCaseStatistics.itemSizeInBytes
      );
    const currentUser = store.getState().auth?.currentUser;
    const isLegaladmin = isLegalAdmin(currentUser);
    return (
      <>
        <Row>
          <Col sm={12}>
            <div className={'d-flex mb-4 align-items-center'}>
              <button
                className={'btn bg-transparent me-2 ps-0'}
                onClick={() => this.props.navigate('/app/LegalCase')}
              >
                <img src={backIcon} alt={'arrow-back'} />
              </button>
              <h3 className={'first-headline-text me-4 mb-0'}>{this.props.record?.caseName}</h3>
            </div>
          </Col>
        </Row>
        <Row>
          {this.state.openInfoTooltip && (
            <LegalCaseTooltip position={this.state.infoPosition} message={this.state.infoMessage} />
          )}
          <Col sm={4}>
            <Widget
              className={`mb-0`}
              bodyClass={'h-100 w-200 d-flex flex-column justify-content-between'}
            >
              <p className='d-flex align-items-center justify-content-between '>
                <small
                  className={'text-muted case-info-text second-body-text'}
                  style={{
                    whiteSpace: 'pre-wrap',
                    overflowWrap: 'break-word',
                    wordBreak: 'break-all'
                  }}
                >
                  Reference ID: {this.props.record.caseReferenceId} --{' '}
                  {this.caseStatusFormatter(this.props.record?.caseStatus)}
                </small>
                {this.props.record?.restoreInProgress && !this.props.record?.restoreComplete && (
                  <p
                    className='mb-0 cursor-pointer'
                    onMouseOver={(e) => {
                      this.setState({
                        openInfoTooltip: true,
                        infoPosition: {
                          top: e.pageY,
                          left: e.pageX
                        }
                      });
                    }}
                    onMouseLeave={() => {
                      this.setState({
                        openInfoTooltip: false,
                        infoPosition: {
                          top: 0,
                          left: 0
                        }
                      });
                    }}
                  >
                    <img
                      src={restoreIcon}
                      alt={'restore'}
                      width='14px'
                      height='14px'
                      className='me-2'
                    />

                    <span className='mb-0'>Restoring Index</span>
                  </p>
                )}
              </p>

              <h5 className={'second-headline-text mb-1'} style={{ marginTop: 12 }}>
                {this.props.record?.caseName}
              </h5>
              <div
                style={{
                  maxHeight: '180px',
                  display: 'flex',
                  overflowY: 'scroll',
                  flexWrap: 'wrap'
                }}
              >
                <small className='first-caption-text'>{this.props.record?.caseDescription}</small>
              </div>
              <div className={'d-flex'} style={{ marginTop: 12 }}>
                <small className={'pe-3 text-muted case-info-text second-body-text'}>
                  Custodians: {this.props.record?.custodianCount}
                </small>
                <small className={'pe-3 text-muted case-info-text second-body-text'}>
                  Holds: {this.props.record?.holdCount}
                </small>
                <small className={'pe-3 text-muted case-info-text second-body-text'}>
                  Searches: {this.props.record?.contentSearchCount}
                </small>
                <small className={'text-muted case-info-text second-body-text'}>
                  Exports: {this.props.record?.exportCount}
                </small>
              </div>
              <div>
                {this.props.record.expireonUser?.firstName && (
                  <small className={'text-muted case-info-text second-body-text'}>
                    Created by: {this.props.record.expireonUser?.firstName}{' '}
                    {this.props.record.expireonUser?.lastName} -{' '}
                    {dataFormat.dateTimeToStringFormatter(this.props.record?.createdTime)}
                  </small>
                )}
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '5px'
                }}
              >
                <div>
                  {this.props.permissions['LegalUI.Cases.Edit'] ? (
                    <div
                      style={{
                        marginRight: '5px'
                      }}
                    >
                      <button
                        style={{ borderRadius: '0' }}
                        className='btn first-body-text me-2 ms-10 d-flex align-items-center justify-content-center'
                        type='button'
                        onClick={() => this.openEditModal()}
                      >
                        <img src={editIcon} alt={'plus'} className={'me-2'} />
                        <span>Properties</span>
                      </button>
                    </div>
                  ) : null}
                </div>
                <div>
                  {currentUser?.userName === 'admin' ||
                  currentUser?.userName === this.props?.record?.expireonUser?.userName ||
                  isLegaladmin === true ? (
                    <button
                      style={{ borderRadius: '0' }}
                      className='btn first-body-text me-2 ms-10 d-flex align-items-center justify-content-center'
                      type='button'
                      onClick={() => this.openCloseCaseModal()}
                    >
                      <img src={lockIcon} alt={'lock'} className={'me-2'} />
                      <span>Case Status</span>
                    </button>
                  ) : null}
                </div>
              </div>
            </Widget>
          </Col>
          <Col sm={4}>
            <Widget
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column'
              }}
              className={' mb-0'}
            >
              <h5
                className={'second-headline-text mb-1 mt-0'}
                style={{ marginTop: 12, marginBottom: '15px' }}
              >
                Case Users
              </h5>
              <div className={styles.caseUsersContainer}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    margin: 'auto 5%'
                  }}
                >
                  <div>
                    <span className='me-2'>{this.props?.record?.expireonUser?.firstName}</span>
                    <span>{this.props?.record?.expireonUser?.lastName}</span>
                  </div>
                  <div>Case Owner</div>
                </div>
                <div>
                  {this.props?.record?.expireonViewers?.map((item, index) => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          margin: 'auto 5%'
                        }}
                        key={index}
                      >
                        <div>
                          {' '}
                          {item.firstName} {item.lastName}
                        </div>
                        <div className='me-1'>
                          <span>Viewer</span>
                          {currentUser?.userName === 'admin' ||
                          currentUser?.userName === this.props?.record?.expireonUser?.userName ||
                          isLegaladmin === true ? (
                            <span
                              style={{ marginLeft: '5px' }}
                              onClick={() => this.openDeleteModal(item)}
                            >
                              <img
                                src={deleteIcon}
                                alt={'delete'}
                                width={'20px'}
                                height={'20px'}
                                style={{
                                  cursor: 'pointer',
                                  marginRight: '0.5rem'
                                }}
                              />
                            </span>
                          ) : null}
                        </div>
                      </div>
                    );
                  })}
                </div>
                {currentUser?.userName === 'admin' ||
                currentUser?.userName === this.props?.record?.expireonUser?.userName ||
                isLegaladmin === true ? (
                  <button
                    style={{
                      borderRadius: '0',
                      right: '20px',
                      bottom: '20px',
                      position: 'absolute'
                    }}
                    className='btn first-body-text me-2 ms-10 d-flex align-items-center justify-content-center'
                    type='button'
                    onClick={() => this.openViewModal()}
                  >
                    <img src={userIcon} alt={'plus'} className={'me-2'} />
                    <span>Change Users</span>
                  </button>
                ) : null}
              </div>
            </Widget>
          </Col>
          <Col sm={4}>
            <Widget
              style={{ height: '100%' }}
              className={' mb-0'}
              bodyClass={'h-100 d-flex flex-column justify-content-center'}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  maxHeight: '250px'
                }}
              >
                <div className={'text-center mb-2'}>
                  <h4 className={'text-center mb-2 first-headline-text'}>Total Items Count</h4>
                  <h3 className={'first-subtitle-text d-inline-block mb-0'}>
                    {toReadableSizeFormat.numberWithCommas(this.props.stats?.totalItemCount || 0)}
                  </h3>
                </div>
                <div className={'text-center mb-2'}>
                  <h4 className={'text-center mb-2 first-headline-text'}>Total Items Size</h4>
                  <h3 className={'first-subtitle-text d-inline-block'}>{attachmentsCount}</h3>
                </div>
              </div>
            </Widget>
          </Col>
        </Row>
        <Row>
          <Col sm={12} style={{ marginTop: 40 }}>
            <LegalHoldListPage legalCaseId={this.props.record?.legalCaseId} />
          </Col>
        </Row>
        <Row>
          <Col sm={12} style={{ marginTop: 40 }}>
            <SearchListPage
              legalCaseId={this.props.record?.legalCaseId}
              caseStatus={this.props.record?.caseStatus}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12} style={{ marginTop: 40 }}>
            <ExportListPage legalCaseId={this.props.record?.legalCaseId} />
          </Col>
        </Row>
        <Modal
          size='md'
          isOpen={this.props.openViewerModal}
          className={`themeStyle${this.props.currentTheme?.replace('#', '')}`}
        >
          <ModalHeader close={customCloseBtn(() => this.closeViewModal())}>
            <p className='second-headline-text'>Add Viewers</p>
          </ModalHeader>
          <ModalBody>
            <LegalCaseViewerFormPage
              dispatch={this.props.dispatch}
              legalCaseId={this.props.record?.legalCaseId}
            />
          </ModalBody>
        </Modal>
        <Modal
          size='md'
          isOpen={this.props.openEditModal}
          className={`themeStyle${this.props.currentTheme?.replace('#', '')}`}
        >
          <ModalHeader close={customCloseBtn(() => this.closeEditModal())}>
            <p className={'second-headline-text'}>Case Information</p>
          </ModalHeader>
          <ModalBody>
            <LegalCaseFormPage
              isEditing={this.state.isEditing}
              legalCaseId={this.props.record?.legalCaseId}
            />
          </ModalBody>
        </Modal>
        <Modal
          size='md'
          isOpen={this.state.openDeleteModal}
          className={`themeStyle${this.props.currentTheme?.replace('#', '')}`}
        >
          <ModalHeader close={customCloseBtn(() => this.closeDeleteModal())}>
            <p className='second-headline-text'>Confirm</p>
          </ModalHeader>
          <ModalBody>
            <>
              <div style={{ marginBottom: '20px' }}>
                {` Are you sure you want to remove ${this.state.currentViewer.firstName} ${this.state.currentViewer.lastName} as a viewer?`}
              </div>
              <div className='d-flex align-items-center justify-content-center'>
                <button
                  className='btn secondary-btn text-white first-body-text d-flex align-items-center justify-content-center me-3'
                  type='button'
                  onClick={() => this.closeDeleteModal()}
                >
                  Cancel
                </button>
                <button
                  className='btn danger-btn text-white first-body-text d-flex align-items-center justify-content-center'
                  type='button'
                  onClick={() => this.deleteExpireonViewer(this.state.currentViewer)}
                >
                  Delete
                </button>
              </div>
            </>
          </ModalBody>
        </Modal>
        <Modal
          size='md'
          isOpen={this.state.openCloseCaseModal}
          className={`themeStyle${this.props.currentTheme?.replace('#', '')}`}
        >
          <ModalHeader close={customCloseBtn(() => this.toggleCloseCaseModal())}>
            <p className='second-headline-text'>Confirm</p>
          </ModalHeader>
          <ModalBody>
            <div>
              <Formik
                onSubmit={this.handleSubmit}
                initialValues={{ closureReason: '' }}
                validationSchema={this.formValidations()}
              >
                {(form) => {
                  return (
                    <form onSubmit={form.handleSubmit}>
                      <TextAreaFormItem
                        name={'closureReason'}
                        schema={{
                          closureReason: {
                            type: 'string',
                            label: 'Case Closure Reason*',
                            required: true
                          }
                        }}
                        textAreaStyle={{ height: 60 }}
                      />
                      <div className='d-flex justify-content-center align-items-center'>
                        <button
                          type='button'
                          className='btn secondary-btn first-body-text me-3 mt-4'
                          onClick={() => this.toggleCloseCaseModal()}
                        >
                          Cancel
                        </button>
                        <button
                          type='button'
                          className='btn text-white first-body-text d-flex align-items-center justify-content-center mt-4'
                          onClick={form.handleSubmit}
                        >
                          Close Case
                        </button>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          size='md'
          isOpen={this.state.openInfoModal}
          className={`themeStyle${this.props.currentTheme?.replace('#', '')}`}
        >
          <ModalHeader
            close={customCloseBtn(() =>
              this.setState({
                openInfoModal: false
              })
            )}
          ></ModalHeader>
          <ModalBody>
            <div>
              <div className={styles.popupHeader}>
                Unable to close this case as indexing of the displayed Legal holds is still in
                progress. Please wait until it is finished.
              </div>
              <div className={styles.popupTableContainer}>
                <ToolkitProvider
                  bootstrap4
                  columns={[
                    {
                      dataField: 'legalHoldName',
                      text: 'Legal Holds'
                    }
                  ]}
                  data={this.legalHoldsToData(this.props.legalHoldsList)}
                  keyField='legalHoldName'
                >
                  {(props) => (
                    <>
                      <BootstrapTable
                        bordered={true}
                        classes={`table-striped table-hover mt-4 fs-sm ${styles.popupTable}`}
                        noDataIndication={emptyDataMessage}
                        {...props.baseProps}
                      />
                    </>
                  )}
                </ToolkitProvider>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }

  render() {
    const { record, loading } = this.props;

    if (loading || !record) {
      return <Loader />;
    }

    return this.renderView();
  }
}

export default LegalCaseView;
