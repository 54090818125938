import React, { Component } from 'react';
import {
  Col,
  Row,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ModalHeader,
  ModalBody,
  Modal
} from 'reactstrap';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import actions from 'actions/AuditLogs/AuditLogsListActions';
import caseActions from 'actions/LegalCase/LegalCaseListActions';
import caseFormActions from 'actions/LegalCase/LegalCaseFormActions';
import { connect } from 'react-redux';
import Widget from 'components/Widget/Widget';
import Loader from '../../Loader';
import gavelIcon from 'images/gavel-solid.svg';
import exportIcon from 'images/box-arrow-right.svg';
import { FiSettings } from 'react-icons/fi';
import { FaBalanceScale } from 'react-icons/fa';
import { FiSearch } from 'react-icons/fi';
import paginationStyles from 'components/CRUD/LegalHold/list/LegalHoldList.module.scss';
import Pagination from 'react-js-pagination';
import { getPageRangeDisplayed } from 'actions/search/searchListActions';
import styles from 'components/CRUD/AuditLogs/Audit.module.scss';
import { ErrorMessage, Formik } from 'formik';
import LegalHoldFields from '../LegalHold/LegalHoldFields';
import SelectFormItem from '../../FormItems/items/SelectFormItem';
import AuditFields from './AuditFields';
import DatePickerFormItem from '../../FormItems/items/DatePickerFormItem';
import PreparedValues from '../../FormItems/preparedValues';
import cx from 'classnames';
import IniValues from '../../FormItems/iniValues';
import * as Yup from 'yup';
import formActions from 'actions/AuditLogs/AuditLogsFormActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTable } from '@fortawesome/free-solid-svg-icons';
import AuditRecordsTable from './AuditRecordsTable';
import caretDown from 'images/icons/caret-down.svg';
import * as LhdataFormat from 'components/CRUD/LegalHold/list/LegalHoldDataFormatters';
import AuditTemplateForm from 'components/CRUD/AuditLogs/form/AuditTemplateForm';
import { customCloseBtn } from 'actions/common';
import AuditDetailedView from './AuditDetailedView';

const DATE_RANGE_OPTIONS = {
  lAST_3_DAYS: 'Last 3 days',
  lAST_7_DAYS: 'Last 7 days',
  lAST_30_DAYS: 'Last 30 days',
  lAST_3_MONTHS: 'Last 3 months',
  lAST_YEAR: 'Last year'
};

const DATE_RANGE_OPTIONS_MAP = {
  lAST_3_DAYS: 'lAST_3_DAYS',
  lAST_7_DAYS: 'lAST_7_DAYS',
  lAST_30_DAYS: 'lAST_30_DAYS',
  lAST_3_MONTHS: 'lAST_3_MONTHS',
  lAST_YEAR: 'lAST_YEAR'
};

class Audit extends Component {
  state = {
    newRows: [],
    totalCount: 0,
    pageNumber: 1,
    pageSize: 10,
    paginationModalOpen: false,
    auditGroups: [],
    displayQuickResult: false,
    displayDetailedView: false,
    widgetActiveIndex: 0,
    dateFrom: null,
    dateTo: null,
    auditDetails: {},
    caseUsers: [],
    openTemplateModal: false,
    legalCaseName: null,
    auditRecordNames: [],
    userNames: [],
    reportTemplateType: 0,
    filteredUsers: [],
    showTable: false,
    from: null,
    to: null
  };

  changeTab(field, id) {
    this.setState({
      [field]: id
    });
  }

  handleCaseUserSelect = (form) => {
    if (form.values?.users?.includes('all')) {
      form.values.users = ['all'];
    }
  };

  handleAuditRecordSelect = (form) => {
    if (form.values?.auditTypes?.includes('all')) {
      form.values.auditTypes = ['all'];
    }
  };

  handleSimplifiedCaseSelect = (form) => {
    if (this.state?.legalCaseId !== form?.values?.legalCaseId) {
      const { dispatch, caseUsers } = this.props;
      if (form?.values?.legalCaseId) {
        dispatch(caseFormActions.doGetUsersByCase(form?.values?.legalCaseId)).then(() => {
          form.setFieldValue('users', ['all']);
          this.setState({
            caseUsers,
            legalCaseId: form?.values?.legalCaseId
          });
        });
      }
    }
  };

  toData = (data) => {
    const arr = [];
    if (typeof data === 'string') {
      return data;
    }
    if (Array.isArray(data)) {
      for (let key in data) {
        const obj = {};
        obj.value = data[key];
        obj.label = data[key];
        arr.push(obj);
      }
    } else {
      for (let key in data) {
        const obj = {};
        obj.value = key;
        obj.label = data[key];
        arr.push(obj);
      }
    }
    return arr;
  };

  renameData = (data, type) => {
    const arr = [];
    if (Array.isArray(data) && type === 'cases') {
      for (let key of data) {
        const obj = {};
        obj.value = key?.legalCaseId;
        obj.label = key?.caseName;
        arr.push(obj);
      }
    }
    if (Array.isArray(data) && type === 'records') {
      if (data.length) {
        arr.push({
          value: 'all',
          label: 'All Records'
        });
      }
      for (let key of data) {
        const obj = {};
        obj.value = key.value;
        obj.label = key.key;
        arr.push(obj);
      }
    }
    return arr;
  };

  usersOptions = (data) => {
    const arr = [
      {
        value: 'all',
        label: 'All Users'
      }
    ];
    if (Array.isArray(data)) {
      for (let key of data) {
        const obj = {};
        obj.value = key.email;
        obj.label = key.userName;
        arr.push(obj);
      }
    }
    return arr;
  };

  handleDateRangeSelect(form) {
    if (form.values?.dateRange) {
      form.values.dateTo = new Date();
      form.values.dateFrom = new Date();
      form.values.dateFrom.setHours(0, 0, 0);
      form.values.dateTo.setHours(23, 59, 59, 999);
      if (form.values.dateRange === DATE_RANGE_OPTIONS_MAP.lAST_3_DAYS) {
        form.values.dateFrom.setDate(form.values.dateFrom.getDate() - 3);
      }
      if (form.values.dateRange === DATE_RANGE_OPTIONS_MAP.lAST_7_DAYS) {
        form.values.dateFrom.setDate(form.values.dateFrom.getDate() - 7);
      }
      if (form.values.dateRange === DATE_RANGE_OPTIONS_MAP.lAST_30_DAYS) {
        form.values.dateFrom.setMonth(form.values.dateFrom.getMonth() - 1);
      }
      if (form.values.dateRange === DATE_RANGE_OPTIONS_MAP.lAST_3_MONTHS) {
        form.values.dateFrom.setMonth(form.values.dateFrom.getMonth() - 3);
      }
      if (form.values.dateRange === DATE_RANGE_OPTIONS_MAP.lAST_YEAR) {
        form.values.dateFrom.setFullYear(form.values.dateFrom.getFullYear() - 1);
      }
    }
  }

  auditGroupConverter = (auditGroup) => {
    const result = this.state.auditGroups?.find((item) => item.value === auditGroup);
    return result.key;
  };

  auditGroupFormatter = (auditGroup) => {
    const result = this.auditGroupConverter(auditGroup);
    switch (result) {
      case 'LegalCase':
        return (
          <span className={styles.icon}>
            <img src={gavelIcon} alt='Legal Case' />
          </span>
        );
      case 'LegalHold':
        return <FaBalanceScale className={styles.settingsIcon} />;
      case 'ContentSearch':
        return <FiSearch className={styles.settingsIcon} />;
      case 'Export':
        return (
          <span className={styles.icon}>
            <img src={exportIcon} alt='Export' />
          </span>
        );
      case 'Other':
        return <FiSettings className={styles.settingsIcon} />;
      default:
        return (
          <span className={styles.icon}>
            <img src={gavelIcon} alt='Other' />
          </span>
        );
    }
  };

  displayAuditDetails(auditEntryId, index) {
    const { dispatch } = this.props;
    dispatch(actions.doFetchAuditEntry(auditEntryId)).then(() => {
      this.setState({
        widgetActiveIndex: index,
        displayDetailedView: true,
        auditDetails: this.props?.auditEntryDetails
      });
    });
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(actions.doGetAuditGroups()).then(() => {
      this.setState({
        auditGroups: this.props?.auditGroups
      });
    });

    dispatch(actions.doGetAuditRecords()).then(() => {
      this.setState({
        auditRecords: this.props.auditRecords
      });
    });

    dispatch(caseActions.doGetSimplifiedCases()).then(() => {
      this.setState({
        simplifiedCases: this.props.simplifiedCases
      });
    });

    dispatch(formActions.doFetchDateRanges()).then(() => {
      this.setState({
        dateRanges: this.props?.dateRanges
      });
    });

    dispatch(formActions.doFetchReportTemplateType()).then(() => {
      this.props.reportTemplateType?.map((item) => {
        if (item.key === 'AuditReport') {
          this.setState({
            reportTemplateType: item.value
          });
        }
      });
    });

    dispatch(formActions.doFetchAllExpireonUsers()).then(() => {
      this.setState({
        allExpireonUsers: this.props.allExpireonUsers
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { dispatch, currentUser } = this.props;
    const params = {
      pageSize: this.state.pageSize,
      pageNumber: this.state.pageNumber,
      legalCaseId: this.state.legalCaseId,
      users: this.state.users,
      auditTypes: this.state.auditTypes,
      from: this.state.dateFrom,
      to: this.state.dateTo,
      loggedUser: currentUser?.userName
    };
    if (prevState.pageNumber !== this.state.pageNumber) {
      dispatch(actions.doFetch(params)).then(() => {
        this.setState(
          {
            newRows: this.props.rows,
            totalCount: this.props.count,
            widgetActiveIndex: 0,
            auditEntryId: this.props?.rows ? this.props?.rows[0]?.auditEntryId : null
          },
          () => {
            if (this.state.widgetActiveIndex !== null) {
              this.displayAuditDetails(this.state.auditEntryId, this.state.widgetActiveIndex);
            }
          }
        );
      });
    }
    if (prevState.pageSize !== this.state.pageSize) {
      dispatch(actions.doFetch(params)).then(() => {
        this.setState({
          newRows: this.props.rows,
          totalCount: this.props.count,
          widgetActiveIndex: 0,
          auditEntryId: this.props?.rows ? this.props?.rows[0]?.auditEntryId : null
        });
        if (this.state.widgetActiveIndex !== null) {
          this.displayAuditDetails(this.state.auditEntryId, this.state.widgetActiveIndex);
        }
      });
    }
    if (prevProps.rows !== this.props.rows) {
      this.setState({
        newRows: this.props.rows,
        totalCount: this.props.count
      });
    }
  }

  handleSubmit = (form) => {
    const { id, ...data } = PreparedValues(AuditFields, form || {});
    const { dispatch, currentUser } = this.props;
    this.setState({
      displayQuickResult: true,
      displayDetailedView: false,
      widgetActiveIndex: null,
      pageNumber: 1,
      pageSize: 10,
      legalCaseId: data.legalCaseId,
      users: data.users,
      auditTypes: data.auditTypes,
      from: data.dateFrom,
      to: data.dateTo
    });
    dispatch(
      actions.doFetch({
        pageNumber: this.state.pageNumber,
        pageSize: this.state.pageSize,
        legalCaseId: data.legalCaseId,
        users: data.users,
        auditTypes: data.auditTypes,
        from: data.dateFrom,
        to: data.dateTo,
        loggedUser: currentUser?.userName
      })
    );
    this.setState({
      displayQuickResult: true,
      legalCaseId: data.legalCaseId,
      users: data.users,
      auditTypes: data.auditTypes,
      dateFrom: data.dateFrom,
      dateTo: data.dateTo
    });
  };

  openTemplateModal(form) {
    this.setState({
      openTemplateModal: true,
      dateFrom: form?.values?.dateFrom,
      dateTo: form?.values?.dateTo,
      userNames: form?.values?.userNames,
      auditRecordNames: form?.values?.auditRecordNames,
      legalCaseName: form?.values?.legalCaseName
    });
    let arr = [];
    if (form?.values?.users?.length > 0 && !form?.values?.users?.includes('all')) {
      form?.values?.users?.map((item) => {
        let selectedUser = this.props?.caseUsers?.find((user) => user.email === item);
        arr.push(selectedUser);
      });
    }

    this.setState({
      filteredUsers: arr
    });
  }

  closeTemplateModal() {
    this.setState({
      openTemplateModal: false
    });
  }

  handleSubmitTemplate = (values) => {
    const { dispatch, currentUser } = this.props;
    const { name: firstName, surName: lastName, email, userName } = currentUser || {};
    const data = {
      name: values?.name,
      description: values?.description,
      owner: {
        firstName,
        lastName,
        email,
        userName
      },
      coOwners: values?.templateUsers,
      reportTemplateType: this.state.reportTemplateType,
      filters: {
        legalCaseId: this.state.legalCaseId,
        dateRange: JSON.parse(values?.dateRanges),
        users: this.state?.filteredUsers,
        auditTypes: this.state.auditTypes.includes('all') ? [] : this.state.auditTypes
      }
    };
    dispatch(actions.doSaveReportTemplate(data)).then(() =>
      this.setState({
        openTemplateModal: false
      })
    );
  };

  handleReset = (form) => {
    this.setState({
      displayQuickResult: false,
      displayDetailedView: false,
      widgetActiveIndex: null,
      legalCaseId: null,
      caseUsers: [],
      showTable: false
    });
  };

  iniValues = () => {
    const record = {
      users: ['all'],
      auditTypes: ['all'],
      dateRange: 'lAST_3_MONTHS'
    };
    return IniValues(AuditFields, record);
  };

  FormValidationSchema = Yup.object().shape({
    legalCaseId: Yup.string().nullable(true).required('Cases* is a required field'),
    dateFrom: Yup.mixed()
      .required('Start Date is a required field')
      .when('dateTo', (dateTo, schema) => {
        return schema.test({
          test: function (dateFrom) {
            return Date.parse(dateTo) > Date.parse(dateFrom);
          },
          message: 'Start date must be earlier than end date'
        });
      }),
    dateTo: Yup.mixed().required('End Date is a required field')
  });

  handlePageChange = (pageNumber) => {
    this.setState({ pageNumber: pageNumber });
  };

  toggleView() {
    this.setState({
      showTable: !this.state.showTable
    });
  }

  renderForm() {
    return (
      <>
        <Widget className={styles.container}>
          <Row>
            <Col sm={12}>
              <div className={styles.header}>
                <h3 className={'first-headline-text  me-4 mb-0'}>Audit</h3>
                {this.state.displayQuickResult && (
                  <button
                    className={`btn fw-semi-bold d-flex align-items-center ${styles.toggleButton}`}
                    type='button'
                    onClick={() => this.toggleView()}
                  >
                    <FontAwesomeIcon icon={faTable} />
                  </button>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <Widget className={styles.widget}>
                <h4 className={'second-headline-text mb-3'}>Filter</h4>
                <div className={styles.filterContainer}>
                  <Formik
                    onSubmit={this.handleSubmit}
                    onReset={this.handleReset}
                    initialValues={this.iniValues()}
                    validationSchema={this.FormValidationSchema}
                  >
                    {(form) => {
                      return (
                        <form onSubmit={form.handleSubmit} onReset={form.handleReset}>
                          <Row>
                            <Col sm={12}>
                              <SelectFormItem
                                name={'legalCaseId'}
                                schema={AuditFields}
                                placeholder={'Select case'}
                                onChange={this.handleSimplifiedCaseSelect(form)}
                                mode={'fetch'}
                                isClearable={false}
                                data={this.renameData(this.props?.simplifiedCases, 'cases')}
                              />
                              <ErrorMessage name='legalCaseId'>
                                {(msg) => <div className={styles.errorMessage}>{msg}</div>}
                              </ErrorMessage>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={12}>
                              <SelectFormItem
                                name={'users'}
                                schema={AuditFields}
                                isMulti={true}
                                placeholder={'All Users'}
                                onChange={this.handleCaseUserSelect(form)}
                                mode={'fetch'}
                                data={this.usersOptions(this.state?.caseUsers)}
                                isRecord={true}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={12}>
                              <SelectFormItem
                                name={'auditTypes'}
                                schema={AuditFields}
                                isMulti={true}
                                placeholder={'All Records'}
                                onChange={this.handleAuditRecordSelect(form)}
                                mode={'fetch'}
                                isRecord={true}
                                data={this.renameData(this.state?.auditRecords, 'records')}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={12}>
                              <SelectFormItem
                                name={'dateRange'}
                                schema={AuditFields}
                                placeholder={'Between'}
                                onChange={this.handleDateRangeSelect(form)}
                                mode={'fetch'}
                                isClearable={false}
                                data={this.toData(DATE_RANGE_OPTIONS)}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={12}>
                              <DatePickerFormItem
                                name={'dateFrom'}
                                schema={AuditFields}
                                showTimeInput
                              />
                              <ErrorMessage name='dateFrom'>
                                {(msg) => <div className={styles.errorMessage}>{msg}</div>}
                              </ErrorMessage>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={12}>
                              <DatePickerFormItem
                                name={'dateTo'}
                                schema={LegalHoldFields}
                                showTimeInput
                              />
                              <ErrorMessage name='dateTo'>
                                {(msg) => <div className={styles.errorMessage}>{msg}</div>}
                              </ErrorMessage>
                            </Col>
                          </Row>
                          <Row style={{ marginTop: '10px' }}>
                            <Col sm={6}>
                              <button
                                className='btn secondary-btn w-100 first-body-text'
                                type='button'
                                style={{ minWidth: '50%' }}
                                onClick={form.handleReset}
                              >
                                Clear
                              </button>
                            </Col>
                            <Col sm={6}>
                              <button
                                className='btn w-100 first-body-text'
                                type='button'
                                style={{ minWidth: '50%' }}
                                onClick={form.handleSubmit}
                              >
                                Search
                              </button>
                            </Col>
                          </Row>
                          <Row style={{ marginTop: '10px' }}>
                            <Col sm={12}>
                              <button
                                className='btn secondary-btn w-100 first-body-text'
                                type='button'
                                style={{ minWidth: '50%' }}
                                disabled={!this.state.displayQuickResult}
                                onClick={() => this.openTemplateModal(form)}
                              >
                                Save as Template
                              </button>
                            </Col>
                          </Row>
                        </form>
                      );
                    }}
                  </Formik>
                </div>
              </Widget>
            </Col>
            <Modal
              size='md'
              isOpen={this.state.openTemplateModal}
              className={`themeStyle${this.props.currentTheme?.replace('#', '')}`}
            >
              <ModalHeader
                close={customCloseBtn(() => this.closeTemplateModal())}
                className={'fw-bold'}
              >
                <p className='second-headline-text'>Template</p>
              </ModalHeader>
              <ModalBody>
                <>
                  <AuditTemplateForm
                    allExpireonUsers={this.state.allExpireonUsers}
                    dateRanges={this.props?.dateRanges}
                    legalCaseName={this.state.legalCaseName}
                    userNames={this.state?.userNames}
                    auditRecordNames={this.state.auditRecordNames}
                    dateFrom={this.state?.dateFrom?.toString()}
                    dateTo={this.state?.dateTo?.toString()}
                    dispatch={this.props.dispatch}
                    doSubmit={this.handleSubmitTemplate}
                  />
                </>
              </ModalBody>
            </Modal>
            {this.state.showTable && (
              <Col sm={9}>
                <AuditRecordsTable
                  params={{
                    pageNumber: this.state.pageNumber,
                    pageSize: this.state.pageSize,
                    legalCaseId: this.state.legalCaseId,
                    users: this.state.users,
                    auditTypes: this.state.auditTypes,
                    from: this.state.from,
                    to: this.state.to,
                    loggedUser: this.props?.currentUser?.userName
                  }}
                />
              </Col>
            )}
            {this.state.displayQuickResult && !this.state.showTable ? (
              <Col sm={4}>
                <Widget className={styles.widget}>
                  <h4 className={'second-headline-text mb-3'}>Quick</h4>
                  <div className={styles.quickResultContainer}>
                    <div className={styles.auditItemsList}>
                      {this.props.loading ? (
                        <Loader />
                      ) : this.state.totalCount === 0 ? (
                        <h3 className={styles.noRecordsText}>No Records available</h3>
                      ) : (
                        <>
                          {this.state.newRows?.map((item, index) => {
                            return (
                              <Widget
                                key={item.auditEntryId}
                                onClick={() => {
                                  this.displayAuditDetails(item?.auditEntryId, index);
                                }}
                                className={cx({
                                  [styles.activeWidget]: this.state.widgetActiveIndex === index
                                })}
                              >
                                <div style={{ maxHeight: '150px' }}>
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'flex-end',
                                      alignItems: 'center'
                                    }}
                                  >
                                    <small className={'text-muted second-body-text'}>
                                      {LhdataFormat.dateTimeFormatter(item.recordDateUtc)}
                                    </small>
                                  </div>
                                  <div className='first-body-text '>
                                    {this.auditGroupFormatter(item?.auditGroup)}
                                    <span>{item?.expireonUser?.userName} </span>
                                  </div>
                                  <div className={`${styles.auditType} second-body-text`}>
                                    {item?.auditType}
                                  </div>
                                </div>
                              </Widget>
                            );
                          })}
                        </>
                      )}
                    </div>
                    <div className={styles.pagination}>
                      {this.state.totalCount !== 0 && <div>Total: {this.state.totalCount}</div>}

                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end'
                        }}
                      >
                        {this.state.totalCount ? (
                          <div style={{ marginRight: '5px' }}>
                            <Pagination
                              totalItemsCount={this.state.totalCount}
                              onChange={this.handlePageChange}
                              activePage={this.state.pageNumber}
                              itemsCountPerPage={this.state.pageSize}
                              pageRangeDisplayed={getPageRangeDisplayed(this.state.pageNumber)}
                              prevPageText={'<'}
                              nextPageText={'>'}
                              firstPageText={'<<'}
                              lastPageText={'>>'}
                              linkClassFirst={paginationStyles.linkNext}
                              linkClassPrev={paginationStyles.linkNext}
                              linkClassNext={paginationStyles.linkNext}
                              linkClassLast={paginationStyles.linkNext}
                              linkClass={paginationStyles.linkClass}
                              activeLinkClass={paginationStyles.activeLinkClass}
                              activeClass={paginationStyles.activeLinkClass}
                            />
                          </div>
                        ) : null}
                        {this.state.totalCount ? (
                          <Dropdown
                            isOpen={this.state.paginationModalOpen}
                            toggle={() =>
                              this.setState({
                                paginationModalOpen: !this.state.paginationModalOpen
                              })
                            }
                            className={paginationStyles.dropdownClass}
                          >
                            <DropdownToggle className='bg-transparent text-white d-flex'>
                              <p
                                style={{
                                  marginBottom: '3px',
                                  marginRight: '2px'
                                }}
                                className='first-body-text'
                              >
                                {this.state.pageSize}
                              </p>
                              <img
                                src={caretDown}
                                alt={'uncollapsed'}
                                width='20px'
                                height='20px'
                                className='ms-2'
                              />
                            </DropdownToggle>
                            <DropdownMenu className='dropdown-position-fixed'>
                              {[10, 15, 25].map((item) => (
                                <DropdownItem
                                  className={paginationStyles.dropdownItem}
                                  key={item}
                                  onClick={() => {
                                    this.setState({
                                      pageSize: item,
                                      paginationModalOpen: false,
                                      pageNumber: 1
                                    });
                                  }}
                                >
                                  {item}
                                </DropdownItem>
                              ))}
                            </DropdownMenu>
                          </Dropdown>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </Widget>
              </Col>
            ) : null}

            {this.state.displayDetailedView && !this.state.showTable ? (
              <Col sm={5}>
                <AuditDetailedView auditDetails={this.state.auditDetails} />
              </Col>
            ) : null}
          </Row>
        </Widget>
      </>
    );
  }

  render() {
    const { loading, rows } = this.props;

    if (loading) {
      return (
        <div className={'hw-100'}>
          <Loader />
        </div>
      );
    }

    if (loading && !rows) {
      return (
        <div className={'hw-100'}>
          <Loader />
        </div>
      );
    }

    return this.renderForm();
  }
}

function mapStateToProps(store) {
  return {
    loading: store.LegalCase.list.loading,
    rows: store.AuditLogs.list.rows,
    auditRecords: store.AuditLogs.list.auditRecords,
    simplifiedCases: store.LegalCase.list.simplifiedCases,
    caseUsers: store.LegalCase.form.caseUsers,
    count: store.AuditLogs.list.count,
    modalOpen: store.LegalCase.list.modalOpen,
    permissions: store.auth.permissions,
    currentUser: store.auth.currentUser,
    auditGroups: store.AuditLogs.list.auditGroups,
    auditEntryDetails: store.AuditLogs.list.auditEntryDetails,
    dateRanges: store.AuditLogs.form.dateRanges,
    reportTemplateType: store.AuditLogs.form.reportTemplateType,
    allExpireonUsers: store.AuditLogs.form.allExpireonUsers,
    currentTheme: store.layout.currentTheme
  };
}

export default connect(mapStateToProps)(Audit);
