import React, { Component } from 'react';
import SearchView from 'components/CRUD/Search/view/SearchView';
import actions from 'actions/search/searchFormActions';
import { connect } from 'react-redux';
import { Client } from '../../../../legal-api';
import config from '../../../../config';
import { Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';
import backIcon from 'images/icons/arrow-left.svg';
import { toast } from 'react-toastify';
import listActions from 'actions/search/searchListActions';
import moment from 'moment';
import s from 'components/CRUD/Search/itemContent/styles.module.scss';
import { defaultOptions } from '../../../../services/userService';
import { withRouter } from 'utils/withRouter';

class SearchPage extends Component {
  state = {
    attachmentTypes: null,
    timer: null,
    promptVisible: false,
    discardClicked: false,
    saveClicked: false,
    enteredPage: false
  };

  handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = '';
  };

  handleBackButton = (event) => {
    const { params, navigate } = this.props;
    this.setState({
      promptVisible: true
    });
    if (this.state.saveClicked) {
      navigate(`/app/LegalCase/${params?.caseId}`, { replace: true });
    } else if (this.state.discardClicked) {
      navigate(`/app/LegalCase/${params?.caseId}`, { replace: true });
    } else {
      if (this.state.enteredPage) {
        navigate(`/app/search/${params?.id}/${params?.caseId}`);
        navigate(`/app/search/${params?.id}/${params?.caseId}`, { replace: true });
      }
    }
  };

  componentDidMount() {
    const { dispatch, params } = this.props;
    const { name: firstName, surName: lastName, email, userName } = this.props?.currentUser || {};
    const expireonUser = {
      firstName,
      lastName,
      email,
      userName
    };
    dispatch(actions.doFetchContentSearch(params?.id, expireonUser));
    dispatch(actions.doGetAllTags());
    dispatch(actions.doGetAllTagGroups());
    new Client(config.externalApi).contentSearchAttachmentsGET().then((res) => {
      this.setState({
        attachmentTypes: res
      });
    });
    this.setState({
      enteredPage: true
    });
  }

  calculateTimeDifference = (currentTime, createdTime) => {
    const currentDateTime = new Date(currentTime);
    const createdDateTime = new Date(createdTime);
    const timeDifference = currentDateTime - createdDateTime;
    const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
    const minutesDifference = Math.floor((timeDifference / (1000 * 60)) % 60);
    const minutesLeft = 30 - minutesDifference;
    if (hoursDifference === 0 && minutesLeft > 0) {
      const minutesLeftInMilliseconds = minutesLeft * 60 * 1000;
      const timer = setTimeout(this.displayPrompt, minutesLeftInMilliseconds);
      this.setState({ timer });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { navigate, params, contentSearch } = this.props;
    if (prevProps.contentSearch !== contentSearch) {
      let currentTime = new Date();
      currentTime = moment().utc(false).format('YYYY-MM-DD HH:mm');
      let createdTime = contentSearch?.creationDate;
      createdTime = moment(createdTime).format('YYYY-MM-DD HH:mm');
      this.calculateTimeDifference(currentTime, createdTime);
    }
    if (prevProps.contentSearch !== contentSearch && !contentSearch?.isSaved) {
      window.addEventListener('beforeunload', this.handleBeforeUnload);
      window.addEventListener('popstate', this.handleBackButton);
    }
    if (prevState.saveClicked !== this.state.saveClicked && this.state.saveClicked === true) {
      navigate(`/app/LegalCase/${params?.caseId}`);
    }
    if (
      prevState.discardClicked !== this.state.discardClicked &&
      this.state.discardClicked === true
    ) {
      navigate(`/app/LegalCase/${params?.caseId}`);
    }
    if (prevState.discardClicked !== this.state.discardClicked) {
      this.handleBackButton();
    }
    if (prevState.saveClicked !== this.state.saveClicked) {
      this.handleBackButton();
    }
  }

  componentWillUnmount() {
    const { timer } = this.state;
    clearTimeout(timer);
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
    window.removeEventListener('popstate', this.handleBackButton);
    this.setState({
      discardClicked: false,
      saveClicked: false,
      enteredPage: false
    });
  }

  displayPrompt = () => {
    if (!this.props.contentSearch?.isSaved) {
      this.setState({ promptVisible: true });
    }
  };

  saveContentSearch = async () => {
    const { params, contentSearch } = this.props;
    const { legalCaseId, contentSearchId, expireonUser } = contentSearch;

    if (this.props.record?.itemCount === 0) {
      toast.error('The Content Search has zero results and cannot be saved', { icon: false });
    } else {
      this.setState(
        {
          promptVisible: false,
          saveClicked: true
        },
        () => {
          this.handleBackButton();
        }
      );

      try {
        const params = {
          legalCaseId,
          contentSearchId,
          expireonUser
        };

        await fetch(`${config.externalApi}/legal/api/ContentSearch/SaveContentSearch`, {
          method: 'POST',
          headers: defaultOptions.headers,
          body: JSON.stringify(params)
        });

        toast.success('Content Search created', { icon: false });
        localStorage.removeItem(`${contentSearch?.name}-${params?.caseId}`);
      } catch (error) {
        console.error(error);
      }
    }
  };

  discardContentSearch = async () => {
    const { dispatch, params, contentSearch } = this.props;
    const expireonUser = contentSearch?.expireonUser;
    this.setState(
      {
        promptVisible: false,
        discardClicked: true
      },
      () => {
        this.handleBackButton();
      }
    );

    try {
      await fetch(
        `${config.externalApi}/legal/api/ContentSearch/${params?.id}/DeleteContentSearch`,
        {
          method: 'PUT',
          headers: defaultOptions.headers,
          body: JSON.stringify(expireonUser)
        }
      );

      dispatch(listActions.doDiscardSearch());
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    const { params, navigate } = this.props;
    return (
      <React.Fragment>
        <Row sm={12}>
          {this.props?.isSaved === true ? (
            <Col sm={12}>
              <div className={'d-flex mb-4 align-items-center'}>
                <button className={'btn bg-transparent me-2 ps-0'} onClick={() => navigate(-1)}>
                  <img src={backIcon} alt={'arrow-back'} />
                </button>
                <h4
                  className={`${s.headerFont} mb-1 first-headline-text d-flex align-items-center mt-1`}
                >
                  Back to Case Details
                </h4>
              </div>
            </Col>
          ) : (
            <Col sm={12}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  marginBottom: '15px'
                }}
              >
                <button
                  className='btn danger-btn fw-semi-bold d-flex align-items-center me-3'
                  type='button'
                  onClick={() => this.discardContentSearch()}
                >
                  <p className={`${s.btnFont} mb-0`}>Discard</p>
                </button>
                <button
                  className='btn fw-semi-bold d-flex align-items-center'
                  type='button'
                  onClick={() => this.saveContentSearch()}
                >
                  <p className={`${s.btnFont} mb-0`}>Save</p>
                </button>
              </div>
            </Col>
          )}
        </Row>
        <SearchView
          findLoading={this.props.findLoading}
          record={this.props.record}
          contentSearchId={params?.id}
          legalCaseId={params?.caseId}
          navigate={this.props.navigate}
          params={this.props.params}
          attachmentTypes={this.state?.attachmentTypes}
          contentSearch={this.props.contentSearch}
        />
        <Modal
          size='md'
          isOpen={this.state.promptVisible}
          className={`themeStyle${this.props.currentTheme?.replace('#', '')}`}
        >
          <ModalHeader>
            <p className={'second-headline-text'}>Confirm</p>
          </ModalHeader>
          <ModalBody>
            <div>
              <div className={'mb-4 second-headline-text text-center'}>
                Please, save or discard the Content Search before leaving the page!
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: '15px',
                  marginTop: '15px'
                }}
              >
                <button
                  className='btn danger-btn fw-semi-bold d-flex align-items-center me-3'
                  type='button'
                  onClick={() => this.discardContentSearch()}
                >
                  <p className={'mb-0'}>Discard</p>
                </button>
                <button
                  className='btn fw-semi-bold d-flex align-items-center'
                  type='button'
                  onClick={() => this.saveContentSearch()}
                >
                  <p className={'mb-0'}>Save</p>
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

function mapStateToProps(store) {
  return {
    findLoading: store.search.form.findLoading,
    record: store.search.form.record,
    isSaved: store.search.form.isSaved,
    contentSearch: store.search.form.contentSearch,
    currentTheme: store.layout.currentTheme,
    currentUser: store.auth.currentUser
  };
}

export default withRouter(connect(mapStateToProps)(SearchPage));
