import React, { Component } from 'react';
import * as dataFormat from '../Search/list/SearchDataFormatters';
import eyeIcon from '../../../images/icons/eye.svg';
import styles from 'components/CRUD/AuditLogs/Audit.module.scss';
import * as LhdataFormat from 'components/CRUD/LegalHold/list/LegalHoldDataFormatters';
import Widget from 'components/Widget/Widget';

class AuditDetailedView extends Component {
  render() {
    const { auditDetails } = this.props;
    return (
      <div>
        <Widget className={styles.widget}>
          <div className={styles.detailsView}>
            <div>
              <h4 className={'second-headline-text mb-3'}>Detailed</h4>
            </div>
            <div>
              <Widget>
                <img src={eyeIcon} alt={'plus'} className={'me-2'} />
                <span className={'first-subtitle-text'}>{auditDetails?.auditType}</span>
                <p style={{ marginBottom: '0' }}>
                  <span
                    style={{
                      paddingRight: '5px'
                    }}
                    className='first-subtitle-text'
                  >
                    Performed By:
                  </span>
                  <span className='second-subtitle-text'>
                    {auditDetails?.expireonUser?.firstName} {auditDetails?.expireonUser?.lastName}
                  </span>
                </p>
                <p style={{ marginBottom: '0' }}>
                  <span
                    style={{
                      paddingRight: '5px'
                    }}
                    className='first-subtitle-text'
                  >
                    Performed On:
                  </span>
                  <span className='second-subtitle-text'>
                    {LhdataFormat.timeFormatter(auditDetails?.recordDateUtc)}
                  </span>
                </p>
              </Widget>
            </div>

            <div>
              {auditDetails?.legalCaseName && (
                <div>
                  <span className={`${styles.details} first-subtitle-text`}>Legal Case Name:</span>
                  <span className='second-subtitle-text'>{auditDetails?.legalCaseName}</span>
                </div>
              )}
              {auditDetails?.legalHold && (
                <>
                  <div>
                    <span className={`${styles.details} first-subtitle-text`}>
                      Legal Hold Name:
                    </span>
                    <span className='second-subtitle-text'>
                      {auditDetails?.legalHold?.legalHoldName}
                    </span>
                  </div>
                  <div>
                    <span className={`${styles.details} first-subtitle-text`}>
                      Legal Hold Description:
                    </span>
                    <span className='second-subtitle-text'>
                      {auditDetails?.legalHold?.legalHoldDescription}
                    </span>
                  </div>
                  <div>
                    <span className={`${styles.details} first-subtitle-text`}> From: </span>
                    <span className='second-subtitle-text'>
                      {dataFormat.dateTimeFormatter(auditDetails?.legalHold?.dateFrom)}
                    </span>
                  </div>
                  <div>
                    <span className={`${styles.details} first-subtitle-text`}> To: </span>
                    <span className='second-subtitle-text'>
                      {dataFormat.dateTimeFormatter(auditDetails?.legalHold?.dateTo)}
                    </span>
                  </div>
                </>
              )}
              {auditDetails?.contentSearch && (
                <>
                  <div>
                    <span className={`${styles.details} first-subtitle-text`}>
                      Content Search Name:
                    </span>
                    <span className='second-subtitle-text'>
                      {auditDetails?.contentSearch?.name}{' '}
                    </span>
                  </div>
                  <div>
                    <span className={`${styles.details} first-subtitle-text`}> Query:</span>
                    <span className='second-subtitle-text'>
                      {auditDetails?.contentSearch?.queryString?.query}
                    </span>
                  </div>
                  <div>
                    <span className={`${styles.details} first-subtitle-text`}>Created:</span>
                    <span className='second-subtitle-text'>
                      {LhdataFormat.dateTimeFormatter(auditDetails?.contentSearch?.creationDate)}
                    </span>
                  </div>
                  <div>
                    <span className={`${styles.details} first-subtitle-text`}>Search in:</span>
                    {auditDetails?.contentSearch?.searchInSubject && (
                      <span className={`${styles.bodyText} second-subtitle-text`}>
                        Subject
                        {`${auditDetails?.contentSearch?.searchInBody && ','}`}
                      </span>
                    )}
                    {auditDetails?.contentSearch?.searchInBody && (
                      <span className={`${styles.bodyText} second-subtitle-text`}>
                        Body
                        {`${auditDetails?.contentSearch?.searchInAttachment && ','}`}
                      </span>
                    )}
                    {auditDetails?.contentSearch?.searchInAttachment && (
                      <span className={`${styles.bodyText} second-subtitle-text`}>Attachment</span>
                    )}
                  </div>
                  <div>
                    <span className={`${styles.details} first-subtitle-text`}>Fuzziness:</span>
                    <span> {auditDetails?.contentSearch?.fuzzynessType}</span>
                  </div>
                </>
              )}
              {auditDetails?.exportJob && (
                <>
                  <div>
                    <span className={`${styles.details} first-subtitle-text`}>Export Name:</span>
                    <span className='second-subtitle-text'>{auditDetails?.exportJob?.name}</span>
                  </div>
                  <div>
                    <span className={`${styles.details} first-subtitle-text`}>Created:</span>
                    <span className='second-subtitle-text'>
                      {LhdataFormat.dateTimeFormatter(auditDetails?.exportJob?.created)}
                    </span>
                  </div>
                  {auditDetails?.exportJob?.legalHolds && (
                    <div>
                      <span className={`${styles.details} first-subtitle-text`}>
                        Exported Legal Holds:
                      </span>
                      {Object.entries(auditDetails?.exportJob?.legalHolds).map((item) => {
                        return (
                          <span key={item[0]} className={`${styles.bodyText} second-subtitle-text`}>
                            {item[1]}
                          </span>
                        );
                      })}
                    </div>
                  )}
                </>
              )}
              {auditDetails?.exportJob?.contentSearches && (
                <div>
                  <span className={`${styles.details} first-subtitle-text`}>
                    Exported Content Searches:
                  </span>
                  {Object.entries(auditDetails?.exportJob?.contentSearches).map((item) => {
                    return (
                      <span key={item[0]} className={`${styles.bodyText} second-subtitle-text`}>
                        {item[1]}
                      </span>
                    );
                  })}
                </div>
              )}
            </div>

            <div className={styles.additionalInfo}>
              {JSON.stringify(auditDetails?.additionalInfoJson) !== '{}' && (
                <>
                  <div className={`${styles.details} first-subtitle-text`}>
                    Additional Information:
                  </div>
                  <div className={styles.additionalInfo}>
                    {Object.entries(auditDetails?.additionalInfoJson).map((item) => {
                      return (
                        <ul key={item[0]}>
                          {auditDetails?.auditType === 'Item Downloaded From Search Preview' ||
                          auditDetails?.auditType === 'Content Search Item Previewed' ? (
                            <li key={item[0]} className={`${styles.bodyText} second-subtitle-text`}>
                              {item[1]} - {[item[0]]}
                            </li>
                          ) : (
                            <li key={item[0]} className={`${styles.bodyText} second-subtitle-text`}>
                              {item[1]}
                            </li>
                          )}
                        </ul>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          </div>
        </Widget>
      </div>
    );
  }
}

export default AuditDetailedView;
