import React, { Component } from 'react';
import SearchForm from 'components/CRUD/Search/form/SearchForm';
import actions from 'actions/search/searchFormActions';
import { connect } from 'react-redux';
import BulkSelectionForm from './BulkSelectionForm';
import { withRouter } from 'utils/withRouter';
class BulkSelectionFormPage extends Component {
  state = {
    dispatched: false
  };

  componentDidMount() {
    this.setState({ dispatched: true });
    const { dispatch, params, navigate } = this.props;
    dispatch(actions.doFindItemsMountBulk(params?.caseId, params?.id, '', 9999, navigate));
  }

  doSubmit = (id, data) => {
    const { dispatch, navigate } = this.props;
    const { name: firstName, surName: lastName, email, userName } = this.props.currentUser || {};
    if (this.isEditing() || this.isProfile()) {
      dispatch(actions.doUpdate(id, data, this.isProfile()));
    } else {
      dispatch(
        actions.doCreate(
          { ...data, legalCaseId: this.props.legalCaseId },
          {
            expireonUser: {
              firstName,
              lastName,
              email,
              userName
            }
          },
          navigate
        )
      );
    }
  };

  isEditing = () => {
    return false;
  };

  isProfile = () => {
    return false;
  };

  render() {
    return (
      <React.Fragment>
        {this.state.dispatched && (
          <BulkSelectionForm
            items={this.props.itemsBulk}
            legalCaseId={this.props.legalCaseId}
            contentSearchId={this.props.contentSearchId}
            dispatch={this.props.dispatch}
            itemIcon={this.props.itemIcon}
            currentTheme={this.props.currentTheme}
            navigate={this.props.navigate}
          />
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(store) {
  return {
    items: store.search.form.rows,
    itemsBulk: store.search.form.itemsBulk,
    itemIcon: store.search.form.addIconToItems,
    currentTheme: store.layout.currentTheme
  };
}

export default withRouter(connect(mapStateToProps)(BulkSelectionFormPage));
