import Errors from 'components/FormItems/error/errors';
import { toast } from 'react-toastify';
import config from '../../config';
import listActions from '../Export/ExportListActions';
import { store } from 'index';
import { defaultOptions } from '../../services/userService';

async function doFetchExportPolicies() {
  const response = await fetch(`${config.externalApi}/legal/api/ExportJob/ExportPolicies`, {
    ...defaultOptions,
    method: 'GET'
  });

  const data = await response.json();
  return data;
}

async function doFetchExportModules() {
  const response = await fetch(`${config.externalApi}/legal/api/ExportJob/ExportModules`, {
    ...defaultOptions,
    method: 'GET'
  });

  const data = await response.json();
  return data;
}

async function doFetchLegalHolds(legalCaseId) {
  const currentUser = store.getState().auth.currentUser;
  const response = await fetch(
    `${config.externalApi}/legal/api/ExportJob/GetLegalHolds?legalCaseId=${legalCaseId}&loggedUser=${currentUser.userName}`,
    {
      ...defaultOptions,
      method: 'GET'
    }
  );

  const data = await response.json();
  return data;
}

async function doFetchContentSearches(legalCaseId) {
  const currentUser = store.getState().auth.currentUser;
  const response = await fetch(
    `${config.externalApi}/legal/api/ExportJob/GetContentSearches?legalCaseId=${legalCaseId}&loggedUser=${currentUser.userName}`,
    {
      ...defaultOptions,
      method: 'GET'
    }
  );

  const data = await response.json();
  return data;
}

async function doFetchExportNames(legalCaseId) {
  const currentUser = store.getState().auth.currentUser;
  const response = await fetch(
    `${config.externalApi}/legal/api/ExportJob/GetExportJobNames?legalCaseId=${legalCaseId}&loggedUser=${currentUser.userName}`,
    {
      ...defaultOptions,
      method: 'GET'
    }
  );

  const data = await response.json();
  return data;
}

async function doCreateExport(data) {
  const response = await fetch(`${config.externalApi}/legal/api/ExportJob`, {
    ...defaultOptions,
    method: 'POST',
    body: JSON.stringify({
      ...data,
      legalHoldIds: JSON.stringify(data.legalHoldIds),
      contentSearchIds: JSON.stringify(data.contentSearchIds),
      moduleIds: JSON.stringify(data.moduleIds)
    })
  });
  return response;
}

const actions = {
  doFindExportPolicies: () => async (dispatch) => {
    try {
      dispatch({
        type: 'EXPORT_FORM_FIND_EXPORT_POLICIES_STARTED'
      });

      doFetchExportPolicies().then((res) => {
        dispatch({
          type: 'EXPORT_FORM_FIND_EXPORT_POLICIES_SUCCESS',
          payload: res
        });
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'EXPORT_FORM_FIND_ERROR'
      });
    }
  },
  doFindExportModules: () => async (dispatch) => {
    try {
      dispatch({
        type: 'EXPORT_FORM_FIND_EXPORT_MODULES_STARTED'
      });

      doFetchExportModules().then((res) => {
        dispatch({
          type: 'EXPORT_FORM_FIND_EXPORT_MODULES_SUCCESS',
          payload: res
        });
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'EXPORT_FORM_FIND_ERROR'
      });
    }
  },
  doFindLegalHolds: (id) => async (dispatch) => {
    try {
      dispatch({
        type: 'EXPORT_FORM_FIND_LEGALHOLDS_STARTED'
      });

      doFetchLegalHolds(id).then((res) => {
        dispatch({
          type: 'EXPORT_FORM_FIND_LEGALHOLDS_SUCCESS',
          payload: res
        });
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'EXPORT_FORM_FIND_ERROR'
      });
    }
  },
  doFindContentSearches: (id) => async (dispatch) => {
    try {
      dispatch({
        type: 'EXPORT_FORM_FIND_CONTENT_SEARCHES_STARTED'
      });

      doFetchContentSearches(id).then((res) => {
        dispatch({
          type: 'EXPORT_FORM_FIND_CONTENT_SEARCHES_SUCCESS',
          payload: res
        });
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'EXPORT_FORM_FIND_ERROR'
      });
    }
  },
  doFindExportJobNames: (legalCaseId) => async (dispatch) => {
    try {
      dispatch({
        type: 'EXPORT_FORM_FIND_EXPORT_NAMES_STARTED'
      });

      doFetchExportNames(legalCaseId).then((res) => {
        dispatch({
          type: 'EXPORT_FORM_FIND_EXPORT_NAMES_SUCCESS',
          payload: res
        });
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'EXPORT_FORM_FIND_ERROR'
      });
    }
  },
  doCreate: (values) => async (dispatch) => {
    try {
      dispatch({
        type: 'EXPORT_LEGAL_CASE_FORM_CREATE_STARTED'
      });

      doCreateExport(values).then((res) => {
        dispatch({
          type: 'EXPORT_LEGAL_CASE_FORM_CREATE_SUCCESS'
        });
        dispatch(
          listActions.doFetch({
            legalCaseId: values.legalCaseId,
            pageNumber: values.pageNumber,
            pageSize: values.pageSize
          })
        );
        toast.success('Export job started', { icon: false });

        dispatch({
          type: 'EXPORT_FORM_CLOSE_CONFIRM'
        });
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'EXPORT_LEGAL_CASE_FORM_CREATE_ERROR'
      });
    }
  },

  doOpenConfirm: () => async (dispatch) => {
    dispatch({
      type: 'EXPORT_FORM_OPEN_CONFIRM'
    });
  },

  doCloseConfirm: () => async (dispatch) => {
    dispatch({
      type: 'EXPORT_FORM_CLOSE_CONFIRM'
    });
  }
};
export default actions;
