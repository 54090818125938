import React, { Component } from 'react';
import styles from './Reports.module.scss';
export default class ExpandReportRow extends Component {
  state = { reportEmailPolicy: null, reportLocationPolicy: null };
  componentDidMount() {
    if (this.props) {
      this.setState({
        reportEmailPolicy: this.props?.reportEmailPolicy,
        reportLocationPolicy: this.props?.reportLocationPolicy
      });
    }
  }
  render() {
    return (
      <div className={styles.contentContainer}>
        <div>
          {this.state.reportEmailPolicy && (
            <div>
              <p className={styles.expandedRowHeader}>Report Email Policy</p>
              <p>
                <span className={styles.detailsExpanded}>Name:</span>
                <span>{this.state.reportEmailPolicy.name}</span>
              </p>
              <p>
                <span className={styles.detailsExpanded}> Description:</span>
                <span>{this.state.reportEmailPolicy.description}</span>
              </p>
              <p>
                <span className={styles.detailsExpanded}>Subject:</span>
                <span>{this.state.reportEmailPolicy.subject}</span>
              </p>
              <p>
                <span className={styles.detailsExpanded}>Recipients:</span>
                {this.state.reportEmailPolicy.recipients?.map((item, index) => (
                  <>
                    <span key={index} className={styles.recipitentItem}>
                      {item}
                    </span>
                    <span>
                      {index < this.state.reportEmailPolicy.recipients?.length - 1 ? ',' : ''}
                    </span>
                  </>
                ))}
              </p>
            </div>
          )}
        </div>
        <div>
          {this.state.reportLocationPolicy && (
            <div>
              <p className={styles.expandedRowHeader}>Report Location Policy</p>
              <p>
                <span className={styles.detailsExpanded}>Name:</span>
                <span>{this.state.reportLocationPolicy.name}</span>
              </p>
              <p>
                <span className={styles.detailsExpanded}>Description:</span>{' '}
                <span>{this.state.reportLocationPolicy.description}</span>
              </p>
              <p>
                <span className={styles.detailsExpanded}>Location:</span>
                <span>{this.state.reportLocationPolicy.location}</span>
              </p>
            </div>
          )}
        </div>
        <div>
          {!this.state.reportEmailPolicy && !this.state.reportLocationPolicy && (
            <div className={styles.expandedRowHeader}>No additional information available!</div>
          )}
        </div>
      </div>
    );
  }
}
