import React from 'react';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { thunk } from 'redux-thunk';
import * as serviceWorker from 'serviceWorker';
import createRootReducer, { routerMiddleware, createReduxHistory } from 'reducers'; // Import routerMiddleware from your reducers file
import App from 'App';
import AuthProvider from 'utils/authProvider';
import userManager from 'services/userService';
import { composeWithDevTools } from '@redux-devtools/extension';
import { HashRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';

export const store = createStore(
  createRootReducer,
  composeWithDevTools(applyMiddleware(routerMiddleware, thunk))
);

const history = createReduxHistory(store);

export function getHistory() {
  return history;
}
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <AuthProvider userManager={userManager} store={store}>
      <HashRouter history={history}>
        <App />
      </HashRouter>
    </AuthProvider>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
