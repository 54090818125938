const initialData = {
  findLoading: false,
  saveLoading: false,
  record: null,
  modalOpen: false,
  certificatesList: [],
};

export default (state = initialData, { type, payload, ...rest }) => {
  if (type === "CREDENTIAL_FORM_RESET") {
    return {
      ...initialData,
    };
  }

  if (type === "CREDENTIAL_FORM_FIND_STARTED") {
    return {
      ...state,
      record: null,
      findLoading: true,
    };
  }

  if (type === "CREDENTIAL_FORM_FIND_SUCCESS") {
    return {
      ...state,
      record: payload,
      findLoading: false,
    };
  }
  if (type === "CREDENTIAL_FORM_FIND_ERROR") {
    return {
      ...state,
      record: null,
      findLoading: false,
    };
  }

  if (type === "CREDENTIAL_FORM_CREATE_STARTED") {
    return {
      ...state,
      saveLoading: true,
    };
  }

  if (type === "CREDENTIAL_FORM_CREATE_SUCCESS") {
    return {
      ...state,
      saveLoading: false,
      modalOpen: false,
    };
  }

  if (type === "CREDENTIAL_FORM_CREATE_ERROR") {
    return {
      ...state,
      saveLoading: false,
    };
  }

  if (type === "CREDENTIAL_FORM_UPDATE_STARTED") {
    return {
      ...state,
      saveLoading: true,
    };
  }

  if (type === "CREDENTIAL_FORM_UPDATE_SUCCESS") {
    return {
      ...state,
      saveLoading: false,
    };
  }

  if (type === "CREDENTIAL_FORM_UPDATE_ERROR") {
    return {
      ...state,
      saveLoading: false,
    };
  }
  if (type === "CERTIFICATE_FORM_CREATE_STARTED") {
    return {
      ...state,
      saveLoading: true,
    };
  }

  if (type === "CERTIFICATE_FORM_CREATE_SUCCESS") {
    return {
      ...state,
      saveLoading: false,
    };
  }

  if (type === "CERTIFICATE_FORM_CREATE_ERROR") {
    return {
      ...state,
      saveLoading: false,
    };
  }

  if (type === "GET_CERTIFICATES_LIST_SUCCESS") {
    return {
      ...state,
      certificatesList: payload,
    };
  }
  if (type === "GET_CERTIFICATES_LIST_ERROR") {
    return {
      ...state,
      certificatesList: [],
    };
  }

  return state;
};
