import React, { Component } from 'react';
import Audit from './Audit';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

class AuditPage extends Component {
  render() {
    return (
      <>
        <div>
          {this.props.permissions['LegalUI.Audit'] ? (
            <Audit />
          ) : (
            <Navigate to='/no-access' replace />
          )}
        </div>
      </>
    );
  }
}

function mapStateToProps(store) {
  return {
    permissions: store.auth.permissions
  };
}
export default connect(mapStateToProps)(AuditPage);
