import React, { Component } from 'react';
import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import actions from 'actions/AuditLogs/AuditLogsListActions';
import reportActions from 'actions/Report/ReportActions';
import { connect } from 'react-redux';
import Widget from 'components/Widget/Widget';
import Pagination from 'react-js-pagination';
import styles from 'components/CRUD/LegalHold/list/LegalHoldList.module.scss';
import auditStyles from 'components/CRUD/AuditLogs/Audit.module.scss';
import plusIcon from '../../../images/icons/plus.svg';
import './CronGenerator.module.scss';
import ReportForm from './ReportForm';
import reportStyles from 'components/CRUD/Reports/Reports.module.scss';
import barsIcon from 'images/bars.svg';
import trashIcon from 'images/icons/trash.svg';
import config from 'config';
import { HiOutlineDownload } from 'react-icons/hi';
import ExpandReportRow from './ExpandReportRow';
import { emptyDataMessage, customCloseBtn } from 'actions/common';
import searchIcon from 'images/icons/search.svg';
import caretDown from 'images/icons/caret-down.svg';
import caretUp from 'images/icons/caret-up.svg';
import * as dataFormat from 'components/CRUD/LegalHold/list/LegalHoldDataFormatters';
import { isLegalAdmin } from 'utils/isLegalAdmin';
class ReportsTable extends Component {
  intervalID = 0;
  state = {
    totalCount: 0,
    pageNumber: 1,
    pageSize: 10,
    newRows: [],
    sort: '',
    dropdownItem: [],
    openDeleteModal: false,
    reportId: '',
    fileLocations: [],
    emailPolicies: [],
    searchString: '',
    expandExport: false
  };

  sortFormatter(sortField) {
    const SortEnum = {
      name: 'name',
      description: 'description',
      'expireonUser.userName': 'username',
      createdDateUtc: 'reportdate',
      reportDeliveryType: 'deliverytype',
      reportOutputFileType: 'outputfiletype'
    };
    return SortEnum[sortField];
  }

  handlePageChange = (pageNumber) => {
    this.setState({ pageNumber: pageNumber });
  };

  handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === 'sort') {
      this.setState({
        pageNumber: 1,
        sort: this.sortFormatter(sortField).concat('_').concat(sortOrder)
      });
    }
  };

  resetDropdowns(e) {
    if (e.target?.getAttribute('alt') != 'bar') {
      this.setState({
        dropdownItem: []
      });
    }
  }

  refreshTable = () => {
    const { dispatch } = this.props;
    const interval = this.props?.refreshInterval.concat('000');
    const refreshInterval = JSON.parse(interval);
    this.intervalID = setInterval(() => {
      dispatch(
        reportActions.doFetchReportsList({
          pageSize: this.state.pageSize,
          pageNumber: this.state.pageNumber,
          sortOrder: this.state.sort,
          searchString: this.state.searchString
        })
      ).then(() => {
        this.setState({
          newRows: this.props.reportsList,
          totalCount: this.props.reportsCount
        });
      });
    }, refreshInterval);
  };
  componentDidMount() {
    const { dispatch } = this.props;
    const userName = this.props?.currentUser?.userName;
    dispatch(actions.doGetReportTemplate(userName)).then(() => {
      this.setState({
        recordTemplates: this.props.recordTemplates
      });
    });

    dispatch(
      reportActions.doFetchReportsList({
        pageSize: this.state.pageSize,
        pageNumber: this.state.pageNumber,
        sortOrder: this.state.sort,
        searchString: this.state.searchString
      })
    ).then(() => {
      this.setState({
        newRows: this.props?.reportsList,
        totalCount: this.props?.reportsCount
      });
    });
    dispatch(reportActions.doFetchReportFileLocations()).then(() => {
      this.setState({
        fileLocations: this.props?.fileLocations
      });
    });
    dispatch(reportActions.doFetchReportEmailPolicies()).then(() => {
      this.setState({
        emailPolicies: this.props?.emailPolicies
      });
    });
    window.addEventListener('click', (e) => this.resetDropdowns(e));
    if (this.props?.refreshInterval) {
      this.refreshTable();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { dispatch } = this.props;
    if (
      prevState.pageSize !== this.state.pageSize ||
      prevState.pageNumber !== this.state.pageNumber ||
      prevState.sort !== this.state.sort
    ) {
      dispatch(
        reportActions.doFetchReportsList({
          pageSize: this.state.pageSize,
          pageNumber: this.state.pageNumber,
          sortOrder: this.state.sort,
          searchString: this.state.searchString
        })
      ).then(() => {
        this.setState({
          newRows: this.props.reportsList,
          totalCount: this.props.reportsCount
        });
      });
    }
    if (prevState.searchString !== this.state.searchString && this.state.searchString === '') {
      dispatch(
        reportActions.doFetchReportsList({
          pageSize: this.state.pageSize,
          pageNumber: this.state.pageNumber,
          sortOrder: this.state.sort,
          searchString: this.state.searchString
        })
      ).then(() => {
        this.setState({
          newRows: this.props.reportsList,
          totalCount: this.props.reportsCount
        });
      });
    }
    if (prevProps.reportsList !== this.props.reportsList) {
      this.setState({
        newRows: this.props.reportsList,
        totalCount: this.props.reportsCount
      });
    }
    if (prevProps.refreshInterval !== this.props.refreshInterval) {
      this.refreshTable();
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
    window.removeEventListener('click', (e) => this.resetDropdowns(e));
  }

  doSubmit = (data) => {
    let formData = { ...data };
    formData.viaEmail = undefined;
    formData.viaExport = undefined;
    data = Object.entries(formData).reduce((acc, [key, value]) => {
      if (value !== undefined && value !== null) {
        acc[key] = value;
      }
      return acc;
    }, {});
    const { dispatch } = this.props;
    dispatch(
      reportActions.doSaveReport({
        ...data
      })
    ).then(() => {
      this.setState({
        pageNumber: 1
      });
      dispatch(
        reportActions.doFetchReportsList({
          pageSize: this.state.pageSize,
          pageNumber: this.state.pageNumber
        })
      ).then(() => {
        this.setState({
          newRows: this.props?.reportsList,
          totalCount: this.props?.reportsCount
        });
      });
    });
    this.setState({
      openCreateReportModal: false
    });
  };

  handleReportDelete = () => {
    const { dispatch } = this.props;
    const filter = {
      pageNumber: this.state.pageNumber,
      pageSize: this.state.pageSize,
      sortOrder: this.state.sortOrder
    };
    dispatch(
      reportActions.doDeleteReport(this.state.reportId, this.props?.currentUser, filter)
    ).then(() => {
      this.setState({
        openDeleteModal: false
      });
    });
  };

  handleReportDownload = (reportId) => {
    const { dispatch } = this.props;
    const legalAdmin = isLegalAdmin(this.props?.currentUser);
    const url = `${config.externalApi}/api/Report/GenerateReportFile?reportId=${reportId}&isLegalAdmin=${legalAdmin}`;
    dispatch(reportActions.handleDownloadReportFile(url, this.props?.currentUser));
  };

  handleSearch = () => {
    const { dispatch } = this.props;
    this.setState({ pageNumber: 1 });
    dispatch(
      reportActions.doFetchReportsList({
        pageSize: this.state.pageSize,
        pageNumber: this.state.pageNumber,
        sortOrder: this.state.sort,
        searchString: this.state.searchString
      })
    ).then(() => {
      this.setState({
        newRows: this.props.reportsList,
        totalCount: this.props.reportsCount
      });
    });
  };

  actionFormatter(cell, row) {
    return (
      <Dropdown group isOpen={this.state.dropdownItem.includes(cell)} size='lg' toggle={() => {}}>
        <DropdownToggle className={'bg-transparent border-0'}>
          <img src={barsIcon} alt='bar' width={'24px'} height={'24px'} />
        </DropdownToggle>
        <DropdownMenu className='dropdown-position-fixed'>
          <div>
            <button
              disabled={!row?.isSynced}
              className={`btn bg-transparent text-white first-body-text d-flex align-items-center w-100 `}
              type='button'
              onClick={() => this.handleReportDownload(cell)}
            >
              <HiOutlineDownload className={reportStyles.icon} />
              <p className={'mb-0'}>Download</p>
            </button>
          </div>
          <div>
            <button
              className={`btn bg-transparent text-white first-body-text d-flex align-items-center w-100 `}
              type='button'
              onClick={() => {
                this.setState({
                  openDeleteModal: true,
                  reportId: cell
                });
              }}
            >
              <img
                src={trashIcon}
                alt={'delete'}
                width={'14px'}
                height={'14px'}
                className={'me-2 '}
              />
              <p className={'mb-0'}>Delete</p>
            </button>
          </div>
        </DropdownMenu>
      </Dropdown>
    );
  }

  toggleExport = () => {
    this.setState({
      expandExport: !this.state.expandExport
    });
  };

  exportMenu = () => {
    return (
      <Dropdown
        isOpen={this.state.expandExport}
        className={`${styles.exportButton}`}
        toggle={this.toggleExport}
      >
        <DropdownToggle
          className={`${styles.exportButton} bg-transparent text-white mb-0`}
          disabled={this.state.totalCount === 0}
        >
          Export
          {!this.state.expandExport ? (
            <img src={caretDown} alt={'uncollapsed'} width='20px' height='20px' className='ms-2' />
          ) : (
            <img src={caretUp} alt={'collapsed'} width='20px' height='20px' className='ms-2' />
          )}
        </DropdownToggle>
        <DropdownMenu className='p-0 bg-transparent'>
          <DropdownItem className={`${styles.exportItem}`} onClick={this.handleExportCSV}>
            CSV
          </DropdownItem>
          <DropdownItem className={`${styles.exportItem}`} onClick={this.handleExportPDF}>
            PDF
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  };

  columnsToExport = [
    'name',
    'description',
    'createdDateUtc',
    'username',
    'reportDeliveryType',
    'reportOutputFileType'
  ];

  handleExportCSV = () => {
    const { dispatch } = this.props;
    const url = `${config.externalApi}/api/Report/ExportReports?`;
    const currentUser = this.props?.currentUser;
    const columnHeaders = this.columnsToExport.join(',');
    dispatch(
      reportActions.handleExportToCSV(url, currentUser, this.state.searchString, columnHeaders)
    );
  };

  handleExportPDF = () => {
    const { dispatch } = this.props;
    const url = `${config.externalApi}/api/Report/ExportToPdf?`;
    const currentUser = this.props?.currentUser;
    const columnHeaders = this.columnsToExport.join(',');
    dispatch(
      reportActions.handleExportToPDF(url, currentUser, this.state.searchString, columnHeaders)
    );
  };

  render() {
    const columns = [
      {
        dataField: 'name',
        text: 'Name',
        sort: true,
        formatter: (cell, row) => <div className={reportStyles.reportsListName}>{cell}</div>
      },
      {
        dataField: 'description',
        text: 'Specification',
        sort: true,
        formatter: (cell, row) => <div className={reportStyles.reportsListDescription}>{cell}</div>
      },
      {
        dataField: 'createdDateUtc',
        text: 'Created',
        sort: true,
        formatter: dataFormat.dateTimeFormatter
      },
      {
        dataField: 'expireonUser.userName',
        text: 'Expireon User',
        sort: true
      },

      {
        dataField: 'reportDeliveryType',
        text: 'Delivery Type',
        sort: true
      },
      {
        dataField: 'reportOutputFileType',
        text: 'Output File Type',
        sort: true
      },
      // {
      //   dataField: "isSynced",
      //   text: "Synced",
      //   sort: true,
      // },
      {
        dataField: 'reportId',
        text: 'Actions',
        formatter: (cell, row) => {
          return this.actionFormatter.bind(this)(cell, row);
        },
        events: {
          onClick: (e, column, columnIndex, row) => {
            if (this.state.dropdownItem.includes(row.reportId)) {
              this.setState({
                dropdownItem: []
              });
            } else {
              this.setState({
                dropdownItem: [row.reportId]
              });
            }
          }
        }
      }
    ];

    const expandRow = {
      parentClassName: `${reportStyles.parentRow}`,
      onlyOneExpanding: true,
      renderer: (row) => (
        <div className={reportStyles.expandedRowContainer}>
          <ExpandReportRow
            reportEmailPolicy={row?.reportEmailPolicy}
            reportLocationPolicy={row?.reportLocationPolicy}
          />
        </div>
      )
    };
    return (
      <>
        <Widget
          style={{
            marginTop: '20px',
            width: '100%'
          }}
        >
          <ToolkitProvider
            bootstrap4
            keyField='reportId'
            columns={columns}
            data={this.state.newRows}
          >
            {(props) => (
              <>
                <Row sm={12} className='mb-4'>
                  <Col sm={12} className={auditStyles.auditTableHeader}>
                    <h3 className={'first-headline-text me-4 mb-0'}>Reports</h3>
                  </Col>
                  <Col sm={12}>
                    <div className='d-flex justify-content-between'>
                      <div className={'d-flex align-items-center'}>
                        <button
                          className='btn first-body-text d-flex align-items-center'
                          type='button'
                          onClick={() => {
                            this.setState({
                              openCreateReportModal: true
                            });
                          }}
                        >
                          <img src={plusIcon} alt={'plus'} className={'me-2'} />
                          <p className={'mb-0'}>New</p>
                        </button>
                      </div>
                      <div className='d-flex'>
                        <div style={{ marginRight: '5px' }}>{this.exportMenu()}</div>
                        <span>
                          <input
                            type='search'
                            placeholder={'Search'}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                this.handleSearch();
                              }
                            }}
                            style={{
                              border: '0.5px solid #0046b1',
                              borderRadius: '3px'
                            }}
                            className={'form-control search-input me-5 w-200'}
                            value={this.state.searchString}
                            onChange={(e) => {
                              this.setState({
                                searchString: e.target.value
                              });
                            }}
                          />{' '}
                        </span>
                        <span>
                          <button
                            className='btn search-button-with-icon me-2 ms-10'
                            type='button'
                            onClick={() => this.handleSearch()}
                            style={{ height: '37px', width: '37px' }}
                          >
                            <img
                              title={'search'}
                              alt={'search'}
                              width={14}
                              height={14}
                              src={searchIcon}
                            />
                          </button>
                        </span>
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className='table-container'>
                  <BootstrapTable
                    bordered={false}
                    classes={`table-striped table-hover fs-sm custom-table`}
                    remote={{
                      filter: false,
                      pagination: false,
                      sort: true,
                      cellEdit: false
                    }}
                    onTableChange={this.handleTableChange}
                    expandRow={expandRow}
                    noDataIndication={emptyDataMessage}
                    {...props.baseProps}
                  />
                </div>
                <Row key={'pagination'} className='mt-3'>
                  <Col>
                    <p className={styles.totalCount}>Total: {this.state.totalCount}</p>
                  </Col>
                  <Col className='d-flex justify-content-end'>
                    {this.state.totalCount ? (
                      <Pagination
                        totalItemsCount={this.state.totalCount}
                        onChange={this.handlePageChange}
                        activePage={this.state.pageNumber}
                        itemsCountPerPage={this.state.pageSize}
                        pageRangeDisplayed={5}
                        prevPageText={'<'}
                        nextPageText={'>'}
                        firstPageText={'<<'}
                        lastPageText={'>>'}
                        linkClassFirst={styles.paginationNext}
                        linkClassPrev={styles.paginationNext}
                        linkClassNext={styles.paginationNext}
                        linkClassLast={styles.paginationNext}
                        linkClass={styles.pageLink}
                        activeLinkClass={styles.activeLinkClass}
                      />
                    ) : null}
                    <Dropdown
                      isOpen={this.state.paginationModalOpen}
                      toggle={() =>
                        this.setState({
                          paginationModalOpen: !this.state.paginationModalOpen
                        })
                      }
                      className={styles.pageSizeDropdown}
                    >
                      <DropdownToggle className='bg-transparent text-white d-flex'>
                        <p
                          style={{ marginBottom: '3px', marginRight: '2px' }}
                          className='first-body-text'
                        >
                          {this.state.pageSize}
                        </p>
                        <img
                          src={caretDown}
                          alt={'uncollapsed'}
                          width='20px'
                          height='20px'
                          className='ms-2'
                        />
                      </DropdownToggle>
                      <DropdownMenu className='dropdown-position-fixed'>
                        {[10, 15, 25].map((item) => (
                          <DropdownItem
                            className={styles.dropdownItem}
                            key={item}
                            onClick={() => {
                              this.setState({
                                pageSize: item,
                                paginationModalOpen: false,
                                pageNumber: 1
                              });
                            }}
                          >
                            {item}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                </Row>
              </>
            )}
          </ToolkitProvider>
        </Widget>
        <Modal
          size='lg'
          isOpen={this.state.openCreateReportModal}
          className={`themeStyle${this.props.currentTheme?.replace('#', '')}`}
        >
          <ModalHeader
            close={customCloseBtn(() =>
              this.setState({
                openCreateReportModal: false
              })
            )}
          >
            <p className='second-headline-text'>Create</p>
          </ModalHeader>
          <ModalBody>
            <ReportForm
              saveLoading={this.props.saveLoading}
              currentUser={this.props.currentUser}
              templates={this.state?.recordTemplates}
              fileLocations={this.state?.fileLocations}
              emailPolicies={this.state?.emailPolicies}
              onSubmit={this.doSubmit}
              dispatch={this.props.dispatch}
            />
          </ModalBody>
        </Modal>
        <Modal
          size='md'
          isOpen={this.state.openDeleteModal}
          className={`themeStyle${this.props.currentTheme?.replace('#', '')}`}
        >
          <ModalHeader
            close={customCloseBtn(() =>
              this.setState({
                openDeleteModal: false
              })
            )}
          >
            <p className='second-headline-text'>Confirm</p>
          </ModalHeader>
          <ModalBody>
            <div className={reportStyles.deleteModalContainer}>
              Are you sure you want to delete this item?
            </div>
            <div className={reportStyles.deleteModalFooter}>
              <button
                type='button'
                onClick={() =>
                  this.setState({
                    openDeleteModal: false
                  })
                }
                className={`${reportStyles.deleteButton} first-body-text btn secondary-btn`}
              >
                Cancel
              </button>
              <button
                type='button'
                onClick={() => this.handleReportDelete()}
                className={`${reportStyles.deleteButton} first-body-text btn danger-btn`}
              >
                Delete
              </button>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

function mapStateToProps(store) {
  return {
    rows: store.AuditLogs.list.rows,
    count: store.AuditLogs.list.count,
    recordTemplates: store.AuditLogs.list.recordTemplates,
    fileLocations: store.Reports.list.fileLocations,
    currentUser: store.auth.currentUser,
    reportsList: store.Reports.list.reportsList,
    reportsCount: store.Reports.list.reportsCount,
    refreshInterval: store.LegalHold.list.refreshInterval,
    emailPolicies: store.Reports.list.emailPolicies,
    currentTheme: store.layout.currentTheme
  };
}

export default connect(mapStateToProps)(ReportsTable);
