import axios from "axios";
import {decodedKey, decryptedObject, decryptValue} from "../config.js";

async function getConfigFromApi() {
  const response = await axios.get(
      decryptValue(window[decodedKey][decryptedObject?.REACT_APP_BACKEND]) + "/api/abp/application-configuration"
  );
  return response.data;
}

export { getConfigFromApi };
