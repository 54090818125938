import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'utils/withRouter';
import logoutIcon from '../../images/icons/logout.svg';
import { Navbar, Nav, NavItem, NavLink } from 'reactstrap';
import adminDefault from '../../images/chat/chat2.png';
import s from './Header.module.scss';
import { signoutRedirect } from '../../services/userService';
import { FiSettings } from 'react-icons/fi';
import { Popper } from '@material-ui/core';
import UserProfileCard from './UserProfileCard';
import { logoutSuccessfull } from '../../actions/auth';

class Header extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    sidebarPosition: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.doLogout = this.doLogout.bind(this);
    this.state = {
      openProfileCard: false,
      cardPosition: {
        top: 0,
        left: 0
      }
    };
  }

  doLogout() {
    signoutRedirect().then(() => {
      logoutSuccessfull();
    });
  }

  render() {
    const user = this.props.currentUser;
    const avatar = user && user.avatar && user.avatar.length && user.avatar[0].publicUrl;
    const firstUserLetter = user?.name ? (user.name || user.email)[0].toUpperCase() : null;

    return (
      <>
        {this.props?.currentUser?.isAuthenticated && (
          <Navbar className={`d-print-none ${s.root}`}>
            <Popper
              id={this.state.openProfileCard ? 'simple-popper' : undefined}
              open={this.state.openProfileCard}
            >
              <UserProfileCard currentUser={user} position={this.state.cardPosition} />
            </Popper>
            <Nav className={s.headerNav}>
              <NavItem className={'d-flex align-items-center me-4'}>
                <span
                  className={`${s.avatar} rounded-circle float-left me-2`}
                  onClick={(e) => {
                    this.setState({
                      openProfileCard: true,
                      cardPosition: {
                        top: e.pageY,
                        left: e.pageX
                      }
                    });
                  }}
                  onMouseLeave={() => {
                    this.setState({
                      openProfileCard: false,
                      cardPosition: {
                        top: 0,
                        left: 0
                      }
                    });
                  }}
                >
                  {avatar ? (
                    <img
                      src={avatar}
                      onError={(e) => (e.target.src = adminDefault)}
                      alt='...'
                      title={user && (user.name || user.email)}
                    />
                  ) : user && user?.roles[0] === 'LegalAdmin' ? (
                    <img
                      src={adminDefault}
                      onError={(e) => (e.target.src = adminDefault)}
                      alt='...'
                      title={user && (user.name || user.email)}
                    />
                  ) : (
                    <span title={user && (user.name || user.email)}>{firstUserLetter}</span>
                  )}
                </span>
                <span className={`small d-sm-down-none`}>
                  {user ? (
                    <>
                      {user.name} {user.surName}
                    </>
                  ) : null}
                </span>
              </NavItem>
              {!(
                this.props.permissions['LegalUI.Credential'] &&
                this.props.permissions['LegalUI.Credential.KeyManagement'] &&
                Object.keys(this.props.permissions).length === 2
              ) && (
                <NavItem>
                  <NavLink onClick={() => this.props.navigate('/app/settings')}>
                    {<FiSettings className={s.settingsIcon} />}
                  </NavLink>
                </NavItem>
              )}
              <NavItem>
                <NavLink onClick={this.doLogout} className={`${s.navItem} text-white`} href='#'>
                  <img style={{ transform: 'rotate(180deg)' }} src={logoutIcon} alt={'logout'} />
                </NavLink>
              </NavItem>
            </Nav>
          </Navbar>
        )}
      </>
    );
  }
}

function mapStateToProps(store) {
  return {
    sidebarPosition: store.navigation.sidebarPosition,
    currentUser: store.auth.currentUser,
    permissions: store.auth.permissions
  };
}

export default withRouter(connect(mapStateToProps)(Header));
