import React, { Component } from 'react';
import LegalCaseStatsListTable from 'components/CRUD/LegalCaseStats/list/LegalCaseStatsListTable';
import actions from 'actions/legalCaseStats/legalCaseStatsListActions';

class LegalCaseStatsListPage extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(actions.doFetch({ legalCaseId: this.props?.legalCaseId }));
  }
  componentDidUpdate(prevProps) {
    const { dispatch } = this.props;
    if (prevProps.legalCaseId !== this.props.legalCaseId) {
      dispatch(actions.doFetch({ legalCaseId: this.props?.legalCaseId }));
    }
  }
  render() {
    return (
      <div>
        <LegalCaseStatsListTable legalCaseId={this.props.legalCaseId} />
      </div>
    );
  }
}

export default LegalCaseStatsListPage;
