import React, { Component } from "react";
import CertificateListTable from "./CertificateListTable";

export default class CertificateListPage extends Component {
  render() {
    return (
      <div>
        <CertificateListTable />
      </div>
    );
  }
}
