import React, { Component } from 'react';
import LegalHoldListTable from 'components/CRUD/LegalHold/list/LegalHoldListTable';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import LegalHoldTilesView from './LegalHoldTilesView';
import { withRouter } from 'utils/withRouter';

class LegalHoldListPage extends Component {
  render() {
    return (
      <div>
        {this.props.permissions['LegalUI.LegalHold'] ? (
          <>
            {this.props.showTableView ? (
              <LegalHoldListTable
                legalCaseId={this.props.legalCaseId}
                caseStatus={this.props.caseStatus}
                navigate={this.props.navigate}
              />
            ) : (
              <LegalHoldTilesView
                legalCaseId={this.props.legalCaseId}
                caseStatus={this.props.caseStatus}
                navigate={this.props.navigate}
              />
            )}
          </>
        ) : (
          <Navigate to='/no-access' replace />
        )}
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    permissions: store.auth.permissions,
    showTableView: store.LegalHold.list.showTableView
  };
}
export default withRouter(connect(mapStateToProps)(LegalHoldListPage));
