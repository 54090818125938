
const initialData = {
    openModal:false,
    rows:[],
    saveLoading:false
};

export default (state = initialData, {type, payload}) => {
    if (type === 'LEGALCASE_VIEWER_FORM_OPEN_CONFIRM') {
        return {
            ...state,
            openModal:true
        };
    }
    if (type === 'LEGALCASE_VIEWER_FORM_CLOSE_CONFIRM') {
        return {
            ...state,
            openModal:false
        };
    }
    if (type === 'LEGALCASE_VIEWER_FORM_CREATE_STARTED') {
        return {
          ...state,
          saveLoading: true,
        };
      }
    
      if (type === 'LEGALCASE_VIEWER_FORM_CREATE_SUCCESS') {
        return {
          ...state,
          saveLoading: false,
          openModal: false
        };
      }
    
      if (type === 'LEGALCASE_VIEWER_FORM_CREATE_ERROR') {
        return {
          ...state,
          saveLoading: false,
        };
      }
      if (type === 'USERS_LIST_FETCH_STARTED') {
        return {
          ...state,
          loading: true,
        };
      }
    
      if (type === 'USERS_LIST_FETCH_SUCCESS') {
        return {
          ...state,
          rows: payload.rows,
        };
      }
    
      if (type === 'USERS_LIST_FETCH_ERROR') {
        return {
          ...state,
          loading: false,
          rows: [],
        };
      }

  
    return state;
};
