import moment from 'moment';
import * as yup from 'yup';

yup.addMethod(yup.mixed, 'requiredIf', function (list, message) {
  return this.test('requiredIf', message, function (value) {
    const { path, createError } = this;

    if (!Array.isArray(list)) return true;

    var anyHasValue = list.some((value) => {
      return Boolean(document.querySelector(`input[name="${value}"]`).value);
    });

    return !value && !anyHasValue ? createError({ path, message }) : true;
  });
});

const formValidations = (fields, record = {}, oneOf) => {
  const yupArray = {};
  Object.keys(fields).forEach((field) => {
    const type = fields[field].type;
    const label = fields[field].label;
    const maxLength = fields[field].maxLength;
    const required = fields[field].required;
    let yupConds = {};
    switch (type) {
      case 'boolean':
        yupConds = yup
          .bool()
          .default(false)
          .required(required || false);
        break;

      case 'date':
        yupConds = yup
          .mixed()
          .nullable(true)
          .required(required || false)
          .test('is-date', '', (value) => {
            if (!value) {
              return true;
            }
            return moment(value, 'YYYY-MM-DD').isValid();
          });
        break;

      case 'datetime':
        yupConds = yup
          .mixed()
          .required(required || false)
          .nullable(true);
        break;

      case 'decimal':
        yupConds = yup
          .number()
          .required(required || false)
          .nullable(true);
        break;

      case 'enum':
        yupConds = yup
          .string()
          .required(required || false)
          .nullable(true);
        break;

      case 'files':
        yupConds = yup
          .array()
          .compact()
          .required(required || false)
          .ensure()
          .nullable(true);
        break;

      case 'images':
        yupConds = yup
          .array()
          .required(required || false)
          .nullable(true);
        break;

      case 'int':
        yupConds = yup
          .number()
          .integer()
          .required(required || false)
          .nullable(true);
        break;

      case 'user_many':
      case 'relation_many':
        yupConds = yup
          .array()
          .required(required || false)
          .nullable(true);
        break;
      case 'custodians':
        yupConds = yup
          .array()
          .required(required || false)
          .nullable(true);

        break;

      case 'user_one':
      case 'relation_one':
        yupConds = yup
          .mixed()
          .required(required || false)
          .nullable(true);
        break;

      case 'stringArray':
        yupConds = yup
          .array()
          .compact()
          .required(required || false)
          .ensure()
          .of(yup.string().trim());
        break;

      case 'string':
        yupConds = yup
          .string()
          .required(required || false)
          .test(
            'not-only-spaces',
            'The field should not contain only spaces',
            (value) => value && value.trim().length > 0
          )
          .max(maxLength || 256, 'You have exceeded the maximum limit of characters.');

        break;

      case 'externalCustodians':
        yupConds = yup.mixed().required(required || false);
        break;

      default:
        yupConds = yup.string();
    }
    yupConds = yupConds.label(label);
    if (required) {
      yupConds = yupConds.required();
    }
    yupArray[field] = yupConds;
  });
  return yup.object().shape(yupArray);
};

export default formValidations;
