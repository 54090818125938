import React, { Component } from 'react';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Widget from 'components/Widget/Widget';
import * as dataFormat from 'components/CRUD/LegalHold/list/LegalHoldDataFormatters';
import { FormattingService } from 'utils/sizeFormatting';
import actions from '../../../../actions/LegalCase/LegalCaseListActions';
import { connect } from 'react-redux';
import barsIcon from '../../../../images/bars.svg';
import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from 'reactstrap';
import syncIcon from '../../../../images/icons/sync.svg';
import viewIcon from '../../../../images/icons/eye.svg';
import Pagination from 'react-js-pagination';
import styles from 'components/CRUD/LegalHold/list/LegalHoldList.module.scss';
import { store } from 'index';
import closedListStyles from 'components/CRUD/LegalCase/closedLegalCasesList/ClosedList.module.scss';
import searchIcon from 'images/icons/search.svg';
import { emptyDataMessage, customCloseBtn } from 'actions/common';
import caretDown from 'images/icons/caret-down.svg';
import { isLegalAdmin } from 'utils/isLegalAdmin';

class ClosedListTable extends Component {
  intervalID = 0;
  state = {
    dropdownItem: [],
    modalOpen: false,
    paginationModalOpen: false,
    totalCount: 0,
    searchString: '',
    pageNumber: 1,
    pageSize: 10,
    sortOrder: '',
    expireonUser: {},
    newRows: []
  };

  actionFormatter(cell, row) {
    const currentUser = store.getState().auth.currentUser;
    const isLegaladmin = isLegalAdmin(currentUser);

    return (
      <Dropdown group isOpen={this.state.dropdownItem.includes(cell)} size='lg' toggle={() => {}}>
        <DropdownToggle className={'bg-transparent border-0'}>
          <img src={barsIcon} alt='bar' width={'24px'} height={'24px'} />
        </DropdownToggle>
        <DropdownMenu className='dropdown-position-fixed' style={{ width: '150px' }}>
          {this.props.permissions['LegalUI.CaseManager'] && (
            <button
              className='btn bg-transparent text-white fw-semi-bold d-flex align-items-center w-100'
              type='button'
              onClick={() => this.props.navigate(`/app/ClosedLegalCase/${cell}`)}
            >
              <img src={viewIcon} alt={'plus'} className={'me-2'} />
              <p className={'mb-0 first-body-text'}>View</p>
            </button>
          )}
          {currentUser?.userName === 'admin' ||
          currentUser?.userName === row?.expireonUser?.userName ||
          isLegaladmin === true ? (
            <button
              className='btn bg-transparent text-white fw-semi-bold d-flex align-items-center w-100'
              type='button'
              onClick={() => {
                this.setState({
                  modalOpen: true,
                  cell,
                  dropdownItem: [...this.state.dropdownItem, cell],
                  expireonUser: row?.expireonUser
                });
              }}
            >
              <img src={syncIcon} alt={'sync'} width={'16px'} height={'16px'} className={'me-2 '} />
              <p className={'mb-0 first-body-text'}>Re-Open</p>
            </button>
          ) : null}
        </DropdownMenu>
      </Dropdown>
    );
  }

  resetDropdowns(e) {
    if (e.target?.getAttribute('alt') != 'bar') {
      this.setState({
        dropdownItem: []
      });
    }
  }

  refreshTable = () => {
    const { dispatch } = this.props;
    const interval = this.props?.refreshInterval.concat('000');
    const refreshInterval = JSON.parse(interval);
    this.intervalID = setInterval(() => {
      dispatch(
        actions.doFetchClosed({
          searchString: this.state.searchString,
          pageSize: this.state.pageSize,
          pageNumber: this.state.pageNumber,
          sortOrder: this.state.sortOrder
        })
      ).then(() => {
        this.setState({
          newRows: this.props.rows,
          totalCount: this.props.count
        });
      });
    }, refreshInterval);
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(
      actions.doFetchClosed({
        searchString: this.state.searchString,
        pageNumber: this.state.pageNumber,
        pageSize: this.state.pageSize,
        sortOrder: this.state.sortOrder
      })
    ).then(() => {
      this.setState({
        totalCount: this.props.count,
        newRows: this.props.rows
      });
    });

    window.addEventListener('click', (e) => this.resetDropdowns(e));
    if (this.props?.refreshInterval) {
      this.refreshTable();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { dispatch } = this.props;
    if (
      (prevState.searchString !== this.state.searchString && this.state.searchString === '') ||
      prevState.pageSize !== this.state.pageSize ||
      prevState.pageNumber !== this.state.pageNumber ||
      prevState.sortOrder !== this.state.sortOrder
    ) {
      dispatch(
        actions.doFetchClosed({
          searchString: this.state.searchString,
          pageSize: this.state.pageSize,
          pageNumber: this.state.pageNumber,
          sortOrder: this.state.sortOrder
        })
      ).then(() => {
        this.setState({
          newRows: this.props.rows,
          totalCount: this.props.count
        });
      });
    }
    if (prevProps.refreshInterval !== this.props.refreshInterval) {
      this.refreshTable();
    }
    if (prevProps.rows !== this.props.rows) {
      this.setState({
        totalCount: this.props.count,
        newRows: this.props.rows
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
    window.removeEventListener('click', (e) => this.resetDropdowns(e));
  }

  handleSearch = () => {
    const { dispatch } = this.props;
    this.setState({
      pageNumber: 1
    });
    dispatch(
      actions.doFetchClosed({
        searchString: this.state.searchString,
        pageNumber: this.state.pageNumber,
        pageSize: this.state.pageSize,
        sortOrder: this.state.sortOrder
      })
    ).then(() => {
      this.setState({
        totalCount: this.props.count,
        newRows: this.props.rows
      });
    });
  };

  handleReOpen() {
    const { dispatch, navigate } = this.props;
    const legalCaseId = this.state.cell;
    const expireonUser = this.state.expireonUser;
    dispatch(actions.doReopenCase(legalCaseId, expireonUser, navigate)).then(() => {
      this.closeReOpenModal();
      dispatch(
        actions.doFetchClosed({
          searchString: this.state.searchString,
          pageNumber: this.state.pageNumber,
          pageSize: this.state.pageSize,
          sortOrder: this.state.sortOrder
        })
      ).then(() => {
        this.setState({
          totalCount: this.props.count,
          newRows: this.props.rows
        });
      });
    });
  }

  closeReOpenModal() {
    this.setState({
      modalOpen: false
    });
  }

  handlePageChange = (pageNumber) => {
    this.setState({ pageNumber: pageNumber });
  };

  sortFormatter(sortField) {
    const SortEnum = {
      caseName: 'casename',
      caseReferenceId: 'casereferenceid',
      caseDescription: 'description',
      holdCount: 'legalholds',
      contentSearchCount: 'contentsearches',
      createdTime: 'createdtime',
      closedTime: 'closed'
    };
    return SortEnum[sortField];
  }

  handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === 'sort') {
      this.setState({
        pageNumber: 1,
        sortOrder: this.sortFormatter(sortField).concat('_').concat(sortOrder)
      });
    }
  };

  render() {
    const toReadableSizeFormat = new FormattingService();

    const columns = [
      {
        dataField: 'caseName',
        text: 'Case Name',
        sort: true,
        formatter: (cell, row) => <div className={styles.holdListName}>{cell}</div>
      },
      {
        dataField: 'caseReferenceId',
        text: 'Reference Id',
        sort: true
      },
      {
        dataField: 'caseDescription',
        text: 'Description',
        sort: true,
        formatter: (cell, row) => <div className={styles.holdListDescription}>{cell}</div>
      },
      {
        dataField: 'holdCount',
        text: 'Legal Holds',
        sort: true,
        formatter: (item) => toReadableSizeFormat.numberWithCommas(item)
      },
      {
        dataField: 'contentSearchCount',
        text: 'Content Searches',
        sort: true,
        formatter: (item) => toReadableSizeFormat.numberWithCommas(item)
      },
      {
        dataField: 'createdTime',
        text: 'Created',
        sort: true,
        formatter: dataFormat.dateTimeFormatter
      },
      {
        dataField: 'closedTime',
        text: 'Closed',
        sort: true,
        formatter: dataFormat.dateTimeFormatter
      },
      {
        dataField: 'legalCaseId',
        text: 'Actions',
        formatter: (cell, row) => {
          return this.actionFormatter.bind(this)(cell, row);
        },
        events: {
          onClick: (e, column, columnIndex, row) => {
            if (this.state.dropdownItem.includes(row.legalCaseId)) {
              this.setState({
                dropdownItem: []
              });
            } else {
              this.setState({
                dropdownItem: [row.legalCaseId]
              });
            }
          }
        }
      }
    ];
    return (
      <>
        <Widget style={{ marginTop: '20px', width: '100%' }}>
          <ToolkitProvider
            bootstrap4
            columns={columns}
            data={this.state.newRows}
            keyField='legalCaseId'
          >
            {(props) => (
              <>
                <Row>
                  <Col style={{ marginTop: '0' }}>
                    <h3 className={'first-headline-text me-4 mb-0'}>Case Manager</h3>
                  </Col>
                </Row>
                <Row key={'table-part'} className='mb-4'>
                  <Col
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginTop: '5px'
                    }}
                  >
                    <span>
                      <input
                        type='search'
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            this.handleSearch();
                          }
                        }}
                        placeholder={'Search'}
                        style={{
                          border: '0.5px solid #0046b1',
                          borderRadius: '3px'
                        }}
                        className={'form-control search-input me-5 w-200'}
                        value={this.state.searchString}
                        onChange={(e) => {
                          this.setState({
                            searchString: e.target.value
                          });
                        }}
                      />
                    </span>
                    <span>
                      <button
                        className='btn search-button-with-icon me-2 ms-10'
                        type='button'
                        onClick={this.handleSearch}
                        style={{ height: '37px', width: '37px' }}
                      >
                        <img
                          title={'search'}
                          alt={'search'}
                          width={14}
                          height={14}
                          src={searchIcon}
                        />
                      </button>
                    </span>
                  </Col>
                </Row>
                <div className='table-container'>
                  <BootstrapTable
                    bordered={false}
                    classes={`table-striped table-hover fs-sm custom-table`}
                    remote={{
                      filter: false,
                      pagination: false,
                      sort: true,
                      cellEdit: false
                    }}
                    onTableChange={this.handleTableChange}
                    noDataIndication={emptyDataMessage}
                    {...props.baseProps}
                  />
                </div>
                <Row key={'pagination'} className='mt-3'>
                  <Col>
                    <p className={styles.totalCount}>Total: {this.state.totalCount}</p>
                  </Col>
                  <Col className='d-flex justify-content-end'>
                    {this.state.totalCount ? (
                      <Pagination
                        totalItemsCount={this.state.totalCount}
                        onChange={this.handlePageChange}
                        activePage={this.state.pageNumber}
                        itemsCountPerPage={this.state.pageSize}
                        pageRangeDisplayed={5}
                        prevPageText={'<'}
                        nextPageText={'>'}
                        firstPageText={'<<'}
                        lastPageText={'>>'}
                        linkClassFirst={styles.paginationNext}
                        linkClassPrev={styles.paginationNext}
                        linkClassNext={styles.paginationNext}
                        linkClassLast={styles.paginationNext}
                        linkClass={styles.pageLink}
                        activeLinkClass={styles.activeLinkClass}
                      />
                    ) : null}
                    <Dropdown
                      isOpen={this.state.paginationModalOpen}
                      toggle={() =>
                        this.setState({
                          paginationModalOpen: !this.state.paginationModalOpen
                        })
                      }
                      className={styles.pageSizeDropdown}
                    >
                      <DropdownToggle className='bg-transparent text-white d-flex'>
                        <p
                          style={{ marginBottom: '3px', marginRight: '2px' }}
                          className='first-body-text'
                        >
                          {this.state.pageSize}
                        </p>
                        <img
                          src={caretDown}
                          alt={'uncollapsed'}
                          width='20px'
                          height='20px'
                          className='ms-2'
                        />
                      </DropdownToggle>
                      <DropdownMenu className='dropdown-position-fixed'>
                        {[10, 15, 25].map((item) => (
                          <DropdownItem
                            className={styles.dropdownItem}
                            key={item}
                            onClick={() => {
                              this.setState({
                                pageSize: item,
                                paginationModalOpen: false,
                                pageNumber: 1
                              });
                            }}
                          >
                            {item}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                </Row>
              </>
            )}
          </ToolkitProvider>
        </Widget>
        <Modal
          size='md'
          isOpen={this.state.modalOpen}
          className={`themeStyle${this.props.currentTheme?.replace('#', '')}`}
        >
          <ModalHeader close={customCloseBtn(() => this.closeReOpenModal())}>
            <p className='second-headline-text'>Confirm</p>
          </ModalHeader>
          <ModalBody>
            <p className='text-center'>Are you sure you want to re-open this legal case?</p>
            <div className='d-flex justify-content-center align-content-center mt-4'>
              <button
                type='button'
                className='btn secondary-btn first-body-text me-3'
                onClick={() => this.closeReOpenModal()}
              >
                Cancel
              </button>
              <button
                type='button'
                className='btn first-body-text'
                onClick={() => this.handleReOpen()}
              >
                Re-Open
              </button>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

function mapStateToProps(store) {
  return {
    rows: store.LegalCase.list.closedLegalCases,
    count: store.LegalCase.list.closedLegalCasesCount,
    permissions: store.auth.permissions,
    refreshInterval: store.LegalHold.list.refreshInterval,
    currentTheme: store.layout.currentTheme
  };
}

export default connect(mapStateToProps)(ClosedListTable);
