const searchFilterFields = {
  legalCaseId: {
    type: "string",
  },
  contentSearchId: {
    type: "string",
  },
  attachmentTypes: {
    type: "relation_many",
    label: "Attachment Type",
  },
  tagsTypes: {
    type: "relation_many",
    label: "Tags Types",
  },
  isExcluded: {
    type: "relation_one",
    label: "Excluded",
    value: "null",
    options: [
      { value: "null", label: "All" },

      { value: "true", label: "Yes" },

      { value: "false", label: "No" },
    ],
    required: true,
  },
  hasAttachments: {
    type: "relation_one",
    label: "Attachments",
    value: "null",
    options: [
      { value: "null", label: "All" },

      { value: "true", label: "Yes" },

      { value: "false", label: "No" },
    ],
    required: true,
  },
  useTags: {
    type: "relation_one",
    label: "Tags",
    value: "null",
    options: [
      { value: "null", label: "All" },

      { value: "true", label: "Yes" },

      { value: "false", label: "No" },
    ],
    required: true,
  },
};

export default searchFilterFields;
