const LegalHoldFields = {
  id: { type: 'id', label: 'ID' },
  legalHoldName: {
    type: 'string',
    label: 'Name*',
    required: true
  },
  legalHoldDescription: {
    type: 'string',
    label: 'Description*',
    required: true
  },
  legalCaseId: {
    type: 'relation_one',
    label: 'Case Id'
  },
  custodians: {
    type: 'custodians',
    label: 'Add internal custodians'
  },
  externalCustodians: {
    type: 'externalCustodians',
    label: 'Identity Search'
  },
  dateFrom: {
    type: 'datetime',
    label: 'Start*',
    required: true
  },
  dateTo: {
    type: 'datetime',
    label: 'End*',
    required: true
  },
  dateRange: {
    type: 'string',
    label: 'Default Date Ranges',
    required: false
  },
  subject: {
    type: 'string',
    label: 'Email Subject Line'
  },
  internalOnly: {
    type: 'enum',
    label: 'Internal Only',
    value: null,

    options: [
      { value: true, label: 'Yes' },

      { value: false, label: 'No' },

      { value: null, label: 'Any' }
    ]
  },
  incoming: {
    type: 'enum',
    label: 'Incoming',
    value: null,
    options: [
      { value: true, label: 'Yes' },

      { value: false, label: 'No' },

      { value: null, label: 'Any' }
    ]
  },
  outgoing: {
    type: 'enum',
    label: 'Outgoing',
    value: null,
    options: [
      { value: true, label: 'Yes' },

      { value: false, label: 'No' },

      { value: null, label: 'Any' }
    ]
  },
  noOtherInternalRecipient: {
    type: 'enum',
    label: 'No Other Internal Recipient',
    value: null,
    options: [
      { value: true, label: 'Yes' },

      { value: false, label: 'No' },

      { value: null, label: 'Any' }
    ]
  },
  messageClass: {
    type: 'relation_one',
    label: 'Message Class',
    value: []
  },
  legalHoldScope: {
    type: 'relation_one',
    label: 'Legal Hold Scope'
  },

  containerIds: {
    type: 'relation_one',
    label: 'Containers*',
    required: true
  },
  flows: {
    type: 'relation_one',
    label: 'Flow',
    value: { ALL_EMAILS: 'All Emails' },
    required: false
  },
  attachmentExtensions: {
    type: 'relation_many',
    label: 'Attachment Type',
    value: []
  },
  attachmentNames: {
    type: 'string',
    label: 'Attachment Name (Query)'
  },
  emailAddress: {
    type: 'string',
    label: 'Email Address*',
    required: true
  },
  displayName: {
    type: 'string',
    label: 'Name'
  },
  companyName: {
    type: 'string',
    label: 'Company'
  },
  channelInfo: {
    type: 'relation_one',
    label: 'Channels',
    value: []
  },
  userId: {
    type: 'string',
    label: 'User Id*',
    required: true
  }
};

export default LegalHoldFields;
