const initialData = {
  dateRanges: [],
  reportTemplateType: [],
  allExpireonUsers: [],
};

export default (state = initialData, { type, payload }) => {
  if (type === "DATE_RANGES_FETCH_STARTED") {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === "DATE_RANGES_FETCH_SUCCESS") {
    return {
      ...state,
      loading: false,
      dateRanges: payload.dateRanges,
    };
  }

  if (type === "DATE_RANGES_FETCH_ERROR") {
    return {
      ...state,
      loading: false,
      dateRanges: [],
    };
  }
  if (type === "REPORT_TEMPLATE_TYPES_FETCH_STARTED") {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === "REPORT_TEMPLATE_TYPES_FETCH_SUCCESS") {
    return {
      ...state,
      loading: false,
      reportTemplateType: payload.reportTemplateType,
    };
  }

  if (type === "REPORT_TEMPLATE_TYPES_FETCH_ERROR") {
    return {
      ...state,
      loading: false,
      reportTemplateType: [],
    };
  }
  if (type === "ALL_EXPIREON_USERS_FETCH_STARTED") {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === "ALL_EXPIREON_USERS_FETCH_SUCCESS") {
    return {
      ...state,
      loading: false,
      allExpireonUsers: payload.allExpireonUsers,
    };
  }

  if (type === "ALL_EXPIREON_USERS_FETCH_ERROR") {
    return {
      ...state,
      loading: false,
      allExpireonUsers: [],
    };
  }
  return state;
};
