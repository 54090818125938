const AuditTemplateFields = {
  name: {
    type: "string",
    label: "Name*",
  },
  description: {
    type: "string",
    label: "Description*",
  },
  templateUsers: {
    type: "string",
    label: "Template Owners*",
  },
  dateRanges: {
    type: "dateRanges",
    label: "Date Range*",
  },
};

export default AuditTemplateFields;
