import { getTheme, setTheme } from 'services/themeService';

export const SIDEBAR_TYPE_TOGGLE = 'SIDEBAR_TYPE_TOGGLE';
export const GET_THEME_COLOR_SUCCESS = 'GET_THEME_COLOR_SUCCESS';
export const SET_THEME_COLOR_SUCCESS = 'SET_THEME_COLOR_SUCCESS';

export function sidebarTypeToggle(value) {
  return {
    type: SIDEBAR_TYPE_TOGGLE,
    payload: value
  };
}
const actions = {
  doGetThemeColor: (loggedUser) => async (dispatch) => {
    try {
      dispatch({
        type: 'GET_THEME_COLOR_STARTED'
      });
      const response = await getTheme(loggedUser);
      const value = response?.settingValue ? response?.settingValue : '#333964';
      dispatch({
        type: 'GET_THEME_COLOR_SUCCESS',
        payload: {
          currentTheme: value
        }
      });
    } catch (error) {
      console.log(error);

      dispatch({
        type: 'GET_THEME_COLOR_ERROR'
      });
    }
  },
  doSetThemeColor: (selectedTheme, loggedUser) => async (dispatch) => {
    try {
      dispatch({
        type: 'SET_THEME_COLOR_STARTED'
      });
      await setTheme(selectedTheme, loggedUser);
      dispatch({
        type: 'SET_THEME_COLOR_SUCCESS',
        payload: {
          currentTheme: selectedTheme
        }
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: 'SET_THEME_COLOR_ERROR'
      });
    }
  }
};
export default actions;
