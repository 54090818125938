import * as dataFormat from 'components/CRUD/LegalHold/list/LegalHoldDataFormatters';
import actions from 'actions/LegalHold/LegalHoldListActions';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Widget from 'components/Widget/Widget';
import {
  Button,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from 'reactstrap';
import actionsLC from 'actions/LegalCase/LegalCaseFormActions';
import plusIcon from '../../../../images/icons/plus.svg';
import viewIcon from '../../../../images/icons/eye.svg';
import syncIcon from '../../../../images/icons/sync.svg';
import closeIcon from '../../../../images/icons/close.svg';
import editIcon from '../../../../images/icons/edit.svg';
import trashIcon from '../../../../images/icons/trash.svg';
import { FormattingService } from '../../../../utils/sizeFormatting';
import { toast } from 'react-toastify';
import formActions from 'actions/LegalHold/LegalHoldFormActions';
import LegalHoldFormPage from '../form/LegalHoldFormPage';
import Pagination from 'react-js-pagination';
import styles from './LegalHoldList.module.scss';
import config from 'config';
import searchIcon from 'images/icons/search.svg';
import caretDown from 'images/icons/caret-down.svg';
import caretUp from 'images/icons/caret-up.svg';
import { faTable } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as searchDataFormat from 'components/CRUD/Search/list/SearchDataFormatters';
import { customCloseBtn } from 'actions/common';

class LegalHoldTilesView extends Component {
  intervalID = 0;
  interval = 0;
  state = {
    modalOpen: false,
    resetModalOpen: false,
    isIndexing: [],
    totalCount: 0,
    pageNumber: 1,
    pageSize: 3,
    newRows: [],
    searchString: '',
    isEditing: false,
    legalHoldId: '',
    holdsToExport: [],
    openDeleteModal: false,
    openWarningModal: false,
    isNotConfirmed: false,
    openModalFromView: false,
    expandExport: false,
    selectedHoldId: ''
  };

  handleIndexing() {
    const { dispatch } = this.props;
    const legalHoldId = this.state.cell;
    const currentUser = this.props?.currentUser;
    dispatch(actions.doAllowIndexing(legalHoldId, currentUser)).then(() => {
      this.setState({
        modalOpen: false
      });
    });
  }

  resetQueuedItems() {
    const { dispatch } = this.props;
    const legalHoldId = this.state.cell;
    const currentUser = this.props?.currentUser;
    dispatch(actions.doResetQueuedItems(legalHoldId, currentUser)).then(() => {
      this.setState({
        resetModalOpen: false
      });
    });
  }

  closeModal() {
    this.setState({
      modalOpen: !this.state.modalOpen
    });
  }

  closeResetModal() {
    this.setState({
      resetModalOpen: !this.state.resetModalOpen
    });
  }

  openFormModal() {
    this.props.dispatch(formActions.doOpenConfirm());
  }

  closeFormModal() {
    if (this.props.currentStep === 5 && this.props.legalHoldStats?.isResultValid) {
      this.setState({
        openWarningModal: true
      });
    } else {
      this.setState({
        isEditing: false,
        openWarningModal: false,
        isNotConfirmed: false
      });
      this.props.dispatch(formActions.doCloseConfirm());
    }
  }

  handleViewLegalHold = async (row) => {
    const { dispatch } = this.props;
    const legalHoldId = row?.legalHoldId;
    if (row?.legalHoldConfirmed) {
      this.props.navigate(`/app/LegalHold/${legalHoldId}`);
    } else {
      dispatch(formActions.doSetCurrentStep(5));
      if (row?.legalHoldStatistics) {
        dispatch(formActions.doGetLegalHoldStatistics(row?.legalHoldId));
      } else {
        this.interval = setInterval(() => {
          dispatch(formActions.doGetLegalHoldStatistics(row?.legalHoldId));
        }, 20000);
      }
      this.setState({
        legalHoldId,
        isNotConfirmed: true,
        openModalFromView: true
      });
      this.openFormModal();
    }
  };

  toggleActions = (cell, row) => {
    if (this.state.selectedHoldId === '') {
      this.setState({
        selectedHoldId: cell
      });
    } else {
      this.setState({
        selectedHoldId: ''
      });
    }
  };

  actionFormatter(cell, row) {
    let value = Math.floor(
      (row.legalHoldStatistics?.indexedItemsCount / row.legalHoldStatistics?.itemCount) * 100
    );
    return (
      <Dropdown
        isOpen={this.state.selectedHoldId === cell}
        className={`${styles.actionsButton}`}
        toggle={() => this.toggleActions(cell, row)}
      >
        <DropdownToggle
          className={`${styles.actionsButton} first-body-text bg-transparent text-white mb-0`}
        >
          Actions
          {this.state.selectedHoldId === cell ? (
            <img src={caretUp} alt={'collapsed'} width='15px' height='15px' className='ms-2' />
          ) : (
            <img src={caretDown} alt={'uncollapsed'} width='15px' height='15px' className='ms-2' />
          )}
        </DropdownToggle>
        <DropdownMenu className='dropdown-position-fixed'>
          {this.props.permissions['LegalUI.LegalHold'] ? (
            <button
              className='btn bg-transparent text-white first-body-text d-flex align-items-center w-100'
              type='button'
              onClick={() => this.handleViewLegalHold(row)}
            >
              <img src={viewIcon} alt={'plus'} className={'me-2'} />
              <p className={'mb-0'}>Details</p>
            </button>
          ) : null}
          {/* {this.props.permissions["LegalUI.LegalHold.Edit"] &&
          this.props?.caseStatus !== config.caseStatus.Closed ? (
            <button
              className={`btn bg-transparent text-white first-body-text d-flex align-items-center w-100 ${
                value !== 100 && value !== 0 && !Number.isNaN(value)
                  ? "disabled"
                  : ""
              }`}
              disabled={value !== 100 && value !== 0 && !Number.isNaN(value)}
              type="button"
              onClick={() => {
                toast.success(
                  "This action can affect number of items for legal hold. It can also affect content search results.",{ icon: false }
                );
                this.setState({
                  isEditing: true,
                  legalHoldId: cell,
                });
                this.openFormModal();
              }}
            >
              <img
                src={editIcon}
                alt={"edit"}
                width={"16px"}
                height={"16px"}
                className={"me-2"}
              />
              <p className={"mb-0"}>Edit</p>
            </button>
          ) : null} */}
          {this.props.permissions['LegalUI.LegalHold.Index'] &&
          this.props?.caseStatus !== config.caseStatus.Closed ? (
            <button
              className={`btn bg-transparent text-white first-body-text d-flex align-items-center w-100`}
              type='button'
              disabled={
                !row.legalHoldStatistics ||
                row.legalHoldStatistics?.itemCount === 0 ||
                row.indexingQueued ||
                row.isIndexed ||
                !row.legalHoldConfirmed ||
                (this.props.legalCase?.restoreInProgress && !this.props.legalCase?.restoreComplete)
              }
              onClick={() => {
                this.setState({
                  modalOpen: true,
                  cell,
                  isIndexing: [...this.state.isIndexing, cell]
                });
              }}
            >
              <img src={syncIcon} alt={'sync'} width={'16px'} height={'16px'} className={'me-2'} />
              <p className={'mb-0'}>Index</p>
            </button>
          ) : null}
          {this.props.permissions['LegalUI.LegalHold'] &&
          this.props?.caseStatus !== config.caseStatus.Closed ? (
            <button
              className={`btn bg-transparent text-white first-body-text d-flex align-items-center w-100`}
              type='button'
              disabled={!row.indexingQueued || !row.legalHoldConfirmed}
              onClick={() => {
                this.setState({
                  resetModalOpen: true,
                  cell,
                  isIndexing: [...this.state.isIndexing, cell]
                });
              }}
            >
              <img src={closeIcon} alt={'sync'} width={'16px'} height={'16px'} className={'me-2'} />
              <p className={'mb-0'}>Reset</p>
            </button>
          ) : null}
          {row?.legalHoldStatistics?.itemCount === 0 &&
          row?.legalHoldStatistics?.isResultValid &&
          row.legalHoldConfirmed &&
          this.props?.caseStatus !== config.caseStatus.Closed ? (
            <button
              className={`btn bg-transparent text-white first-body-text d-flex align-items-center w-100`}
              type='button'
              onClick={() => {
                this.setState({
                  openDeleteModal: true,
                  legalHoldId: cell
                });
              }}
            >
              <img src={trashIcon} alt={'sync'} width={'14px'} height={'14px'} className={'me-2'} />
              <p className={'mb-0'}>Delete</p>
            </button>
          ) : null}
          {!row.legalHoldConfirmed &&
            row?.legalHoldStatistics !== null &&
            row?.legalHoldStatistics?.isResultValid &&
            this.props?.caseStatus !== config.caseStatus.Closed && (
              <button
                className={`btn bg-transparent text-white first-body-text d-flex align-items-center w-100`}
                type='button'
                onClick={() => {
                  this.setState({
                    legalHoldId: cell
                  });
                  this.props
                    .dispatch(
                      formActions.doDiscardLegalHold(
                        row?.legalCaseId,
                        this.props?.currentUser,
                        row?.legalHoldId
                      )
                    )
                    .then(() => {
                      toast.success('Legal Hold discarded',{ icon: false });
                      this.props.dispatch(
                        actions.doFetch({
                          legalCaseId: row?.legalCaseId,
                          pageNumber: this.state.pageNumber,
                          pageSize: this.state.pageSize
                        })
                      );
                    });
                }}
              >
                <img
                  src={trashIcon}
                  alt={'sync'}
                  width={'14px'}
                  height={'14px'}
                  className={'me-2'}
                />
                <p className={'mb-0'}>Discard</p>
              </button>
            )}
        </DropdownMenu>
      </Dropdown>
    );
  }

  refreshTable = () => {
    const { dispatch, navigate } = this.props;
    const interval = this.props?.refreshInterval.concat('000');
    const refreshInterval = JSON.parse(interval);
    this.intervalID = setInterval(() => {
      dispatch(actionsLC.doGetStats(this.props.legalCaseId, navigate));
      dispatch(
        actions.doFetch({
          legalCaseId: this.props.legalCaseId,
          searchString: this.state.searchString,
          pageNumber: this.state.pageNumber,
          pageSize: this.state.pageSize
        })
      ).then(() => {
        this.setState({
          totalCount: this.props.count,
          newRows: this.props.rows
        });
      });
    }, refreshInterval);
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(
      actions.doFetch({
        searchString: this.state.searchString,
        legalCaseId: this.props.legalCaseId,
        pageNumber: this.state.pageNumber,
        pageSize: this.state.pageSize
      })
    ).then(() => {
      this.setState({
        totalCount: this.props.count,
        newRows: this.props.rows
      });
    });
    if (this.props.refreshInterval !== null) {
      this.refreshTable();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { dispatch } = this.props;
    if (prevProps.refreshInterval !== this.props.refreshInterval) {
      this.refreshTable();
    }
    if (
      (prevState.searchString !== this.state.searchString && this.state.searchString === '') ||
      prevState.pageSize !== this.state.pageSize ||
      prevState.pageNumber !== this.state.pageNumber
    ) {
      dispatch(
        actions.doFetch({
          legalCaseId: this.props.legalCaseId,
          searchString: this.state.searchString,
          pageSize: this.state.pageSize,
          pageNumber: this.state.pageNumber
        })
      ).then(() => {
        this.setState({
          newRows: this.props.rows,
          totalCount: this.props.count
        });
      });
    }
    if (prevProps.rows !== this.props.rows) {
      this.setState({
        newRows: this.props.rows,
        totalCount: this.props.count
      });
    }
    if (prevProps.holdsToExport !== this.props.holdsToExport) {
      this.setState({
        holdsToExport: this.props?.holdsToExport
      });
    }
    if (
      prevProps.legalHoldStats !== this.props.legalHoldStats &&
      this.props.legalHoldStats?.isResultValid
    ) {
      clearInterval(this.interval);
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  handleSearch = () => {
    const { dispatch } = this.props;
    this.setState({ pageNumber: 1 });
    dispatch(
      actions.doFetch({
        legalCaseId: this.props.legalCaseId,
        searchString: this.state.searchString,
        pageNumber: this.state.pageNumber,
        pageSize: this.state.pageSize
      })
    ).then(() => {
      this.setState({
        newRows: this.props.rows,
        totalCount: this.props.count
      });
    });
  };

  handlePageChange = (pageNumber) => {
    this.setState({ pageNumber: pageNumber });
  };

  handleDeleteLegalHold = () => {
    const { dispatch } = this.props;
    const filter = {
      legalCaseId: this.props?.legalCaseId,
      searchString: this.state.searchString,
      pageNumber: this.state.pageNumber,
      pageSize: this.state.pageSize,
      sortOrder: ''
    };
    dispatch(actions.doDelete(this.state.legalHoldId, filter, this.props?.currentUser)).then(() => {
      this.setState({
        openDeleteModal: false
      });
    });
  };

  getContainerIds = (data) => {
    const containerIds = [];
    data.map((item) => {
      containerIds.push(item.containerId);
    });
    return containerIds;
  };

  formatData = (data) => {
    const custodianIds = [];
    data?.custodians?.map((item) => {
      custodianIds.push(item?.custodianId);
    });
    const containerIds = this.getContainerIds(data?.containers);
    const dataToSubmit = {
      legalHoldName: data.legalHoldName,
      legalHoldDescription: data.legalHoldDescription,
      legalCaseId: data?.legalCaseId,
      custodians: custodianIds,
      externalCustodians: data.externalCustodians,
      dateFrom: data.dateFrom,
      dateTo: data.dateTo,
      subject: data.subject,
      internalOnly: data.internalOnly,
      incoming: data.incoming,
      outgoing: data.outgoing,
      noOtherInternalRecipient: data.noOtherInternalRecipient,
      messageClass: data.noOtherInternalRecipient,
      subjectLine: data.subjectLine,
      attachmentNames: data.attachmentNames,
      attachmentExtensions: data.attachmentExtensions,
      containerIds
    };
    return dataToSubmit;
  };

  handleSaveLegalHold = () => {
    const { dispatch } = this.props;
    const data = this.state.openModalFromView
      ? this.formatData(this.props.record)
      : this.props.filters?.filter;

    const expireonUser = this.state.openModalFromView
      ? this.props.record?.expireonUser
      : this.props.filters?.expireonUser;
    dispatch(
      formActions.doCreate(
        { ...data, legalHoldId: this.props?.createdLegalHoldId },
        {
          expireonUser: expireonUser
        }
      )
    ).then(() => {
      dispatch(
        actions.doFetch({
          legalCaseId: this.props.legalCaseId,
          pageNumber: this.state.pageNumber,
          pageSize: this.state.pageSize
        })
      );
      this.setState({
        openWarningModal: false
      });
      dispatch(formActions.doCloseConfirm());
    });
  };

  handleDiscardLegalHold = async () => {
    const { dispatch } = this.props;
    const legalCaseId = this.props.legalCaseId;
    const legalHoldId = this.props?.createdLegalHoldId;
    await dispatch(
      formActions.doDiscardLegalHold(legalCaseId, this.props?.currentUser, legalHoldId)
    ).then(() => {
      toast.success('Legal Hold discarded',{ icon: false });
      dispatch(
        actions.doFetch({
          legalCaseId: legalCaseId,
          pageNumber: this.state.pageNumber,
          pageSize: this.state.pageSize
        })
      );
      this.setState({
        openWarningModal: false
      });
      dispatch(formActions.doCloseConfirm());
    });
  };

  toggleExport = () => {
    this.setState({
      expandExport: !this.state.expandExport
    });
  };

  exportMenu = () => {
    return (
      <Dropdown
        isOpen={this.state.expandExport}
        className={`${styles.exportButton}`}
        toggle={this.toggleExport}
      >
        <DropdownToggle
          className={`${styles.exportButton} bg-transparent text-white mb-0`}
          disabled={this.state.totalCount === 0}
        >
          Export
          {!this.state.expandExport ? (
            <img src={caretDown} alt={'uncollapsed'} width='20px' height='20px' className='ms-2' />
          ) : (
            <img src={caretUp} alt={'collapsed'} width='20px' height='20px' className='ms-2' />
          )}
        </DropdownToggle>
        <DropdownMenu className='p-0 bg-transparent'>
          <DropdownItem className={`${styles.exportItem}`} onClick={this.handleExportCSV}>
            CSV
          </DropdownItem>
          <DropdownItem className={`${styles.exportItem}`} onClick={this.handleExportPDF}>
            PDF
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  };

  showTable() {
    const { dispatch } = this.props;
    dispatch(actions.doShowTable());
  }

  columnsToExport = [
    'legalHoldName',
    'legalHoldDescription',
    'creationDate',
    'itemSizeInBytes',
    'itemCount',
    'finallyFailedItemsCount',
    'legalHoldConfirmed'
  ];

  handleExportCSV = () => {
    const { dispatch } = this.props;
    const url = `${config.externalApi}/legal/api/LegalHold/ExportLegalHolds?`;
    const currentUser = this.props?.currentUser;
    const columnHeaders = this.columnsToExport.join(',');
    dispatch(
      actions.handleExportToCSV(
        url,
        currentUser,
        this.props?.legalCaseId,
        this.state.searchString,
        columnHeaders
      )
    );
  };

  handleExportPDF = () => {
    const { dispatch } = this.props;
    const url = `${config.externalApi}/legal/api/LegalHold/ExportToPdf?`;
    const currentUser = this.props?.currentUser;
    const columnHeaders = this.columnsToExport.join(',');
    dispatch(
      actions.handleExportToPDF(
        url,
        currentUser,
        this.props?.legalCaseId,
        this.state.searchString,
        columnHeaders
      )
    );
  };
  getAllAttachments = (item) => {
    let allAttachments = [];
    allAttachments = item?.attachmentExtensions?.concat(item?.attachmentNames);
    if (allAttachments.length > 0) {
      return (
        <span className={styles.messageClass}>
          {allAttachments?.map((item, index) => (
            <span key={index} style={{ margin: '3px' }}>
              {item}
              {index + 1 < allAttachments?.length && <span>,</span>}
            </span>
          ))}
        </span>
      );
    } else {
      return <p>Any</p>;
    }
  };

  render() {
    const toReadableSizeFormat = new FormattingService();

    return (
      <div>
        <Widget style={{ marginTop: '20px' }}>
          <Row>
            <Col sm={12}>
              <>
                <Row>
                  <Col style={{ marginTop: '0px', marginBottom: '10px' }}>
                    <div className={'d-flex align-items-center'}>
                      <h3 className={'first-headline-text me-4 mb-0'}>Legal Holds</h3>
                    </div>
                  </Col>
                </Row>
                <Row key={'table-part'}>
                  <Col style={{ marginTop: '8px' }}>
                    <div className={'d-flex align-items-center'}>
                      {this.props.permissions['LegalUI.LegalHold.Create'] &&
                      this.props?.caseStatus !== config.caseStatus.Closed ? (
                        <button
                          className='btn first-body-text d-flex align-items-center'
                          type='button'
                          onClick={() => {
                            this.setState({
                              isEditing: false,
                              openWarningModal: false,
                              isNotConfirmed: false,
                              openModalFromView: false
                            });
                            this.openFormModal();
                          }}
                        >
                          <img src={plusIcon} alt={'plus'} className={'me-2'} />
                          <p className={'mb-0'}>New</p>
                        </button>
                      ) : null}
                    </div>
                  </Col>
                  <Col
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginTop: '5px'
                    }}
                  >
                    <div style={{ marginRight: '5px' }}>{this.exportMenu()}</div>
                    <span>
                      <button
                        className='btn fw-semi-bold d-flex align-items-center'
                        type='button'
                        onClick={() => this.showTable()}
                        style={{ height: '37px', marginRight: '5px' }}
                      >
                        <FontAwesomeIcon icon={faTable} />
                      </button>
                    </span>
                    <span>
                      <input
                        type='search'
                        placeholder={'Search'}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            this.handleSearch();
                          }
                        }}
                        style={{
                          border: '0.5px solid #0046b1',
                          borderRadius: '3px'
                        }}
                        className={'form-control search-input me-5 w-200'}
                        value={this.state.searchString}
                        onChange={(e) => {
                          this.setState({
                            searchString: e.target.value
                          });
                        }}
                      />
                    </span>
                    <span>
                      <button
                        className='btn search-button-with-icon me-2 ms-10'
                        type='button'
                        onClick={this.handleSearch}
                        style={{ height: '37px', width: '37px' }}
                      >
                        <img
                          title={'search'}
                          alt={'search'}
                          width={14}
                          height={14}
                          src={searchIcon}
                        />
                      </button>
                    </span>
                  </Col>
                </Row>
              </>
            </Col>
          </Row>
          <Row sm={12} className='mt-4'>
            {this.state.newRows?.length ? (
              this.state.newRows?.map((item) => (
                <Col sm={4} key={item.legalHoldId}>
                  <Widget
                    style={{
                      height: '530px',
                      overflowY: 'auto',
                      overflowX: 'hidden',
                      width: '100%'
                    }}
                  >
                    <p className={`${styles.cardName} first-headline-text`}>{item.legalHoldName}</p>
                    <div className='w-100'>
                      <p className='mb-0'>
                        <span className='me-2 first-subtitle-text'>Description:</span>
                        <span className={`${styles.messageClass} first-body-text`}>
                          {item.legalHoldDescription}
                        </span>
                      </p>
                      <p>
                        <span className='me-2 first-subtitle-text'>Confirmed:</span>{' '}
                        <span className='first-body-text'>
                          {item.legalHoldConfirmed ? 'Yes' : 'No'}
                        </span>
                      </p>
                    </div>
                    <div>
                      <p className={`${styles.sectionHeader} first-subtitle-text`}>Filter by</p>
                      <p className='mb-0'>
                        <span className='me-2 first-subtitle-text'>Date:</span>
                        <span className='first-body-text'>
                          {searchDataFormat.dateTimeFormatter(item.dateFrom)}
                        </span>{' '}
                        {' - '}
                        <span className='first-body-text'>
                          {searchDataFormat.dateTimeFormatter(item.dateTo)}
                        </span>
                      </p>
                      <p className='mb-0'>
                        <span className='me-2 first-subtitle-text'>Direction:</span>
                        <span className='first-body-text'>
                          {this.props?.record?.internalOnly ? (
                            <span>Internal Emails Only</span>
                          ) : this.props?.record?.incoming === null &&
                            this.props?.record?.outgoing === null &&
                            this.props?.record?.internalOnly === false ? (
                            <span>Incoming & Outgoing</span>
                          ) : this.props?.record?.outgoing ? (
                            <span>Outgoing Emails Only</span>
                          ) : this.props?.record?.incoming ? (
                            <span>Incoming Emails Only</span>
                          ) : (
                            <span>All Emails</span>
                          )}
                        </span>
                      </p>
                      <p className='mb-0'>
                        <span className='me-2 first-subtitle-text'> Subject:</span>
                        <span className='first-body-text'>
                          {item?.subject ? item?.subject : 'Any'}
                        </span>
                      </p>
                      <p className='mb-0 d-flex'>
                        <span
                          className='first-subtitle-text'
                          style={{
                            minWidth: '120px'
                          }}
                        >
                          Message Class:
                        </span>
                        <span className={`${styles.messageClass} first-body-text`}>
                          {item.messageClass ? item.messageClass : 'Any'}
                        </span>
                      </p>
                      <p className='d-flex'>
                        <span className='first-subtitle-text me-2'>Attachments:</span>
                        <span className={`${styles.messageClass} first-body-text`}>
                          {this.getAllAttachments(item)}
                        </span>
                      </p>
                    </div>
                    <div>
                      <p className={`${styles.sectionHeader} first-subtitle-text`}>Results</p>
                      <div className='d-flex w-100 mb-0'>
                        <p className='w-50 mb-0'>
                          <span className='me-2 first-subtitle-text'>Items:</span>
                          <span className='first-body-text'>
                            {item?.legalHoldStatistics?.itemCount
                              ? `${toReadableSizeFormat.numberWithCommas(
                                  item.legalHoldStatistics.itemCount
                                )}`
                              : 0}
                          </span>
                        </p>
                        <p className='w-50 mb-0'>
                          <span className='first-subtitle-text me-2'>Size:</span>
                          <span className='first-body-text'>
                            {item?.legalHoldStatistics?.itemSizeInBytes
                              ? `${dataFormat.sizeFormatter(
                                  item.legalHoldStatistics.itemSizeInBytes
                                )}`
                              : '0.00 B'}
                          </span>
                        </p>
                      </div>
                      <p className='mb-0'>
                        <span className='first-subtitle-text me-2'> Items Indexed:</span>{' '}
                        <span className='first-body-text'>
                          {item?.legalHoldStatistics?.indexedItemsCount
                            ? `${toReadableSizeFormat.numberWithCommas(
                                item.legalHoldStatistics?.indexedItemsCount
                              )}`
                            : 0}
                        </span>
                      </p>
                      <p>
                        <span className='first-subtitle-text me-2'> Index Failures: </span>{' '}
                        <span className='first-body-text'>
                          {item?.legalHoldStatistics?.finallyFailedItemsCount
                            ? `${toReadableSizeFormat.numberWithCommas(
                                item.legalHoldStatistics.finallyFailedItemsCount
                              )}`
                            : 0}
                        </span>
                      </p>
                    </div>
                    <p>
                      <span className='first-subtitle-text me-2'>Created by:</span>
                      <span className='me-1 first-body-text'>{item?.expireonUser?.firstName}</span>
                      <span className='me-1 first-body-text'>{item?.expireonUser?.lastName}</span>
                      <span>({dataFormat.dateTimeFormatter(item.creationDate)})</span>
                    </p>
                    <p className='d-flex justify-content-end mb-0'>
                      {this.actionFormatter(item.legalHoldId, item)}
                    </p>
                  </Widget>
                </Col>
              ))
            ) : (
              <div
                style={{ height: this.state.newRows?.length ? 500 : 'auto' }}
                className={'d-flex w-100 justify-content-center align-items-center'}
              >
                <p className={'text-white'}>No data available</p>
              </div>
            )}
          </Row>
          <Row>
            <Col style={{ marginTop: '3px' }}>
              <p>Total: {this.state.totalCount}</p>
            </Col>
            <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {this.state.totalCount ? (
                <Pagination
                  totalItemsCount={this.state.totalCount}
                  onChange={this.handlePageChange}
                  activePage={this.state.pageNumber}
                  itemsCountPerPage={this.state.pageSize}
                  pageRangeDisplayed={5}
                  prevPageText={'<'}
                  nextPageText={'>'}
                  firstPageText={'<<'}
                  lastPageText={'>>'}
                  linkClassFirst={styles.paginationNext}
                  linkClassPrev={styles.paginationNext}
                  linkClassNext={styles.paginationNext}
                  linkClassLast={styles.paginationNext}
                  linkClass={styles.pageLink}
                  activeLinkClass={styles.activeLinkClass}
                />
              ) : null}
              <Dropdown
                isOpen={this.state.paginationModalOpen}
                toggle={() =>
                  this.setState({
                    paginationModalOpen: !this.state.paginationModalOpen
                  })
                }
                className={styles.pageSizeDropdown}
              >
                <DropdownToggle className='bg-transparent text-white d-flex'>
                  <p
                    style={{ marginBottom: '3px', marginRight: '2px' }}
                    className='first-body-text'
                  >
                    {this.state.pageSize}
                  </p>
                  <img
                    src={caretDown}
                    alt={'uncollapsed'}
                    width='20px'
                    height='20px'
                    className='ms-2'
                  />
                </DropdownToggle>
                <DropdownMenu className='dropdown-position-fixed'>
                  {[3, 6, 9].map((item) => (
                    <DropdownItem
                      className={styles.dropdownItem}
                      key={item}
                      onClick={() => {
                        this.setState({
                          pageSize: item,
                          paginationModalOpen: false,
                          pageNumber: 1
                        });
                      }}
                    >
                      {item}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </Col>
          </Row>
        </Widget>
        <Modal
          size='md'
          isOpen={this.state.modalOpen}
          className={`themeStyle${this.props.currentTheme?.replace('#', '')}`}
        >
          <ModalHeader close={customCloseBtn(() => this.closeModal())}>
            <p className='second-headline-text'>Confirm</p>
          </ModalHeader>
          <ModalBody>
            <p className='text-center'>Are you sure you want to index this legal hold?</p>
            <div className='d-flex justify-content-center align-content-center'>
              <button
                type='button'
                className='btn secondary-btn mt-4 first-body-text me-3'
                onClick={() => this.closeModal()}
              >
                Cancel
              </button>
              <button
                type='button'
                className='btn mt-4 first-body-text'
                onClick={() => this.handleIndexing()}
              >
                Index
              </button>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          size='md'
          isOpen={this.state.resetModalOpen}
          className={`themeStyle${this.props.currentTheme?.replace('#', '')}`}
        >
          <ModalHeader close={customCloseBtn(() => this.closeResetModal())}>
            <p className='second-headline-text'>Confirm</p>
          </ModalHeader>
          <ModalBody>
            <p className='text-center'>Are you sure you want to reset queued items?</p>
            <div className='d-flex justify-content-center align-content-center'>
              <button
                type='button'
                className='btn secondary-btn mt-4 me-3 first-body-text'
                onClick={() => this.closeResetModal()}
              >
                Cancel
              </button>
              <button
                type='button'
                className='btn mt-4 first-body-text'
                onClick={() => this.resetQueuedItems()}
              >
                Reset
              </button>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          size='lg'
          isOpen={this.props.openFormModal}
          className={`themeStyle${this.props.currentTheme?.replace('#', '')}`}
        >
          <ModalHeader close={customCloseBtn(() => this.closeFormModal())}>
            <p className='second-headline-text'>
              {this.state.isEditing ? 'Edit Legal Hold' : ' Create Legal Hold'}
            </p>
          </ModalHeader>

          <ModalBody>
            <LegalHoldFormPage
              isEditing={this.state.isEditing}
              legalCaseId={this.props.legalCaseId}
              legalHoldId={this.state.legalHoldId}
              pageNumber={this.state.pageNumber}
              pageSize={this.state.pageSize}
              isNotConfirmed={this.state.isNotConfirmed}
            />
          </ModalBody>
        </Modal>
        <Modal
          size='md'
          isOpen={this.state.openDeleteModal}
          className={`themeStyle${this.props.currentTheme?.replace('#', '')}`}
        >
          <ModalHeader
            close={customCloseBtn(() =>
              this.setState({
                openDeleteModal: false
              })
            )}
          >
            <p className='second-headline-text'>Confirm</p>
          </ModalHeader>
          <ModalBody>
            <div className={styles.deleteModalContainer}>
              Are you sure you want to delete this item?
            </div>
            <div className={styles.deleteModalFooter}>
              <button
                type='button'
                onClick={() =>
                  this.setState({
                    openDeleteModal: false
                  })
                }
                className={`${styles.deleteButton} first-body-text btn secondary-btn`}
              >
                Cancel
              </button>
              <button
                type='button'
                onClick={() => this.handleDeleteLegalHold()}
                className={`${styles.deleteButton} first-body-text btn danger-btn`}
              >
                Delete
              </button>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          size='md'
          isOpen={this.state.openWarningModal}
          className={`themeStyle${this.props.currentTheme?.replace('#', '')}`}
        >
          <ModalHeader
            close={customCloseBtn(() =>
              this.setState({
                openWarningModal: false
              })
            )}
          ></ModalHeader>
          <ModalBody>
            <div className='fw-bold text-center'>
              <p className={styles.warningMessage}>
                Please confirm or discard legal hold before leaving!
              </p>
            </div>
            {this.props.legalHoldStats?.isResultValid &&
              this.props.legalHoldStats?.itemCount !== null && (
                <div className='d-flex justify-content-center mt-4'>
                  <button
                    className='btn danger-btn fw-semi-bold d-flex align-items-center me-3'
                    onClick={() => this.handleDiscardLegalHold()}
                  >
                    Discard
                  </button>
                  <button
                    className='btn fw-semi-bold d-flex align-items-center'
                    onClick={() => this.handleSaveLegalHold()}
                  >
                    Confirm
                  </button>
                </div>
              )}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    rows: store.LegalHold.list.rows,
    permissions: store.auth.permissions,
    count: store.LegalHold.list.count,
    currentUser: store.auth.currentUser,
    openFormModal: store.LegalHold.form.openFormModal,
    refreshInterval: store.LegalHold.list.refreshInterval,
    currentTheme: store.layout.currentTheme,
    legalHoldStats: store.LegalHold.form.legalHoldStats,
    currentStep: store.LegalHold.form.currentStep,
    record: store.LegalHold.form.record,
    filters: store.LegalHold.form.filters,
    legalCase: store.LegalCase.form.record,
    createdLegalHoldId: store.LegalHold.form.legalHoldId
  };
}

export default connect(mapStateToProps)(LegalHoldTilesView);
