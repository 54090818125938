import React, { Component } from 'react';
import LegalCaseViewerForm from './LegalCaseViewerForm';
import actions from 'actions/viewers/LegalCaseViewerFormActions';
import { withRouter } from 'utils/withRouter';

class LegalCaseViewerFormPage extends Component {
  doSubmit = (data) => {
    const { dispatch, navigate } = this.props;
    dispatch(actions.doCloseConfirm());
    dispatch(actions.doAddNewViewer(data?.expireonUsers, this.props.legalCaseId, navigate));
  };

  render() {
    return (
      <>
        <LegalCaseViewerForm onSubmit={this.doSubmit} legalCaseId={this.props.legalCaseId} />
      </>
    );
  }
}

export default withRouter(LegalCaseViewerFormPage);
