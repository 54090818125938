import React, { useEffect } from 'react';
import { signinRedirectCallback } from '../../../services/userService';
import { useNavigate } from 'react-router-dom';
import Loader from 'components/Loader/Loader';
import { getConfigFromApi } from '../../../services/apiService';
import { getCurrentUser } from '../../../actions/auth';
import { store } from 'index';

function SigninOidc() {
  const navigate = useNavigate();

  useEffect(() => {
    async function signinAsync() {
      await signinRedirectCallback();
      let configData = await getConfigFromApi();
      let currentUser = configData.currentUser;
      let permissions = configData.auth.grantedPolicies;
      store.dispatch(getCurrentUser(currentUser, permissions));
      let hasPermission =
        Object.keys(permissions).length === 2 &&
        permissions['LegalUI.Credential'] &&
        permissions['LegalUI.Credential.KeyManagement'];

      if (hasPermission) {
        navigate('/admin/credentials');
      } else if (Object.keys(permissions).length > 0) {
        navigate('/');
      } else {
        navigate('/denied');
      }
    }
    signinAsync();
  }, [navigate]);

  return <Loader />;
}

export default SigninOidc;
