import React, { Component } from 'react';
import LegalCaseForm from 'components/CRUD/LegalCase/form/LegalCaseForm';
import actions from 'actions/LegalCase/LegalCaseFormActions';
import { connect } from 'react-redux';
import { withRouter } from 'utils/withRouter';

class LegalCaseFormPage extends Component {
  state = {
    dispatched: false
  };

  componentDidMount() {
    const { dispatch } = this.props;
    if (!this.isEditing()) {
      dispatch(actions.doNew());
    }
    this.setState({ dispatched: true });
  }

  componentDidUpdate(prevProps) {
    const { dispatch, navigate } = this.props;
    if (prevProps.legalCaseId !== this.props.legalCaseId) {
      dispatch(actions.doFind(this.props.legalCaseId, navigate));
    }
  }

  doSubmit = (data) => {
    const { dispatch, navigate } = this.props;
    if (this.isEditing()) {
      const expireonUser = this.props?.record?.expireonUser;
      dispatch(
        actions.doUpdate(
          this.props.record.legalCaseId,
          {
            ...data,
            expireonUser
          },
          navigate
        )
      ).then(() => {
        dispatch(actions.doFind(this.props.legalCaseId, navigate));
      });
    } else {
      const { name: firstName, surName: lastName, email, userName } = this.props.currentUser || {};
      dispatch(
        actions.doCreate(
          {
            ...data,
            expireonUser: {
              firstName,
              lastName,
              email,
              userName
            }
          },
          this.props.pageNumber,
          this.props.pageSize
        )
      );
    }
  };

  isEditing = () => {
    return this.props.isEditing;
  };

  render() {
    return (
      <React.Fragment>
        {this.state.dispatched && (
          <LegalCaseForm
            saveLoading={this.props.saveLoading}
            findLoading={this.props.findLoading}
            currentUser={this.props.currentUser}
            record={this.isEditing() ? this.props.record : {}}
            isEditing={this.isEditing()}
            onSubmit={this.doSubmit}
            modalOpen={this.props.modalOpen}
          />
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(store) {
  return {
    findLoading: store.LegalCase.form.findLoading,
    saveLoading: store.LegalCase.form.saveLoading,
    record: store.LegalCase.form.record,
    modalOpen: store.LegalCase.form.modalOpen,
    currentUser: store.auth.currentUser
  };
}

export default withRouter(connect(mapStateToProps)(LegalCaseFormPage));
