import React from 'react';
import { withRouter } from 'utils/withRouter';
import { connect } from 'react-redux';
import s from './ErrorPage.module.scss';

class ErrorPage extends React.Component {
  handleBackButton() {
    const { navigate } = this.props;
    navigate(-1);
  }

  render() {
    return (
      <div className={s.errorPage}>
        <div>
          <p className={s.errorCode}>404</p>
          <h1 className={s.errorHeadline}>Page Not Found!</h1>
          <p className={s.errorInfo}>
            Opps! The page you are looking for does not exist. It might have been removed, had its
            name changed or is temporarily unavailable.
          </p>

          <div className='d-flex justify-content-center'>
            <button
              className={`${s.errorBtn} btn text-white first-body-text d-flex align-items-center justify-content-center`}
              type='submit'
              color='success'
              onClick={() => this.handleBackButton()}
            >
              Go Back
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect()(ErrorPage));
