import React, { Component } from 'react';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Widget from 'components/Widget/Widget';
import { Dropdown, DropdownMenu, DropdownToggle, DropdownItem, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import Pagination from 'react-js-pagination';
import styles from 'components/CRUD/LegalHold/list/LegalHoldList.module.scss';
import reportStyles from 'components/CRUD/Reports/Reports.module.scss';
import actions from 'actions/Report/ReportActions';
import auditStyles from 'components/CRUD/AuditLogs/Audit.module.scss';
import searchIcon from 'images/icons/search.svg';
import { emptyDataMessage } from 'actions/common';
import caretDown from 'images/icons/caret-down.svg';

class LocationPoliciesTable extends Component {
  intervalID = 0;
  state = {
    totalCount: 0,
    pageNumber: 1,
    pageSize: 5,
    paginationModalOpen: false,
    newRows: [],
    sortOrder: '',
    searchString: ''
  };

  refreshTable = () => {
    const { dispatch } = this.props;
    const interval = this.props?.refreshInterval.concat('000');
    const refreshInterval = JSON.parse(interval);
    this.intervalID = setInterval(() => {
      dispatch(
        actions.doFetchLocationPoliciesList({
          pageNumber: this.state.pageNumber,
          pageSize: this.state.pageSize,
          sortOrder: this.state.sortOrder,
          searchString: this.state.searchString
        })
      ).then(() => {
        this.setState({
          totalCount: this.props.locationPoliciesCount,
          newRows: this.props.locationPoliciesList
        });
      });
    }, refreshInterval);
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(
      actions.doFetchLocationPoliciesList({
        sortOrder: this.state.sortOrder,
        pageNumber: this.state.pageNumber,
        pageSize: this.state.pageSize,
        searchString: this.state.searchString
      })
    ).then(() => {
      this.setState({
        totalCount: this.props.locationPoliciesCount,
        newRows: this.props.locationPoliciesList
      });
    });
    if (this.props?.refreshInterval) {
      this.refreshTable();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { dispatch } = this.props;
    if (
      prevState.pageSize !== this.state.pageSize ||
      prevState.pageNumber !== this.state.pageNumber ||
      prevState.sortOrder !== this.state.sortOrder
    ) {
      dispatch(
        actions.doFetchLocationPoliciesList({
          sortOrder: this.state.sortOrder,
          pageNumber: this.state.pageNumber,
          pageSize: this.state.pageSize,
          searchString: this.state.searchString
        })
      ).then(() => {
        this.setState({
          totalCount: this.props.locationPoliciesCount,
          newRows: this.props.locationPoliciesList
        });
      });
    }
    if (prevState.searchString !== this.state.searchString && this.state.searchString === '') {
      dispatch(
        actions.doFetchLocationPoliciesList({
          sortOrder: this.state.sortOrder,
          pageNumber: this.state.pageNumber,
          pageSize: this.state.pageSize,
          searchString: this.state.searchString
        })
      ).then(() => {
        this.setState({
          totalCount: this.props.locationPoliciesCount,
          newRows: this.props.locationPoliciesList
        });
      });
    }
    if (prevProps.locationPoliciesList !== this.props.locationPoliciesList) {
      this.setState({
        newRows: this.props.locationPoliciesList,
        totalCount: this.props.locationPoliciesCount
      });
    }
    if (prevProps.refreshInterval !== this.props.refreshInterval) {
      this.refreshTable();
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  handlePageChange = (pageNumber) => {
    this.setState({ pageNumber: pageNumber });
  };

  sortFormatter(sortField) {
    const SortEnum = {
      name: 'name',
      description: 'description',
      location: 'location'
    };
    return SortEnum[sortField];
  }

  handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === 'sort') {
      this.setState({
        pageNumber: 1,
        sortOrder: this.sortFormatter(sortField).concat('_').concat(sortOrder)
      });
    }
  };

  handleSearch = () => {
    const { dispatch } = this.props;
    this.setState({ pageNumber: 1 });
    dispatch(
      actions.doFetchLocationPoliciesList({
        sortOrder: this.state.sortOrder,
        pageNumber: this.state.pageNumber,
        pageSize: this.state.pageSize,
        searchString: this.state.searchString
      })
    ).then(() => {
      this.setState({
        totalCount: this.props.locationPoliciesCount,
        newRows: this.props.locationPoliciesList
      });
    });
  };

  render() {
    const columns = [
      {
        dataField: 'name',
        text: 'Name',
        sort: true,
        formatter: (cell, row) => <div className={reportStyles.reportsListName}>{cell}</div>
      },
      {
        dataField: 'description',
        text: 'Description',
        sort: true,
        formatter: (cell, row) => <div className={reportStyles.reportsListDescription}>{cell}</div>
      },
      {
        dataField: 'location',
        text: 'Location',
        sort: true,
        formatter: (cell, row) => <div className={reportStyles.reportsListName}>{cell}</div>
      }
    ];
    return (
      <>
        <Widget style={{ marginTop: '20px', width: '100%' }}>
          <ToolkitProvider bootstrap4 columns={columns} data={this.state.newRows} keyField='id'>
            {(props) => (
              <>
                <Row sm={12}>
                  <Col sm={12} className={auditStyles.auditTableHeader}>
                    <h3 className={'first-headline-text me-4 mb-0'}>Report Location Policies</h3>
                    <div style={{ display: 'flex' }}>
                      <span>
                        <input
                          type='search'
                          placeholder={'Search'}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              this.handleSearch();
                            }
                          }}
                          style={{
                            border: '0.5px solid #0046b1',
                            borderRadius: '3px'
                          }}
                          className={'form-control search-input me-5 w-200'}
                          value={this.state.searchString}
                          onChange={(e) => {
                            this.setState({
                              searchString: e.target.value
                            });
                          }}
                        />
                      </span>
                      <span>
                        <button
                          className='btn search-button-with-icon me-2 ms-10'
                          type='button'
                          onClick={() => this.handleSearch()}
                          style={{ height: '37px', width: '37px' }}
                        >
                          <span>
                            <img
                              title={'search'}
                              alt={'search'}
                              width={14}
                              height={14}
                              src={searchIcon}
                            />
                          </span>
                        </button>
                      </span>
                    </div>
                  </Col>
                </Row>
                <div className='table-container'>
                  <BootstrapTable
                    bordered={false}
                    classes={`table-striped table-hover fs-sm custom-table`}
                    remote={{
                      filter: false,
                      pagination: false,
                      sort: true,
                      cellEdit: false
                    }}
                    onTableChange={this.handleTableChange}
                    noDataIndication={emptyDataMessage}
                    {...props.baseProps}
                  />
                </div>
                <Row key={'pagination'} className='mt-3'>
                  <Col>
                    <p className={styles.totalCount}>Total: {this.state.totalCount}</p>
                  </Col>
                  <Col className='d-flex justify-content-end'>
                    {this.state.totalCount ? (
                      <Pagination
                        totalItemsCount={this.state.totalCount}
                        onChange={this.handlePageChange}
                        activePage={this.state.pageNumber}
                        itemsCountPerPage={this.state.pageSize}
                        pageRangeDisplayed={5}
                        prevPageText={'<'}
                        nextPageText={'>'}
                        firstPageText={'<<'}
                        lastPageText={'>>'}
                        linkClassFirst={styles.paginationNext}
                        linkClassPrev={styles.paginationNext}
                        linkClassNext={styles.paginationNext}
                        linkClassLast={styles.paginationNext}
                        linkClass={styles.pageLink}
                        activeLinkClass={styles.activeLinkClass}
                      />
                    ) : null}
                    <Dropdown
                      isOpen={this.state.paginationModalOpen}
                      toggle={() =>
                        this.setState({
                          paginationModalOpen: !this.state.paginationModalOpen
                        })
                      }
                      className={styles.pageSizeDropdown}
                    >
                      <DropdownToggle className='bg-transparent text-white d-flex'>
                        <p
                          style={{ marginBottom: '3px', marginRight: '2px' }}
                          className='first-body-text'
                        >
                          {this.state.pageSize}
                        </p>
                        <img
                          src={caretDown}
                          alt={'uncollapsed'}
                          width='20px'
                          height='20px'
                          className='ms-2'
                        />
                      </DropdownToggle>
                      <DropdownMenu className='dropdown-position-fixed'>
                        {[5, 10, 25].map((item) => (
                          <DropdownItem
                            className={styles.dropdownItem}
                            key={item}
                            onClick={() => {
                              this.setState({
                                pageSize: item,
                                paginationModalOpen: false,
                                pageNumber: 1
                              });
                            }}
                          >
                            {item}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                </Row>
              </>
            )}
          </ToolkitProvider>
        </Widget>
      </>
    );
  }
}
function mapStateToProps(store) {
  return {
    refreshInterval: store.LegalHold.list.refreshInterval,
    locationPoliciesList: store.Reports.list.locationPoliciesList,
    locationPoliciesCount: store.Reports.list.locationPoliciesCount
  };
}
export default connect(mapStateToProps)(LocationPoliciesTable);
