const initialData = {
  legalCasesCount: 0,
  legalHoldsCount: 0,
  contentSearchesCount: 0,
  exportJobsCount: 0,
  legalCasesPerStatus: {},
  exportJobsByIsExported: {},
};

export default (state = initialData, { type, payload }) => {
  if (type === "LEGAL_CASES_COUNT_FETCH_STARTED") {
    return {
      ...state,
    };
  }

  if (type === "LEGAL_CASES_COUNT_FETCH_SUCCESS") {
    return {
      ...state,
      legalCasesCount: payload,
    };
  }

  if (type === "LEGAL_CASES_COUNT_FETCH_ERROR") {
    return {
      ...state,
      legalCasesCount: 0,
    };
  }
  if (type === "LEGAL_HOLDS_COUNT_FETCH_STARTED") {
    return {
      ...state,
    };
  }

  if (type === "LEGAL_HOLDS_COUNT_FETCH_SUCCESS") {
    return {
      ...state,
      legalHoldsCount: payload,
    };
  }

  if (type === "LEGAL_HOLDS_COUNT_FETCH_ERROR") {
    return {
      ...state,
      legalHoldsCount: 0,
    };
  }

  if (type === "CONTENT_SEARCHES_COUNT_FETCH_STARTED") {
    return {
      ...state,
    };
  }

  if (type === "CONTENT_SEARCHES_COUNT_FETCH_SUCCESS") {
    return {
      ...state,
      contentSearchesCount: payload,
    };
  }

  if (type === "CONTENT_SEARCHES_COUNT_FETCH_ERROR") {
    return {
      ...state,
      contentSearchesCount: 0,
    };
  }

  if (type === "EXPORT_JOBS_COUNT_FETCH_STARTED") {
    return {
      ...state,
    };
  }

  if (type === "EXPORT_JOBS_COUNT_FETCH_SUCCESS") {
    return {
      ...state,
      exportJobsCount: payload,
    };
  }

  if (type === "EXPORT_JOBS_COUNT_FETCH_ERROR") {
    return {
      ...state,
      exportJobsCount: 0,
    };
  }

  if (type === "LEGAL_CASES_STATUS_FETCH_STARTED") {
    return {
      ...state,
    };
  }

  if (type === "LEGAL_CASES_STATUS_FETCH_SUCCESS") {
    return {
      ...state,
      legalCasesPerStatus: payload,
    };
  }

  if (type === "LEGAL_CASES_STATUS_FETCH_ERROR") {
    return {
      ...state,
      legalCasesPerStatus: null,
    };
  }

  if (type === "EXPORT_JOBS_STATUS_FETCH_STARTED") {
    return {
      ...state,
    };
  }

  if (type === "EXPORT_JOBS_STATUS_FETCH_SUCCESS") {
    return {
      ...state,
      exportJobsByIsExported: payload,
    };
  }

  if (type === "EXPORT_JOBS_STATUS_FETCH_ERROR") {
    return {
      ...state,
      exportJobsByIsExported: null,
    };
  }
  return state;
};
