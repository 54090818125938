import React, { Component } from 'react';
import { connect } from 'react-redux';
import Widget from 'components/Widget';
import Loader from '../../../Loader';
import { FormattingService } from '../../../../utils/sizeFormatting';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { Col, Row } from 'reactstrap';
import { emptyDataMessage } from 'actions/common';
import exportStyles from 'components/CRUD/Export/Export.module.scss';

class LegalCaseStatsListTable extends Component {
  state = {
    showName: false,
    position: {
      top: 0,
      left: 0
    },
    content: '',
    topSendersArray: [],
    externalEmailsArray: [],
    internalEmailsArray: []
  };

  componentDidUpdate(prevProps) {
    if (prevProps.rows?.summaryCaseStatistics !== this.props.rows?.summaryCaseStatistics) {
      this.setState({
        topSendersArray: this.toData(this.props?.rows?.summaryCaseStatistics?.topSenders),
        externalEmailsArray: this.toData(
          this.props?.rows?.summaryCaseStatistics?.topExternalEmailAddresses
        ),
        internalEmailsArray: this.toData(
          this.props?.rows?.summaryCaseStatistics?.topInternalEmailAddresses
        )
      });
    }
  }
  toData = (values) => {
    let modifiedArray = [];
    if (JSON.stringify(values) !== '{}') {
      Object.entries(values)?.map((item, index) => {
        modifiedArray.push({ id: index + 1, email: item[0], count: item[1] });
      });
    }
    return modifiedArray;
  };
  renderForm() {
    const { rows } = this.props;
    const toReadableSizeFormat = new FormattingService();

    const columns = [
      {
        dataField: 'id',
        text: '#',
        sort: false,
        headerStyle: {
          width: '15%'
        }
      },
      {
        dataField: 'email',
        text: 'Email',
        sort: false,
        headerStyle: {
          width: '65%'
        },
        formatter: (cell, row) => <div className={exportStyles.exportName}>{cell}</div>
      },
      {
        dataField: 'count',
        text: 'Count',
        sort: false,
        headerStyle: {
          width: '20%'
        },
        formatter: (item) => toReadableSizeFormat.numberWithCommas(item)
      }
    ];
    const attachmentsCount =
      !Array.isArray(this.props.rows) &&
      toReadableSizeFormat.getBytesReadable(rows.summaryCaseStatistics.itemSizeInBytes);

    return (
      <div>
        <Row>
          <Col sm={12}>
            <div className={'d-flex mb-4 align-items-center'}>
              <h3 className={'second-headline-text me-4 mb-0'}>{this.props.rows.caseName}</h3>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Widget>
              <h4 className={'text-center mb-2 second-headline-text'}>Total Items Size</h4>
              <div className={'text-center'}>
                <h3 className={'first-subtitle-text d-inline-block'}>{attachmentsCount}</h3>
              </div>
            </Widget>
            <Widget>
              <h4 className={'text-center mb-2 second-headline-text'}>Total Items Count</h4>
              <div className={'text-center'}>
                <h3 className={'first-subtitle-text d-inline-block mb-0'}>
                  {toReadableSizeFormat.numberWithCommas(this.props.rows.totalItemCount || 0)}
                </h3>
              </div>
            </Widget>
            <Widget>
              <h4 className={'text-center mb-2 second-headline-text'}>Items With Attachments</h4>
              <div className={'text-center'}>
                <h3 className={'first-subtitle-text d-inline-block mb-0'}>
                  {toReadableSizeFormat.numberWithCommas(
                    this.props.rows.summaryCaseStatistics?.hasAttachmentsCount || 0
                  )}
                </h3>
              </div>
            </Widget>
            <Widget title={<h4 className='first-headline-text'>Top Senders</h4>}>
              <div>
                <ToolkitProvider
                  bootstrap4
                  columns={columns}
                  data={this.state.topSendersArray}
                  keyField='id'
                >
                  {(props) => (
                    <>
                      <BootstrapTable
                        bordered={false}
                        classes={`table-striped table-hover mt-4 fs-sm`}
                        remote={{
                          filter: false,
                          pagination: false,
                          sort: false,
                          cellEdit: false
                        }}
                        noDataIndication={emptyDataMessage}
                        {...props.baseProps}
                      />
                    </>
                  )}
                </ToolkitProvider>
              </div>
            </Widget>

            {/* <Widget title={<h4 className='first-headline-text'>Top External Emails</h4>}>
              <div>
                <ToolkitProvider
                  bootstrap4
                  columns={columns}
                  data={this.state.externalEmailsArray}
                  keyField="id"
                >
                  {(props) => (
                    <>
                      <BootstrapTable
                        bordered={false}
                        classes={`table-striped table-hover mt-4 fs-sm`}
                        remote={{
                          filter: false,
                          pagination: false,
                          sort: false,
                          cellEdit: false,
                        }}
                        noDataIndication={emptyDataMessage}
                        {...props.baseProps}
                      />
                    </>
                  )}
                </ToolkitProvider>
              </div>
            </Widget> */}
          </Col>
          <Col sm={6}>
            <Widget
              title={<h4 className='second-headline-text'>Case Description</h4>}
              style={{ height: 332 }}
            >
              <div
                style={{
                  maxHeight: '250px',
                  overflowY: 'scroll',
                  display: 'flex',
                  flexWrap: 'wrap'
                }}
                className='first-subtitle-text'
              >
                {rows?.caseDescription}
              </div>
            </Widget>

            {/* <Widget title={<h4 className='first-headline-text'>Top Internal Emails</h4>}>
              <div>
                <ToolkitProvider
                  bootstrap4
                  columns={columns}
                  data={this.state.internalEmailsArray}
                  keyField="id"
                >
                  {(props) => (
                    <>
                      <BootstrapTable
                        bordered={false}
                        classes={`table-striped table-hover mt-4 fs-sm`}
                        remote={{
                          filter: false,
                          pagination: false,
                          sort: false,
                          cellEdit: false,
                        }}
                        noDataIndication={emptyDataMessage}
                        {...props.baseProps}
                      />
                    </>
                  )}
                </ToolkitProvider>
              </div>
            </Widget> */}
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    const { loading, record } = this.props;
    if (loading) {
      return (
        <div className={'hw-100'}>
          <Loader />
        </div>
      );
    }

    if (loading && !record) {
      return (
        <div className={'hw-100'}>
          <Loader />
        </div>
      );
    }

    return this.renderForm();
  }
}

function mapStateToProps(store) {
  return {
    loading: store.legalCaseStats.list.loading,
    rows: store.legalCaseStats.list.rows,
    permissions: store.auth.permissions,
    currentUser: store.auth.currentUser,
    record: store.LegalCase.form.record
  };
}

export default connect(mapStateToProps)(LegalCaseStatsListTable);
