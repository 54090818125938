import { defaultOptions } from "./userService";

async function downloadService(downloadUrl, method, data) {
    try {
        const requestOptions = {
            method,
            headers: defaultOptions.headers,
        };

        if (method !== "GET" && method !== "HEAD") {
            requestOptions.body = JSON.stringify(data);
        }
        const response = await fetch(downloadUrl, requestOptions);

        if (!response.ok) {
            throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;

        const contentDisposition = response.headers.get("content-disposition");

        let filename = "";
        if (contentDisposition) {
            filename = contentDisposition
                .split(";")
                .find((n) => n.includes("filename="))
                .replace("filename=", "")
                .trim();

            if (filename.length >= 2 && filename.charAt(0) === '"' && filename.charAt(filename.length - 1) === '"') {
                filename = filename.substring(1, filename.length - 1);
            }
        }

        link.setAttribute("download", filename || "download");
        document.body.appendChild(link);
        link.click();
    } catch (error) {
        console.error("Error downloading file:", error.message);
    }
}

export {downloadService};
