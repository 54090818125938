import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Row
} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import plusIcon from '../../../../images/icons/plus.svg';
import barsIcon from '../../../../images/bars.svg';
import editIcon from '../../../../images/icons/edit.svg';
import CredentialFormPage from '../form/CredentialFormPage';
import actions from 'actions/credentials/CredentialListActions';
import styles from 'components/CRUD/Credentials/list/CredentialList.module.scss';
import { emptyDataMessage } from 'actions/common';
import Pagination from 'react-js-pagination';
import holdListStyles from 'components/CRUD/LegalHold/list/LegalHoldList.module.scss';
import { ImInfo } from 'react-icons/im';
import CredentialListTooltip from './CredentialListTooltip';
import config from 'config';
import Widget from 'components/Widget/Widget';
import searchIcon from 'images/icons/search.svg';
import { defaultOptions } from '../../../../services/userService';
import caretDown from 'images/icons/caret-down.svg';
import caretUp from 'images/icons/caret-up.svg';
import { customCloseBtn } from 'actions/common';

class CredentialListTable extends Component {
  constructor(props) {
    super(props);
    this.openModal = this.openModal.bind(this);
    this.openEditModal = this.openEditModal.bind(this);
  }

  state = {
    dropdownItem: [],
    openFilter: false,
    credentialId: null,
    openDeleteModal: false,
    pageNumber: 1,
    pageSize: 5,
    totalCount: 0,
    newRows: [],
    paginationModalOpen: false,
    sortOrder: '',
    position: {
      top: 0,
      left: 0
    },
    credentialContent: [],
    searchString: '',
    expandExport: false
  };

  openModal() {
    this.props.dispatch(actions.doToggleTooltip(false));
    this.setState({
      isEditing: false
    });
    this.props.dispatch(actions.doOpenConfirm());
  }

  openEditModal() {
    this.props.dispatch(actions.doToggleTooltip(false));
    this.setState(
      {
        isEditing: true
      },
      () => {
        this.props.dispatch(actions.doOpenConfirm());
      }
    );
  }

  closeModal() {
    this.setState({
      isEditing: false
    });
    this.props.dispatch(actions.doCloseConfirm());
  }

  resetDropdowns(e) {
    if (e.target?.getAttribute('alt') != 'bar') {
      this.setState({
        dropdownItem: []
      });
    }
  }

  actionFormatter(cell, row) {
    return (
      <Dropdown group isOpen={this.state.dropdownItem.includes(cell)} size='lg' toggle={() => {}}>
        <DropdownToggle className={'bg-transparent border-0'}>
          <img src={barsIcon} alt='bar' width={'24px'} height={'24px'} />
        </DropdownToggle>
        <DropdownMenu className='dropdown-position-fixed'>
          <button
            className={`btn text-white fw-semi-bold d-flex align-items-center w-100 bg-transparent`}
            type='button'
            onClick={() => {
              this.openEditModal(row?.credentialId);
            }}
          >
            <img src={editIcon} alt={'edit'} width={'16px'} height={'16px'} className={'me-2 '} />
            <p className={'mb-0'}>Edit</p>
          </button>
        </DropdownMenu>
      </Dropdown>
    );
  }

  sortFormatter(sortField) {
    const SortEnum = {
      credentialId: 'credentialid',
      credentialName: 'credentialname',
      credentialType: 'credentialtype'
    };
    return SortEnum[sortField];
  }

  handlePageChange = (pageNumber) => {
    this.setState({ pageNumber: pageNumber });
  };

  handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === 'sort') {
      this.setState({
        pageNumber: 1,
        sortOrder: this.sortFormatter(sortField).concat('_').concat(sortOrder)
      });
    }
  };

  toggleExport = () => {
    this.setState({
      expandExport: !this.state.expandExport
    });
  };

  exportMenu = () => {
    return (
      <Dropdown
        isOpen={this.state.expandExport}
        className={`${holdListStyles.exportButton}`}
        toggle={this.toggleExport}
      >
        <DropdownToggle
          className={`${holdListStyles.exportButton} bg-transparent text-white mb-0`}
          disabled={this.state.totalCount === 0}
        >
          Export
          {!this.state.expandExport ? (
            <img src={caretDown} alt={'uncollapsed'} width='20px' height='20px' className='ms-2' />
          ) : (
            <img src={caretUp} alt={'collapsed'} width='20px' height='20px' className='ms-2' />
          )}
        </DropdownToggle>
        <DropdownMenu className='p-0 bg-transparent'>
          <DropdownItem className={`${holdListStyles.exportItem}`} onClick={this.handleExportCSV}>
            CSV
          </DropdownItem>
          <DropdownItem className={`${holdListStyles.exportItem}`} onClick={this.handleExportPDF}>
            PDF
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  };

  columnsToExport = ['credentialId', 'credentialName', 'credentialType'];

  handleExportCSV = () => {
    const { dispatch } = this.props;
    const columnHeaders = this.columnsToExport.join(',');
    const url = `${config.externalApi}/api/Credentials/ExportToCsvCredentials?`;
    const { name: firstName, surName: lastName, email, userName } = this.props?.currentUser || {};
    const expireonUser = {
      firstName,
      lastName,
      email,
      userName
    };
    dispatch(actions.handleExportToCSV(url, columnHeaders, this.state.searchString, expireonUser));
  };

  handleExportPDF = () => {
    const { dispatch } = this.props;
    const columnHeaders = this.columnsToExport.join(',');
    const url = `${config.externalApi}/api/Credentials/ExportToPdf?`;
    const { name: firstName, surName: lastName, email, userName } = this.props?.currentUser || {};
    const expireonUser = {
      firstName,
      lastName,
      email,
      userName
    };
    dispatch(actions.handleExportToPDF(url, columnHeaders, this.state.searchString, expireonUser));
  };

  getCredentialDetails = (id) => {
    fetch(`${config.externalApi}/api/Credentials/${id}/GetCredentialVersionsById`, {
      ...defaultOptions
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ credentialContent: data });
      })
      .catch((error) => {
        console.error('Error fetching credential details:', error);
      });
  };

  handleSearch = () => {
    const { dispatch } = this.props;
    this.setState({
      pageNumber: 1
    });
    dispatch(
      actions.doFetch({
        pageSize: this.state.pageSize,
        pageNumber: this.state.pageNumber,
        sortOrder: this.state.sortOrder,
        searchString: this.state.searchString
      })
    ).then(() => {
      this.setState({
        newRows: this.props.rows,
        totalCount: this.props.totalCount
      });
    });
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(
      actions.doFetch({
        pageSize: this.state.pageSize,
        pageNumber: this.state.pageNumber,
        sortOrder: this.state.sortOrder,
        searchString: this.state.searchString
      })
    ).then(() => {
      this.setState({
        newRows: this.props.rows,
        totalCount: this.props.totalCount
      });
    });
    window.addEventListener('click', (e) => this.resetDropdowns(e));
  }

  componentDidUpdate(prevProps, prevState) {
    const { dispatch } = this.props;
    if (prevProps.rows !== this.props.rows) {
      this.setState({
        newRows: this.props.rows,
        totalCount: this.props.totalCount
      });
    }
    if (
      prevState.pageNumber !== this.state.pageNumber ||
      prevState.pageSize !== this.state.pageSize ||
      prevState.sortOrder !== this.state.sortOrder ||
      (prevState.searchString !== this.state.searchString && this.state.searchString === '')
    ) {
      dispatch(
        actions.doFetch({
          pageSize: this.state.pageSize,
          pageNumber: this.state.pageNumber,
          sortOrder: this.state.sortOrder,
          searchString: this.state.searchString
        })
      ).then(() => {
        this.setState({
          newRows: this.props.rows,
          totalCount: this.props.totalCount
        });
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('click', (e) => this.resetDropdowns(e));
  }

  render() {
    const columns = [
      {
        dataField: 'credentialId',
        text: 'Credential Id',
        sort: true
      },
      {
        dataField: 'credentialName',
        text: 'Credential Name',
        sort: true,
        formatter: (cell, row) => <div className={styles.credentialName}>{cell}</div>
      },
      {
        dataField: 'credentialType',
        text: 'Credential Type',
        sort: true,
        formatter: (cell, row) => (
          <div>
            <span>{cell}</span>
            {row?.type === 2 && (
              <span
                className={styles.infoIcon}
                onClick={(e) => {
                  this.setState({
                    position: {
                      top: e.pageY,
                      left: e.pageX
                    }
                  });
                }}
              >
                <ImInfo
                  className={`${styles.infoIcon} ms-2`}
                  onClick={(e) => {
                    this.setState({ credentialContent: [] });
                    this.getCredentialDetails(row?.credentialId);
                    this.props.dispatch(actions.doToggleTooltip(true));
                  }}
                />
              </span>
            )}
          </div>
        )
      },
      {
        dataField: 'credentialId',
        text: 'Actions',
        formatter: (cell, row) => {
          return this.actionFormatter.bind(this)(cell, row);
        },
        events: {
          onClick: (e, column, columnIndex, row) => {
            this.setState({
              credentialId: row.credentialId
            });
            if (this.state.dropdownItem.includes(row.credentialId)) {
              this.setState({
                dropdownItem: []
              });
            } else {
              this.setState({
                dropdownItem: [row.credentialId]
              });
            }
          }
        }
      }
    ];

    return (
      <Widget>
        <Row>
          <Col sm={12}>
            {this.props.showTooltip && (
              <CredentialListTooltip
                credentialContent={this.state.credentialContent}
                position={this.state.position}
              />
            )}
            <ToolkitProvider
              bootstrap4
              columns={columns}
              data={this.state.newRows}
              keyField='credentialId'
            >
              {(props) => (
                <>
                  <Row className={'w-100 mb-4'}>
                    <Col sm={12}>
                      <h3 className={'fw-semi-bold me-4 mb-4'}>Credentials</h3>
                    </Col>
                    <Col sm={12} className={'d-flex justify-content-between'}>
                      <div className='d-flex justify-content-between align-items-center mb-3'>
                        <div>
                          <button
                            className='btn fw-semi-bold d-flex align-items-center'
                            type='button'
                            onClick={this.openModal}
                          >
                            <img src={plusIcon} alt={'plus'} className={'me-2'} />
                            <p className={'mb-0'}>Add</p>
                          </button>
                        </div>
                      </div>
                      <div className='d-flex'>
                        <span style={{ marginLeft: '20px' }}>{this.exportMenu()}</span>
                        <span>
                          <input
                            type='search'
                            placeholder={'Search'}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                this.handleSearch();
                              }
                            }}
                            style={{
                              border: '0.5px solid #0046b1',
                              borderRadius: '0'
                            }}
                            className={'form-control search-input me-5 ms-2 w-200'}
                            value={this.state.searchString}
                            onChange={(e) => {
                              this.setState({
                                searchString: e.target.value
                              });
                            }}
                          />
                        </span>
                        <span>
                          <button
                            style={{
                              borderRadius: '0',
                              height: '37px',
                              width: '37px'
                            }}
                            className='btn me-2 ms-10'
                            type='button'
                            onClick={this.handleSearch}
                          >
                            <img
                              title={'search'}
                              alt={'search'}
                              width={14}
                              height={14}
                              src={searchIcon}
                            />
                          </button>
                        </span>
                      </div>
                    </Col>
                  </Row>
                  <div
                    onClick={(e) => {
                      if (this.props.showTooltip) {
                        this.props.dispatch(actions.doToggleTooltip(false));
                      }
                    }}
                    className='table-container'
                  >
                    <BootstrapTable
                      bordered={false}
                      classes={`table-striped table-hover fs-sm custom-table`}
                      remote={{
                        filter: false,
                        pagination: false,
                        sort: true,
                        cellEdit: false
                      }}
                      onTableChange={this.handleTableChange}
                      noDataIndication={emptyDataMessage}
                      {...props.baseProps}
                    />
                  </div>
                  <Row key={'pagination'} className='mt-3'>
                    <Col>
                      <p className={holdListStyles.totalCount}>Total: {this.state.totalCount}</p>
                    </Col>
                    <Col className='d-flex justify-content-end'>
                      {this.state.totalCount ? (
                        <Pagination
                          totalItemsCount={this.state.totalCount}
                          onChange={this.handlePageChange}
                          activePage={this.state.pageNumber}
                          itemsCountPerPage={this.state.pageSize}
                          pageRangeDisplayed={5}
                          prevPageText={'<'}
                          nextPageText={'>'}
                          firstPageText={'<<'}
                          lastPageText={'>>'}
                          linkClassFirst={holdListStyles.paginationNext}
                          linkClassPrev={holdListStyles.paginationNext}
                          linkClassNext={holdListStyles.paginationNext}
                          linkClassLast={holdListStyles.paginationNext}
                          linkClass={holdListStyles.pageLink}
                          activeLinkClass={holdListStyles.activeLinkClass}
                        />
                      ) : null}
                      <Dropdown
                        isOpen={this.state.paginationModalOpen}
                        toggle={() =>
                          this.setState({
                            paginationModalOpen: !this.state.paginationModalOpen
                          })
                        }
                        className={holdListStyles.pageSizeDropdown}
                      >
                        <DropdownToggle className='bg-transparent text-white d-flex'>
                          <p style={{ marginBottom: '3px', marginRight: '2px' }}>
                            {this.state.pageSize}
                          </p>
                          <img
                            src={caretDown}
                            alt={'uncollapsed'}
                            width='20px'
                            height='20px'
                            className='ms-2'
                          />
                        </DropdownToggle>
                        <DropdownMenu className='dropdown-position-fixed'>
                          {[5, 10, 25].map((item) => (
                            <DropdownItem
                              className={holdListStyles.dropdownItem}
                              key={item}
                              onClick={() => {
                                this.setState({
                                  pageSize: item,
                                  paginationModalOpen: false,
                                  pageNumber: 1
                                });
                              }}
                            >
                              {item}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                    </Col>
                  </Row>
                </>
              )}
            </ToolkitProvider>
          </Col>
        </Row>
        <Modal
          size='md'
          isOpen={this.props.modalOpen}
          className={`themeStyle${this.props.currentTheme?.replace('#', '')}`}
        >
          <ModalHeader close={customCloseBtn(() => this.closeModal())}>
            {this.state.isEditing ? (
              <p className='second-headline-text'>Credential Information </p>
            ) : (
              <p className='second-headline-text'>Add New Credential</p>
            )}
          </ModalHeader>
          <ModalBody>
            <CredentialFormPage
              isEditing={this.state.isEditing}
              credentialId={this.state.credentialId}
              pageNumber={this.state.pageNumber}
              pageSize={this.state.pageSize}
              searchString={this.state.searchString}
              sortOrder={this.state.sortOrder}
            />
          </ModalBody>
        </Modal>
      </Widget>
    );
  }
}

function mapStateToProps(store) {
  return {
    loading: store.credentials.list.loading,
    rows: store.credentials.list.rows,
    totalCount: store.credentials.list.count,
    modalOpen: store.credentials.list.modalOpen,
    idToDelete: store.credentials.list.idToDelete,
    currentTheme: store.layout.currentTheme,
    showTooltip: store.credentials.list.showTooltip,
    currentUser: store.auth.currentUser
  };
}

export default connect(mapStateToProps)(CredentialListTable);
