import { Formik, ErrorMessage } from 'formik';
import React, { Component } from 'react';
import Loader from 'components/Loader';
import { Row, Col } from 'reactstrap';
import InputFormItem from 'components/FormItems/items/InputFormItem';
import CredentialFields from '../CredentialFields';
import IniValues from 'components/FormItems/iniValues';
import PreparedValues from 'components/FormItems/preparedValues';
import SelectFormItem from 'components/FormItems/items/SelectFormItem';
import * as Yup from 'yup';
import styles from '../list/CredentialList.module.scss';
import { credentialTypeEnum } from 'actions/common';
import fileUpload from '../../../../images/icons/file-upload.svg';

class CredentialForm extends Component {
  state = {
    encryptedTenantFilename: '',
    hashedTenantFilename: ''
  };

  iniValues = () => {
    const record = {
      ...this.props.record,
      login: !this.props.record?.login ? '' : this.props.record?.login,
      password: !this.props.record?.password ? '' : this.props.record?.password,
      tenantCertificateId: !this.props.record?.tenantCertificateId
        ? ''
        : this.props.record?.tenantCertificateId,
      credentialType: this.props.record?.credentialType
        ? this.getSelectedCredential(this.props?.record?.credentialType)
        : '',
      //When AIProvider credential type is selected url and apiKey fields will be attached to login and password fields temporarily
      url:
        this.props.record?.type === credentialTypeEnum.AIProvider ? this.props.record?.login : '',
      apiKey:
        this.props.record?.type === credentialTypeEnum.AIProvider ? this.props.record?.password : ''
    };
    return IniValues(CredentialFields, record || {});
  };

  FormValidationSchema = Yup.object().shape({
    credentialName: Yup.string().trim().required('Name* is a required field'),
    tenantCertificateId: Yup.string().when('credentialType', {
      is: (value) => value === credentialTypeEnum.S3DataEncryption,
      then: () => Yup.string().required('Certificate* is a required field'),
      otherwise: () => Yup.string()
    }),
    login: Yup.string()
      .trim()
      .when('credentialType', {
        is: (value) =>
          value === credentialTypeEnum.CloudficientObjectStorage && !this.props.isEditing,
        then: () => Yup.string().required('Login* is a required field'),
        otherwise: () => Yup.string()
      }),
    password: Yup.string()
      .trim()
      .when('credentialType', {
        is: (value) =>
          value === credentialTypeEnum.CloudficientObjectStorage && !this.props.isEditing,
        then: () => Yup.string().required('Password* is a required field'),
        otherwise: () => Yup.string()
      }),
    url: Yup.string()
      .trim()
      .when('credentialType', {
        is: (value) => value === credentialTypeEnum.AIProvider,
        then: () => Yup.string().required('Url* is a required field'),
        otherwise: () => Yup.string()
      }),
    apiKey: Yup.string()
      .trim()
      .when('credentialType', {
        is: (value) =>
          value === credentialTypeEnum.AIProvider ||
          value === credentialTypeEnum.SlackAttachmentStorage,
        then: () => Yup.string().required('API Key* is a required field'),
        otherwise: () => Yup.string()
      }),
    credentialType: Yup.string().trim().required('Credential Type* is a required field'),
    encryptedTenantSecret: Yup.string().when('credentialType', {
      is: (value) => value === credentialTypeEnum.S3DataEncryption,
      then: () =>
        Yup.string()
          .test('fileExtension', 'Invalid: Please upload a base64 encoded file', (value) => {
            if (!value) return true;
            const fileExtension = this.state.encryptedTenantFilename?.substring(
              this.state.encryptedTenantFilename?.length - 3
            );
            if (fileExtension !== 'b64') return false;
            return true;
          })
          .required('Encrypted Tenant Secret* is a required field'),
      otherwise: () => Yup.string()
    }),
    hashedTenantSecret: Yup.string().when('credentialType', {
      is: (value) => value === credentialTypeEnum.S3DataEncryption,
      then: () =>
        Yup.string()
          .test('fileExtension', 'Invalid: Please upload a base64 encoded file', (value) => {
            if (!value) return true;
            const fileExtension = this.state.hashedTenantFilename?.substring(
              this.state.hashedTenantFilename?.length - 3
            );
            if (fileExtension !== 'b64') return false;
            return true;
          })
          .required('Hashed Tenant Secret* is a required field'),
      otherwise: () => Yup.string()
    })
  });

  contentToSubmit(
    credentialType,
    data,
    S3Data,
    ObjectStorageData,
    AIProviderData,
    SlackAttachmentStorage
  ) {
    switch (credentialType) {
      case credentialTypeEnum.S3DataEncryption:
        return { ...S3Data };
      case credentialTypeEnum.AIProvider:
        return { ...AIProviderData };
      case credentialTypeEnum.SlackAttachmentStorage:
        return { ...SlackAttachmentStorage };
      case credentialTypeEnum.CloudficientObjectStorage:
        return { ...ObjectStorageData };
      default:
        return { ...data };
    }
  }

  handleSubmit = (values) => {
    const { id, ...data } = PreparedValues(CredentialFields, values || {});
    let formData = { ...data };

    const S3Data = {
      credentialName: data.credentialName,
      credentialType: data.credentialType,
      encryptedTenantSecret: data.encryptedTenantSecret,
      hashedTenantSecret: data.hashedTenantSecret,
      tenantCertificateId: data.tenantCertificateId
    };

    const ObjectStorageData = {
      credentialName: data.credentialName,
      credentialType: data.credentialType,
      login: data.login,
      password: data.password
    };

    //When AIProvider credential type is selected url and apiKey fields will be sent to backend under login and password name fields
    const AIProviderData = {
      credentialName: data.credentialName,
      credentialType: data.credentialType,
      login: data.url,
      password: values.apiKey
    };

    const SlackAttachmentStorage = {
      credentialName: data.credentialName,
      credentialType: data.credentialType,
      password: values.apiKey
    };

    formData = this.contentToSubmit(
      values?.credentialType,
      data,
      S3Data,
      ObjectStorageData,
      AIProviderData,
      SlackAttachmentStorage
    );
    this.props.onSubmit(id, formData);
  };

  getSelectedCredential = (name) => {
    let selectedType = '';
    Object.entries(this.props?.credentialTypes).map((item) => {
      if (name === item[1]) {
        selectedType = item[0];
      }
    });
    return selectedType;
  };

  toData = (data) => {
    const arr = [];
    if (typeof data === 'string') {
      return data;
    }
    if (Array.isArray(data)) {
      for (let key in data) {
        const obj = {};
        obj.value = data[key];
        obj.label = data[key];
        arr.push(obj);
      }
    } else {
      for (let key in data) {
        const obj = {};
        obj.value = key;
        obj.label = data[key];
        arr.push(obj);
      }
    }
    return arr;
  };

  getCertificates = (data) => {
    const arr = [];
    data.forEach((item) => {
      const obj = {};
      obj.value = item?.id;
      obj.label = item?.uniqueName;
      arr.push(obj);
    });
    return arr;
  };

  getEncryptedTenantSecret = (event, form) => {
    const file = event.target.files[0];
    this.setState(
      {
        encryptedTenantFilename: file?.name
      },
      () => {
        form.setFieldTouched('encryptedTenantSecret', true);
        form.setFieldValue('encryptedTenantSecret', file);
      }
    );
  };

  getHashedTenantSecret = (event, form) => {
    const file = event.target.files[0];
    this.setState(
      {
        hashedTenantFilename: file?.name
      },
      () => {
        form.setFieldTouched('hashedTenantSecret', true);
        form.setFieldValue('hashedTenantSecret', file);
      }
    );
  };

  renderForm() {
    const { saveLoading } = this.props;
    return (
      <>
        <Formik
          onSubmit={this.handleSubmit}
          initialValues={this.iniValues()}
          validationSchema={this.FormValidationSchema}
          enableReinitialize
        >
          {(form) => {
            return (
              <Row>
                <Col sm={12}>
                  <form onSubmit={form.handleSubmit}>
                    <div className='mb-2'>
                      <InputFormItem name={'credentialName'} schema={CredentialFields} autoFocus />
                    </div>
                    <SelectFormItem
                      name={'credentialType'}
                      schema={CredentialFields}
                      mode={'fetch'}
                      data={this.toData(this.props?.credentialTypes)}
                      placeholder={this.props.isEditing ? null : 'Select...'}
                      isDisabled={this.props.isEditing}
                    />
                    {form.errors?.credentialType && form.touched?.credentialType && (
                      <div style={{ fontSize: '11.2px', color: '#db2a34' }}>
                        {form?.errors?.credentialType}
                      </div>
                    )}
                    {form.values?.credentialType &&
                      form.values?.credentialType ===
                        credentialTypeEnum.CloudficientObjectStorage &&
                      !this.props.isEditing && (
                        <>
                          <InputFormItem name={'login'} schema={CredentialFields} />
                          <InputFormItem
                            name={'password'}
                            password={this.props.isEditing ? 'password' : ''}
                            isDisabled={this.props.isEditing}
                            schema={CredentialFields}
                          />
                        </>
                      )}

                    {form.values?.credentialType &&
                      form?.values?.credentialType === credentialTypeEnum.AIProvider && (
                        <InputFormItem name={'url'} schema={CredentialFields} />
                      )}
                    {form.values?.credentialType &&
                      (form?.values?.credentialType === credentialTypeEnum.AIProvider ||
                        form?.values?.credentialType ===
                          credentialTypeEnum.SlackAttachmentStorage) && (
                        <InputFormItem name={'apiKey'} schema={CredentialFields} />
                      )}
                    {form.values?.credentialType &&
                      form?.values?.credentialType === credentialTypeEnum.S3DataEncryption && (
                        <>
                          <SelectFormItem
                            name={'tenantCertificateId'}
                            schema={CredentialFields}
                            mode={'fetch'}
                            data={this.getCertificates(this.props?.certificatesList)}
                            placeholder={this.props.isEditing ? null : 'Select...'}
                          />
                          <ErrorMessage name='tenantCertificateId'>
                            {(msg) => <div className={styles.errorMessage}>{msg}</div>}
                          </ErrorMessage>
                        </>
                      )}

                    {form?.values?.tenantCertificateId &&
                      form.values?.credentialType &&
                      form?.values?.credentialType === credentialTypeEnum.S3DataEncryption && (
                        <div className='mt-3'>
                          <div className='d-flex  w-100'>
                            <p style={{ width: '42%' }}>Encrypted Tenant Secret*</p>
                            <input
                              name='encryptedTenantSecret'
                              id='encryptedTenantSecret'
                              type='file'
                              className={`w-50 ${styles.hiddenFileInput}`}
                              onChange={(event) => this.getEncryptedTenantSecret(event, form)}
                            />
                            <label htmlFor='encryptedTenantSecret'>
                              <img
                                className={`${styles.uploadFile}`}
                                height={22}
                                src={fileUpload}
                                alt={'fileUpload'}
                              />
                            </label>
                            {form.values?.encryptedTenantSecret ? (
                              <p className='mr-2' style={{ width: '52%' }}>
                                {this.state.encryptedTenantFilename}
                              </p>
                            ) : (
                              <p className='mr-2'>Choose file</p>
                            )}
                          </div>
                          <ErrorMessage name='encryptedTenantSecret'>
                            {(msg) => <div className={styles.errorMessage}>{msg}</div>}
                          </ErrorMessage>
                          <div className='d-flex  w-100'>
                            <p style={{ width: '42%' }}>Hashed Tenant Secret*</p>
                            <input
                              name='hashedTenantSecret'
                              id='hashedTenantSecret'
                              type='file'
                              className={`w-50 ${styles.hiddenFileInput}`}
                              onChange={(event) => this.getHashedTenantSecret(event, form)}
                            />
                            <label htmlFor='hashedTenantSecret'>
                              <img
                                className={`${styles.uploadFile}`}
                                height={22}
                                src={fileUpload}
                                alt={'fileUpload'}
                              />
                            </label>
                            {form.values?.hashedTenantSecret ? (
                              <p className='mr-2' style={{ width: '52%' }}>
                                {this.state.hashedTenantFilename}
                              </p>
                            ) : (
                              <p className='mr-2'>Choose file</p>
                            )}
                          </div>
                          <ErrorMessage name='hashedTenantSecret'>
                            {(msg) => <div className={styles.errorMessage}>{msg}</div>}
                          </ErrorMessage>
                        </div>
                      )}
                    <div className='form-buttons d-flex justify-content-center mt-4'>
                      <span style={{ marginRight: '30px' }}>
                        <button
                          className='btn w-100 fw-bold'
                          disabled={saveLoading || !form.dirty}
                          type='button'
                          onClick={form.handleSubmit}
                        >
                          {this.props.isEditing ? 'Save Credential Information' : 'Add Credential'}
                        </button>
                      </span>
                    </div>
                  </form>
                </Col>
              </Row>
            );
          }}
        </Formik>
      </>
    );
  }

  render() {
    const { isEditing, findLoading, record } = this.props;

    if (findLoading) {
      return <Loader />;
    }

    if (isEditing && !record) {
      return <Loader />;
    }

    return this.renderForm();
  }
}

export default CredentialForm;
