import React, { Component } from 'react';
import SearchListTable from 'components/CRUD/Search/list/SearchListTable';
import SearchTable from './SearchTable';
import Widget from 'components/Widget/Widget';
import { connect } from 'react-redux';
import { withRouter } from 'utils/withRouter';
import actions from 'actions/search/searchListActions';
import config from 'config';

class SearchListPage extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    if (this.props?.caseStatus === config.caseStatus.Closed) {
      dispatch(actions.doShowTable());
    } else {
      dispatch(actions.doHideTable());
    }
  }
  render() {
    return (
      <Widget style={{ marginTop: '20px' }}>
        {this.props.showTableView ? (
          <SearchTable
            legalCaseId={this.props.legalCaseId}
            caseStatus={this.props.caseStatus}
            navigate={this.props.navigate}
          />
        ) : (
          <SearchListTable
            legalCaseId={this.props.legalCaseId}
            caseStatus={this.props.caseStatus}
            navigate={this.props.navigate}
          />
        )}
      </Widget>
    );
  }
}
function mapStateToProps(store) {
  return {
    showTableView: store.search.list.showTableView
  };
}
export default withRouter(connect(mapStateToProps)(SearchListPage));
