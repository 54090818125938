import React, { Component } from 'react';
import ExportListTable from './ExportListTable';
import { connect } from 'react-redux';
import ExportTilesView from './ExportTilesView';
import { withRouter } from 'utils/withRouter';

class ExportListPage extends Component {
  render() {
    return (
      <React.Fragment>
        {this.props.permissions['LegalUI.Export'] && (
          <>
            {this.props.showTableView ? (
              <ExportListTable
                legalCaseId={this.props.legalCaseId}
                caseStatus={this.props.caseStatus}
                navigate={this.props.navigate}
              />
            ) : (
              <ExportTilesView
                legalCaseId={this.props.legalCaseId}
                caseStatus={this.props.caseStatus}
                navigate={this.props.navigate}
              />
            )}
          </>
        )}
      </React.Fragment>
    );
  }
}
function mapStateToProps(store) {
  return {
    permissions: store.auth.permissions,
    showTableView: store.Export.list.showTableView
  };
}
export default withRouter(connect(mapStateToProps)(ExportListPage));
