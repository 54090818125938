import Errors from 'components/FormItems/error/errors';
import config from '../../config';
import { toast } from 'react-toastify';
import { store } from 'index';
import { downloadService } from 'services/downloadService';
import { defaultOptions } from '../../services/userService';
import { isLegalAdmin } from 'utils/isLegalAdmin';

async function saveReport(data) {
  const url = `${config.externalApi}/api/Report`;
  await fetch(url, {
    ...defaultOptions,
    method: 'POST',
    body: JSON.stringify(data)
  });
}

async function getReportsList(pageNumber, pageSize, sortOrder, searchString) {
  const currentUser = store.getState().auth?.currentUser;
  const legalAdmin = isLegalAdmin(currentUser);
  let baseUrl = `${config.externalApi}/api/Report/${currentUser.userName}?`;
  if (pageNumber !== undefined && pageNumber !== null) {
    baseUrl += 'PageNumber=' + encodeURIComponent('' + pageNumber) + '&';
  }
  if (pageSize !== undefined && pageSize !== null) {
    baseUrl += 'PageSize=' + encodeURIComponent('' + pageSize) + '&';
  }
  if (sortOrder !== undefined && sortOrder !== '' && sortOrder !== null) {
    baseUrl += 'SortOrder=' + encodeURIComponent('' + sortOrder) + '&';
  }
  if (searchString !== undefined && searchString !== '' && searchString !== null) {
    baseUrl += 'SearchString=' + encodeURIComponent('' + searchString) + '&';
  }
  if (legalAdmin !== undefined && legalAdmin !== null) {
    baseUrl += `isLegalAdmin=` + encodeURIComponent('' + legalAdmin) + '&';
  }
  baseUrl = baseUrl.replace(/[?&]$/, '');

  const response = await fetch(baseUrl, {
    ...defaultOptions,
    method: 'GET'
  });

  const data = await response.json();
  return data;
}

async function getEmailPolicies() {
  const currentUser = store.getState().auth.currentUser;
  let baseUrl = `${config.externalApi}/api/Report/${currentUser.userName}/GetEmailPolicy`;
  const response = await fetch(baseUrl, {
    ...defaultOptions,
    method: 'GET'
  });

  const data = await response.json();
  return data;
}

async function getFileLocations() {
  const currentUser = store.getState().auth.currentUser;
  let baseUrl = `${config.externalApi}/api/Report/${currentUser.userName}/GetFileLocationPolicy`;
  const response = await fetch(baseUrl, {
    ...defaultOptions,
    method: 'GET'
  });

  const data = await response.json();
  return data;
}

async function deleteReport(reportId, expireonUser) {
  const { name: firstName, surName: lastName, email, userName } = expireonUser || {};
  let loggedUserString = { firstName, lastName, email, userName };
  loggedUserString = JSON.stringify(loggedUserString);
  const legalAdmin = isLegalAdmin(expireonUser);
  await fetch(
    `${config.externalApi}/api/Report/${loggedUserString}/${reportId}?isLegalAdmin=${legalAdmin}`,
    {
      ...defaultOptions,
      method: 'DELETE'
    }
  );
}

async function downloadReportFile(downloadUrl, currentUser) {
  const { name: firstName, surName: lastName, email, userName } = currentUser || {};
  const data = { firstName, lastName, email, userName };
  await downloadService(downloadUrl, 'POST', data);
}

async function getEmailPoliciesList(pageNumber, pageSize, sortOrder, searchString) {
  let baseUrl = `${config.externalApi}/api/Report/GetReportEmailPolicies?`;
  if (pageNumber !== undefined && pageNumber !== null) {
    baseUrl += 'PageNumber=' + encodeURIComponent('' + pageNumber) + '&';
  }
  if (pageSize !== undefined && pageSize !== null) {
    baseUrl += 'PageSize=' + encodeURIComponent('' + pageSize) + '&';
  }
  if (sortOrder !== undefined && sortOrder !== '' && sortOrder !== null) {
    baseUrl += 'SortOrder=' + encodeURIComponent('' + sortOrder) + '&';
  }
  if (searchString !== undefined && searchString !== '' && searchString !== null) {
    baseUrl += 'SearchString=' + encodeURIComponent('' + searchString) + '&';
  }
  baseUrl = baseUrl.replace(/[?&]$/, '');

  const response = await fetch(baseUrl, {
    ...defaultOptions,
    method: 'GET'
  });

  const data = await response.json();
  return data;
}

async function getLocationPoliciesList(pageNumber, pageSize, sortOrder, searchString) {
  let baseUrl = `${config.externalApi}/api/Report/GetReportLocationPolicies?`;
  if (pageNumber !== undefined && pageNumber !== null) {
    baseUrl += 'PageNumber=' + encodeURIComponent('' + pageNumber) + '&';
  }
  if (pageSize !== undefined && pageSize !== null) {
    baseUrl += 'PageSize=' + encodeURIComponent('' + pageSize) + '&';
  }
  if (sortOrder !== undefined && sortOrder !== '' && sortOrder !== null) {
    baseUrl += 'SortOrder=' + encodeURIComponent('' + sortOrder) + '&';
  }
  if (searchString !== undefined && searchString !== '' && searchString !== null) {
    baseUrl += 'SearchString=' + encodeURIComponent('' + searchString) + '&';
  }
  baseUrl = baseUrl.replace(/[?&]$/, '');

  const response = await fetch(baseUrl, {
    ...defaultOptions,
    method: 'GET'
  });

  const data = await response.json();
  return data;
}
async function exportToCSV(downloadUrl, currentUser, searchString, columnHeaders) {
  const { name: firstName, surName: lastName, email, userName } = currentUser || {};
  const data = { firstName, lastName, email, userName };
  const legalAdmin = isLegalAdmin(currentUser);
  let baseUrl = downloadUrl;
  if (searchString !== undefined && searchString !== null && searchString !== '') {
    baseUrl += 'searchString=' + encodeURIComponent('' + searchString) + '&';
  }
  if (columnHeaders !== undefined && columnHeaders !== null) {
    baseUrl += 'columnHeaders=' + encodeURIComponent('' + columnHeaders) + '&';
  }
  if (legalAdmin !== undefined && legalAdmin !== null) {
    baseUrl += 'isLegalAdmin=' + encodeURIComponent('' + legalAdmin) + '&';
  }

  baseUrl = baseUrl.replace(/[?&]$/, '');
  await downloadService(baseUrl, 'POST', data);
}

async function exportToPDF(downloadUrl, currentUser, searchString, columnHeaders) {
  const { name: firstName, surName: lastName, email, userName } = currentUser || {};
  const data = { firstName, lastName, email, userName };
  const legalAdmin = isLegalAdmin(currentUser);
  let baseUrl = downloadUrl;
  if (searchString !== undefined && searchString !== null && searchString !== '') {
    baseUrl += 'searchString=' + encodeURIComponent('' + searchString) + '&';
  }
  if (columnHeaders !== undefined && columnHeaders !== null) {
    baseUrl += 'columnHeaders=' + encodeURIComponent('' + columnHeaders) + '&';
  }
  if (legalAdmin !== undefined && legalAdmin !== null) {
    baseUrl += 'isLegalAdmin=' + encodeURIComponent('' + legalAdmin) + '&';
  }
  baseUrl = baseUrl.replace(/[?&]$/, '');
  await downloadService(baseUrl, 'POST', data);
}

const actions = {
  doSaveReport: (data) => async (dispatch) => {
    try {
      dispatch({
        type: 'SAVE_REPORT_STARTED'
      });
      await saveReport(data);
      dispatch({
        type: 'SAVE_REPORT_SUCCESS'
      });
      toast.success('Report created successfully', { icon: false });
    } catch (error) {
      Errors.handle(error);
      toast.error('Something went wrong, please try again!', { icon: false });
      dispatch({
        type: 'SAVE_REPORT_ERROR'
      });
    }
  },
  doFetchReportsList: (filter) => async (dispatch) => {
    try {
      dispatch({
        type: 'REPORTS_LIST_FETCH_STARTED'
      });

      const response = await getReportsList(
        filter.pageNumber,
        filter.pageSize,
        filter.sortOrder,
        filter.searchString
      );

      dispatch({
        type: 'REPORTS_LIST_FETCH_SUCCESS',
        payload: {
          reportsList: response.reports,
          reportsCount: response.totalCount
        }
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'REPORTS_LIST_FETCH_ERROR'
      });
    }
  },
  doFetchReportFileLocations: () => async (dispatch) => {
    try {
      dispatch({
        type: 'FILE_LOCATIONS_FETCH_STARTED'
      });

      const response = await getFileLocations();

      dispatch({
        type: 'FILE_LOCATIONS_FETCH_SUCCESS',
        payload: {
          fileLocations: response
        }
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'FILE_LOCATIONS_FETCH_ERROR'
      });
    }
  },
  doFetchReportEmailPolicies: () => async (dispatch) => {
    try {
      dispatch({
        type: 'EMAIL_POLICIES_FETCH_STARTED'
      });

      const response = await getEmailPolicies();

      dispatch({
        type: 'EMAIL_POLICIES_FETCH_SUCCESS',
        payload: {
          emailPolicies: response
        }
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'EMAIL_POLICIES_FETCH_ERROR'
      });
    }
  },
  doDeleteReport: (id, expireonUser, filter) => async (dispatch) => {
    try {
      dispatch({
        type: 'REPORT_LIST_DELETE_STARTED'
      });

      await deleteReport(id, expireonUser);
      dispatch({
        type: 'REPORT_LIST_DELETE_SUCCESS'
      });
      toast.success('Report deleted successfully', { icon: false });
      const response = await getReportsList(filter.pageNumber, filter.pageSize, filter.sortOrder);
      dispatch({
        type: 'REPORTS_LIST_FETCH_SUCCESS',
        payload: {
          reportsList: response.reports,
          reportsCount: response.totalCount
        }
      });
    } catch (error) {
      toast.error('Something went wrong, please try again!', { icon: false });
      dispatch({
        type: 'REPORT_LIST_DELETE_ERROR'
      });
    }
  },
  handleDownloadReportFile: (downloadUrl, currentUser) => async (dispatch) => {
    try {
      dispatch({
        type: 'REPORT_FILE_DOWNLOAD_STARTED'
      });

      downloadReportFile(downloadUrl, currentUser).then((response) => {
        dispatch({
          type: 'REPORT_FILE_DOWNLOAD_SUCCESS'
        });
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'REPORT_FILE_DOWNLOAD_ERROR'
      });
    }
  },
  doFetchEmailPoliciesList: (filter) => async (dispatch) => {
    try {
      dispatch({
        type: 'EMAIL_POLICIES_LIST_FETCH_STARTED'
      });

      const response = await getEmailPoliciesList(
        filter.pageNumber,
        filter.pageSize,
        filter.sortOrder,
        filter.searchString
      );

      dispatch({
        type: 'EMAIL_POLICIES_LIST_FETCH_SUCCESS',
        payload: {
          rows: response?.reportEmailPolicies,
          count: response?.totalCount
        }
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'EMAIL_POLICIES_LIST_FETCH_ERROR'
      });
    }
  },
  doFetchLocationPoliciesList: (filter) => async (dispatch) => {
    try {
      dispatch({
        type: 'LOCATION_POLICIES_LIST_FETCH_STARTED'
      });

      const response = await getLocationPoliciesList(
        filter.pageNumber,
        filter.pageSize,
        filter.sortOrder,
        filter.searchString
      );

      dispatch({
        type: 'LOCATION_POLICIES_LIST_FETCH_SUCCESS',
        payload: {
          rows: response?.reportLocationPolicies,
          count: response?.totalCount
        }
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'LOCATION_POLICIES_LIST_FETCH_ERROR'
      });
    }
  },
  handleExportToCSV:
    (downloadUrl, currentUser, searchString, columnHeaders) => async (dispatch) => {
      try {
        dispatch({
          type: 'EXPORT_TO_CSV_STARTED'
        });
        exportToCSV(downloadUrl, currentUser, searchString, columnHeaders).then(() => {
          dispatch({
            type: 'EXPORT_TO_CSV_SUCCESS'
          });
        });
      } catch (error) {
        Errors.handle(error);
        dispatch({
          type: 'EXPORT_TO_CSV_ERROR'
        });
      }
    },
  handleExportToPDF:
    (downloadUrl, currentUser, searchString, columnHeaders) => async (dispatch) => {
      try {
        dispatch({
          type: 'EXPORT_TO_PDF_STARTED'
        });
        exportToPDF(downloadUrl, currentUser, searchString, columnHeaders).then(() => {
          dispatch({
            type: 'EXPORT_TO_PDF_SUCCESS'
          });
        });
      } catch (error) {
        Errors.handle(error);
        dispatch({
          type: 'EXPORT_TO_PDF_ERROR'
        });
      }
    }
};

export default { ...actions };
