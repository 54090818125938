import React from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { getHistory } from 'index';

export function withRouter(Component) {
  return function WithRouterComponent(props) {
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const history = getHistory();
    return (
      <Component
        {...props}
        navigate={navigate}
        params={params}
        location={location}
        history={history}
      />
    );
  };
}
