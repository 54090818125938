import React, { Component } from 'react';
import actions from 'actions/credentials/CredentialFormActions';
import { connect } from 'react-redux';
import CredentialForm from '../../Credentials/form/CredentialForm';
import listActions from 'actions/credentials/CredentialListActions';
import { withRouter } from 'utils/withRouter';

class CredentialFormPage extends Component {
  state = {
    dispatched: false
  };

  componentDidMount() {
    const { dispatch, navigate } = this.props;
    if (this.isEditing()) {
      dispatch(actions.doFind(this.props.credentialId, navigate));
      dispatch(listActions.doGetCredentialTypes());
      dispatch(actions.doGetCertificatesList());
    } else {
      dispatch(actions.doNew());
      dispatch(listActions.doGetCredentialTypes());
      dispatch(actions.doGetCertificatesList());
    }
    this.setState({ dispatched: true });
  }

  componentDidUpdate(prevProps) {
    const { dispatch, navigate } = this.props;
    if (prevProps.credentialId !== this.props.credentialId) {
      dispatch(actions.doFind(this.props.credentialId, navigate));
    }
  }

  doSubmit = (id, data, pageNumber, pageSize) => {
    const { dispatch } = this.props;
    const { name: firstName, surName: lastName, email, userName } = this.props.currentUser || {};
    if (this.isEditing()) {
      dispatch(
        actions.doUpdate(
          this.props.credentialId,
          data,
          {
            expireonUser: {
              firstName,
              lastName,
              email,
              userName
            }
          },
          this.props.pageNumber,
          this.props.pageSize,
          this.props.searchString,
          this.props.sortOrder
        )
      );
    } else {
      dispatch(
        actions.doCreate(
          data,
          {
            expireonUser: {
              firstName,
              lastName,
              email,
              userName
            }
          },
          this.props.pageNumber,
          this.props.pageSize,
          this.props.searchString,
          this.props.sortOrder
        )
      );
    }
  };

  isEditing = () => {
    return this.props.isEditing;
  };

  isProfile = () => {
    return false;
  };

  render() {
    return (
      <React.Fragment>
        {this.state.dispatched && (
          <CredentialForm
            saveLoading={this.props.saveLoading}
            findLoading={this.props.findLoading}
            currentUser={this.props.currentUser}
            record={this.isEditing() || this.isProfile() ? this.props.record : {}}
            isEditing={this.isEditing()}
            isProfile={this.isProfile()}
            onSubmit={this.doSubmit}
            modalOpen={this.props.modalOpen}
            onCancel={() => this.props.navigate('/credentials')}
            credentialTypes={this.props.credentialTypes}
            certificatesList={this.props.certificatesList}
          />
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(store) {
  return {
    findLoading: store.credentials.form.findLoading,
    saveLoading: store.credentials.form.saveLoading,
    record: store.credentials.form.record,
    modalOpen: store.credentials.form.modalOpen,
    currentUser: store.auth.currentUser,
    credentialTypes: store.credentials.list.credentialTypes,
    certificatesList: store.credentials.form.certificatesList
  };
}

export default withRouter(connect(mapStateToProps)(CredentialFormPage));
