import React from "react";
import s from "../../../Tooltip/Tooltip.module.scss";
import "../../../Tooltip/Tooltip.css";
import Loader from "components/Loader/Loader";

export default function LegalHoldFormTooltip({ currentCustodian, position }) {
  let hasAdditionalData = true;
  const extendedTooltip = (
    <>
      {hasAdditionalData ? (
        <>
          <p
            style={{
              fontSize: "13px",
              marginTop: "15px",
              marginLeft: "10px",
              marginRight: "10px",
            }}
          >
            {currentCustodian.value}
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: "10px",
              marginLeft: "10px",
            }}
          ></div>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
  return (
    <>
      <div
        className={s.tooltip}
        style={{
          position: "absolute",
          zIndex: "100000000",
          marginTop: "-110px",
          top: position,
          left: position,
          display: position.top ? "block" : "none",
          minWidth: "250px",
          maxWidth: "300px",
        }}
      >
        {extendedTooltip}
      </div>
    </>
  );
}
