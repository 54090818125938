
export default function colorFormatter(caseStatus) {
    switch (caseStatus) {
        case "New":
            return "#5EFF5A";
        case "InProgress":
            return "#F7C244";
        case "Closed":
            return "#FF2D2E";
        case "LegalHoldReleased":
            return "#3978F6";
        case "Frozen":
            return "#bb6a0d";
        default:
            return "#5EFF5A";
    }
}
