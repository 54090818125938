import React from "react";
import ReactDOM from "react-dom";
import s from "components/Tooltip/Tooltip.module.scss";

function SearchListTooltip({ position, content }) {
  const initialTooltipStyle = {
    position: "absolute",
    zIndex: 1000,
    padding: 16,
    top: position.top,
    left: position.left + 20,
    display: position.top ? "block" : "none",
    wordBreak: "break-word",
    whiteSpace: "normal",
    maxWidth: "300px",
  };
  return ReactDOM.createPortal(
    <div style={initialTooltipStyle} className={s.tooltip}>
      {content}
    </div>,
    document.querySelector("#modal-root")
  );
}

export default SearchListTooltip;
