import { toast } from 'react-toastify';

const DEFAULT_ERROR_MESSAGE = 'Something went wrong, please try again!';

function selectErrorMessage(error) {
  if (
    error &&
    error.response &&
    error.response.data &&
    Object.keys(error.response.data).length !== 0
  ) {
    const data = error.response.data;
    if (data.error && data.error.message) {
      return data.error.message;
    }
    if (data.error) {
      return data.error;
    }
  }

  return DEFAULT_ERROR_MESSAGE;
}

function selectErrorCode(error) {
  if (error && error.response && error.response.status) {
    return error.response.status;
  }

  return 500;
}

export default class Errors {
  static errorCode(error) {
    return selectErrorCode(error);
  }

  static selectMessage(error) {
    return selectErrorMessage(error);
  }

  static showMessage(error) {
    toast.error(selectErrorMessage(error), { icon: false });
  }

  static handle(error) {
    this.showMessage(error);
  }
}
