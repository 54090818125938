import React, { Component } from 'react';
import PropTypes from 'prop-types';
import s from './ColorPicker.module.scss';

class CustomColorPicker extends Component {
  state = {
    selectedColor: this.props.activeColor
  };

  componentDidUpdate(prevProps) {
    if (prevProps.activeColor !== this.props.activeColor) {
      this.setState({
        selectedColor: this.props.activeColor
      });
    }
  }
  render() {
    const { colors, updateColor } = this.props;
    return (
      <div className={s.colorPickerContainer}>
        <div className={s.themeColors}>
          <ul className={s.colorsList}>
            {Object.entries(colors).map((color) => {
              return (
                <div className={s.themeContainer}>
                  <li
                    key={color[1]}
                    className={`${s.colorBox} ${
                      this.state.selectedColor === color[1] ? s.active : ''
                    }`}
                    style={{ background: color[1] }}
                    onClick={() => {
                      this.setState({
                        selectedColor: color[1]
                      });
                    }}
                  ></li>
                  <div
                    className={`${s.themeLabel} ${
                      this.state.selectedColor === color[1] ? 'fw-bold' : ''
                    }`}
                  >
                    {color[0]}
                  </div>
                </div>
              );
            })}
          </ul>
        </div>
        <div className={s.themeBtn}>
          <button
            className='btn first-body-text'
            onClick={() => updateColor(this.state.selectedColor)}
          >
            Save
          </button>
        </div>
      </div>
    );
  }
}

CustomColorPicker.propTypes = {
  colors: PropTypes.object,
  activeColor: PropTypes.string,
  updateColor: PropTypes.func,
  customizationItem: PropTypes.string
};

export default CustomColorPicker;
