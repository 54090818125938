const initialData = {
  rows: [],
  loading: false,
  count: null,
  auditGroups: [],
  auditEntryDetails: {},
  activityRows: [],
};

export default (state = initialData, { type, payload }) => {
  if (type === "AUDIT_LOGS_LIST_FETCH_STARTED") {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === "AUDIT_LOGS_LIST_FETCH_SUCCESS") {
    return {
      ...state,
      loading: false,
      rows: payload.rows,
      count: payload.count,
    };
  }

  if (type === "AUDIT_LOGS_LIST_FETCH_ERROR") {
    return {
      ...state,
      loading: false,
      rows: [],
    };
  }
  if (type === "AUDIT_GROUPS_FETCH_STARTED") {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === "AUDIT_GROUPS_FETCH_SUCCESS") {
    return {
      ...state,
      loading: false,
      auditGroups: payload.auditGroups,
    };
  }

  if (type === "AUDIT_GROUPS_FETCH_ERROR") {
    return {
      ...state,
      loading: false,
      auditGroups: [],
    };
  }
  if (type === "AUDIT_RECORDS_FETCH_STARTED") {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === "AUDIT_RECORDS_FETCH_SUCCESS") {
    return {
      ...state,
      loading: false,
      auditRecords: payload.auditRecords,
    };
  }

  if (type === "AUDIT_RECORDS_FETCH_ERROR") {
    return {
      ...state,
      loading: false,
      auditRecords: [],
    };
  }
  if (type === "AUDIT_ENTRY_FETCH_STARTED") {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === "AUDIT_ENTRY_FETCH_SUCCESS") {
    return {
      ...state,
      loading: false,
      auditEntryDetails: payload.auditEntryDetails,
    };
  }
  if (type === "AUDIT_ENTRY_FETCH_ERROR") {
    return {
      ...state,
      loading: false,
      auditEntryDetails: {},
    };
  }
  if (type === "REPORT_TEMPLATE_FETCH_STARTED") {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === "REPORT_TEMPLATE_FETCH_SUCCESS") {
    return {
      ...state,
      loading: false,
      recordTemplates: payload.recordTemplates,
    };
  }
  if (type === "REPORT_TEMPLATE_FETCH_ERROR") {
    return {
      ...state,
      loading: false,
      recordTemplates: {},
    };
  }
  if (type === "AUDIT_LOGS_ACTIVITY_LIST_FETCH_STARTED") {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === "AUDIT_LOGS_ACTIVITY_LIST_FETCH_SUCCESS") {
    return {
      ...state,
      loading: false,
      activityRows: payload.activityRows,
      count: payload.count,
    };
  }

  if (type === "AUDIT_LOGS_ACTIVITY_LIST_FETCH_ERROR") {
    return {
      ...state,
      loading: false,
      activityRows: [],
    };
  }
  return state;
};
