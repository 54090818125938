import React from 'react';
import PropTypes from 'prop-types';
import { default as ReactSelect } from 'react-select';
import { useEffect, useState } from 'react';

const MySelect = ({
  allOption = {
    label: 'Select all',
    value: '*'
  },
  ...props
}) => {
  const values = props.value;
  const [selectedAllOptions, setSelectedAllOptions] = useState(false);
  useEffect(() => {
    const selectedArray = props.value?.map((item) => item.value);
    if (props?.options?.length === selectedArray?.length) {
      setSelectedAllOptions(true);
    } else {
      setSelectedAllOptions(false);
    }
  }, [values]);

  if (props.allowSelectAll) {
    return (
      <ReactSelect
        {...props}
        options={selectedAllOptions ? [...props.options] : [allOption, ...props.options]}
        onChange={(selected) => {
          if (
            selected !== null &&
            selected.length > 0 &&
            selected[selected.length - 1].value === allOption.value
          ) {
            return props.onChange(props.options);
          }
          return props.onChange(selected);
        }}
      />
    );
  }

  return (
    <>
      <ReactSelect {...props} />
    </>
  );
};

MySelect.propTypes = {
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  allowSelectAll: PropTypes.bool,
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  }),
  placeholder: PropTypes.string
};

export default MySelect;
