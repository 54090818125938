import React from 'react';
import RefreshInterval from '../settings/RefreshInterval';
import Widget from 'components/Widget/Widget';
import s from './SettingsPage.module.scss';
import ColorTheme from './ColorTheme';
export default function SettingsPage() {
  return (
    <>
      <Widget className={s.container}>
        <h3 className={'fw-semi-bold me-4 mb-4'}>Settings</h3>
        <Widget className={s.refreshWidget}>
          <h5 className='second-headline-text text-center'>Refresh Interval</h5>
          <RefreshInterval />
        </Widget>
        <Widget className={s.refreshWidget}>
          <h5 className='second-headline-text text-center'>Theme Color</h5>
          <ColorTheme />
        </Widget>
      </Widget>
    </>
  );
}
