import React, { Component } from 'react';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { emptyDataMessage } from 'actions/common';

export default class ActionItemsTable extends Component {
  render() {
    const columns = [
      {
        dataField: 'actionItem',
        text: 'Action Item'
      },
      {
        dataField: 'dueDate',
        text: 'Due Date'
      },
      {
        dataField: 'notes',
        text: 'Notes'
      },
      {
        dataField: 'owner',
        text: 'Owner'
      },
      {
        dataField: 'status',
        text: 'Status'
      }
    ];
    return (
      <div>
        <ToolkitProvider
          bootstrap4
          columns={columns}
          data={this.props?.actionItems}
          keyField='actionItem'
        >
          {(props) => (
            <div className='table-container'>
              <BootstrapTable
                bordered={false}
                classes={`table-striped table-hover fs-sm custom-table`}
                noDataIndication={emptyDataMessage}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    );
  }
}
