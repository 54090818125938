import Errors from 'components/FormItems/error/errors';
import config from '../../config';
import { defaultOptions } from '../../services/userService';

async function getDateRanges() {
  const response = await fetch(`${config.externalApi}/legal/api/ReportTemplate/GetDateRanges`, {
    ...defaultOptions,
    method: 'GET'
  });

  const data = await response.json();
  return data;
}

async function getReportTemplateType() {
  const response = await fetch(
    `${config.externalApi}/legal/api/ReportTemplate/GetReportTemplateType`,
    {
      ...defaultOptions,
      method: 'GET'
    }
  );

  const data = await response.json();
  return data;
}

async function getAllExpireonUsers() {
  const response = await fetch(
    `${config.externalApi}/legal/api/ReportTemplate/GetAllExpireonUsers`,
    {
      ...defaultOptions,
      method: 'GET'
    }
  );

  const data = await response.json();
  return data;
}

const actions = {
  doFetchDateRanges: () => async (dispatch) => {
    try {
      dispatch({
        type: 'DATE_RANGES_FETCH_STARTED'
      });

      const response = await getDateRanges();

      dispatch({
        type: 'DATE_RANGES_FETCH_SUCCESS',
        payload: {
          dateRanges: response
        }
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'DATE_RANGES_FETCH_ERROR'
      });
    }
  },
  doFetchReportTemplateType: () => async (dispatch) => {
    try {
      dispatch({
        type: 'REPORT_TEMPLATE_TYPES_FETCH_STARTED'
      });

      const response = await getReportTemplateType();

      dispatch({
        type: 'REPORT_TEMPLATE_TYPES_FETCH_SUCCESS',
        payload: {
          reportTemplateType: response
        }
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'REPORT_TEMPLATE_TYPES_FETCH_ERROR'
      });
    }
  },
  doFetchAllExpireonUsers: () => async (dispatch) => {
    try {
      dispatch({
        type: 'ALL_EXPIREON_USERS_FETCH_STARTED'
      });

      const response = await getAllExpireonUsers();

      dispatch({
        type: 'ALL_EXPIREON_USERS_FETCH_SUCCESS',
        payload: {
          allExpireonUsers: response
        }
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'ALL_EXPIREON_USERS_FETCH_ERROR'
      });
    }
  }
};

export default { ...actions };
