import React from "react";
import { useState } from "react";
import styles from "components/CRUD/Search/itemContent/styles.module.scss";

export default function ExtendEmailList(props) {
  const [extendEmails, setExtendEmails] = useState(false);

  const handleExtendEmails = () => {
    setExtendEmails(!extendEmails);
  };
  const initialEmails = props?.response.slice(0, 3);
  const firstEmails = (
    <>
      <span
        style={{ cursor: "pointer" }}
        title={props.response}
        onClick={handleExtendEmails}
      >
        {initialEmails.map((item, index) => (
          <span style={{ paddingRight: "5px" }}>
            <span key={index} style={{ fontSize: "14px" }}>
              {item}
            </span>
            {index + 1 < initialEmails?.length && <span>;</span>}
          </span>
        ))}
      </span>
      <span
        style={{ fontWeight: "bold", cursor: "pointer" }}
        onClick={handleExtendEmails}
      >
        ...
      </span>
    </>
  );

  const allEmails = (
    <>
      <span
        title={props.response}
        style={{
          maxHeight: "60px",
          overflowY: "scroll",
          display: "flex",
          flexWrap: "wrap",
          fontSize: "14px",
        }}
      >
        {props?.response.map((item, index) => (
          <span style={{ paddingRight: "5px" }}>
            <span key={index}>{item}</span>
            {index + 1 < props.response?.length && <span>;</span>}
          </span>
        ))}
      </span>
    </>
  );

  const showEmails = !extendEmails ? firstEmails : allEmails;

  return (
    <>
      <span className={`${styles.headerFont}`}>
        {props.response.length < 4
          ? props.response.map((item, index) => (
              <span style={{ paddingRight: "5px" }}>
                <span key={index}>{item}</span>
                {index + 1 < props.response?.length && <span>;</span>}
              </span>
            ))
          : showEmails}
      </span>
    </>
  );
}
