const searchFields = {
  id: { type: 'id', label: 'ID' },
  legalCaseId: {
    type: 'relation_one',
    label: 'Legal Case Id'
  },
  legalHoldIds: {
    type: 'relation_many',
    label: 'Legal Holds*',
    required: true
  },
  fuzzyness: {
    type: 'fuzzyness',
    label: 'Fuzziness'
  },
  searchInHeader: {
    type: 'boolean',
    label: 'Header'
  },
  searchInBody: {
    type: 'boolean',
    label: 'Body'
  },
  searchInAttachment: {
    type: 'boolean',
    label: 'Attachment'
  },
  searchInSubject: {
    type: 'boolean',
    label: 'Subject Line'
  },
  onlyMailsWithAttachments: {
    type: 'boolean',
    label: 'Only Mails With Attachments'
  },
  queryString: {
    type: 'string',
    label: 'Query*',
    required: true
  },
  name: {
    type: 'string',
    label: 'Search Name*',
    required: true
  },
  viaText: {
    type: 'boolean',
    label: 'Text'
  },
  viaBuilder: {
    type: 'boolean',
    label: 'Builder'
  },
  queryBuilder: {
    type: 'string',
    label: 'Query*',
    required: true
  },
  subject: {
    type: 'string',
    label: 'Subject Line'
  },
  from: {
    type: 'string',
    label: 'Sender (From:)'
  },
  to: {
    type: 'string',
    label: 'To'
  },

  cc: {
    type: 'string',
    label: 'CC:'
  },

  bcc: {
    type: 'string',
    label: 'BCC:'
  },

  messageId: {
    type: 'string',
    label: 'MessageID'
  },
  recipients: {
    type: 'string',
    label: 'Recipients(To:,CC:,BCC:)'
  },
  isSplitField: {
    type: 'boolean',
    label: 'Split Fields'
  }
};

export default searchFields;
