import React, { useState } from "react";
import base64 from "base-64";

export default function AttachmentContent({ attachment, attachmentType }) {
  let contentComponent;
  const decodedContent = base64.decode(attachment);
  switch (attachmentType) {
    case ".txt":
      contentComponent = (
        <pre
          style={{ backgroundColor: "inherit", color: "white", border: "none" }}
        >
          {decodedContent}
        </pre>
      );
      break;
    case ".json":
      const parsedJson = JSON.parse(decodedContent);
      contentComponent = (
        <pre
          style={{ backgroundColor: "inherit", color: "white", border: "none" }}
        >
          {JSON.stringify(parsedJson, null, 2)}
        </pre>
      );
      break;
    default:
      contentComponent = <p>Unsupported content type: {attachmentType}</p>;
      break;
  }
  return <div>{contentComponent}</div>;
}
