"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormattingService = void 0;
var FormattingService = /** @class */ (function () {
    function FormattingService() {
    }
    FormattingService.prototype.getBytesReadable = function (bytes) {
        var kilobyte = 1024;
        var megabyte = kilobyte * 1024;
        var gigabyte = megabyte * 1024;
        var terabyte = gigabyte * 1024;
        var petabyte = terabyte * 1024;
        var exabyte = petabyte * 1024;
        if ((bytes >= 0) && (bytes < kilobyte)) {
            this.readable = bytes;
            this.suffix = 'B';
        }
        else if ((bytes >= kilobyte) && (bytes < megabyte)) {
            this.readable = (bytes / kilobyte);
            this.suffix = "KB";
        }
        else if ((bytes >= megabyte) && (bytes < gigabyte)) {
            this.readable = (bytes / megabyte);
            this.suffix = "MB";
        }
        else if ((bytes >= gigabyte) && (bytes < terabyte)) {
            this.readable = (bytes / gigabyte);
            this.suffix = "GB";
        }
        else if (bytes >= terabyte && (bytes < petabyte)) {
            this.readable = (bytes / terabyte);
            this.suffix = "TB";
        }
        else if (bytes >= petabyte && (bytes < exabyte)) {
            this.readable = (bytes / petabyte);
            this.suffix = "PB";
        }
        else if (bytes >= exabyte) {
            this.readable = (bytes / exabyte);
            this.suffix = "EB";
        }
        else {
            bytes + "B";
        }
        return this.readable.toFixed(2) + ' ' + this.suffix;
    };
    FormattingService.prototype.numberWithCommas = function (x) {
        return x.toString().replace(/\B(?!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    };
    return FormattingService;
}());
exports.FormattingService = FormattingService;
