import Errors from 'components/FormItems/error/errors';
import config from 'config';
import { toast } from 'react-toastify';
import { store } from '../../index';
import caseActions from 'actions/LegalCase/LegalCaseFormActions';
import caseViewerFormActions from 'actions/viewers/LegalCaseViewerFormActions';
import { defaultOptions } from '../../services/userService';

async function getExpireonUsers(legalCaseId) {
  let expireonUsers = null;
  const response = await fetch(
    `${config.externalApi}/legal/api/LegalCase/${legalCaseId}/GetExpireonUsers`,
    {
      ...defaultOptions,
      method: 'GET'
    }
  );

  if (response.ok) {
    expireonUsers = await response.json();
  }

  return expireonUsers;
}

async function addViewer(data, legalCaseId) {
  const currentUser = store.getState().auth.currentUser;
  const response = await fetch(
    `${config.externalApi}/legal/api/LegalCase/${legalCaseId}/AddViewers?FirstName=${currentUser.name}&LastName=${currentUser.surName}&Email=${currentUser.email}&UserName=${currentUser.userName}`,
    {
      ...defaultOptions,
      method: 'PUT',
      body: JSON.stringify(data)
    }
  );
  return response;
}

async function deleteExpireonViewer(legalCaseId, viewerEmail) {
  const expireonUser = store.getState().auth.currentUser;
  const { name: firstName, surName: lastName, email, userName } = expireonUser || {};
  let loggedUserString = { firstName, lastName, email, userName };
  const encodedEmail = encodeURIComponent(viewerEmail);
  const response = await fetch(
    `${config.externalApi}/legal/api/LegalCase/DeleteExpireonViewer?legalCaseId=${legalCaseId}&email=${encodedEmail}`,
    {
      ...defaultOptions,
      method: 'DELETE',
      body: JSON.stringify(loggedUserString)
    }
  );

  return response;
}

const actions = {
  doGetExpireonUsers: (legalCaseId) => async (dispatch) => {
    try {
      dispatch({
        type: 'USERS_LIST_FETCH_STARTED'
      });

      const response = await getExpireonUsers(legalCaseId);

      dispatch({
        type: 'USERS_LIST_FETCH_SUCCESS',
        payload: {
          rows: response
        }
      });
    } catch (error) {
      Errors.handle(error);
      dispatch(caseViewerFormActions.doCloseConfirm());
      dispatch({
        type: 'USERS_LIST_FETCH_ERROR'
      });
    }
  },
  doAddNewViewer: (values, legalCaseId, navigate) => async (dispatch) => {
    try {
      dispatch({
        type: 'LEGALCASE_VIEWER_FORM_CREATE_STARTED'
      });

      addViewer(values, legalCaseId).then(async (res) => {
        dispatch({
          type: ' LEGALCASE_VIEWER_FORM_CREATE_SUCCESS'
        });
        toast.success('New Viewer added', { icon: false });
        dispatch(caseActions.doFind(legalCaseId, navigate));
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'LEGALCASE_VIEWER_FORM_CREATE_ERROR'
      });
    }
  },
  doDeleteExpireonViewer: (legalCaseId, email, navigate) => async (dispatch) => {
    try {
      dispatch({
        type: 'LEGALCASE_DELETE_VIEWER_STARTED'
      });
      deleteExpireonViewer(legalCaseId, email).then(() => {
        dispatch({
          type: 'LEGALCASE_DELETE_VIEWER_SUCCESS'
        });
        toast.success('User removed!', { icon: false });
        dispatch(caseActions.doFind(legalCaseId, navigate));
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'LEGALCASE_DELETE_VIEWER_ERROR'
      });
    }
  },
  doOpenConfirm: () => async (dispatch) => {
    dispatch({
      type: 'LEGALCASE_VIEWER_FORM_OPEN_CONFIRM'
    });
  },
  doCloseConfirm: () => async (dispatch) => {
    dispatch({
      type: 'LEGALCASE_VIEWER_FORM_CLOSE_CONFIRM'
    });
  }
};

export default { ...actions };
