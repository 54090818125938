import React, { Component } from 'react';
import Widget from 'components/Widget/Widget';
import styles from 'components/CRUD/AuditLogs/Audit.module.scss';
import { Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import * as dataFormat from 'components/CRUD/LegalHold/list/LegalHoldDataFormatters';
import { connect } from 'react-redux';
import actions from 'actions/AuditLogs/AuditLogsListActions';
import { getPageRangeDisplayed } from 'actions/search/searchListActions';
import paginationStyles from 'components/CRUD/LegalHold/list/LegalHoldList.module.scss';
import Pagination from 'react-js-pagination';
import ExpandRowComponent from './ExpandRowComponent';
import searchIcon from 'images/icons/search.svg';
import { emptyDataMessage } from 'actions/common';
import caretDown from 'images/icons/caret-down.svg';
class AuditRecordsTable extends Component {
  state = {
    pageNumber: 1,
    pageSize: 10,
    newRows: [],
    totalCount: 0,
    paginationModalOpen: false,
    sort: '',
    searchString: ''
  };

  componentDidMount() {
    if (this.props?.params?.legalCaseId) {
      const { dispatch } = this.props;
      const params = { ...this.props.params, pageSize: 10, pageNumber: 1 };
      dispatch(actions.doFetch(params)).then(() => {
        this.setState({
          newRows: this.props?.rows,
          totalCount: this.props?.count
        });
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { dispatch } = this.props;
    if (
      prevState.pageNumber !== this.state.pageNumber ||
      prevState.pageSize !== this.state.pageSize ||
      prevState.sort !== this.state.sort
    ) {
      const params = {
        ...this.props.params,
        pageSize: this.state.pageSize,
        pageNumber: this.state.pageNumber,
        sortOrder: this.state.sort,
        currentFilter: this.state.searchString
      };
      dispatch(actions.doFetch(params)).then(() => {
        this.setState({
          newRows: this.props?.rows,
          totalCount: this.props?.count
        });
      });
    }
    if (prevState.searchString !== this.state.searchString && this.state.searchString === '') {
      const params = {
        ...this.props.params,
        pageSize: this.state.pageSize,
        pageNumber: this.state.pageNumber,
        sortOrder: this.state.sort,
        currentFilter: this.state.searchString
      };
      dispatch(actions.doFetch(params)).then(() => {
        this.setState({
          newRows: this.props?.rows,
          totalCount: this.props?.count
        });
      });
    }
    if (prevProps.rows !== this.props.rows) {
      this.setState({
        newRows: this.props?.rows,
        totalCount: this.props?.count
      });
    }
  }

  sortFormatter(sortField) {
    const SortEnum = {
      legalCaseName: 'casename',
      recordDateUtc: 'recorddate',
      auditType: 'audittype',
      fullName: 'fullname'
    };
    return SortEnum[sortField];
  }

  handlePageChange = (pageNumber) => {
    this.setState({ pageNumber: pageNumber });
  };

  handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === 'sort') {
      this.setState({
        pageNumber: 1,
        sort: this.sortFormatter(sortField).concat('_').concat(sortOrder)
      });
    }
  };

  handleSearch = () => {
    const { dispatch } = this.props;
    this.setState({ pageNumber: 1 });
    const params = {
      ...this.props.params,
      pageSize: this.state.pageSize,
      pageNumber: this.state.pageNumber,
      sortOrder: this.state.sort,
      currentFilter: this.state.searchString
    };
    dispatch(actions.doFetch(params)).then(() => {
      this.setState({
        newRows: this.props?.rows,
        totalCount: this.props?.count
      });
    });
  };

  render() {
    const columns = [
      {
        dataField: 'recordDateUtc',
        text: 'Performed On',
        sort: true,
        formatter: dataFormat.dateTimeFormatter
      },
      {
        dataField: 'fullName',
        text: 'Performed By',
        sort: true,
        formatter: (cell, row) => <div className={styles.activityCaseName}>{cell}</div>
      },
      {
        dataField: 'auditType',
        text: 'Event type',
        sort: true,
        formatter: (cell, row) => <div className={styles.activityCaseName}>{cell}</div>
      },
      {
        dataField: 'legalCaseName',
        text: 'Case name',
        sort: true,
        formatter: (cell, row) => <div className={styles.activityCaseName}>{cell}</div>
      }
    ];

    const expandRow = {
      parentClassName: `${styles.parentRow}`,
      onlyOneExpanding: true,
      renderer: (row, rowIndex) => (
        <div className={styles.expandedRowContainer}>
          <ExpandRowComponent auditEntryId={row.auditEntryId} dispatch={this.props.dispatch} />
        </div>
      )
    };

    return (
      <>
        <Widget className={styles.widget}>
          <ToolkitProvider
            bootstrap4
            keyField='auditEntryId'
            columns={columns}
            data={this.state.newRows}
          >
            {(props) => (
              <>
                <Row sm={12}>
                  <Col sm={12} className={styles.auditTableHeader}>
                    <h3 className={'first-headline-text me-4 mb-0'}>Audit Records</h3>
                    <div className='d-flex mb-4'>
                      <span>
                        <input
                          type='search'
                          placeholder={'Search'}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              this.handleSearch();
                            }
                          }}
                          style={{
                            border: '0.5px solid #0046b1',
                            borderRadius: '3px'
                          }}
                          className={'form-control search-input me-5 w-200'}
                          value={this.state.searchString}
                          onChange={(e) => {
                            this.setState({
                              searchString: e.target.value
                            });
                          }}
                        />{' '}
                      </span>
                      <span>
                        <button
                          className='btn search-button-with-icon me-2 ms-10'
                          type='button'
                          onClick={() => this.handleSearch()}
                          style={{ height: '37px', width: '37px' }}
                        >
                          <img
                            title={'search'}
                            alt={'search'}
                            width={14}
                            height={14}
                            src={searchIcon}
                          />
                        </button>
                      </span>
                    </div>
                  </Col>
                </Row>
                <div className='table-container'>
                  <BootstrapTable
                    bordered={false}
                    classes={`table-striped table-hover fs-sm custom-table`}
                    remote={{
                      filter: false,
                      pagination: false,
                      sort: true,
                      cellEdit: false
                    }}
                    onTableChange={this.handleTableChange}
                    expandRow={expandRow}
                    noDataIndication={emptyDataMessage}
                    {...props.baseProps}
                  />
                </div>
                <Row key={'pagination'} className='mt-3'>
                  <Col>
                    <p className={paginationStyles.totalCount}>Total: {this.state.totalCount}</p>
                  </Col>
                  <Col className='d-flex justify-content-end'>
                    {this.state.totalCount ? (
                      <Pagination
                        totalItemsCount={this.state.totalCount}
                        onChange={this.handlePageChange}
                        activePage={this.state.pageNumber}
                        itemsCountPerPage={this.state.pageSize}
                        pageRangeDisplayed={getPageRangeDisplayed(this.state.pageNumber)}
                        prevPageText={'<'}
                        nextPageText={'>'}
                        firstPageText={'<<'}
                        lastPageText={'>>'}
                        linkClassFirst={paginationStyles.linkNext}
                        linkClassPrev={paginationStyles.linkNext}
                        linkClassNext={paginationStyles.linkNext}
                        linkClassLast={paginationStyles.linkNext}
                        linkClass={paginationStyles.linkClass}
                        activeLinkClass={paginationStyles.activeLinkClass}
                        activeClass={paginationStyles.activeLinkClass}
                      />
                    ) : null}
                    <Dropdown
                      isOpen={this.state.paginationModalOpen}
                      toggle={() =>
                        this.setState({
                          paginationModalOpen: !this.state.paginationModalOpen
                        })
                      }
                      className={paginationStyles.dropdownClass}
                    >
                      <DropdownToggle className='bg-transparent text-white d-flex'>
                        <p
                          style={{ marginBottom: '3px', marginRight: '2px' }}
                          className='first-body-text'
                        >
                          {this.state.pageSize}
                        </p>
                        <img
                          src={caretDown}
                          alt={'uncollapsed'}
                          width='20px'
                          height='20px'
                          className='ms-2'
                        />
                      </DropdownToggle>
                      <DropdownMenu className='dropdown-position-fixed'>
                        {[10, 20, 25].map((item) => (
                          <DropdownItem
                            className={paginationStyles.dropdownItem}
                            key={item}
                            onClick={() => {
                              this.setState({
                                pageSize: item,
                                paginationModalOpen: false,
                                pageNumber: 1
                              });
                            }}
                          >
                            {item}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                </Row>
              </>
            )}
          </ToolkitProvider>
        </Widget>
      </>
    );
  }
}

function mapStateToProps(store) {
  return {
    loading: store.AuditLogs.list.loading,
    rows: store.AuditLogs.list.rows,
    count: store.AuditLogs.list.count
  };
}

export default connect(mapStateToProps)(AuditRecordsTable);
