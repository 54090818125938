import Errors from 'components/FormItems/error/errors';
import config from '../../config';
import { Client } from '../../legal-api';
import { store } from 'index';

async function list(legalCaseId) {
  const loggedUser = store.getState().auth.currentUser?.userName;
  const legalCaseStats = await new Client(config.externalApi).legalCaseStats(
    legalCaseId,
    loggedUser
  );
  const legalCase = await new Client(config.externalApi).legalCaseGET(legalCaseId, loggedUser);
  return Object.assign(legalCaseStats, legalCase);
}

const actions = {
  doFetch:
    (filter, keepPagination = false) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: 'LEGALCASESTATS_LIST_FETCH_STARTED',
          payload: { filter, keepPagination }
        });

        const response = await list(filter.legalCaseId);

        dispatch({
          type: 'LEGALCASESTATS_LIST_FETCH_SUCCESS',
          payload: {
            rows: response,
            count: response.count
          }
        });
      } catch (error) {
        Errors.handle(error);
        dispatch({
          type: 'LEGALCASESTATS_LIST_FETCH_ERROR'
        });
      }
    }
};

export default actions;
