import React, { Component } from "react";
import styles from "components/CRUD/Reports/Reports.module.scss";

export default class TagGroupTooltip extends Component {
  state = {
    tagsList: [],
  };

  getTagsList() {
    const selectedItem = this.props.data?.find(
      (item) => item.id === this.props?.id
    );
    this.setState({
      tagsList: selectedItem?.tags,
    });
  }
  componentDidMount() {
    if (this.props) {
      this.getTagsList();
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      this.getTagsList();
    }
  }
  render() {
    return (
      <>
        {this.state.tagsList ? (
          <div className={styles.cardContainer}>
            <div className={styles.header}>Tags:</div>
            <div className={styles.tagList}>
              {this.state.tagsList?.map((item, index) => (
                <div key={index}>{item.displayName}</div>
              ))}
            </div>
          </div>
        ) : null}
      </>
    );
  }
}
