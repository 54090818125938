import { FastField } from 'formik';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import FormErrors from 'components/FormItems/formErrors';
import AsyncSelect from 'react-select/async';

const AUTOCOMPLETE_SERVER_FETCH_SIZE = 100;

class AutocompleteFormItemNotFast extends Component {
  state = {
    isMounted: false,
    holdsList: []
  };

  value = () => {
    const { mode } = this.props;
    if (mode === 'multiple') {
      return this.valueMultiple();
    } else {
      return this.valueOne();
    }
  };

  valueMultiple = () => {
    const { form, name, mapper } = this.props;

    if (form.values[name]) {
      if (!Array.isArray(form.values[name])) {
        return Object.entries(form.values[name]).map((value) => mapper.toAutocomplete(value));
      }
      return form.values[name].map((value) => mapper.toAutocomplete(value));
    }

    return [];
  };

  valueOne = () => {
    const { form, name, mapper } = this.props;

    if (form.values[name]) {
      return mapper.toAutocomplete(form.values[name]);
    }

    return '';
  };

  handleSelect = (value) => {
    const { form, name } = this.props;
    form.setFieldTouched(name);

    const { mode } = this.props;
    if (mode === 'multiple') {
      return this.handleSelectMultiple(value);
    } else {
      return this.handleSelectOne(value);
    }
  };

  handleSelectMultiple = (values) => {
    const { form, name, mapper } = this.props;

    if (!values) {
      form.setFieldValue(name, []);
      return;
    }

    form.setFieldValue(
      name,
      values.map((value) => mapper.toValue(value))
    );
  };

  handleSelectOne = (value) => {
    const { form, name, mapper } = this.props;

    if (!value) {
      form.setFieldValue(name, '');
      return;
    }

    form.setFieldValue(name, mapper.toValue(value));
  };

  handleSearch = async (value) => {
    const { fetchFn, mapper } = this.props;

    try {
      const results = await fetchFn(value, AUTOCOMPLETE_SERVER_FETCH_SIZE);

      // if (this.props.indexed) {
      //     return Object.keys(results).map((item) => {
      //         return {
      //             label: results[item],
      //             value: item
      //         }
      //     })
      // }

      if (!Array.isArray(results)) {
        return Object.keys(results).map((item) => {
          return {
            label: results[item].value,
            value: item
          };
        });
      }

      const res = results?.map((result) => mapper.toAutocomplete(result));
      this.setState(
        {
          holdsList: res
        },
        () => {
          if (this.props.isDiscarded === true) {
            let data = [];
            this.props?.legalHoldIds?.map((item) => {
              this.state.holdsList?.map((option) => {
                if (option.value === item) {
                  data.push(option);
                }
              });
            });
            this.handleSelectMultiple(data);
          } else if (this.props.selectedHolds.length !== 0) {
            const data = this.props.selectedHolds.map((value) => mapper.toAutocomplete(value));
            this.handleSelectMultiple(data);
          } else if (!this.state.isMounted && this.props.defaultValue) {
            this.handleSelectMultiple(res);
            this.setState({
              isMounted: true
            });
          }
        }
      );
      return res;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  render() {
    const {
      form,
      name,
      hint,
      size,
      placeholder,
      autoFocus,
      inputProps,
      errorMessage,
      mode,
      required,
      isClearable
    } = this.props;

    const { label } = this.props.schema[name];

    const sizeLabelClassName =
      {
        small: 'col-form-label-sm',
        large: 'col-form-label-lg'
      }[size] || '';

    const isInvalid = !!FormErrors.displayableError(form, name, errorMessage);

    const controlStyles = isInvalid
      ? {
          control: (provided) => ({
            ...provided,
            borderColor: 'red',
            backgroundColor: '#040620'
          }),
          option: (provided, state) => ({
            backgroundColor: state.isFocused ? '#474D84' : '',
            height: 30,
            paddingLeft: '5px',
            paddingTop: '5px'
          }),
          menuList: (provided) => ({
            ...provided,
            backgroundColor: '#040620'
          }),
          input: (provided) => ({
            ...provided,
            color: 'white'
          }),
          multiValue: (provided) => ({
            ...provided,
            backgroundColor: '#474D84'
          }),
          multiValueLabel: (provided) => ({
            ...provided,
            color: 'white'
          })
        }
      : {
          control: (provided) => ({
            ...provided,
            backgroundColor: '#040620',
            border: 'none',
            overflowX: 'auto',
            maxHeight: 100
          }),
          option: (provided, state) => ({
            backgroundColor: state.isFocused ? '#474D84' : '',
            height: 30,
            paddingLeft: '5px',
            paddingTop: '5px'
          }),
          menuList: (provided) => ({
            ...provided,
            backgroundColor: '#040620'
          }),
          input: (provided) => ({
            ...provided,
            color: 'white'
          }),
          multiValue: (provided) => ({
            ...provided,
            backgroundColor: '#474D84'
          }),
          multiValueLabel: (provided) => ({
            ...provided,
            color: 'white'
          })
        };

    return (
      <div className='form-group'>
        {!!label && (
          <label
            className={`col-form-label ${required ? 'required' : null} ${sizeLabelClassName}`}
            htmlFor={name}
          >
            {label}
          </label>
        )}
        <div style={{ display: 'flex' }}>
          <AsyncSelect
            className='w-100'
            styles={controlStyles}
            closeMenuOnSelect={mode === 'multiple' ? false : true}
            id={name}
            name={name}
            defaultOptions={true}
            isMulti={mode === 'multiple' ? true : false}
            loadOptions={this.handleSearch}
            placeholder={placeholder || ''}
            autoFocus={autoFocus || undefined}
            onChange={this.handleSelect}
            value={this.value()}
            isClearable={isClearable}
            formatOptionLabel={(option) => {
              return (
                <div
                  style={{
                    maxHeight: 25,
                    overflowY: 'hidden',
                    overflowX: 'hidden'
                  }}
                >
                  {option.label}
                </div>
              );
            }}
            {...inputProps}
          />
        </div>

        <div className='invalid-feedback'>
          {FormErrors.displayableError(form, name, errorMessage)}
        </div>
        {!!hint && <small className='form-text text-muted'>{hint}</small>}
      </div>
    );
  }
}

AutocompleteFormItemNotFast.defaultProps = {
  isClearable: true,
  mode: 'default',
  required: false
};

AutocompleteFormItemNotFast.propTypes = {
  form: PropTypes.object.isRequired,
  fetchFn: PropTypes.func.isRequired,
  mapper: PropTypes.object.isRequired,
  required: PropTypes.bool,
  mode: PropTypes.string,
  name: PropTypes.string.isRequired,
  hint: PropTypes.string,
  autoFocus: PropTypes.bool,
  size: PropTypes.string,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  isClearable: PropTypes.bool,
  inputProps: PropTypes.object,
  showCreate: PropTypes.bool,
  hasPermissionToCreate: PropTypes.bool
};

class AutocompleteFormItem extends Component {
  render() {
    return (
      <FastField name={this.props.name}>
        {({ form }) => <AutocompleteFormItemNotFast {...this.props} form={form} />}
      </FastField>
    );
  }
}

export default AutocompleteFormItem;
