const initialData = {
  loading: false,
  reportsList: [],
  reportsCount: null,
  fileLocations: [],
  emailPolicies: [],
  emailPoliciesList: [],
  emailPoliciesCount: null,
  locationPoliciesList: [],
  locationPoliciesCount: null,
};

export default (state = initialData, { type, payload }) => {
  if (type === "REPORTS_LIST_FETCH_STARTED") {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === "REPORTS_LIST_FETCH_SUCCESS") {
    return {
      ...state,
      loading: false,
      reportsList: payload.reportsList,
      reportsCount: payload.reportsCount,
    };
  }

  if (type === "REPORTS_LIST_FETCH_ERROR") {
    return {
      ...state,
      loading: false,
      reportsList: [],
    };
  }

  if (type === "FILE_LOCATIONS_FETCH_STARTED") {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === "FILE_LOCATIONS_FETCH_SUCCESS") {
    return {
      ...state,
      loading: false,
      fileLocations: payload.fileLocations,
    };
  }

  if (type === "FILE_LOCATIONS_FETCH_ERROR") {
    return {
      ...state,
      loading: false,
      fileLocations: [],
    };
  }
  if (type === "EMAIL_POLICIES_FETCH_STARTED") {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === "EMAIL_POLICIES_FETCH_SUCCESS") {
    return {
      ...state,
      loading: false,
      emailPolicies: payload.emailPolicies,
    };
  }

  if (type === "EMAIL_POLICIES_FETCH_ERROR") {
    return {
      ...state,
      loading: false,
      emailPolicies: [],
    };
  }
  if (type === "REPORT_LIST_DELETE_STARTED") {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === "REPORT_LIST_DELETE_SUCCESS") {
    return {
      ...state,
      loading: false,
    };
  }

  if (type === "REPORT_LIST_DELETE_ERROR") {
    return {
      ...state,
      loading: false,
    };
  }
  if (type === "EMAIL_POLICIES_LIST_FETCH_STARTED") {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === "EMAIL_POLICIES_LIST_FETCH_SUCCESS") {
    return {
      ...state,
      loading: false,
      emailPoliciesList: payload.rows,
      emailPoliciesCount: payload.count,
    };
  }

  if (type === "EMAIL_POLICIES_LIST_FETCH_ERROR") {
    return {
      ...state,
      loading: false,
      emailPoliciesList: [],
    };
  }
  if (type === "LOCATION_POLICIES_LIST_FETCH_STARTED") {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === "LOCATION_POLICIES_LIST_FETCH_SUCCESS") {
    return {
      ...state,
      loading: false,
      locationPoliciesList: payload.rows,
      locationPoliciesCount: payload.count,
    };
  }

  if (type === "LOCATION_POLICIES_LIST_FETCH_ERROR") {
    return {
      ...state,
      loading: false,
      locationPoliciesList: [],
    };
  }
  return state;
};
