import axios from 'axios';
import config from '../config';
import Errors from 'components/FormItems/error/errors';
import { setAuthHeader } from '../utils/axiosHeaders';
import { store } from 'index';
import { defaultOptions } from '../services/userService';
export const AUTH_INIT_SUCCESS = 'AUTH_INIT_SUCCESS';
export const AUTH_INIT_ERROR = 'AUTH_INIT_ERROR';
export const STORE_USER = 'STORE_USER';
export const STORE_USER_ERROR = 'STORE_USER_ERROR';


export function storeUser(user) {
  setAuthHeader(user.access_token);
  return {
    type: STORE_USER,
    payload: { user }
  };
}

export function getCurrentUser(currentUser, permissions) {
  return {
    type: AUTH_INIT_SUCCESS,
    payload: { currentUser, permissions }
  };
}

export function storeUserError() {
  return {
    type: STORE_USER_ERROR
  };
}

export async function loginSuccessfull() {
  const currentUser = store.getState().auth.currentUser;
  try{
    axios({
      url: `${config.externalApi}/legal/api/Login/LoginSuccessfull`,
      method: 'POST',
      headers: defaultOptions.headers,
      data: {
        firstName: currentUser?.name,
        lastName: currentUser?.surName,
        email: currentUser?.email,
        userName: currentUser?.userName
      }
    });
  }catch(error){
    Errors.handle(error)
  }

}
export async function logoutSuccessfull() {
  const currentUser = store.getState().auth.currentUser;
  try{
    axios({
      url: `${config.externalApi}/legal/api/Login/LogoutSuccessfull`,
      method: 'POST',
      headers: defaultOptions.headers,
      data: {
        firstName: currentUser?.name,
        lastName: currentUser?.surName,
        email: currentUser?.email,
        userName: currentUser?.userName
      }
    });
  }catch(error){
    Errors.handle(error)
  }
}

export async function getWelcomeMessage() {
  const response = await axios.get(`${config.externalApi}/api/Widget/GetWelcomeMessage`, {
    ...defaultOptions
  });
  return response.data
}

