const LegalCaseFields = {
  caseName: { type: 'string', label: 'Name*', maxLength: 192, required: true },
  caseReferenceId: {
    type: 'string',
    label: 'Reference ID*',
    maxLength: 256,
    required: true
  },
  caseDescription: {
    type: 'string',
    label: 'Description*',
    maxLength: 1024,
    required: true
  }
};

export default LegalCaseFields;
