import Errors from '../../components/FormItems/error/errors';
import config from '../../config';
import { downloadService } from 'services/downloadService';
import { store } from 'index';
import { defaultOptions } from '../../services/userService';

async function list(pageSize, pageNumber, sortOrder, searchString) {
  const roleName = store.getState().auth.currentUser?.roles[0];
  let baseUrl = `${config.externalApi}/api/Credentials?`;
  if (pageNumber !== undefined) {
    baseUrl += 'pageNumber=' + encodeURIComponent('' + pageNumber) + '&';
  }
  if (pageSize !== undefined) {
    baseUrl += 'pageSize=' + encodeURIComponent('' + pageSize) + '&';
  }
  if (searchString !== undefined && searchString !== '') {
    baseUrl += 'searchString=' + encodeURIComponent('' + searchString) + '&';
  }
  if (sortOrder !== undefined && sortOrder !== '') {
    baseUrl += 'sortOrder=' + encodeURIComponent('' + sortOrder) + '&';
  }
  if (roleName !== undefined && roleName !== '') {
    baseUrl += 'roleName=' + encodeURIComponent('' + roleName) + '&';
  }
  baseUrl = baseUrl.replace(/[?&]$/, '');
  const response = await fetch(baseUrl, { ...defaultOptions, method: 'GET' });

  const data = await response.json();
  return data;
}

async function getCredentialTypes() {
  const roleName = store.getState().auth.currentUser?.roles[0];
  const response = await fetch(
    `${config.externalApi}/api/Credentials/GetCredentialTypes?roleName=${roleName}`,
    {
      ...defaultOptions,
      method: 'GET'
    }
  );

  const data = await response.json();
  return data;
}

async function exportToCSV(downloadUrl, columnHeaders, searchString, expireonUser) {
  const roleName = store.getState().auth.currentUser?.roles[0];
  let baseUrl = downloadUrl;
  if (roleName !== undefined && roleName !== null && roleName !== '') {
    baseUrl += 'roleName=' + encodeURIComponent('' + roleName) + '&';
  }
  if (columnHeaders !== undefined && columnHeaders !== null) {
    baseUrl += 'columnHeaders=' + encodeURIComponent('' + columnHeaders) + '&';
  }
  if (searchString !== undefined && searchString !== null && searchString !== '') {
    baseUrl += 'searchString=' + encodeURIComponent('' + searchString) + '&';
  }
  baseUrl = baseUrl.replace(/[?&]$/, '');
  await downloadService(baseUrl, 'POST', expireonUser);
}

async function exportToPDF(downloadUrl, columnHeaders, searchString, expireonUser) {
  const roleName = store.getState().auth.currentUser?.roles[0];
  let baseUrl = downloadUrl;
  if (roleName !== undefined && roleName !== null && roleName !== '') {
    baseUrl += 'roleName=' + encodeURIComponent('' + roleName) + '&';
  }
  if (columnHeaders !== undefined && columnHeaders !== null) {
    baseUrl += 'columnHeaders=' + encodeURIComponent('' + columnHeaders) + '&';
  }
  if (searchString !== undefined && searchString !== null && searchString !== '') {
    baseUrl += 'searchString=' + encodeURIComponent('' + searchString) + '&';
  }
  baseUrl = baseUrl.replace(/[?&]$/, '');
  await downloadService(baseUrl, 'POST', expireonUser);
}

async function getCertificates(pageSize, pageNumber, sortOrder, searchString) {
  let baseUrl = `${config.externalApi}/api/Credentials/GetTenantCertificates?`;
  if (pageNumber !== undefined) {
    baseUrl += 'pageNumber=' + encodeURIComponent('' + pageNumber) + '&';
  }
  if (pageSize !== undefined) {
    baseUrl += 'pageSize=' + encodeURIComponent('' + pageSize) + '&';
  }
  if (searchString !== undefined && searchString !== '') {
    baseUrl += 'searchString=' + encodeURIComponent('' + searchString) + '&';
  }
  if (sortOrder !== undefined && sortOrder !== '') {
    baseUrl += 'sortOrder=' + encodeURIComponent('' + sortOrder) + '&';
  }
  baseUrl = baseUrl.replace(/[?&]$/, '');

  const response = await fetch(baseUrl, {
    ...defaultOptions,
    method: 'GET'
  });

  const data = await response.json();
  return data;
}

async function downloadCertificate(downloadUrl) {
  await downloadService(downloadUrl, 'GET', undefined);
}

const actions = {
  doFetch:
    (filter, keepPagination = false) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: 'CREDENTIAL_LIST_FETCH_STARTED',
          payload: { filter, keepPagination }
        });

        const response = await list(
          filter.pageSize,
          filter.pageNumber,
          filter.sortOrder,
          filter.searchString
        );

        dispatch({
          type: 'CREDENTIAL_LIST_FETCH_SUCCESS',
          payload: {
            rows: response.credentials,
            count: response.totalCount
          }
        });
      } catch (error) {
        Errors.handle(error);
        dispatch({
          type: 'CREDENTIAL_LIST_FETCH_ERROR'
        });
      }
    },

  doGetCredentialTypes: () => async (dispatch) => {
    try {
      dispatch({
        type: 'CREDENTIAL_TYPES_LIST_FETCH_STARTED'
      });
      const response = await getCredentialTypes();
      dispatch({
        type: 'CREDENTIAL_TYPES_LIST_FETCH_SUCCESS',
        payload: {
          credentialTypes: response
        }
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'CREDENTIAL_TYPES_LIST_FETCH_ERROR'
      });
    }
  },
  doOpenConfirm: (id) => async (dispatch) => {
    dispatch({
      type: 'CREDENTIAL_LIST_OPEN_CONFIRM',
      payload: {
        id: id
      }
    });
  },
  doCloseConfirm: () => async (dispatch) => {
    dispatch({
      type: 'CREDENTIAL_LIST_CLOSE_CONFIRM'
    });
  },
  handleExportToCSV:
    (downloadUrl, columnHeaders, searchString, expireonUser) => async (dispatch) => {
      try {
        dispatch({
          type: 'EXPORT_TO_CSV_STARTED'
        });
        exportToCSV(downloadUrl, columnHeaders, searchString, expireonUser).then(() => {
          dispatch({
            type: 'EXPORT_TO_CSV_SUCCESS'
          });
        });
      } catch (error) {
        Errors.handle(error);
        dispatch({
          type: 'EXPORT_TO_CSV_ERROR'
        });
      }
    },
  handleExportToPDF:
    (downloadUrl, columnHeaders, searchString, expireonUser) => async (dispatch) => {
      try {
        dispatch({
          type: 'EXPORT_TO_PDF_STARTED'
        });
        exportToPDF(downloadUrl, columnHeaders, searchString, expireonUser).then(() => {
          dispatch({
            type: 'EXPORT_TO_PDF_SUCCESS'
          });
        });
      } catch (error) {
        Errors.handle(error);
        dispatch({
          type: 'EXPORT_TO_PDF_ERROR'
        });
      }
    },
  doToggleTooltip: (showTooltip) => async (dispatch) => {
    dispatch({
      type: 'CREDENTIAL_LIST_SHOW_TOOLTIP',
      payload: {
        showTooltip
      }
    });
  },
  doOpenKeyModal: () => async (dispatch) => {
    dispatch({
      type: 'CERTIFICATE_MODAL_OPEN'
    });
  },
  doCloseKeyModal: () => async (dispatch) => {
    dispatch({
      type: 'CERTIFICATE_MODAL_CLOSE'
    });
  },
  doGetCertificatesList:
    (filter, keepPagination = false) =>
    async (dispatch) => {
      try {
        dispatch({
          type: 'CERTIFICATES_LIST_FETCH_STARTED',
          payload: { filter, keepPagination }
        });

        const response = await getCertificates(
          filter.pageSize,
          filter.pageNumber,
          filter.sortOrder,
          filter.searchString
        );

        dispatch({
          type: 'CERTIFICATES_LIST_FETCH_SUCCESS',
          payload: {
            certificatesList: response.tenantEncryptionCertificates,
            certificatesCount: response.totalCount
          }
        });
      } catch (error) {
        Errors.handle(error);
        dispatch({
          type: 'CERTIFICATES_LIST_FETCH_ERROR'
        });
      }
    },
  handleDownloadCertificate: (downloadUrl) => async (dispatch) => {
    try {
      dispatch({
        type: 'CERTIFICATE_DOWNLOAD_STARTED'
      });

      downloadCertificate(downloadUrl).then(() => {
        dispatch({
          type: 'CERTIFICATE_DOWNLOAD_SUCCESS'
        });
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'CERTIFICATE_DOWNLOAD_ERROR'
      });
    }
  }
};

export default { ...actions, list };
