import React, { Component } from 'react';
import AutocompleteFormItem from 'components/FormItems/items/AutocompleteFormItem';
import { connect } from 'react-redux';
import config from '../../../../config';
import { Client } from '../../../../legal-api';
import { store } from '../../../../index';
async function listIndexedAutocomplete(query) {
  const loggedUser = store.getState().auth?.currentUser?.userName;
  const response = await new Client(config.externalApi).legalCaseGetIndexedLegalHolds(
    query,
    loggedUser
  );
  const newResponse = Object.entries(response).map((item) => {
    return {
      legalHoldId: item[0],
      legalHoldName: item[1]
    };
  });
  return newResponse;
}

class LegalHoldAutocompleteFormItem extends Component {
  fetchFn = (value, limit) => {
    value = this.props.legalCaseId;
    return listIndexedAutocomplete(value, limit);
  };

  mapper = {
    toAutocomplete(originalValue) {
      if (!originalValue) {
        return undefined;
      }

      let value, label;
      value = originalValue.id ? originalValue.id : originalValue.legalHoldId;
      label = originalValue.label ? originalValue.label : originalValue.legalHoldName;

      return {
        key: value,
        value,
        label
      };
    },

    toValue(originalValue) {
      if (!originalValue) {
        return undefined;
      }

      return {
        id: originalValue.value,
        label: originalValue.label
      };
    }
  };

  render() {
    const { form, ...rest } = this.props;

    return (
      <React.Fragment>
        <AutocompleteFormItem
          {...rest}
          fetchFn={this.fetchFn}
          mapper={this.mapper}
          defaultValue={true}
          isDiscarded={this.props.isDiscarded}
        />
      </React.Fragment>
    );
  }
}

const select = (state) => ({
  hasPermissionToCreate: state.LegalHold.hasPermissionToCreate
});

export default connect(select)(LegalHoldAutocompleteFormItem);
