import React, { Component } from 'react';
import LegalHoldView from 'components/CRUD/LegalHold/view/LegalHoldView';
import actions from 'actions/LegalHold/LegalHoldFormActions';
import { connect } from 'react-redux';
import { withRouter } from 'utils/withRouter';
class LegalHoldPage extends Component {
  componentDidMount() {
    const { dispatch, params, navigate } = this.props;
    const { name: firstName, surName: lastName, email, userName } = this.props.currentUser || {};
    const expireonUser = {
      firstName,
      lastName,
      email,
      userName
    };
    dispatch(actions.doFind(params?.id, expireonUser, navigate));
  }

  render() {
    return (
      <React.Fragment>
        <LegalHoldView
          findLoading={this.props.loading}
          record={this.props.record}
          saveLoading={this.props.saveLoading}
          history={this.props.history}
          dispatch={this.props.dispatch}
          userIdentities={this.props.userIdentities}
          navigate={this.props.navigate}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(store) {
  return {
    findLoading: store.LegalHold.form.findLoading,
    saveLoading: store.LegalHold.form.saveLoading,
    record: store.LegalHold.form.record,
    currentUser: store.auth.currentUser,
    userIdentities: store.LegalHold.form.userIdentities
  };
}

export default withRouter(connect(mapStateToProps)(LegalHoldPage));
