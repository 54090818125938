import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'utils/withRouter';
import { connect } from 'react-redux';
import {Col, Container, Row } from 'reactstrap';
import logo from '../../../images/expireon-logo.png';
import cloudficientLogo from '../../../images/cloudficientLogo.png';
import remadLogo from '../../../images/remad-logo.png';
import { signinRedirect } from '../../../services/userService';
import { getWelcomeMessage } from 'actions/auth';
import s from './Login.module.scss';
import { Navigate } from 'react-router-dom';

class Login extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  };
  state = {
    welcomeMessage: ''
  };

  constructor(props) {
    super(props);

    this.doLogin = this.doLogin.bind(this);
  }

  componentDidMount() {
    getWelcomeMessage().then((res) => {
      this.setState({
        welcomeMessage: res
      });
    });
   
    this.setState({
      currentYear: new Date().getFullYear()
    });
  }

  doLogin(e) {
    e.preventDefault();
    signinRedirect();
  }

  render() {
    return (
      <>
        {this.props?.currentUser?.isAuthenticated ? (
          <Navigate to='/app/Dashboard' replace />
        ) : (
          <div
            className={[
              s.root,
              `theme-${
                localStorage.getItem('themeColor')
                  ? localStorage.getItem('themeColor').replace('#', '')
                  : '333964'
              }`,
              'light-blue-dashboard'
            ].join(' ')}
          >
            <Container>
              <div className='widget-auth mx-auto'>
                <div
                  style={{ width: '50%', padding: '70px 0' }}
                  className={'d-flex align-items-center flex-column justify-content-center'}
                >
                  <div className={'d-flex justify-content-center'} style={{ marginBottom: 'auto' }}>
                    <img src={logo} width={'50%'} alt={'Expireon'} />
                  </div>
                  <p className={'text-center text-white mt-4'}>powered by</p>
                  <a
                    className='d-block text-center mb-4'
                    href='https://www.cloudficient.com/'
                    target={'_blank'}
                  >
                    <img src={cloudficientLogo} width='50%' alt={'cloudficient logo'} />
                  </a>
                </div>
                  <div
                    className='bg-widget h-100 align-items-center justify-content-center '
                    style={{ margin: 'auto', padding: '130px 30px',width: '50%' }}
                  >
                    <Row className='align-items-center justify-content-center mb-5'>
                      <Col style={{ textAlign: 'center' }}>
                        <span>{this.state.welcomeMessage}</span>
                      </Col>
                    </Row>
                    <Row className='mt-5'>
                      <Col className='d-flex align-items-center flex-column justify-content-center'>
                        <button
                          onClick={(e) => this.doLogin(e)}
                          type='button'
                          style={{ width: '150px' }}
                          className='btn fw-semi-bold d-flex justify-content-center'
                        >
                          Go to Log-in
                        </button>
                      </Col>
                    </Row>
                  </div>
              </div>
            </Container>
            <footer className='auth-footer'>
             <p> &copy; 2021-{this.state.currentYear} by cloudficient Software AG, Switzerland</p>
             <a href='https://www.cloudficient.com/technology/remad-platform' target={'_blank'}>
              <img
                src={remadLogo}
                alt={'remad logo'}
                style={{
                  position: 'absolute',
                  right: 20,
                  bottom: 10,
                  width: 200
                }}
              />
            </a>
            </footer>
          </div>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    currentUser: state.auth.currentUser,
  };
}

export default withRouter(connect(mapStateToProps)(Login));
