import React, { Component } from 'react';
import LegalHoldSlackForm from './LegalHoldSlackForm';
import actions from 'actions/LegalHold/LegalHoldFormActions';
import { connect } from 'react-redux';
import { withRouter } from 'utils/withRouter';

class LegalHoldSlackFormPage extends Component {
  componentDidMount() {
    const { dispatch, navigate } = this.props;
    const isChat = true;
    dispatch(actions.doFindContainers(navigate, isChat));
    dispatch(actions.doFindLegalHoldNames(this.props?.legalCaseId));
  }

  doSubmit = (data) => {
    const { dispatch, navigate, legalCaseId, currentUser } = this.props;
    const { name: firstName, surName: lastName, email, userName } = currentUser || {};
    let formData = { ...data };
    dispatch(
      actions.doCreateChatLH({
        ...formData,
        legalCaseId,
        expireonUser: {
          firstName,
          lastName,
          email,
          userName
        }
      })
    ).then(async () => {
      navigate(`/app/rsmfViewer?legalHoldId=${this.props?.chatLegalHoldId}`);
      dispatch(actions.doCloseChatLH());
    });
  };

  render() {
    return (
      <>
        <LegalHoldSlackForm
          currentUser={this.props.currentUser}
          containers={this.props.containers}
          onSubmit={this.doSubmit}
          history={this.props.history}
          legalHoldNames={this.props.legalHoldNames}
          dispatch={this.props.dispatch}
          currentTheme={this.props.currentTheme}
          legalCaseId={this.props.legalCaseId}
          openFormModal={this.props.openFormModal}
          currentStep={this.props.currentStep}
          pageNumber={this.props.pageNumber}
          pageSize={this.props.pageSize}
          selectedCustodiansList={this.props.selectedCustodiansList}
          selectedExternalCustodians={this.props.selectedExternalCustodians}
          allCustodiansList={this.props.allCustodiansList}
          userIdentities={this.props.userIdentities}
          openInternalSelectionModal={this.props.openInternalSelectionModal}
          temporaryCustodiansCount={this.props.temporaryCustodiansCount}
          createdLegalHoldId={this.props.createdLegalHoldId}
          channelsList={this.props.channelsList}
          navigate={this.props.navigate}
          chatLegalHold={this.props.chatLegalHold}
          discardChatLH={this.props.discardChatLH}
        />
      </>
    );
  }
}

function mapStateToProps(store) {
  return {
    containers: store.LegalHold.form.containers,
    currentUser: store.auth.currentUser,
    rows: store.LegalHold.list.rows,
    legalHoldNames: store.LegalHold.form.legalHoldNames,
    currentTheme: store.layout.currentTheme,
    openFormModal: store.LegalHold.form.openFormModal,
    currentStep: store.LegalHold.form.currentStep,
    selectedCustodiansList: store.LegalHold.form.selectedCustodiansList,
    selectedExternalCustodians: store.LegalHold.form.selectedExternalCustodians,
    allCustodiansList: store.LegalHold.form.allCustodiansList,
    userIdentities: store.LegalHold.form.userIdentities,
    openInternalSelectionModal: store.LegalHold.form.openInternalSelectionModal,
    temporaryCustodiansCount: store.LegalHold.form.temporaryCustodiansCount,
    createdLegalHoldId: store.LegalHold.form.legalHoldId,
    channelsList: store.LegalHold.form.channelsList,
    chatLegalHoldId: store.LegalHold.form.chatLegalHoldId,
    chatLegalHold: store.LegalHold.form.chatLegalHold,
    discardChatLH: store.LegalHold.form.discardChatLH
  };
}

export default withRouter(connect(mapStateToProps)(LegalHoldSlackFormPage));
