import {
  SIDEBAR_TYPE_TOGGLE,
  GET_THEME_COLOR_SUCCESS,
  SET_THEME_COLOR_SUCCESS
} from '../actions/layout';
import config from '../config';

export const DashboardThemes = {
  LIGHT: 'light',
  DARK: 'dark'
};

export const SidebarTypes = {
  SOLID: 'solid',
  TRANSPARENT: 'transparent'
};

export const NavbarTypes = {
  STATIC: 'static',
  FLOATING: 'floating'
};

export const LayoutComponents = {
  NAVBAR: 'navbar',
  SIDEBAR: 'sidebar'
};

Object.freeze(DashboardThemes);
Object.freeze(SidebarTypes);
Object.freeze(NavbarTypes);
Object.freeze(LayoutComponents);

const defaultState = {
  dashboardTheme: DashboardThemes.DARK,
  sidebarColor: '#ffffff',
  navbarColor: config.app.themeColors.light,
  navbarType: NavbarTypes.STATIC,
  sidebarType: SidebarTypes.SOLID
};

export default function layoutReducer(state = defaultState, action) {
  switch (action.type) {
    case GET_THEME_COLOR_SUCCESS:
      return {
        ...state,
        currentTheme: action.payload?.currentTheme
      };
    case SET_THEME_COLOR_SUCCESS:
      return {
        ...state,
        currentTheme: action.payload?.currentTheme
      };
    case SIDEBAR_TYPE_TOGGLE:
      return {
        ...state,
        sidebarType: action.payload
      };
    default:
      return state;
  }
}
