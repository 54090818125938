const ExportJobFields = {
  name: {
    type: 'string',
    label: 'Name*',
    required: true
  },

  exportPolicyId: {
    type: 'relation_one',
    label: 'Export Policy*',
    required: true
  },
  legalHoldIds: { type: 'relation_one', label: 'Legal Holds' },
  contentSearchIds: { type: 'relation_one', label: 'Content Searches' }
};

export default ExportJobFields;
