import React, { Component } from 'react';
import ClosedListTable from './ClosedListTable';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { withRouter } from 'utils/withRouter';
class ClosedPage extends Component {
  render() {
    return (
      <React.Fragment>
        {this.props.permissions['LegalUI.CaseManager'] ? (
          <ClosedListTable navigate={this.props.navigate} />
        ) : (
          <Navigate to='/no-access' replace />
        )}
      </React.Fragment>
    );
  }
}
function mapStateToProps(store) {
  return {
    permissions: store.auth.permissions
  };
}
export default withRouter(connect(mapStateToProps)(ClosedPage));
