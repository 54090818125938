import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'utils/withRouter';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Hammer from 'rc-hammerjs';
import Header from '../Header';
import Sidebar from '../Sidebar';
import { openSidebar, closeSidebar } from '../../actions/navigation';
import s from './Layout.module.scss';
import layoutActions from 'actions/layout';
import 'styles/themeStyles.scss';
import routesConfig from './routesConfig';

class Layout extends React.Component {
  static propTypes = {
    sidebarStatic: PropTypes.bool,
    sidebarOpened: PropTypes.bool,
    dispatch: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.handleSwipe = this.handleSwipe.bind(this);
  }

  isFirefox = navigator?.userAgent?.toLowerCase()?.indexOf('firefox') > -1;

  handleSwipe(e) {
    if ('ontouchstart' in window) {
      if (e.direction === 4 && !this.state.chatOpen) {
        this.props.dispatch(openSidebar());
        return;
      }

      if (e.direction === 2 && this.props.sidebarOpened) {
        this.props.dispatch(closeSidebar());
        return;
      }

      this.setState({ chatOpen: e.direction === 2 });
    }
  }

  componentDidMount() {
    this.props.dispatch(layoutActions.doGetThemeColor(this.props?.currentUser?.userName));
  }

  render() {
    return (
      <div
        className={[
          s.root,
          `theme-${this.props.currentTheme ? this.props.currentTheme.replace('#', '') : '333964'}`,
          'light-blue-dashboard',
          'sidebar-' + this.props.sidebarPosition,
          'sidebar-' + this.props.sidebarVisibility,
          `${this.isFirefox ? 'firefox-layout-container' : ''}`
        ].join(' ')}
      >
        <div
          className={
            this.props?.currentUser?.isAuthenticated ? `${s.wrap} ${s.sidebarOpen}` : `${s.wrap}`
          }
        >
          <Header />
          <Sidebar />
          {/* <Hammer onSwipe={this.handleSwipe}> */}
          <TransitionGroup>
            <CSSTransition timeout={200}>
              <div className={s.mainView}>{routesConfig(this.props)}</div>
            </CSSTransition>
          </TransitionGroup>
          {/* </Hammer> */}
        </div>
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
    sidebarPosition: store.navigation.sidebarPosition,
    sidebarVisibility: store.navigation.sidebarVisibility,
    permissions: store.auth.permissions,
    themeColor: store.layout.themeColor,
    currentUser: store.auth.currentUser,
    currentTheme: store.layout.currentTheme
  };
}

export default withRouter(connect(mapStateToProps)(Layout));
