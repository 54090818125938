import React from 'react';
import CredentialListTable from '../../components/CRUD/Credentials/list/CredentialListTable';
import actions from 'actions/credentials/CredentialListActions';
import { connect } from 'react-redux';
import CertificateListPage from 'components/CRUD/Credentials/certificate/list/CertificateListPage';

function CredentialListPage(props) {
  const handleTooltipToogle = async () => {
    const { dispatch } = props;
    if (props.showTooltip) {
      await dispatch(actions.doToggleTooltip(false));
    }
  };

  return (
    <div style={{ width: '100%', minHeight: '100vh' }} onClick={handleTooltipToogle}>
      {props?.permissions['LegalUI.Credential'] ? (
        <div style={{ marginBottom: '50px' }}>
          <CredentialListTable />
        </div>
      ) : null}
      {props?.permissions['LegalUI.Credential.KeyManagement'] ? (
        <div>
          <CertificateListPage />
        </div>
      ) : null}
    </div>
  );
}

function mapStateToProps(store) {
  return {
    showTooltip: store.credentials.list.showTooltip,
    permissions: store.auth.permissions
  };
}
export default connect(mapStateToProps)(CredentialListPage);
