import config from 'config';
import { defaultOptions } from './userService';

async function getTheme(loggedUser) {
  const response = await fetch(
    `${config.externalApi}/api/Widget/GetSelectedTheme?expireonUser=${loggedUser}`,
    {
      ...defaultOptions,
      method: 'GET'
    }
  );

  if (response.status == 204) {
    return {};
  }
  return response.json();
}

async function setTheme(selectedTheme, loggedUser) {
  const { name: firstName, surName: lastName, email, userName } = loggedUser || {};
  const expireonUser = {
    firstName,
    lastName,
    email,
    userName
  };
  const data = {
    settingValue: selectedTheme,
    expireonUser
  };

  await fetch(`${config.externalApi}/api/Widget/AddOrUpdateTheme`, {
    ...defaultOptions,
    method: 'POST',
    body: JSON.stringify(data)
  });
}
export { getTheme, setTheme };
