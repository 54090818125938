import {
  AUTH_INIT_SUCCESS,
  AUTH_INIT_ERROR,
  STORE_USER_ERROR,
  STORE_USER,
} from 'actions/auth';

export default function auth(
  state = {
    currentUser: null,
    user: null,
    loadingInit: false,
    permissions: null,
  },
  { type, payload }
) {
  switch (type) {
    case AUTH_INIT_SUCCESS:
      return Object.assign({}, state, {
        currentUser: payload.currentUser,
        permissions: payload.permissions,
        loadingInit: false
      });
    case AUTH_INIT_ERROR:
      return Object.assign({}, state, {
        currentUser: null,
        loadingInit: false
      });
    case STORE_USER:
      return Object.assign({}, state, {
        loadingInit: false,
        user: payload.user
      });

    case STORE_USER_ERROR:
      return Object.assign({}, state, {
        currentUser: null,
        loadingInit: false,
        user: null
      });
    default:
      return state;
  }
}
