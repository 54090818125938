import React from "react";
import ReactDOM from "react-dom";
import s from "components/Tooltip/Tooltip.module.scss";
import "components/Tooltip/Tooltip.css";

export default function SearchFormTooltip({ message, position }) {
  const tooltipStyle = {
    position: "absolute",
    zIndex: 2000,
    padding: "5px",
    maxWidth: "250px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    top: position.top - 50,
    left: position.left + 20,
    fontSize: "12px",
  };

  return ReactDOM.createPortal(
    <div className={s.tooltip} style={tooltipStyle}>
      <p className="mb-0">{message}</p>
    </div>,
    document.querySelector("#modal-root")
  );
}
