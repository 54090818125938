import React, { Component } from 'react';
import SearchView from 'components/CRUD/Search/itemContent/ItemContentTable';
import actions from 'actions/search/searchFormActions';
import { connect } from 'react-redux';
import { withRouter } from 'utils/withRouter';

class SearchPage extends Component {
  componentDidMount() {
    const { dispatch, navigate } = this.props;
    const { name: firstName, surName: lastName, email, userName } = this.props.currentUser || {};
    const expireonUser = {
      firstName,
      lastName,
      email,
      userName
    };
    dispatch(
      actions.doFindItemContent(this.props.legalCaseId, this.props.itemId, expireonUser, navigate)
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { dispatch, navigate } = this.props;
    const { name: firstName, surName: lastName, email, userName } = this.props.currentUser || {};
    const expireonUser = {
      firstName,
      lastName,
      email,
      userName
    };
    if (prevProps.itemId !== this.props.itemId) {
      dispatch(
        actions.doFindItemContent(this.props.legalCaseId, this.props.itemId, expireonUser, navigate)
      );
    }
  }

  render() {
    return (
      <React.Fragment>
        <SearchView
          findLoading={this.props.findLoadingItemContent}
          record={this.props.record}
          itemId={this.props.itemId}
          contentSearchId={this.props.contentSearchId}
          dispatch={this.props.dispatch}
          isExcluded={this.props.isExcluded}
          itemIcon={this.props.itemIcon}
          legalCaseId={this.props.legalCaseId}
          infoTags={this.props.infoTags}
          tagGroups={this.props.tagGroups}
          navigate={this.props.navigate}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(store) {
  return {
    findLoadingItemContent: store.search.form.findLoadingItemContent,
    record: store.search.form.itemContentRecord,
    itemIcon: store.search.form.addIconToItems,
    currentUser: store.auth.currentUser
  };
}

export default withRouter(connect(mapStateToProps)(SearchPage));
