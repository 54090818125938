import React, { Component } from 'react';
import styles from './Reports.module.scss';

export default class EmailPolicyTooltip extends Component {
  state = {
    subject: '',
    recipients: []
  };

  getCardDetails() {
    const selectedItem = this.props.data?.find((item) => item.id === this.props?.id);
    this.setState({
      subject: selectedItem?.subject,
      recipients: selectedItem?.recipients
    });
  }

  componentDidMount() {
    if (this.props) {
      this.getCardDetails();
    }
  }

  render() {
    return (
      <>
        <div className={styles.emailPolicyTooltip}>
          <div className={styles.header}>Details</div>
          <div>
            <span className='fw-bold ms-2'>Subject:</span>
            <span className={styles.subject}>{this.state.subject}</span>
          </div>

          <div className='fw-bold ms-2'>Recipients:</div>
          <div className={styles.recipients}>
            {this.state.recipients?.map((item, index) => (
              <div key={index}>{item}</div>
            ))}
          </div>
        </div>
      </>
    );
  }
}
