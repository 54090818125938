import moment from 'moment';
import React from 'react';
import { truncate } from 'lodash';
import { FormattingService } from '../../../../utils/sizeFormatting';

function imageFormatter(cell) {
  const imageUrl = cell && cell.length ? cell[0].publicUrl : undefined;
  return (
    <span>
      {imageUrl ? (
        <img width='60' height='60' className='rounded-circle' src={imageUrl} alt='avatar' />
      ) : null}
    </span>
  );
}

function booleanFormatter(cell) {
  return cell ? 'Yes' : 'No';
}

function formatDate(date, format) {
  const formattedDate = moment.utc(date).local().format(format);
  return date ? formattedDate : null;
}

function dateTimeFormatter(cell) {
  return formatDate(cell, 'YYYY-MM-DD HH:mm');
}

function timeFormatter(cell) {
  return formatDate(cell, 'ddd MMM DD YYYY HH:mm');
}

function dateTimeToStringFormatter(cell) {
  return formatDate(cell, 'ddd MMM DD YYYY');
}

function filesFormatter(cell) {
  return (
    <div>
      {cell &&
        cell.map((value) => {
          return (
            <div key={value.id}>
              <i className='la la-link text-muted me-2'></i>
              <a href={value.publicUrl} target='_blank' rel='noopener noreferrer' download>
                {truncate(value.name)}
              </a>
            </div>
          );
        })}
    </div>
  );
}

function listFormatter(cell) {
  if (!cell) return null;

  return (
    <div>
      {cell &&
        cell.length &&
        cell.map((value) => {
          return (
            <div key={value.id}>
              <a href={value.id}>{value.legalHoldName}</a>
            </div>
          );
        })}
      {cell && (
        <div key={cell.id}>
          <a href={cell.id}>{cell.legalHoldName}</a>
        </div>
      )}
    </div>
  );
}

function sizeFormatter(cell) {
  const toReadableSizeFormat = new FormattingService();
  return toReadableSizeFormat.numberWithCommas(toReadableSizeFormat.getBytesReadable(cell));
}

export {
  booleanFormatter,
  imageFormatter,
  dateTimeFormatter,
  listFormatter,
  filesFormatter,
  sizeFormatter,
  timeFormatter,
  dateTimeToStringFormatter
};
