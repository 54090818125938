import React, { Component } from 'react';
import { Row, Col, Progress } from 'reactstrap';
import backIcon from 'images/icons/arrow-left.svg';
import Widget from 'components/Widget/Widget';
import { FormattingService } from 'utils/sizeFormatting';
import styles from './ExportViewDetails.module.scss';
import * as dataFormat from 'components/CRUD/LegalHold/list/LegalHoldDataFormatters';

export default class ExportViewDetails extends Component {
  state = {
    exportJob: {}
  };

  componentDidUpdate(prevProps) {
    if (prevProps.exportJob !== this.props.exportJob) {
      this.setState({
        exportJob: this.props?.exportJob
      });
    }
  }

  exportProgressFormatter(item) {
    if (!item) {
      return null;
    }
    let value = 0;
    if (item?.exportedItems > 0) {
      value = Math.floor((item?.exportedItems / item?.itemsRoutedForExport) * 100);
    }
    return (
      <Progress
        animated={value < 100}
        value={value}
        style={{
          position: 'relative',
          width: '150px',
          marginBottom: '0px',
          height: '22px'
        }}
      >
        <span
          style={{
            left: 'calc(100% / 2 - 10px)',
            position: 'absolute'
          }}
        >
          {value} %
        </span>
      </Progress>
    );
  }

  render() {
    const toReadableSizeFormat = new FormattingService();
    const { exportJob } = this.state;
    return (
      <div>
        <Row>
          <Col sm={12}>
            <div className={'d-flex mb-4 align-items-center'}>
              <button
                className={'btn bg-transparent me-2 ps-0'}
                onClick={() => this.props.navigate(-1)}
              >
                <img src={backIcon} alt={'arrow-back'} />
              </button>
              <h3
                style={{
                  whiteSpace: 'pre-wrap',
                  overflowWrap: 'break-word',
                  wordBreak: 'break-all'
                }}
                className={'first-headline-text me-4 mb-0'}
              >
                {exportJob?.name}
              </h3>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Widget style={{ height: 420, marginRight: '3%' }}>
              <h4 className={`${styles.widgetHeader} ms-2 first-headline-text`}>Export settings</h4>
              <div className='d-flex justify-content-between w-100'>
                <h5 className='mb-3 w-50 d-flex'>
                  <span className='ms-2 second-headline-text me-2'>Name:</span>
                  <span className={`${styles.exportName} first-subtitle-text`}>
                    {exportJob?.name}
                  </span>
                </h5>
                <p className='w-50'>
                  <h5 className='mb-3'>
                    <span className='second-headline-text'>Created:</span>
                    <span className='ms-2 first-subtitle-text'>
                      {dataFormat.dateTimeFormatter(exportJob?.created)}
                    </span>
                  </h5>
                  <h5 className='mb-3'>
                    <span className='second-headline-text'>by:</span>
                    <span className='ms-2 first-subtitle-text'>
                      {exportJob?.expireonUser?.firstName} {exportJob?.expireonUser?.lastName}
                    </span>
                  </h5>
                </p>
              </div>
              <h5 className='mb-4'>
                <span className='ms-2 second-headline-text'>Policy:</span>
                <span className='ms-2 me-1 first-subtitle-text'>{exportJob?.exportPolicyName}</span>
                {exportJob?.deduplicationType === 0 ? '(No Dedupe)' : '(Dedupe)'}
              </h5>
              <div className={styles.policyDetails}>
                <h5 className='mb-3'>
                  <span className='second-headline-text ms-2'>Format:</span>
                  <span className='ms-2 first-subtitle-text'>{exportJob?.targetFormat}</span>
                </h5>
                <h5 className='mb-3'>
                  <span className='second-headline-text ms-2'>Target:</span>
                  <span className='ms-2 first-subtitle-text'>{exportJob?.target}</span>
                </h5>
                <h5 className='mb-3'>
                  <span className='second-headline-text ms-2'>Path:</span>
                  <span className='ms-2 first-subtitle-text'>{exportJob?.outputPath}</span>
                </h5>
                <h5 className='mb-3'>
                  <span className='second-headline-text ms-2'>Dedupe:</span>
                  <span className='ms-2 first-subtitle-text'>{exportJob?.deduplication}</span>
                </h5>
                <h5 className='mb-3'>
                  <span className='second-headline-text ms-2'>Manifest:</span>
                  <span className='ms-2 first-subtitle-text'>
                    {exportJob?.manifest ? 'Yes' : 'No'}
                  </span>
                </h5>
              </div>
            </Widget>
          </Col>
          <Col sm={6}>
            <Widget style={{ height: 420 }}>
              <h4 className={`${styles.widgetHeader} ms-2 first-headline-text`}>Results</h4>
              <div className='d-flex justify-content-between ms-2 me-4 w-100'>
                <h5 className='mb-3 w-50'>
                  <span className='second-headline-text'>Total count:</span>
                  {exportJob?.totalItems ? (
                    <span className='ms-2 first-subtitle-text'>
                      {toReadableSizeFormat.numberWithCommas(exportJob?.totalItems)} items
                    </span>
                  ) : (
                    <span className='ms-2 first-subtitle-text'>0 items</span>
                  )}
                </h5>
                <p className='w-50'>
                  <h5 className='mb-2'>
                    <span className='second-headline-text'>Expected after Export:</span>
                    {exportJob?.itemsRoutedForExport ? (
                      <span className='ms-2 first-subtitle-text'>
                        {toReadableSizeFormat.numberWithCommas(exportJob?.itemsRoutedForExport)}{' '}
                        items
                      </span>
                    ) : (
                      <span span className='ms-2 first-subtitle-text'>
                        0 items
                      </span>
                    )}
                  </h5>
                  <h5 className='mb-3'>
                    <span className='second-headline-text'>Dedupe factor:</span>
                    <span className='ms-2 first-subtitle-text'>
                      {exportJob?.dedupeFactorPerHour}
                    </span>
                  </h5>
                </p>
              </div>
              <p className='d-flex justify-content-between ms-2 w-100 mb-1'>
                <h5 className='mb-3 w-50'>
                  <span className='second-headline-text'>Exported:</span>
                  {exportJob?.exportedItems ? (
                    <span className='ms-2 first-subtitle-text'>
                      {toReadableSizeFormat.numberWithCommas(exportJob?.exportedItems)} items{' '}
                      {`(${exportJob?.exportedItemsPercentage})`}
                    </span>
                  ) : (
                    <span className='ms-2 first-subtitle-text'>0 items (0%)</span>
                  )}
                </h5>
                <h5 className='mb-3 w-50'>
                  <span className='second-headline-text me-2'>Failed:</span>
                  {exportJob?.failedItems ? (
                    <span className='first-subtitle-text'>
                      {toReadableSizeFormat.numberWithCommas(exportJob?.failedItems)} items{' '}
                      {`(${exportJob?.failedItemsPercentage})`}
                    </span>
                  ) : (
                    <span className='first-subtitle-text'>0 items (0%)</span>
                  )}
                </h5>
              </p>
              <p className='mb-1'>
                <h5 className='mb-3'>
                  <span className='second-headline-text ms-2'>Size:</span>
                  {exportJob?.totalSizeInBytes ? (
                    <span className='ms-2 first-subtitle-text'>
                      {dataFormat.sizeFormatter(exportJob?.totalSizeInBytes)}
                    </span>
                  ) : (
                    <span className='ms-2 first-subtitle-text'>0.00 B</span>
                  )}
                </h5>
              </p>
              <p className='d-flex justify-content-between ms-2 me-4 w-100'>
                <h5 className='mb-3 w-50'>
                  <span className='second-headline-text'>Duration:</span>
                  <span className='ms-2 first-subtitle-text'>{exportJob?.duration}</span>
                </h5>
                <h5 className='mb-3 w-50'>
                  <span className='second-headline-text'>Throughput:</span>
                  {exportJob?.throughputPerHour ? (
                    <span className='ms-2 first-subtitle-text'>
                      {dataFormat.sizeFormatter(exportJob?.throughputPerHour)}/h
                    </span>
                  ) : (
                    <span className='ms-2 first-subtitle-text'>0.00 B/h</span>
                  )}
                </h5>
              </p>
            </Widget>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Widget style={{ height: 420, marginTop: '3%', marginRight: '3%' }}>
              <h4 className={`${styles.widgetHeader} ms-2 first-headline-text`}>Progress</h4>
              <p className='d-flex justify-content-between ms-2 w-100'>
                <h5 className='mb-3 w-50'>
                  <span className='second-headline-text'>Export started:</span>
                  <span className='ms-2 first-subtitle-text'>
                    {' '}
                    {dataFormat.dateTimeFormatter(exportJob?.created)}
                  </span>
                </h5>
                <h5 className='mb-3 w-50'>
                  <span className='second-headline-text'>Export finished:</span>
                  <span className='ms-2 first-subtitle-text'>
                    {dataFormat.dateTimeFormatter(exportJob?.exportFinishedTimestamp)}
                  </span>
                </h5>
              </p>
              <p className='d-flex justify-content-between ms-2 w-100 mb-0'>
                <p className={`${styles.container} w-50 mb-0`}>
                  <h5 className={`${styles.progressBarHeader} second-headline-text`}>Progress:</h5>
                  <p className={styles.progressBar}>
                    {this.exportProgressFormatter(this.props?.exportJob)}
                  </p>
                </p>
                <h5 className='mb-3 w-50'>
                  <span className='second-headline-text'>Space remaining:</span>
                  <span className='ms-2 first-subtitle-text'>
                    {exportJob?.freeSpaceinBytes ? (
                      <span className='ms-2'>
                        {dataFormat.sizeFormatter(exportJob?.freeSpaceinBytes)}
                      </span>
                    ) : (
                      <span className='ms-2 first-subtitle-text'>0.00 B</span>
                    )}
                  </span>
                </h5>
              </p>
              <h5 className='mb-3'>
                <span className='second-headline-text ms-2'>Status:</span>
                <span className='ms-2 first-subtitle-text'>{exportJob?.exportStatus}</span>
              </h5>
              <h5 className='mb-3'>
                <span className='second-headline-text ms-2'>Last message:</span>
                <span className='ms-2 first-subtitle-text'>{exportJob?.statusMessage}</span>
              </h5>
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }
}
