import React, { Component } from 'react';
import AuditLogsTable from '../AuditLogs/AuditLogsTable';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

class ActivityPage extends Component {
  render() {
    return (
      <React.Fragment>
        {this.props.permissions['LegalUI.Activity'] ? (
          <AuditLogsTable />
        ) : (
          <Navigate to='/no-access' replace />
        )}
      </React.Fragment>
    );
  }
}
function mapStateToProps(store) {
  return {
    permissions: store.auth.permissions
  };
}
export default connect(mapStateToProps)(ActivityPage);
