import React from 'react';

export const MESSAGES = {
  LIMIT_REACHED:
    'Item Preview limit reached! Please use filter criteria to drill down on the content.'
};

export const emptyDataMessage = () => {
  return (
    <p
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        minWidth: '200px'
      }}
    >
      No data available
    </p>
  );
};

export const credentialTypeEnum = {
  CloudficientObjectStorage: '0',
  S3DataEncryption: '2',
  AIProvider: '3',
  SlackAttachmentStorage: '4'
};

export const customCloseBtn = (closeModal) => (
  <button className='close' onClick={closeModal} type='button'>
    &times;
  </button>
);
