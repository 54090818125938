import React, { Component } from 'react';
import actions from 'actions/LegalCase/LegalCaseFormActions';
import { connect } from 'react-redux';
import ClosedLegalCaseView from './ClosedLegalCaseView';
import { withRouter } from 'utils/withRouter';

class LegalCasePage extends Component {
  componentDidMount() {
    const { dispatch, params, navigate } = this.props;
    dispatch(actions.doFind(params?.id, navigate));
    dispatch(actions.doGetStats(params?.id, navigate));
  }

  render() {
    return (
      <React.Fragment>
        <ClosedLegalCaseView
          loading={this.props.loading}
          record={this.props.record}
          history={this.props.history}
          stats={this.props.stats}
          statsLoading={this.props.statsLoading}
          modalOpen={this.props.modalOpen}
          dispatch={this.props.dispatch}
          openViewerModal={this.props.openViewerModal}
          permissions={this.props.permissions}
          openEditModal={this.props.openEditModal}
          currentTheme={this.props.currentTheme}
          navigate={this.props.navigate}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(store) {
  return {
    loading: store.LegalCase.form.findLoading,
    record: store.LegalCase.form.record,
    stats: store.LegalCase.form.stats,
    statsLoading: store.LegalCase.form.findStatsLoading,
    modalOpen: store.Export.form.modalOpen,
    openViewerModal: store.viewers.form.openModal,
    permissions: store.auth.permissions,
    openEditModal: store.LegalCase.list.modalOpen,
    currentTheme: store.layout.currentTheme
  };
}

export default withRouter(connect(mapStateToProps)(LegalCasePage));
