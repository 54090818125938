const initialData = {
  rows: [],
  loading: false,
  count: 0,
  credentialTypes: {},
  showTooltip: false,
  keyModal: false,
  certificatesList: [],
  certificatesCount: 0
};

export default (state = initialData, { type, payload }) => {
  if (type === 'CREDENTIAL_LIST_FETCH_STARTED') {
    return {
      ...state,
      loading: true
    };
  }

  if (type === 'CREDENTIAL_LIST_FETCH_SUCCESS') {
    return {
      ...state,
      loading: false,
      rows: payload.rows,
      count: payload.count
    };
  }

  if (type === 'CREDENTIAL_LIST_FETCH_ERROR') {
    return {
      ...state,
      loading: false,
      rows: []
    };
  }

  if (type === 'CREDENTIAL_LIST_OPEN_CONFIRM') {
    return {
      ...state,
      loading: false,
      modalOpen: true,
      idToDelete: payload.id
    };
  }

  if (type === 'CREDENTIAL_LIST_CLOSE_CONFIRM') {
    return {
      ...state,
      loading: false,
      modalOpen: false
    };
  }

  if (type === 'CREDENTIAL_TYPES_LIST_FETCH_SUCCESS') {
    return {
      ...state,
      credentialTypes: payload.credentialTypes
    };
  }

  if (type === 'CREDENTIAL_TYPES_LIST_FETCH_ERROR') {
    return {
      ...state,
      credentialTypes: {}
    };
  }

  if (type === 'CREDENTIAL_LIST_SHOW_TOOLTIP') {
    return {
      ...state,
      showTooltip: payload.showTooltip
    };
  }
  if (type === 'CERTIFICATE_MODAL_OPEN') {
    return {
      ...state,
      keyModal: true
    };
  }
  if (type === 'CERTIFICATE_MODAL_CLOSE') {
    return {
      ...state,
      keyModal: false
    };
  }

  if (type === 'CERTIFICATES_LIST_FETCH_SUCCESS') {
    return {
      ...state,
      certificatesList: payload.certificatesList,
      certificatesCount: payload.certificatesCount
    };
  }

  if (type === 'CERTIFICATES_LIST_FETCH_ERROR') {
    return {
      ...state,
      certificatesList: [],
      certificatesCount: 0
    };
  }

  return state;
};
