import React, { Component } from 'react';
import SearchDetailsView from './SearchDetailsView';
import actions from 'actions/search/searchFormActions';
import { connect } from 'react-redux';
import { withRouter } from 'utils/withRouter';
class SearchDetailsPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    const { dispatch, params } = this.props;
    const { name: firstName, surName: lastName, email, userName } = this.props?.currentUser || {};
    const expireonUser = {
      firstName,
      lastName,
      email,
      userName
    };
    const contentSearchId = params?.id;
    dispatch(actions.doFetchContentSearch(contentSearchId, expireonUser));
  }
  render() {
    return (
      <div>
        <SearchDetailsView
          history={this.props.history}
          navigate={this.props.navigate}
          contentSearch={this.props.contentSearch}
        />
      </div>
    );
  }
}
function mapStateToProps(store) {
  return {
    contentSearch: store.search.form.contentSearch,
    currentUser: store.auth.currentUser
  };
}
export default withRouter(connect(mapStateToProps)(SearchDetailsPage));
