import React, { Component } from 'react';
import { connect } from 'react-redux';
import CertificateForm from './CertificateForm';
import actions from 'actions/credentials/CredentialFormActions';

class CertificateFormPage extends Component {
  doSubmit = (data) => {
    const { dispatch, pageNumber, pageSize, searchString, sortOrder } = this.props;
    const { name: firstName, surName: lastName, email, userName } = this.props.currentUser || {};
    const formData = {
      ...data,
      expireonUser: {
        firstName,
        lastName,
        email,
        userName
      }
    };
    dispatch(actions.doCreateCertificate(formData, pageNumber, pageSize, searchString, sortOrder));
  };

  render() {
    return (
      <div>
        <CertificateForm
          onSubmit={this.doSubmit}
          keyModal={this.props.keyModal}
          saveLoading={this.props.saveLoading}
        />
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    keyModal: store.credentials.list.keyModal,
    saveLoading: store.credentials.form.saveLoading,
    currentUser: store.auth.currentUser
  };
}
export default connect(mapStateToProps)(CertificateFormPage);
