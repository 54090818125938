import React, { Component } from 'react';
import Widget from 'components/Widget/Widget';
import { connect } from 'react-redux';
import actions from 'actions/Dashboard/DashboardStatsActions';
import { Col, Row } from 'reactstrap';
import { FormattingService } from 'utils/sizeFormatting';
import ApexChart from 'react-apexcharts';
import styles from './Dashboard.module.scss';

class DashboardStats extends Component {
  state = {
    casesStatusLabels: [],
    casesStatusSeries: [],
    exportJobsLabels: [],
    exportJobsSeries: []
  };

  getLegalCasesStatusData = () => {
    const labels = [];
    const series = [];
    this.props?.legalCasesPerStatus?.distributionResult &&
      Object.entries(this.props?.legalCasesPerStatus?.distributionResult).map(([key, value]) => {
        labels.push(key);
        series.push(value);
      });
    this.setState({
      casesStatusLabels: labels,
      casesStatusSeries: series
    });
  };

  getExportJobsStatusData = () => {
    const labels = [];
    const series = [];
    this.props?.exportJobsByIsExported?.distributionResult &&
      Object.entries(this.props?.exportJobsByIsExported?.distributionResult).map(([key, value]) => {
        labels.push(key);
        series.push(value);
      });
    this.setState({
      exportJobsLabels: labels,
      exportJobsSeries: series
    });
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(actions.doGetContentSearchesCount());
    dispatch(actions.doGetExportJobsCount());
    dispatch(actions.doGetLegalCasesCount());
    dispatch(actions.doGetLegalHoldsCount());
    dispatch(actions.doGetExportJobsByStatus()).then(() => {
      this.getExportJobsStatusData();
    });
    dispatch(actions.doGetLegalCasesPerStatus()).then(() => {
      this.getLegalCasesStatusData();
    });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.legalCasesPerStatus !== this.props.legalCasesPerStatus) {
      this.getLegalCasesStatusData();
      this.getExportJobsStatusData();
    }
  }
  render() {
    const toReadableSizeFormat = new FormattingService();
    return (
      <div style={{ margin: '20px' }}>
        <Row sm={12}>
          <Col sm={6} className={styles.section}>
            <Widget style={{ minHeight: '100px' }}>
              <h4 className={'second-headline-text text-center mb-10'}>Total Legal Cases</h4>
              <div className={'text-center'}>
                <h3 className={'first-subtitle-text'}>{this.props.legalCasesCount}</h3>
              </div>
            </Widget>
            <Widget style={{ minHeight: '100px' }}>
              <h4 className={'second-headline-text text-center mb-10'}>Total Legal Holds</h4>
              <div className={'text-center'}>
                <h3 className={'first-subtitle-text d-inline-block mb-0'}>
                  {toReadableSizeFormat.numberWithCommas(this.props.legalHoldsCount || 0)}
                </h3>
              </div>
            </Widget>
            <Widget style={{ minHeight: '100px', marginBottom: '15px' }}>
              <h4 className={'second-headline-text text-center mb-10'}>Total Content Searches</h4>
              <div className={'text-center mt-3'}>
                <h3 className={'first-subtitle-text d-inline-block mb-0'}>
                  {toReadableSizeFormat.numberWithCommas(this.props.contentSearchesCount || 0)}
                </h3>
              </div>
            </Widget>
            <Widget style={{ minHeight: '100px', marginBottom: '0px' }}>
              <h4 className={'second-headline-text text-center mb-10'}>Total Export Jobs</h4>
              <div className={'text-center mt-3'}>
                <h3 className={'first-subtitle-text d-inline-block mb-0'}>
                  {toReadableSizeFormat.numberWithCommas(this.props.exportJobsCount || 0)}
                </h3>
              </div>
            </Widget>
          </Col>
          <Col sm={6} className={styles.section}>
            <Widget className={styles.legalCaseStatusChart}>
              <h4 className={'second-headline-text'}> Legal Cases Status</h4>
              <div className={'first-caption-text'}>
                <ApexChart
                  type='donut'
                  style={{ color: 'white' }}
                  series={this.state.casesStatusSeries || []}
                  height={150}
                  options={{
                    dataLabels: {
                      enabled: false
                    },
                    plotOptions: {
                      pie: {
                        donut: {
                          size: '75%',
                          labels: {
                            show: false
                          }
                        }
                      }
                    },
                    labels: this.state.casesStatusLabels || [],
                    legend: {
                      color: 'white'
                    },
                    stroke: {
                      width: 0
                    },
                    chart: {
                      foreColor: '#fff',
                      fontFamily: 'Poppins'
                    }
                  }}
                />
              </div>
            </Widget>

            <Widget className={styles.exportStatusChart}>
              <h4 className='second-headline-text'> Export Status</h4>
              <div className={'first-caption-text mb-0'}>
                <ApexChart
                  type='donut'
                  series={this.state.exportJobsSeries || []}
                  height={150}
                  options={{
                    dataLabels: {
                      enabled: false
                    },
                    plotOptions: {
                      pie: {
                        donut: {
                          size: '75%',
                          labels: {
                            show: false
                          }
                        }
                      }
                    },
                    labels: this.state.exportJobsLabels,
                    stroke: {
                      width: 0
                    },
                    chart: {
                      foreColor: '#fff',
                      fontFamily: 'Poppins'
                    }
                  }}
                />
              </div>
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    legalCasesCount: store.Dashboard.stats.legalCasesCount,
    legalHoldsCount: store.Dashboard.stats.legalHoldsCount,
    contentSearchesCount: store.Dashboard.stats.contentSearchesCount,
    exportJobsCount: store.Dashboard.stats.exportJobsCount,
    legalCasesPerStatus: store.Dashboard.stats.legalCasesPerStatus,
    exportJobsByIsExported: store.Dashboard.stats.exportJobsByIsExported
  };
}

export default connect(mapStateToProps)(DashboardStats);
