import moment from 'moment';

const PreparedValues = (fields, record={}, isEditing) => {
	const preparedArray = {};
	Object.keys(fields).forEach(field => {
		const type = fields[field].type;
		const value = record[field];
		let preparedValue = '';
		switch(type) {
			case 'datetime':
				// change timezone to UTC +0
				let date;
				if (value) {
					date = new Date(value);
				}
				preparedValue = date
					? new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString()
					: null;
				break;
			case 'date':
			    preparedValue = value
			      ? value.toDateString()
			      : null;
				break;

			case 'user_many':
			case 'relation_many':
		        if (!value || !value.length) {
		          preparedValue = [];
		        }
		        else if (isEditing) {
					preparedValue = value.map((item) => item);
				} else {
					preparedValue = value.map((item) => item.id || item.custodianId);
				}
				break;

			case 'user_one':
			case 'relation_one':
				preparedValue = value ? value.id : null;
				break;
            case 'fuzzyness':
				preparedValue = value ? parseInt(value) : 0;
				break;
			default:
				preparedValue = value;
		}
		preparedArray[field] = preparedValue;
	});
	return preparedArray;
}

export default PreparedValues;
