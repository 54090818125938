import React from "react";
import ReactDOM from "react-dom";
import s from "../../../Tooltip/Tooltip.module.scss";
import "../../../Tooltip/Tooltip.css";
import { CgProfile } from "react-icons/cg";
import { BiPhone } from "react-icons/bi";
import { AiOutlineApartment } from "react-icons/ai";
import { FaRegAddressCard } from "react-icons/fa";
import { MdOutlineMailOutline } from "react-icons/md";
import { RiAccountPinBoxLine } from "react-icons/ri";
import Loader from "components/Loader/Loader";

export default function LegalHoldTooltip({ currentCustodian, position }) {
  let firstLetter = null,
    secondLetter = null;
  if (currentCustodian.displayName) {
    firstLetter = currentCustodian.displayName.split(".")[0][0];
    if (currentCustodian.displayName.split(".").length < 3) {
      secondLetter = null;
    } else {
      secondLetter = currentCustodian.displayName.split(".")[1][0];
    }
  }
  let hasAdditionalData =
    currentCustodian.displayName ||
    currentCustodian.title ||
    currentCustodian.department ||
    currentCustodian.phoneNumber ||
    currentCustodian.samAccountName !== null
      ? true
      : false;
  const initialTooltip = (
    <>
      {currentCustodian.displayName ? (
        <>
          <div style={{ marginLeft: "10px", marginTop: "10px" }}>
            <span
              style={{
                borderRadius: "50%",
                width: 50,
                height: 50,
                backgroundColor: "#3978F6",
                color: "#fff",
                marginRight: "5px",
                marginBottom: "10px",
              }}
              className={
                "d-flex justify-content-center align-items-center fw-bold text-uppercase"
              }
            >
              <h1
                className={"mb-0 fw-bold text-white "}
                style={{ fontSize: "1.2rem" }}
              >
                {firstLetter}
                {secondLetter}
              </h1>
            </span>
            <span style={{ wordBreak: "break-word", whiteSpace: "normal" }}>
              <h6 className={" text-white  fw-bold"}>
                {currentCustodian.displayName}
              </h6>
              <div style={{ fontSize: "10px", textAlign: "center" }}>
                No additional information
              </div>
            </span>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </>
  );

  const extendedTooltip = (
    <>
      {hasAdditionalData ? (
        <>
          <div
            style={{ display: "flex", alignItems: "center", marginTop: "10px" }}
          >
            <span
              style={{
                borderRadius: "50%",
                width: 50,
                height: 50,
                backgroundColor: "#3978F6",
                color: "#fff",
                marginRight: "15px",
                marginLeft: "10px",
              }}
              className={
                "d-flex justify-content-center align-items-center fw-bold text-uppercase"
              }
            >
              <h1
                className={"mb-0 fw-bold text-white "}
                style={{ fontSize: "1.2rem" }}
              >
                {firstLetter}
                {secondLetter}
              </h1>
            </span>
            <span style={{ wordBreak: "break-word", whiteSpace: "normal" }}>
              <h6 className={" text-white  fw-bold "}>
                {currentCustodian.displayName}
              </h6>
            </span>
          </div>
          <p
            style={{
              fontWeight: "bold",
              fontSize: "12px",
              marginTop: "15px",
              marginLeft: "10px",
            }}
          >
            Contact
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: "10px",
              marginLeft: "10px",
              marginBottom: "15px",
            }}
          >
            {currentCustodian?.displayName ? (
              <p>
                <span>
                  <CgProfile className="icon" />
                </span>
                <span className="contact-info">
                  {currentCustodian?.displayName}
                </span>
              </p>
            ) : null}

            {currentCustodian?.title ? (
              <p>
                <span>
                  <FaRegAddressCard className="icon" />
                </span>
                <span className="contact-info">{currentCustodian?.title}</span>
              </p>
            ) : null}

            {currentCustodian?.department ? (
              <p>
                <span>
                  <AiOutlineApartment className="icon" />
                </span>
                <span className="contact-info">
                  {currentCustodian?.department}
                </span>
              </p>
            ) : null}

            {currentCustodian?.phoneNumber ? (
              <p>
                <span>
                  <BiPhone className="icon" />
                </span>
                <span className="contact-info">
                  {currentCustodian?.phoneNumber}
                </span>
              </p>
            ) : null}

            {currentCustodian?.upn ? (
              <p className="d-flex">
                <span>
                  <MdOutlineMailOutline className="icon" />
                </span>
                <span className="contact-info">{currentCustodian?.upn}</span>
              </p>
            ) : null}

            {currentCustodian?.samAccountName ? (
              <p>
                <span>
                  <RiAccountPinBoxLine className="icon" />
                </span>
                <span className="contact-info">
                  {currentCustodian?.samAccountName}
                </span>
              </p>
            ) : null}
          </div>
        </>
      ) : (
        <Loader />
      )}
    </>
  );

  const initialTooltipStyle = {
    position: "absolute",
    zIndex: 2000,
    padding: 16,
    top: position.top + 20,
    left: position.left + 20,
    display: position.top ? "block" : "none",
  };

  const extendedTooltipStyle = {
    position: "absolute",
    zIndex: 2000,
    padding: 16,
    top: position.top - 100,
    left: position.left + 20,
    display: position.top ? "block" : "none",
  };

  return ReactDOM.createPortal(
    <div
      className={s.tooltip}
      style={hasAdditionalData ? extendedTooltipStyle : initialTooltipStyle}
    >
      {hasAdditionalData ? extendedTooltip : initialTooltip}
    </div>,
    document.querySelector("#modal-root")
  );
}
