import Errors from 'components/FormItems/error/errors';
import config from '../../config';
import { store } from '../../index';
import { defaultOptions } from '../../services/userService';
import { isLegalAdmin } from 'utils/isLegalAdmin';

async function getLegalCasesCount() {
  const currentUser = store.getState().auth.currentUser;
  const legalAdmin = isLegalAdmin(currentUser);
  const response = await fetch(
    `${config.externalApi}/api/Widget/GetLegalCasesCount?loggedUser=${currentUser.userName}&isLegalAdmin=${legalAdmin}`,
    {
      ...defaultOptions,
      method: 'GET'
    }
  );

  const data = await response.json();
  return data;
}

async function getLegalHoldsCount() {
  const currentUser = store.getState().auth.currentUser;
  const legalAdmin = isLegalAdmin(currentUser);
  const response = await fetch(
    `${config.externalApi}/api/Widget/GetLegalHoldsCount?loggedUser=${currentUser.userName}&isLegalAdmin=${legalAdmin}`,
    {
      ...defaultOptions,
      method: 'GET'
    }
  );

  const data = await response.json();
  return data;
}

async function getContentSearchesCount() {
  const currentUser = store.getState().auth.currentUser;
  const legalAdmin = isLegalAdmin(currentUser);
  const response = await fetch(
    `${config.externalApi}/api/Widget/GetContentSearchesCount?loggedUser=${currentUser.userName}&isLegalAdmin=${legalAdmin}`,
    {
      ...defaultOptions,
      method: 'GET'
    }
  );

  const data = await response.json();
  return data;
}

async function getExportJobsCount() {
  const currentUser = store.getState().auth.currentUser;
  const legalAdmin = isLegalAdmin(currentUser);
  const response = await fetch(
    `${config.externalApi}/api/Widget/GetExportJobsCount?loggedUser=${currentUser.userName}&isLegalAdmin=${legalAdmin}`,
    {
      ...defaultOptions,
      method: 'GET'
    }
  );

  const data = await response.json();
  return data;
}

async function getLegalCasesPerStatus() {
  const currentUser = store.getState().auth.currentUser;
  const legalAdmin = isLegalAdmin(currentUser);
  const response = await fetch(
    `${config.externalApi}/api/Widget/GetLegalCasesPerStatus?loggedUser=${currentUser.userName}&isLegalAdmin=${legalAdmin}`,
    {
      ...defaultOptions,
      method: 'GET'
    }
  );

  const data = await response.json();
  return data;
}

async function getExportJobsByIsExported() {
  const currentUser = store.getState().auth.currentUser;
  const legalAdmin = isLegalAdmin(currentUser);
  const response = await fetch(
    `${config.externalApi}/api/Widget/GetExportJobsByIsExported?loggedUser=${currentUser.userName}&isLegalAdmin=${legalAdmin}`,
    {
      ...defaultOptions,
      method: 'GET'
    }
  );

  const data = await response.json();
  return data;
}

const actions = {
  doGetLegalCasesCount: () => async (dispatch) => {
    try {
      dispatch({
        type: 'LEGAL_CASES_COUNT_FETCH_STARTED'
      });

      const response = await getLegalCasesCount();

      dispatch({
        type: 'LEGAL_CASES_COUNT_FETCH_SUCCESS',
        payload: response
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'LEGAL_CASES_COUNT_FETCH_ERROR'
      });
    }
  },

  doGetLegalHoldsCount: () => async (dispatch) => {
    try {
      dispatch({
        type: 'LEGAL_HOLDS_COUNT_FETCH_STARTED'
      });

      const response = await getLegalHoldsCount();

      dispatch({
        type: 'LEGAL_HOLDS_COUNT_FETCH_SUCCESS',
        payload: response
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'LEGAL_HOLDS_COUNT_FETCH_ERROR'
      });
    }
  },

  doGetContentSearchesCount: () => async (dispatch) => {
    try {
      dispatch({
        type: 'CONTENT_SEARCHES_COUNT_FETCH_STARTED'
      });

      const response = await getContentSearchesCount();

      dispatch({
        type: 'CONTENT_SEARCHES_COUNT_FETCH_SUCCESS',
        payload: response
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'CONTENT_SEARCHES_COUNT_FETCH_ERROR'
      });
    }
  },

  doGetExportJobsCount: () => async (dispatch) => {
    try {
      dispatch({
        type: 'EXPORT_JOBS_COUNT_FETCH_STARTED'
      });

      const response = await getExportJobsCount();

      dispatch({
        type: 'EXPORT_JOBS_COUNT_FETCH_SUCCESS',
        payload: response
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'EXPORT_JOBS_COUNT_FETCH_ERROR'
      });
    }
  },

  doGetLegalCasesPerStatus: () => async (dispatch) => {
    try {
      dispatch({
        type: 'LEGAL_CASES_STATUS_FETCH_STARTED'
      });

      const response = await getLegalCasesPerStatus();

      dispatch({
        type: 'LEGAL_CASES_STATUS_FETCH_SUCCESS',
        payload: response
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'LEGAL_CASES_STATUS_FETCH_ERROR'
      });
    }
  },

  doGetExportJobsByStatus: () => async (dispatch) => {
    try {
      dispatch({
        type: 'EXPORT_JOBS_STATUS_FETCH_STARTED'
      });

      const response = await getExportJobsByIsExported();

      dispatch({
        type: 'EXPORT_JOBS_STATUS_FETCH_SUCCESS',
        payload: response
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'EXPORT_JOBS_STATUS_FETCH_ERROR'
      });
    }
  }
};

export default { ...actions };
