import React, { Component } from 'react';
import CustomColorPicker from 'components/ColorPicker/ColorPicker';
import config from 'config';
import actions from 'actions/layout';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

class ColorTheme extends Component {
  state = {
    activeColor: this.props.currentTheme
  };

  updateColor = (value) => {
    const { dispatch, currentUser } = this.props;
    dispatch(actions.doSetThemeColor(value, currentUser)).then(() => {
      this.setState({
        activeColor: value
      });
      toast.success('Color theme updated successfully',{ icon: false });
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.currentTheme !== this.props.currentTheme) {
      this.setState({
        activeColor: this.props.currentTheme
      });
    }
  }

  componentDidMount() {
    const { dispatch, currentUser } = this.props;
    dispatch(actions.doGetThemeColor(currentUser?.userName));
  }

  render() {
    return (
      <div>
        <CustomColorPicker
          colors={config.app.themeColors}
          updateColor={this.updateColor}
          activeColor={this.state.activeColor}
        />
      </div>
    );
  }
}
function mapStateToProps(store) {
  return {
    currentUser: store.auth.currentUser,
    currentTheme: store.layout.currentTheme
  };
}

export default connect(mapStateToProps)(ColorTheme);
