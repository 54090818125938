import React from 'react';
import ReactDOM from 'react-dom';
import s from 'components/Tooltip/Tooltip.module.scss';
import 'components/Tooltip/Tooltip.css';
import styles from './Wizard.module.scss';

export default function LongNameTooltip({ longName, position, isContainer = false }) {
  const tooltipStyle = {
    position: 'absolute',
    zIndex: 2000,
    padding: '10px',
    maxWidth: '250px',
    maxHeight: '100px',
    display: 'flex',
    alignItems: 'center',
    overflowX: 'scroll',
    wordBreak: 'break-word',
    top: position.top - 50,
    left: position.left + 20
  };

  return ReactDOM.createPortal(
    <div className={s.tooltip} style={tooltipStyle}>
      <p className={styles.longNameTooltip}>
        {isContainer && (longName === 'null' || longName === null)
          ? 'No description added'
          : longName}
      </p>
    </div>,
    document.querySelector('#modal-root')
  );
}
